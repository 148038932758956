import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import {
  ArrowButton,
  Text,
} from '@alj-react/ui-components';
import colors from '@alj-react/ui-components/styles/colors';

export const BottomNavDiv = styled.div`
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media only screen and (min-width: 550px) {
      flex-direction: row;
    }
`;

export const ArrowButtonStyled = styled(ArrowButton)`
  margin-bottom: 10px;
  @media only screen and (min-width: 550px) {
    margin-bottom: 0;
  }
`;

export const RadioButtonDiv = styled.div`
  position: relative;

  label {
    display: flex;
    font-weight: bold;
    border: 1px solid ${colors.axaBlue};
    margin-bottom: 10px;
    color: ${colors.darkGray};
    background-color: ${colors.white};
    font-size: 16px;
    margin: 0;
    padding: 8px 0 8px 24px;
    white-space: pre-wrap;
  }

  input[type="radio"] {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: 8px 0 8px 8px;

    &:checked + label {
      color: ${colors.white};
      background-color: ${colors.axaBlue};
    }
  }
`;

export const ScrollAreaDiv = styled.div<{ style?: any }>`
${({ style }): FlattenSimpleInterpolation =>
  style
  ? css`
      ${style}
  `
  : css`
      height: 300px;
      overflow: auto;
      border: solid 1px #BDBDBD;
      borderRadius: 5px;
      padding: 20px;
      backgroundColor: #ffffff;
      fontSize: 13px
  `}
`;

