import React, { useState } from 'react';
import {
  Text,
  TextInput,
  SelectField,
  TextInputProps,
  TableFormRequiredBadge,
} from '@alj-react/ui-components';
import FlowList from '@alj-react/ui-components/FlowList';
import FlowListItem from '@alj-react/ui-components/FlowListItem';
import { SHA3 } from 'sha3';
import publicIp from 'public-ip';
import _ from 'lodash';
import colors from '@alj-react/ui-components/styles/colors';
import Checkbox from '../components/molecules/CheckboxCustom';
import Aura from '../components/pages/Aura';
import {
  setBDisplayErrorSign,
  setCheckboxSelectedSign,
  setIsLoadingSign,
  setApiResultSign
} from '../utils/dataFetchers';
import styled from 'styled-components';
import TypeHidden from '../components/organisms/TypeHidden';
import TypeRemoteSelect from '../components/organisms/TypeRemoteSelect';
import DownloadBtn from '../components/organisms/DownloadBtn';
import TypeSelector from '../components/organisms/TypeSelector';
import { ScrollAreaDiv } from '../components/pages/ESignature.styles'
import TypeDatalist from '../components/organisms/TypeDatalist';

const URL_API = process.env.REACT_APP_URL_API || 'http://localhost:3001';

type SelectFieldStyledCompProps = {
  classic: boolean;
}

const SelectFieldStyledComp = styled(SelectField)<SelectFieldStyledCompProps>`
  & > div:first-child {
    padding-right: 0px;
  }

  div > label {
    border-radius: ${props => props.classic ? '0px' : '7px'};
  }
  `;

type SelectFieldStyledProps = {
  idx: number;
  subIdx: number | undefined;
  content: any;
  name: string;
};

const SelectFieldStyled = (props: SelectFieldStyledProps) => {
  const { name, idx, subIdx, content } = props;
  return (
    <div style={{
      ...(!content.classic && {
        border: '1px solid lightgray',
        padding: '1px',
      })
    }}>
      <SelectFieldStyledComp
        key={`select-${name}-${idx}-${subIdx}`}
        id={content.key}
        name={content.key}
        options={
          content.options?.map((x: string) => {
            return { label: x, value: x };
          }) || []
        }
        defaultValue={content.value}
        required={content.required === '1' || false}
        style={{ transition: 'none',  whiteSpace: 'pre-wrap', width: '100%', paddingRight: '0px' }}
        fullWidth={true}
        classic={content.classic}
      />
    </div>
  );
};

// we only need to set it first time module is loaded and should be same after
const currentDate = new Intl.DateTimeFormat('ja-JP-u-ca-japanese', {year:'numeric',month:'long',day:'numeric'}).format(Date.now());

type TextInputPostalCodeWithErrorMsgProps = {
  errorMsg?: string;
  content: any;
};
const TextInputPostalCodeWithErrorMsg = (props: TextInputProps & TextInputPostalCodeWithErrorMsgProps) => {
  const { className, errorMsg, content, style, ...others } = props;
  const [bDisplayError, setBDisplayError] = useState<boolean>(false);
  const [bDisplayError2, setBDisplayError2] = useState<boolean>(false);

  const handleChange1 = (e: React.FocusEvent<HTMLInputElement>) => {
    if (e.target.validity.valid === false)
    {
      setBDisplayError(true);
    } else {
      setBDisplayError(false);
    }
  };
  const handleChange2 = (e: React.FocusEvent<HTMLInputElement>) => {
    if (e.target.validity.valid === false)
    {
      setBDisplayError2(true);
    } else {
      setBDisplayError2(false);
    }
  };

  return (
    <div style={style} className={className} >
      <div style={{ display: 'flex', border: '1px solid lightgray', padding: '1px' }}>
        <TextInput
          name={content.key1}
          defaultValue={content.value1}
          pattern="^[0-9]{3}$"
          maxLength={3}
          inputMode='decimal'
          required={content.required === '1' || false}
          style={{ transition: 'none', width: '84px', minWidth: '84px', maxWidth: '84px', borderRadius: '7px' }}
          onChange={handleChange1}
          hasError={bDisplayError}
        />
        <Text style={{ transition: 'none', alignSelf: 'center', padding: '0px 10px' }}>-</Text>
        <TextInput
          name={content.key2}
          defaultValue={content.value2}
          pattern="^[0-9]{4}$"
          maxLength={4}
          inputMode='decimal'
          required={content.required === '1' || false}
          style={{ transition: 'none', width: '94px', minWidth: '94px', maxWidth: '94px', borderRadius: '7px' }}
          onChange={handleChange2}
          hasError={bDisplayError2}
        />
      </div>
      { (bDisplayError || bDisplayError2) && <Text className='errorMsg' color='shyTomato' style={{ transition: 'none',  whiteSpace: 'pre-wrap', paddingTop: '10px' }}>{content.errorMsg}</Text>}
    </div>
  );
}

type TextInputWithErrorMsgProps = {
  classic?: boolean;
  errorMsg?: string;
  isAutoComplete?: boolean;
}
const TextInputWithErrorMsg = (props: TextInputProps & TextInputWithErrorMsgProps) => {
  const {
    errorMsg,
    style,
    pattern,
    classic,
    isAutoComplete,
    ...others
  } = props;
  const [bDisplayError, setBDisplayError] = useState<boolean>(false);

  const handleChange = (e: React.FocusEvent<HTMLInputElement>) => {
    if (e.target.validity.valid === false)
    {
      setBDisplayError(true);
    } else {
      setBDisplayError(false);
    }
  };
  return (
    <div style={{
      ...(!classic && {
        border: '1px solid lightgray',
        padding: '1px',
      }),
      ...style,
    }}>
      <TextInput
        autoComplete={props.isAutoComplete ? 'off' : undefined}
        list={props.list}
        style={{ width: '100%', ...(!classic && { borderRadius: '7px' }) }}
        onChange={handleChange}
        onBlur={handleChange}
        hasError={bDisplayError}
        pattern={pattern}
        {...others}
      />
      { bDisplayError &&
        <Text
          className='errorMsg'
          color='shyTomato'
          style={{ transition: 'none', whiteSpace: 'pre-wrap', paddingTop: '10px' }}
        >
          {errorMsg}
        </Text>
      }
    </div>
  );
};

const onChecked = (e: React.ChangeEvent<HTMLInputElement>, dispatch: React.Dispatch<ContextAction>): void => {
  setCheckboxSelectedSign(dispatch, e.target.checked);
};

const formatCleaning = (str: string) => {
  // return String(str).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
  return String(str).replace(/<\?xml version.*?encoding.*?>\n/, '').replace(/\\/g, '');
};

const getComponent = (state: ContextState, dispatch: React.Dispatch<ContextAction>, restoreFormInputsToForm: () => void, content: Content, name: string, idx: number, subIdx?: number, inputResultList?: any) => {
  let res = <></>;

  const formattedInputResult = inputResultList?.reduce((accumulator: any, current: any) => {
    accumulator[current.formKey] = current.formContents.reduce((acc: any, curr: any) => {
      acc = { ...acc, ...curr };
      return acc;
    }, {});

    return accumulator;
  }, {});

  const childTypes = content.childTypes?.map((x: any, idx: number) => {
    return getComponent(state, dispatch, restoreFormInputsToForm, x, `childTypes-${content.key}`, idx, inputResultList);
  });

  switch (content.type.toLowerCase()) {
    case 'type01':
      res = <Text style={{ transition: 'none',  whiteSpace: 'pre-wrap' }} key={`${name}-${idx}-${subIdx}`} color='axaBlue' size="28">{content.value}</Text>
      break;
    case 'type02':
      res = <Text key={`${name}-${idx}-${subIdx}`} size="24" style={{ transition: 'none',  whiteSpace: 'pre-wrap', paddingTop: '10px' }}>{content.value}</Text>
      break;
    case 'type03':
      res = (
        <div key={`type03-${idx}-${subIdx}`} style={{ display: 'flex', alignItems: 'center' }}>
          <Text key={`${name}-${idx}-${subIdx}`} size="20" style={{ transition: 'none',  whiteSpace: 'pre-wrap', paddingTop: '10px', ...content.style }} color={content?.textColor}>{content.value}</Text>
          {content.requiredBadge && <TableFormRequiredBadge />}
        </div>
      )
      break;
    case 'type04':
      res = <Text key={`${name}-${idx}-${subIdx}`} style={{ transition: 'none',  whiteSpace: 'pre-wrap', backgroundColor: colors.wildSand, padding: '7px', borderLeft: '1px solid lightgray', borderTop: '1px solid lightgray', borderRight: '1px solid lightgray', borderBottom: '1px solid lightgray', fontWeight: 'bold', ...content.style }}>{content.value}</Text>
      break;
    case 'type05':
      res = <Text key={`${name}-${idx}-${subIdx}`} style={{ transition: 'none',  whiteSpace: 'pre-wrap', backgroundColor: 'white', padding: '7px', border: '1px solid lightgray' }}>{content.value}</Text>
      break;
    case 'type06':
      res = <Text key={`${name}-${idx}-${subIdx}`} style={{ transition: 'none',  whiteSpace: 'pre-wrap', paddingTop: '10px', paddingBottom: '10px', ...content.style }}>{content.value}</Text>
      break;
    case 'type07':
      res = <Text key={`${name}-${idx}-${subIdx}`} style={{ transition: 'none',  whiteSpace: 'pre-wrap', backgroundColor: 'lightYellow', padding: '7px', marginBottom: '0px', fontWeight: 'bold', border: '1px solid lightgray' }}>{content.value}</Text>
      break;
    case 'type08':
      res = <div key={`type08-${idx}-${subIdx}`} style={{ display: 'flex' }}>
        <Text key={`${name}-${idx}-${subIdx}-1`} style={{ transition: 'none',  whiteSpace: 'pre-wrap', flex:'0 0 5%', backgroundColor: 'white', padding: '7px', border: '1px solid lightgray' }}>{content.value1}</Text>
        <Text key={`${name}-${idx}-${subIdx}-2`} style={{ transition: 'none',  whiteSpace: 'pre-wrap', flex:'0 0 70%', backgroundColor: 'white', padding: '7px', border: '1px solid lightgray' }}>{content.value2}</Text>
        <Text key={`${name}-${idx}-${subIdx}-3`} style={{ transition: 'none',  whiteSpace: 'pre-wrap', flex:'0 0 25%', backgroundColor: 'white', padding: '7px', border: '1px solid lightgray' }}>{content.value3}</Text>
      </div>
      break;
    case 'type09':
      res = <div key={`type09-${idx}-${subIdx}`} style={{ display: 'flex' }}>
        <Text key={`${name}-${idx}-${subIdx}-1`} style={{ transition: 'none',  whiteSpace: 'pre-wrap', flex:'0 0 40%', backgroundColor: colors.wildSand, padding: '7px', borderLeft: '1px solid lightgray', borderTop: '1px solid lightgray', borderBottom: '1px solid lightgray', fontWeight: 'bold' }}>{content.value1}</Text>
        <Text key={`${name}-${idx}-${subIdx}-2`} style={{ transition: 'none',  whiteSpace: 'pre-wrap', flex:'0 0 15%', backgroundColor: colors.wildSand, padding: '7px', borderLeft: '1px solid lightgray', borderTop: '1px solid lightgray', borderBottom: '1px solid lightgray', fontWeight: 'bold' }}>{content.value2}</Text>
        <Text key={`${name}-${idx}-${subIdx}-3`} style={{ transition: 'none',  whiteSpace: 'pre-wrap', flex:'0 0 15%', backgroundColor: colors.wildSand, padding: '7px', borderLeft: '1px solid lightgray', borderTop: '1px solid lightgray', borderBottom: '1px solid lightgray', fontWeight: 'bold' }}>{content.value3}</Text>
        <Text key={`${name}-${idx}-${subIdx}-4`} style={{ transition: 'none',  whiteSpace: 'pre-wrap', flex:'0 0 30%', backgroundColor: colors.wildSand, padding: '7px', borderLeft: '1px solid lightgray', borderTop: '1px solid lightgray', borderBottom: '1px solid lightgray', borderRight: '1px solid lightgray', fontWeight: 'bold' }}>{content.value4}</Text>
      </div>
      break;
    case 'type10':
      res = <div key={`type10-${idx}-${subIdx}`} style={{ display: 'flex' }}>
        <Text key={`${name}-${idx}-${subIdx}-1`} style={{ transition: 'none',  whiteSpace: 'pre-wrap', flex:'0 0 40%', backgroundColor: 'white', padding: '7px', borderLeft: '1px solid lightgray', borderBottom: '1px solid lightgray' }}>{content.value1}</Text>
        <Text key={`${name}-${idx}-${subIdx}-2`} style={{ transition: 'none',  whiteSpace: 'pre-wrap', flex:'0 0 15%', backgroundColor: 'white', padding: '7px', borderLeft: '1px solid lightgray', borderBottom: '1px solid lightgray' }}>{content.value2}</Text>
        <Text key={`${name}-${idx}-${subIdx}-3`} style={{ transition: 'none',  whiteSpace: 'pre-wrap', flex:'0 0 15%', backgroundColor: 'white', padding: '7px', borderLeft: '1px solid lightgray', borderBottom: '1px solid lightgray' }}>{content.value3}</Text>
        <Text key={`${name}-${idx}-${subIdx}-4`} style={{ transition: 'none',  whiteSpace: 'pre-wrap', flex:'0 0 30%', backgroundColor: 'white', padding: '7px', borderLeft: '1px solid lightgray', borderBottom: '1px solid lightgray', borderRight: '1px solid lightgray' }}>{content.value4}</Text>
      </div>
      break;
    case 'type11':
      res = <div key={`type11-${idx}-${subIdx}`} style={{ display: 'flex' }}>
        <Text key={`${name}-${idx}-${subIdx}-1`} style={{ transition: 'none',  whiteSpace: 'pre-wrap', flex:'0 0 70%', backgroundColor: colors.wildSand, padding: '7px', borderLeft: '1px solid lightgray', borderTop: '1px solid lightgray', borderBottom: '1px solid lightgray', fontWeight: 'bold' }}>{content.value1}</Text>
        <Text key={`${name}-${idx}-${subIdx}-2`} style={{ transition: 'none',  whiteSpace: 'pre-wrap', flex:'0 0 15%', backgroundColor: colors.wildSand, padding: '7px', borderLeft: '1px solid lightgray', borderTop: '1px solid lightgray', borderBottom: '1px solid lightgray', fontWeight: 'bold' }}>{content.value2}</Text>
        <Text key={`${name}-${idx}-${subIdx}-3`} style={{ transition: 'none',  whiteSpace: 'pre-wrap', flex:'0 0 15%', backgroundColor: colors.wildSand, padding: '7px', borderLeft: '1px solid lightgray', borderTop: '1px solid lightgray', borderBottom: '1px solid lightgray', borderRight: '1px solid lightgray', fontWeight: 'bold' }}>{content.value3}</Text>
      </div>
      break;
    case 'type12':
      res = <div key={`type12-${idx}-${subIdx}`} style={{ display: 'flex' }}>
        <Text key={`${name}-${idx}-${subIdx}-1`} style={{ transition: 'none',  whiteSpace: 'pre-wrap', flex:'0 0 70%', backgroundColor: 'white', padding: '7px', borderLeft: '1px solid lightgray', borderBottom: '1px solid lightgray', }}>{content.value1}</Text>
        <Text key={`${name}-${idx}-${subIdx}-2`} style={{ transition: 'none',  whiteSpace: 'pre-wrap', flex:'0 0 15%', backgroundColor: 'white', padding: '7px', borderLeft: '1px solid lightgray', borderBottom: '1px solid lightgray' }}>{content.value2}</Text>
        <Text key={`${name}-${idx}-${subIdx}-3`} style={{ transition: 'none',  whiteSpace: 'pre-wrap', flex:'0 0 15%', backgroundColor: 'white', padding: '7px', borderLeft: '1px solid lightgray', borderBottom: '1px solid lightgray', borderRight: '1px solid lightgray' }}>{content.value3}</Text>
      </div>
      break;
    case 'type13':
      res = <div key={`type13-${idx}-${subIdx}`} style={{ display: 'flex' }}>
        <Text key={`${name}-${idx}-${subIdx}-1`} style={{ transition: 'none',  whiteSpace: 'pre-wrap', flex:'0 0 85%', backgroundColor: colors.wildSand, padding: '7px', borderLeft: '1px solid lightgray', borderTop: '1px solid lightgray', borderBottom: '1px solid lightgray', fontWeight: 'bold' }}>{content.value1}</Text>
        <Text key={`${name}-${idx}-${subIdx}-2`} style={{ transition: 'none',  whiteSpace: 'pre-wrap', flex:'0 0 15%', backgroundColor: colors.wildSand, padding: '7px', borderLeft: '1px solid lightgray', borderTop: '1px solid lightgray', borderBottom: '1px solid lightgray', borderRight: '1px solid lightgray', fontWeight: 'bold' }}>{content.value2}</Text>
      </div>
      break;
    case 'type14':
      res = <div key={`type14-${idx}-${subIdx}`} style={{ display: 'flex' }}>
        <Text key={`${name}-${idx}-${subIdx}-1`} style={{ transition: 'none',  whiteSpace: 'pre-wrap', flex:'0 0 85%', backgroundColor: 'white', padding: '7px', borderLeft: '1px solid lightgray', borderBottom: '1px solid lightgray', }}>{content.value1}</Text>
        <Text key={`${name}-${idx}-${subIdx}-2`} style={{ transition: 'none',  whiteSpace: 'pre-wrap', flex:'0 0 15%', backgroundColor: 'white', padding: '7px', borderLeft: '1px solid lightgray', borderBottom: '1px solid lightgray', borderRight: '1px solid lightgray' }}>{content.value2}</Text>
      </div>
      break;
    case 'type15':
      res = <Text style={{ transition: 'none',  whiteSpace: 'pre-wrap', textDecoration: 'underline', textUnderlineOffset: '5px' }} key={`${name}-${idx}-${subIdx}`} color='axaBlue' size="28">{content.value}</Text>
      break;
    case 'type16':
      res = <div key={`type16-${idx}-${subIdx}`} style={{ display: 'flex' }}>
        <Text key={`${name}-${idx}-${subIdx}-1`} style={{ transition: 'none',  whiteSpace: 'pre-wrap', flex:'0 0 30%', backgroundColor: 'white', padding: '7px', borderTop: '1px solid lightgray', borderLeft: '1px solid lightgray', borderBottom: '1px solid lightgray', }}>{content.value1}</Text>
        <Text key={`${name}-${idx}-${subIdx}-2`} style={{ transition: 'none',  whiteSpace: 'pre-wrap', flex:'0 0 70%', backgroundColor: 'white', padding: '7px', borderTop: '1px solid lightgray', borderLeft: '1px solid lightgray', borderBottom: '1px solid lightgray', borderRight: '1px solid lightgray' }}>{content.value2}</Text>
      </div>
      break;
    case 'type17':
      res = <div key={`type17-${idx}-${subIdx}`} style={{ display: 'flex' }}>
        <Text key={`${name}-${idx}-${subIdx}-1`} style={{ transition: 'none',  whiteSpace: 'pre-wrap', flex:'0 0 50%', backgroundColor: colors.wildSand, padding: '7px', borderLeft: '1px solid lightgray', borderTop: '1px solid lightgray', borderBottom: '1px solid lightgray', fontWeight: 'bold' }}>{content.value1}</Text>
        <Text key={`${name}-${idx}-${subIdx}-2`} style={{ transition: 'none',  whiteSpace: 'pre-wrap', flex:'0 0 50%', backgroundColor: colors.wildSand, padding: '7px', borderLeft: '1px solid lightgray', borderTop: '1px solid lightgray', borderBottom: '1px solid lightgray', borderRight: '1px solid lightgray', fontWeight: 'bold' }}>{content.value2}</Text>
      </div>
      break;
    case 'type18':
      res = <div key={`type18-${idx}-${subIdx}`} style={{ display: 'flex' }}>
        <Text key={`${name}-${idx}-${subIdx}-1`} style={{ transition: 'none',  whiteSpace: 'pre-wrap', flex:'0 0 50%', backgroundColor: 'white', padding: '7px', borderLeft: '1px solid lightgray', borderBottom: '1px solid lightgray', }}>{content.value1}</Text>
        <Text key={`${name}-${idx}-${subIdx}-2`} style={{ transition: 'none',  whiteSpace: 'pre-wrap', flex:'0 0 50%', backgroundColor: 'white', padding: '7px', borderLeft: '1px solid lightgray', borderBottom: '1px solid lightgray', borderRight: '1px solid lightgray' }}>{content.value2}</Text>
      </div>
      break;
    case 'type19':
      res = <div key={`type19-${idx}-${subIdx}`} style={{ display: 'flex', flexDirection: 'column' }}>
        <Text key={`${name}-${idx}-${subIdx}-1`} style={{ transition: 'none',  whiteSpace: 'pre-wrap', backgroundColor: 'white', padding: '7px 7px 0px 7px', borderLeft: '1px solid lightgray', borderRight: '1px solid lightgray', }}>{content.value1}</Text>
        <Text key={`${name}-${idx}-${subIdx}-2`} style={{ transition: 'none',  whiteSpace: 'pre-wrap', color: `${colors.axaRed}`, backgroundColor: 'white', padding: '0px 7px 7px 7px', borderLeft: '1px solid lightgray', borderBottom: '1px solid lightgray', borderRight: '1px solid lightgray' }}>{content.value2}</Text>
      </div>
      break;
    case 'type20':
      res = <div key={`type20-${idx}-${subIdx}`} style={{ display: 'flex' }}>
        <Text key={`${name}-${idx}-${subIdx}-1`} style={{ transition: 'none',  whiteSpace: 'pre-wrap', width: '40px', backgroundColor: 'white', padding: '7px', borderLeft: '1px solid lightgray', borderBottom: '1px solid lightgray', }}>{content.value1}</Text>
        <Text key={`${name}-${idx}-${subIdx}-2`} style={{ transition: 'none',  whiteSpace: 'pre-wrap', width: '100%',backgroundColor: 'white', padding: '7px', borderLeft: '1px solid lightgray', borderBottom: '1px solid lightgray', borderRight: '1px solid lightgray' }}>{content.value2}</Text>
      </div>
      break;
    case 'type21':
      const localContent = content as { type: string, values: string[], styles: { colWidth: string, backColor: string, font?: { color?: string, bold?: boolean, size?: string } }[], closeTop?: boolean, hideIfTargetBlank?: string };
      const idxLastItem = localContent.values.length - 1;
      res = <div key={`type21a-${idx}-${subIdx}`} style={{ display: 'flex', flexDirection: 'column' }}>
        {
          localContent.closeTop && <span key={`${name}-${idx}-${subIdx}`} style={{ transition: 'none', width: '100%', borderBottom: '1px solid lightgray' }}></span>
        }
        <div key={`type21b-${idx}-${subIdx}`} style={{ display: 'flex' }}>
          {
            localContent.values.map((val, idxLocal) => {
              const targetVal = _.get(formattedInputResult, val) || val;

              if (localContent?.hideIfTargetBlank
                && !_.get(formattedInputResult, localContent.hideIfTargetBlank)) {
                return;
              }

              if (idxLocal === idxLastItem) {
                return <Text key={`${name}-${idx}-${subIdx}-${idxLocal}`} style={{ transition: 'none',  whiteSpace: 'pre-wrap', width: localContent.styles[idxLocal].colWidth, backgroundColor: localContent.styles[idxLocal].backColor, padding: '7px', borderLeft: '1px solid lightgray', borderBottom: '1px solid lightgray', borderRight: '1px solid lightgray', color: localContent.styles[idxLocal].font?.color, fontWeight: localContent.styles[idxLocal].font?.bold ? 'bold' : 'inherit', fontSize: localContent.styles[idxLocal].font?.size }}>{targetVal}</Text>
              }

              return <Text key={`${name}-${idx}-${subIdx}-${idxLocal}`} style={{ transition: 'none',  whiteSpace: 'pre-wrap', width: localContent.styles[idxLocal].colWidth, backgroundColor: localContent.styles[idxLocal].backColor, padding: '7px', borderLeft: '1px solid lightgray', borderBottom: '1px solid lightgray', color: localContent.styles[idxLocal].font?.color, fontWeight: localContent.styles[idxLocal].font?.bold ? 'bold' : 'inherit', fontSize: localContent.styles[idxLocal].font?.size }}>{targetVal}</Text>
            })
          }
        </div>
      </div>
      break;
    case 'form':
      res = <form id={content.key} key={`form-${content.key}`}>
        {
          content.formContents.map((x: any, idx: number) => {
            return getComponent(state, dispatch, restoreFormInputsToForm, x, `form-${content.key}`, idx);
          })
        }
      </form>
      break;
    case 'type22':
      res = <TextInputWithErrorMsg
        key={`type22-${name}-${idx}-${subIdx}`}
        name={content.key}
        defaultValue={content.value}
        type={content.valueType}
        maxLength={Number(content?.maxLength) || undefined}
        inputMode={content.inputMode}
        required={content.required === '1' || false}
        style={{ transition: 'none',  whiteSpace: 'pre-wrap' }}
        classic={content.classic}
        errorMsg={content.errorMsg}
        pattern={content.pattern}
        readOnly={content.readOnly}
        hidden={content.hidden}
        list={content.list}
        isAutoComplete={content.isAutoComplete}
      />
      break;
    case 'type23':
      res = <TextInputPostalCodeWithErrorMsg
        key={`type23-container-${name}-${idx}-${subIdx}`}
        content={content as any}
        errorMsg={content.errorMsg}
        // style={{ transition: 'none',  whiteSpace: 'pre-wrap', width: '100%' }}
      />
      break;
    case 'typeremoteselect':
      const localContentForTypeRemoteSelect = content as { type: string, key: string, endpoint?: string, defaultOptions?: any[], errorTxt?: string };

      if (!localContentForTypeRemoteSelect.key) {
        return <></>;
      }

      res = <TypeRemoteSelect
        key={`form-${idx}-${subIdx}`}
        idx={idx}
        name={localContentForTypeRemoteSelect.key}
        defaultOptions={localContentForTypeRemoteSelect.defaultOptions}
        endpoint={localContentForTypeRemoteSelect?.endpoint}
        errorTxt={localContentForTypeRemoteSelect?.errorTxt}
        restoreFormInputsToForm={restoreFormInputsToForm}
      />
      break;
    case 'typeselector':
      if (!content.key) return res = <></>;
      if (!content.mapping) return res = <></>;
      const selector = getComponent(state, dispatch, restoreFormInputsToForm, content.selector, `selector-${content.key}`, 0);
      const selectorOptions = content.selector.options;
      const mapping = selectorOptions.map((x: any) => content.mapping[x].map((y: any, idx: number) => { return getComponent(state, dispatch, restoreFormInputsToForm, y, `selected-${x}`, idx) }) );
      res = <TypeSelector key={`typeSelector-${name}-${idx}-${subIdx}`} idName={`typeSelector-${name}-${idx}-${subIdx}`} selector={selector} mapping={mapping} keyElementToWatch={content.key} />
      break;
    case 'typehidden':
      if (!content.key) return res = <></>;
      if (!content.value) return res = <></>;
      const visibleChildren = content.visibleContents.map((x: any, idx: number) => {
              return getComponent(state, dispatch, restoreFormInputsToForm, x, `visible-${content.key}`, idx);
            });
      const hiddenChildren = content.hiddenContents.map((x: any, idx: number) => {
              return getComponent(state, dispatch, restoreFormInputsToForm, x, `hidden-${content.key}`, idx);
            });
      res = <TypeHidden key={`typeHidden-${name}-${idx}-${subIdx}`} idName={`typeHidden-${name}-${idx}-${subIdx}`} visibleChildren={visibleChildren} hiddenChildren={hiddenChildren} keyElementToWatch={content.key} valueElementToWatch={content.value}/>
      break;
    case 'typedatalist':
      const localContentForTypeDatalist = content as {
        type: string,
        childTypes: any,
        endpoint: string,
        idName: string,
        sourceName: string,
        hiddenSourceName: string,
        targetName: string,
        hiddenTargetName: string,
        errorTxt: string
      }

      if (!content.key) return res = <></>;
      if (!content.childTypes) return res = <></>;

      res = <TypeDatalist
        key={`typeDatalist-${name}-${idx}-${subIdx}`}
        idName={`typeDatalist-${name}-${idx}-${subIdx}`}
        childTypes={childTypes}
        sourceName={localContentForTypeDatalist.sourceName}
        hiddenSourceName={localContentForTypeDatalist.hiddenSourceName}
        targetName={localContentForTypeDatalist.targetName}
        hiddenTargetName={localContentForTypeDatalist.hiddenTargetName}
        endpoint={localContentForTypeDatalist.endpoint}
        errorTxt={localContentForTypeDatalist.errorTxt}
      />
      break;
    case 'currentdate':
      res = <Text key={`typeCurrentDate-${name}-${idx}-${subIdx}`} style={{ transition: 'none',  whiteSpace: 'pre-wrap', backgroundColor: 'white', padding: '7px', border: '1px solid lightgray' }}>{currentDate}</Text>
      break;
    case 'select':
      res = <SelectFieldStyled key={`typeSelect-${name}-${idx}-${subIdx}`} name={name} idx={idx} subIdx={subIdx} content={content} />
      break;
    case 'image':
      res = <div key={`imageContainer-${idx}-${subIdx}`} style={{ display: 'flex', justifyContent: 'center', margin: '20px' }}><img alt='img' style={{ border: '1px solid lightgray', maxWidth: 'calc(100vw - 30px)' }} src={content.value}/></div>;
      break;
    case 'checkbox':
      // console.debug('Display checkbox style: ', isDoingAuraStuff ? (auraXmlOutput ? 'block' : 'none') : 'block');
      // console.debug('isDoingAuraStuff', isDoingAuraStuff);
      // console.debug('auraXmlOutput', auraXmlOutput);
      res = <div key={`checkboxContainer-${idx}-${subIdx}`} style={{ display: state.isDoingAuraStuff ? (state.auraXmlOutput ? 'block' : 'none') : 'block', paddingTop: '30px', paddingBottom: '30px' }}><Checkbox key={`cb-${idx}-${subIdx}`} id="checkAgreed" label={content.value || '-'} checked={state.checkboxSelected} name="agreement" onChange={(e: React.ChangeEvent<HTMLInputElement>): void => onChecked (e, dispatch)} /></div>
      break;
    case 'aura':
      res = content.value
        ? <Aura key={`aura-${idx}-${subIdx}`} inputXml={formatCleaning(content.value)} />
        : <Text>Aura input XML is empty</Text>
      break;
    case 'stepflow':
      res = <div key={`step-flow-container-${idx}-${subIdx}`} style={{ margin: '10px 0 50px 0' }}>
          <FlowList key={`flow-list-container-${idx}-${subIdx}`}>
            {
              content.steps?.map((step: string, index: number) => {
                return <FlowListItem key={`flow-list-item-${idx}-${subIdx}-${index}`} title={step}></FlowListItem>
              })
            }
          </FlowList>
      </div>
      break;
    case 'downloadbtn':
      res = <DownloadBtn
              downloadBtnTxt={content?.downloadBtnTxt}
              fileUrl={content?.fileUrl}
              fileName={content?.fileName}
            />
      break;
    case 'ol':
        res = <ol style={{...content?.style}}>{childTypes}</ol>
        break;
    case 'ul':
        res = <ul style={{...content?.style}}>{childTypes}</ul>
      break;
    case 'li':
      res = (
        <>
          <li style={{...content?.style}}>{content.value}</li>
          {childTypes}
        </>
      )
      break;
    case 'scrollarea':
      res = <ScrollAreaDiv style={content?.style}>{childTypes}</ScrollAreaDiv>
      break;
    case 'linktxt':
      if (!content) return <></>;

      const { text, links } = content;

      if (!text || !links) return <></>;

      // Turns a string with placeholders into an array.
      // e.g. 'hello {{foo}} world {{bar}}' -> ['hello ', '{{foo}}', ' world ', '{{bar}}']
      const stringList = text.split(/({{\w+}})/g);

      const linkifiedText = stringList.map((value: string) => {
        // Returns the current element if not formatted as a placeholder, e.g. {{myPlaceholder}}.
        if (!value.match(/{{(\w+)}}/g)) return value;

        // Clean up placeholder
        // e.g. {{myPlaceholder}} -> myPlaceholder
        const placeholder = value.replace(/[{}]/g, '');
        const linkInfo  = links[placeholder];

        // Search for a key named myPlaceholder in the links object,
        // and return the current string if nothing found.
        if (!linkInfo) return value;

        const [label, href] = linkInfo;

        return <a href={href} target="_blank" rel="noopener noreferrer">{label}</a>;
      });

      res = (
        <span style={{...content.style}}>
          {linkifiedText}
        </span>
      )
      break;
      case 'typehiddeninput':
        res = (
          <input
            id={`typeHiddenInput-${name}-${idx}-${subIdx}`}
            key={`typeHiddenInput-${name}-${idx}-${subIdx}`}
            name={content.key}
            style={{
              display: 'block',
              visibility: 'hidden',
              height: 0,
              padding: 0,
              border: 0,
            }}
            value={content.value}
          />
        )
      break;
    default:
      break;
  }
  return res;
};

const getSignType = (valToFind: string, contents?: { pageNo: number, pageContents: any[] }[], currentPage?: number) => {
  let res = false;
  contents?.forEach((content) => {
    content.pageContents.forEach((page: {type: string, value: string}, idx: number) => {
      switch (page.type.toLowerCase()) {
        case valToFind.toLowerCase():
          if (currentPage) {
            if (content.pageNo === currentPage) {
              res = true;
              // console.debug('Aura is present on this page');
            } else {
              // console.debug('Aura is present in payload but not on this page');
            }
          } else {
            res = true;
          }
          break;
        default:
          break;
      }
    });
  });
  return res;
};

const getCurrentPageContent = (currentPage: number, contents?: PageContent[]): any => (contents?.find((content: PageContent) => (currentPage === content.pageNo)));

const getTransitionBtnTxt = (targetBtnType: 'goToPrevPageBtnTxt' | 'goToNextPageBtnTxt', currentPage: number, contents?: PageContent[]): string | undefined => {
  return getCurrentPageContent(currentPage, contents)?.[targetBtnType];
};

const sendToApi = async (state: ContextState, dispatch: React.Dispatch<ContextAction>, isCancelled: React.MutableRefObject<boolean>, priorC? : boolean) => {
  // Create the hash of the signed content to be verified by server/lambda before saving data
  const hash = new SHA3(512);
  hash.update(JSON.stringify(state.dataToDisplay));
  const hashString = hash.digest('hex');
  console.log('Input data to display to be signed is ', JSON.stringify(state.dataToDisplay));
  console.log('Hash (SHA3) of the input data to displayed signed is ', hashString);
  try {
    // Check if doing aura stuff and auraXmlOutput is not empty
    if (getSignType('aura', state.dataToDisplay?.Contents)) {
      if (state.auraXmlOutput === undefined || state.auraXmlOutput === '') {
        throw new Error('The Aura XML Output is empty !');
      }
    }
    const body = {
      image: state.signature,
      auraXmlOutput: state.auraXmlOutput,
      createdAt: new Date().toISOString(),
      clientInfo: {
        userAgent: navigator.userAgent,
        platform: navigator.platform,
        vendor: navigator.vendor,
        languages: navigator.languages,
        ip: await publicIp.v4({
          fallbackUrls: [
              'https://ifconfig.co/ip',
          ],
        }).catch(() => '???'),
      },
      planId: state.queryParameters.planId,
      hashString,
      priorConsent: priorC, // || false,
      inputResultList: state.inputResultList,
    };
    const reqOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: state.queryParameters.token,
      },
      body: JSON.stringify(body),
    }
    console.log('Will save following: ', body);
    const response = await fetch(`${URL_API}/signature`, reqOptions);
    // console.log(response);
    // 401 is a valid response so it will not go in catch !
    const responseData = await response.json();
    console.log(responseData);
    if (!isCancelled.current) {
      if (responseData.status === 200) {
        setApiResultSign(dispatch, responseData);
        console.log('saved result in local state');
      } else if (responseData.statusCode === 401) {
        setApiResultSign(dispatch, { msg: 'Unauthorized, please check the token value', status: 401, payload: undefined });
        setBDisplayErrorSign(dispatch, true);
      } else {
        setApiResultSign(dispatch, responseData);
        setBDisplayErrorSign(dispatch, true);
      }
    } else {
      console.log('canceled and fetch res is ignored !');
    }
  } catch (error: any) {
    console.error(error);
    if (error.message === 'The Aura XML Output is empty !') {
      setApiResultSign(dispatch, { msg: 'Payload incomplete (Aura XML output empty)', status: 500, payload: undefined });
      setBDisplayErrorSign(dispatch, true);
    } else {
      if (!isCancelled.current) {
        setApiResultSign(dispatch, error.message);
      } else {
        console.log('canceled and fetch res is ignored !');
      }
    }
  }
  isCancelled.current = false;
  setIsLoadingSign(dispatch, false);
};

export {
  getComponent,
  getSignType,
  sendToApi,
  getTransitionBtnTxt,
  URL_API
};
