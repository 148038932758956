import React, { useContext, useState } from "react";
import { useAllQuestions } from "../../hooks/useAllQuestions";
import { EsignatureTemplate } from "../templates/EsignatureTemplate";
import { RootCard } from "../organisms/RootCard";
import { ProgressBar } from "../atoms/ProgressBar";
import { Text } from "@alj-react/ui-components";
import { UnsuccessfulRequest } from "../organisms/UnsuccessfulRequest";
import styled from "styled-components";
import { SubmitButton } from "../atoms/SubmitButton";
import { isIndexableQuestion } from "../../utils/questionHandler";
import { useAuth } from "../../hooks/useAuth";
import { auraServiceApi } from "../../utils/AuraApi";
import { setAuraXmlOutputSign } from "../../utils/dataFetchers";
import { Context } from "../../utils/ESignatureContext";
import { useSession } from "../../hooks/useSession";
import { StatusUpdateFailure } from "../../apis";
import { extractErrMsg } from "../../utils/errorHandler";

export type Credentials = {
  accessToken: string;
  sessionId: string;
  clientId: string;
  tenant: string;
  etag: string;
  setEtag: React.Dispatch<string>;
};

export type Statistics = {
  updateQuestionCount: (count: number) => void;
  updateAnsweredCount: (count: number) => void;
  updateInputErrorCount: (key: number, count: number) => void;
  conflictIds: Array<number>;
};

export const CredentialsContext = React.createContext({} as Credentials);

export const StatisticsContext = React.createContext({} as Statistics);

type Props = {
  inputXml: string;
};
const Aura: React.FC<Props> = ({ inputXml }) => {
  const { dispatch, state } = useContext(Context);
  const [answerable, setAnswerable] = useState(true);
  const [forConfirmation, setForConfirmation] = useState(false);
  const [conflictIds, setConflictIds] = useState<Array<number>>([]);

  if (forConfirmation !== !!state.auraXmlOutput) {
    setForConfirmation(!!state.auraXmlOutput);
  }

  const { data: authenticationData, ...authStatus } = useAuth(
    state.queryParameters.token
  );

  const accessToken = mapToStr(authenticationData?.data.access_token);

  const clientId = mapToStr(process.env.REACT_APP_AURA_CLIENT_ID);
  const tenant = mapToStr(process.env.REACT_APP_AURA_TENANT);

  const { data: sessionData, ...sessionStatus } = useSession(
    clientId,
    tenant,
    accessToken,
    inputXml,
    "ja_JP",
    authStatus.isSuccess
  );
  const sessionId = mapToStr(sessionData?.data.sessionId);

  const { data, ...status } = useAllQuestions(
    clientId,
    sessionId,
    accessToken,
    tenant,
    sessionStatus.isSuccess
  );

  const [etag, setEtag] = useState(data?.headers.etag);

  const [questionCount, setQuestionCount] = useState(0);
  const [answeredCount, setAnsweredCount] = useState(0);
  const [inputErrorDic, setInputErrorDic] = useState<{ [key: number]: number }>(
    {}
  );

  const [errorMsg, setErrorMsg] = useState<string>();
  const [submitting, setSubmitting] = useState(false);

  const submitHandler = async () => {
    setSubmitting(true);
    try {
      const { etag: newEtag, result } = await submit(
        etag || data?.headers.etag,
        clientId,
        sessionId,
        tenant,
        accessToken
      );
      setEtag(newEtag);
      setConflictIds([]);
      setAuraXmlOutputSign(dispatch, result);
      setForConfirmation(true);
      window.scrollTo({ left: 0, top: 0, behavior: "smooth" });
    } catch (err) {
      const anyErr: any = err;
      if (anyErr.response?.status === 422) {
        const questionIds: Array<number> = [];
        (anyErr.response.data as StatusUpdateFailure).errors.forEach(
          ({ earlierDate, laterDate }) => {
            questionIds.push(earlierDate.questionId);
            questionIds.push(laterDate.questionId);
          }
        );
        setConflictIds(questionIds);
      } else {
        setErrorMsg(extractErrMsg(anyErr));
      }
    }
    setSubmitting(false);
  };

  return status.isSuccess ? (
    <EsignatureTemplate>
      {!forConfirmation && (
        <>
          <InformHead>告知</InformHead>
          <InformText>
            下記の質問事項にご回答のうえ、「告知しました」ボタンを押してください
          </InformText>
        </>
      )}
      <CredentialsContext.Provider
        value={{
          accessToken,
          sessionId,
          clientId,
          tenant,
          etag: etag || data?.headers.etag,
          setEtag,
        }}
      >
        <StatisticsContext.Provider
          value={{
            updateQuestionCount: (updateCount) =>
              setQuestionCount((count) => count + updateCount),
            updateAnsweredCount: (updateCount) =>
              setAnsweredCount((count) => count + updateCount),
            updateInputErrorCount: (key, count) =>
              setInputErrorDic((dic) => {
                dic[key] = count;
                return { ...dic };
              }),
            conflictIds,
          }}
        >
          {(() => {
            let index = 0;
            return data?.data
              .filter((question) => !question.parentQuestionId)
              .map((question) => {
                const isIndexable = isIndexableQuestion(question);
                if (isIndexable) {
                  index++;
                }
                return (
                  <RootCard
                    key={question.id}
                    question={question}
                    index={isIndexable ? index : 0}
                    confirmation={forConfirmation}
                    answerable={answerable}
                    updateAnswerable={(updateAnswerable) =>
                      setAnswerable(updateAnswerable)
                    }
                  />
                );
              });
          })()}
        </StatisticsContext.Provider>
        {!forConfirmation && (
          <BottomDiv>
            <ProgressBarSubmitContainer>
              <ProgressBar overall={questionCount} now={answeredCount} />
              <SubmitButton
                disabled={
                  questionCount !== answeredCount ||
                  !!Object.values(inputErrorDic).reduce((x, y) => x + y, 0) ||
                  !answerable
                }
                submitHandler={submitHandler}
                submitting={submitting}
              >
                告知しました
              </SubmitButton>
            </ProgressBarSubmitContainer>
          </BottomDiv>
        )}
      </CredentialsContext.Provider>
      {errorMsg && <ErrorBottomDiv>{errorMsg}</ErrorBottomDiv>}
    </EsignatureTemplate>
  ) : (
    <UnsuccessfulRequest statuses={[authStatus, sessionStatus, status]} />
  );
};

const mapToStr = (strOrUndefined?: string) => strOrUndefined || "";

const submit = async (
  etag: string,
  clientId: string,
  sessionId: string,
  tenant: string,
  accessToken: string
) => {
  const headers = { Authorization: `Bearer ${accessToken}` };
  const sessionRes = await auraServiceApi.updateSessionStatus(
    etag,
    clientId,
    sessionId,
    tenant,
    { submitted: true },
    {
      headers,
    }
  );
  await auraServiceApi.calculateRisk(clientId, sessionId, tenant, {
    headers,
  });

  const resultRes = await auraServiceApi.fetchInterviewResult(
    clientId,
    sessionId,
    tenant,
    {
      headers,
    }
  );

  const { interviewDetails, rulesResults } = resultRes.data;
  const trimedResult = {
    interviewDetails: interviewDetails.replaceAll(/>\s+</g, ">\n<"),
    rulesResults: rulesResults.replaceAll(/>\s+</g, ">\n<"),
  };

  return {
    etag: sessionRes.headers.etag,
    result: JSON.stringify(trimedResult),
  };
};

const ErrorBottomDiv = styled.div`
  color: red;
  display: flex;
  justify-content: end;
  font-weight: bold;
  padding-right: 3.5rem;
  margin-top: 1rem;
`;

const BottomDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 5%;
`;

const ProgressBarSubmitContainer = styled.div`
  width: 100%;
  max-width: 337px;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const InformHead = styled(Text)`
  padding: 10px 0 13px 0;
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
`;

const InformText = styled(Text)`
  line-height: 24px;
  padding: 10px 0 10px 0;
`;

export default Aura;
