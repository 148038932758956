import React, { useEffect, useState } from 'react';
import { RadioButtonDiv } from '../pages/ESignature.styles';
import Spinner from '../atoms/Spinner';
import colors from '@alj-react/ui-components/styles/colors';
import styled, { keyframes } from 'styled-components';
import LoadingSpinner from '../molecules/LoadingSpinner';
import { Context } from '../../utils/ESignatureContext';
import { setApiResultSign } from '../../utils/dataFetchers';

type TypeRemoteSelectProps = {
  idx: number,
  name: string,
  defaultOptions?: any[],
  endpoint?: string,
  errorTxt?: string,
  restoreFormInputsToForm: () => void,
};

const rotateAnimation = keyframes`
  to{ transform: rotate(360deg); }
`;

const SpinnerStyled = styled(Spinner)`
  vertical-align: middle;
  text-align: center;
  width: 54px;
  height: 54px;
  color: ${colors.axaBlue};

  animation-name: ${rotateAnimation};
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
`;

const TypeRemoteSelect: React.FC<TypeRemoteSelectProps> = (props) => {
  const {
    idx,
    name,
    endpoint,
    errorTxt,
    defaultOptions = [],
    restoreFormInputsToForm,
  } = props;

  const { dispatch, state } = React.useContext(Context);
  const [data, setData] = useState(defaultOptions);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    async function fetchData() {
      if (!endpoint) {
        setIsLoaded(true);
        return;
      }

      try {
        const reqOptions = {
          method: 'GET',
          headers: { Authorization: state.queryParameters.token },
        }
        const res = await fetch(endpoint, reqOptions);

        if (!res.ok) {
          console.log(res.status);
          throw new Error('Failed to fetch data.');
        }

        const jsonRes: any = await res.json();

        console.log("jsonRes", jsonRes);

        setData((prevData) => [...jsonRes, ...prevData]);
        setIsLoaded(true);
      } catch (error) {
        console.log(error);
        setApiResultSign(dispatch, { msg: errorTxt, status: 500, payload: { fetchFailed: true } });
        setIsLoaded(true);
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    if (isLoaded) {
      document.querySelector(`#input-radio-${idx}-0`)?.dispatchEvent(new Event('change', { bubbles: true, cancelable: true }));

      restoreFormInputsToForm();
    }
  }, [isLoaded]);


  if (!isLoaded) {
    return <LoadingSpinner loading={true}/>
  }

  return (
    <>
      {data.map((btnContent: any, subIndex: number) => {
        const {
          label,
          value,
          formValues,
        } = btnContent;
        const isFirstChild = subIndex === 0;
        const commonId = `${idx}-${subIndex}`;

        return (
          <RadioButtonDiv key={`typeRemoteSelect-${commonId}`} style={{ marginTop: isFirstChild ? 0 : '10px' }}>
            <input
              id={`input-radio-${commonId}`}
              name={name}
              type="radio"
              value={formValues ? JSON.stringify(formValues) : value}
              defaultChecked={isFirstChild}
            />
            <label
              htmlFor={`input-radio-${commonId}`}
            >
              {label}
            </label>
          </RadioButtonDiv>
        );
      })}
    </>
  );
};

export default TypeRemoteSelect;
