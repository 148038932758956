import { useQuery } from "react-query";
import { auraServiceApi } from "../utils/AuraApi";

export const useAllQuestions = (
  clientId: string,
  sessionId: string,
  accessToken: string,
  tenant: string,
  enabled?: boolean
) =>
  useQuery(
    ["fetchAllQuestion", accessToken, sessionId],
    () =>
      auraServiceApi.fetchQuestions(
        clientId,
        sessionId,
        tenant,
        undefined,
        undefined,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      ),
    { enabled, cacheTime: 0 }
  );
