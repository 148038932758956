import React, { useRef, useState } from "react";
import { Question, SeparableQuestion } from "../../apis";
import { isSeparableQuestion } from "../../utils/questionHandler";
import { Card } from "./Card";
import { Cards } from "./Cards";

type Props = {
  question: Question;
  index: number;
  confirmation: boolean;
  answerable: boolean;
  updateAnswerable: (answerable: boolean) => void;
};

export type Index = {
  indexMap: { [key: number]: number };
  updateIndexMap: (questionId?: number) => void;
};

export const IndexContext = React.createContext({} as Index);

export const RootCard: React.FC<Props> = ({
  question,
  index,
  confirmation,
  answerable,
  updateAnswerable,
}) => {
  const [indexMap, setIndexMap] = useState({});
  const ref = useRef<HTMLDivElement>(null);

  const updateIndexMap = (questionId?: number) => {
    if (ref.current) {
      const map: { [key: number]: number } = {};
      Array.from(ref.current.children)
        .filter(
          (child) => child.id && child.id !== "" && child.id !== `${questionId}`
        )
        .forEach((child, newIndex) => (map[parseInt(child.id)] = newIndex));
      setIndexMap(map);
    }
  };

  return (
    <IndexContext.Provider value={{ indexMap, updateIndexMap }}>
      <div ref={ref}>
        {(() => {
          if (isSeparableQuestion(question)) {
            return (
              <Cards
                initQuestion={question as SeparableQuestion}
                index={index}
                confirmation={confirmation}
                answerable={answerable}
                updateAnswerable={updateAnswerable}
              />
            );
          }

          return (
            <Card
              initQuestion={question}
              index={index}
              confirmation={confirmation}
              answerable={answerable}
              updateAnswerable={updateAnswerable}
            />
          );
        })()}
      </div>
    </IndexContext.Provider>
  );
};
