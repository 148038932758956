import React from 'react';
import {
  CheckRadioInputBase,
  CheckRadioInputInput,
  CheckRadioInputLabel,
  CheckRadioInputLabelText,
} from './CheckboxComponentsCustom';

export type CheckRadioInputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  /** Label text */
  label: string;
  /** Function triggered on change. Note: should be passed down from CheckRadioField component */
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  /** Set to true to have box checked by default */
  isCheckedDefault?: boolean;
  /** Set to true to have boxes aligned horizontally. Note: should be passed down from CheckRadioField component */
  displayInline?: boolean;
  /** Makes field disabled. Note: should be passed down from CheckRadioField component */
  disabled?: boolean;
  /** Shows error state */
  hasError?: boolean;
  /** Changes the style */
  variant?: 'default' | 'button';
  /** Type for the input */
  type: 'checkbox' | 'radio';
};

const CheckRadioInput: React.FC<CheckRadioInputProps> = (props) => {
  const {
    className,
    displayInline = false,
    hasError = false,
    id,
    disabled = false,
    label,
    name,
    onChange,
    type,
    value,
    variant = 'default',
    ...rest
  } = props;

  const inputId = id || `${name}-${value}`;

  return (
    <CheckRadioInputBase displayInline={displayInline} variant={variant} className={className}>
      <CheckRadioInputInput
        disabled={disabled}
        id={inputId}
        name={name}
        onChange={onChange}
        type={type}
        value={value}
        variant={variant}
        {...rest}
      />
      <CheckRadioInputLabel htmlFor={inputId} variant={variant} disabled={disabled} hasError={hasError} type={type}>
        <CheckRadioInputLabelText variant={variant}>{label}</CheckRadioInputLabelText>
      </CheckRadioInputLabel>
    </CheckRadioInputBase>
  );
};

CheckRadioInput.defaultProps = {
  disabled: false,
  displayInline: false,
  hasError: false,
  variant: 'default',
};

export default CheckRadioInput;
