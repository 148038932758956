import React from 'react';
import CheckRadioInput from './CheckRadioInput';

export type CheckboxProps = React.InputHTMLAttributes<HTMLInputElement> & {
  /** Label text */
  label: string;
  /** Function triggered on change. Note: should be passed down from CheckboxField component */
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  /** Set to true to have box checked by default */
  isCheckedDefault?: boolean;
  /** Set to true to have boxes aligned horizontally. Note: should be passed down from CheckboxField component */
  displayInline?: boolean;
  /** Makes field disabled. Note: should be passed down from CheckboxField component */
  disabled?: boolean;
  /** Shows error state */
  hasError?: boolean;
  /** Changes the style */
  variant?: 'default' | 'button';
};

const Checkbox: React.FC<CheckboxProps> = (props) => <CheckRadioInput {...props} type="checkbox" />;

Checkbox.defaultProps = {
  disabled: false,
  displayInline: false,
  hasError: false,
  isCheckedDefault: false,
  variant: 'default',
};

export default React.memo(Checkbox);
