import { useQuery } from "react-query";
import { defaultApi } from "../utils/AuraApi";

export const useAuth = (token: string) =>
  useQuery(
    "authentication",
    () =>
      defaultApi.fetchAuthentication({ headers: { Authorization: token } }),
    { cacheTime: 0 }
  );
