import React, { useEffect, useState } from "react";
import styled from "styled-components";
import colors from "@alj-react/ui-components/styles/colors";

type DataTableDataRenderHandler = (data: any, dataKey: string, row: any, rowId: any, rowIdKey: string) => any;

export type DataTablePagination = {
  defaultSize: number;
  defaultPage: number;
  sizeOptions: number[];
};

export type DataTableColumnType = {
  title: string;
  data: string;
  dataAlign?: "center" | "left" | "right";
  isId?: boolean;
  width?: string;
  hidden?: boolean;
  dataRendering?: DataTableDataRenderHandler;
};

export type DataTableStyle = {
  scrollWidth: number;
}

export type DataTableProps = {
  columns: DataTableColumnType[];
  data: any[];
  pagination?: DataTablePagination;
  emptyDataTxt?: string;
  tableStyle?: DataTableStyle
};

const DataTable: React.FC<DataTableProps> = (props) => {
  const { data, columns, pagination: paginationProp } = props;

  const [rowIdKey, setRowIdKey] = useState<string>();
  const [pagination, setPagination] = useState<DataTablePagination>({
    defaultPage: 1,
    defaultSize: 20,
    sizeOptions: [20, 50, 100],
  });
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(20);
  const [pageList, setPageList] = useState<number[]>([]);

  const getColumnData = (row: any, colName: string) => row[Object.keys(row).find((col) => col === colName) ?? ""];

  useEffect(() => {
    setRowIdKey(columns.find((col) => col.isId)?.data);

    if (paginationProp) {
      setPagination({
        defaultPage: paginationProp.defaultPage ?? pagination.defaultPage,
        defaultSize: paginationProp.defaultSize ?? pagination.defaultSize,
        sizeOptions: paginationProp.sizeOptions ?? pagination.sizeOptions,
      });
      setPage(paginationProp.defaultPage ?? pagination.defaultPage);
      setPageSize(paginationProp.defaultSize ?? pagination.defaultSize);
    }
  }, []);

  useEffect(() => {
    const pageList = [];
    const pageLength = Math.ceil(data.length / pageSize);
    for (let p = 1; p <= pageLength; p++) {
      pageList.push(p);
    }
    setPageList(pageList);
    if (!page || !pageList.includes(page)) setPage(pageList[pageList.length - 1]);
  }, [data, page, pageSize]);

  return (
    <>
      <TableContainer>
        <Table width={props.tableStyle?.scrollWidth}>
          <thead>
            <tr>
              {columns.filter(c => !c.hidden).map((column, colIdx) => {
                return (
                  <TableHeader style={{ width: column.width ?? "auto" }} key={`dataTable-column-${colIdx}`}>
                    {column.title}
                  </TableHeader>
                );
              })}
            </tr>
          </thead>
          <TableBody>
            {rowIdKey &&
              data &&
              data.slice((page - 1) * pageSize, page * pageSize).map((row, idx) => {
                const rowId = rowIdKey ? getColumnData(row, rowIdKey) : idx;

                return (
                  <TableRow key={`dataTable-row-${rowId}`}>
                    {columns.filter(c => !c.hidden).map((column, colIdx) => (
                      <TableCell key={`dataTable-row-${rowId}-${colIdx}`} style={{ textAlign: column.dataAlign ?? "left" }}>
                        {column.dataRendering ? column.dataRendering(row[column.data], column.data, row, rowId, rowIdKey) : row[column.data]}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      {data && data.length === 0 && <EmptyTxtContainer>{props.emptyDataTxt}</EmptyTxtContainer>}
      {data && data.length > 0 && (
        <PaginationRow>
          <PageListText>頁</PageListText>
          <PageList>
            {pageList.map((option) => (
              <li key={option}>
                <span className={page === option ? "selected" : ""} onClick={() => (page === option ? null : setPage(option))}>
                  {option}
                </span>
              </li>
            ))}
          </PageList>
          <PageSizeContainer>
            <PageSizeList>
              {pagination.sizeOptions.map((option) => (
                <li key={option}>
                  <span className={pageSize === option ? "selected" : ""} onClick={() => (pageSize === option ? null : setPageSize(option))}>
                    {option}
                  </span>
                </li>
              ))}
            </PageSizeList>
            <PageSizeText>件ずつ表示</PageSizeText>
          </PageSizeContainer>
        </PaginationRow>
      )}
    </>
  );
};

export default DataTable;

const TableContainer = styled.div`
  width: 100%;
  overflow-x: auto;
`

const Table = styled.table`
  border: 1px solid ${colors.darkGray};
  border-collapse: collapse;
`;

const TableRow = styled.tr`
  :hover {
    background-color: #E5E5E5;
  }
`

const TableCell = styled.td`
  border: 1px solid ${colors.darkGray};
  line-break: anywhere;
`;

const TableHeader = styled.th`
  font-size: 0.75rem;
  border: 1px solid #000000;
  background-color: ${colors.axaBlue};
  color: ${colors.white};
  white-space: pre-wrap;
`;

const TableBody = styled.tbody`
  font-size: 0.75rem;
  white-space: pre-wrap;
`;

const EmptyTxtContainer = styled.div`
  text-align: center;
  padding: 3rem;
`

const PaginationRow = styled.div`
  text-align: center;
`;

const PageList = styled.ul`
  list-style: none;
  display: inline-block;
  padding-inline-start: 0;

  li {
    display: inline;
    padding-left: 0.25rem;
    font-size: 1rem;
  }

  li span {
    color: ${colors.oceanBlue};
    text-decoration: underline;
    cursor: pointer;
  }

  li span.selected {
    color: ${colors.darkGray};
    text-decoration: none;
    cursor: none;
  }
`;

const PageListText = styled.span`
  font-size: 1rem;
  margin-right: 0.25rem;
`;

const PageSizeContainer = styled.div`
  float: right;
`;

const PageSizeList = styled.ul`
  list-style: none;
  display: inline-block;
  padding-inline-start: 0;

  li {
    display: inline;
    padding-left: 0.25rem;
    font-size: 1rem;
  }

  li span {
    color: ${colors.oceanBlue};
    text-decoration: underline;
    cursor: pointer;
  }

  li span.selected {
    color: ${colors.darkGray};
    text-decoration: none;
    cursor: none;
  }
`;

const PageSizeText = styled.span`
  font-size: 1rem;
  margin-left: 0.25rem;
`;
