import React from "react";
import { Answer } from "../../apis";
import { WideButton } from "../atoms/WideButton";

type Props = {
  options: Array<Option>;
  selected?: Option;
  answerQuestion: (answer: Answer) => Promise<void>;
  answerable: boolean;
};

export const UnitizedChoiceRadio: React.FC<Props> = ({
  options,
  selected,
  answerQuestion,
  answerable,
}) => (
  <>
    {options.map(({ label, value }) => (
      <WideButton
        key={value}
        label={label}
        active={selected?.value === value}
        onClick={() => {
          const answer = {
            questionType: "UNITIZED_CHOICE",
            value,
          };
          answerQuestion({ answer });
        }}
        disabled={!answerable}
      />
    ))}
  </>
);
