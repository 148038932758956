import { useQuery } from "react-query";
import { auraServiceApi } from "../utils/AuraApi";

export const useSearchQuestions = (
  clientId: string,
  sessionId: string,
  accessToken: string,
  questionId: number,
  tenant: string,
  keyWord: string
) =>
  useQuery(
    ["fetchSearchQuestions", questionId, keyWord],
    () =>
      auraServiceApi.fetchQuestion(
        clientId,
        sessionId,
        questionId,
        tenant,
        keyWord,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      ),
    { enabled: !!keyWord, cacheTime: 0 }
  );
