export const alphabetizeIndex = (index: number) => {

  const alphabets = 'abcdefghijklmnopqrstuvwxyz';

  if (index === 0) {
    return alphabets.charAt(0);
  }

  let result = '';
  let cur = index;
  while (cur) {
    let tem = cur % 26;

    result = result.concat(alphabets.charAt(tem));

    cur = Math.floor(cur / 26);
  }

  return result.split("").reverse().join("");
}
