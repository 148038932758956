import React, { useEffect, useState } from 'react';
import { Context } from '../../utils/ESignatureContext';
import { setApiResultSign } from '../../utils/dataFetchers';

type TypeDatalistProps = {
  childTypes: any,
  endpoint: string,
  idName: string,
  sourceName: string,
  hiddenSourceName: string,
  targetName: string,
  hiddenTargetName: string,
  errorTxt: string
}

// Add list attribute to datalist related input element and return the element.
const getElem = (idName: string, name: string) => {
  return document.querySelector(`#${idName}-datalistContainer input[name="${name}"]`) as HTMLInputElement;
}

// Clear any existing datalist.
const resetDatalist = (idName: string, name: string) => {
  const elem = getElem(idName, name);
  const prevDatalist = elem?.querySelector('datalist');
  prevDatalist?.remove();
}

const resetText = (idName: string, name: string) => {
  const elem = getElem(idName, name);

  elem && (elem.value = '');
}

// Currently "disabled" attribute does not seem to work in TextInput component.
// So in order to disable elem, readOnly is used here.
const disableElem = (idName: string, name: string) => {
  const elem = getElem(idName, name);

  elem && (elem.readOnly = true);
}

const enableElem = (idName: string, name: string) => {
  const elem = getElem(idName, name);

  elem && (elem.readOnly = false);
}

const updateHiddenInputValue = (idName: string, name: string, matchedData: any) => {
  const elem = getElem(idName, name);

  elem && (elem.value = JSON.stringify(matchedData.value));
}

const TypeDatalist = (props: TypeDatalistProps) => {
  const {
    childTypes,
    sourceName,
    hiddenSourceName,
    targetName,
    hiddenTargetName,
    idName,
    endpoint,
    errorTxt
  } = props;

  const { dispatch, state } = React.useContext(Context);
  const [sourceData, setSourceData] = useState<any[]>([]);
  const [targetData, setTargetData] = useState<any[]>([]);

  const reqOptions = {
    method: 'GET',
    headers: { Authorization: state.queryParameters.token },
  }

  // Fetch target data.
  const fetchTargetData = async(e: any) => {
    if (!e.target.value) {
      console.log('Invalid input');
      return;
    }

    const sourceElem = getElem(idName, sourceName);

    const selectedOption = sourceElem?.querySelector(`option[value="${e.target.value}"]`);
    const targetEndpoint = selectedOption?.getAttribute('data-endpoint');

    if (!targetEndpoint) return;

    try {
      const response = await fetch(targetEndpoint, reqOptions);

      if (!response.ok) {
        console.log(response.status);
        throw new Error('Failed to fetch data.');
      }

      const responseData = await response.json();

      console.log('responseData', responseData);

      setTargetData(responseData);
    } catch (error) {
      console.log(error);
    }
  };

  // Append datalist to DOM.
  const appendDatalist = (
    elem: HTMLInputElement,
    name: string,
    data: any[]
  ) => {
    resetDatalist(idName, name);

    const datalistElem = document.createElement('datalist');
    datalistElem.id = name;

    data.forEach((item: any) => {
      const path = item.endpoint;
      const option = document.createElement('option');

      option.value = item.label;
      option.setAttribute('data-testId', item.label);

      if (name === sourceName) {
        option.setAttribute('data-endpoint', `${endpoint}/${path}`);
      }

      datalistElem.appendChild(option);
    })

    if (data.length) {
      elem?.append(datalistElem);
    }
  }

  const generateDatalist = {
    source: () => {
      const sourceElem = getElem(idName, sourceName);
      appendDatalist(sourceElem, sourceName, sourceData);
    },
    target: () => {
      const targetElem = getElem(idName, targetName);
      appendDatalist(targetElem, targetName, targetData);
    }
  }

  const validateInput = {
    source: (e: any) => {
      const matchedData = sourceData.find((item) => item.label === e.target.value);

      if (matchedData) {
        enableElem(idName, targetName);
        updateHiddenInputValue(idName, hiddenSourceName, matchedData);
        return;
      }

      resetText(idName, sourceName);
      resetText(idName, targetName);

      resetDatalist(idName, targetName);
      disableElem(idName, targetName);
    },
    target: (e: any) => {
      const matchedData = targetData.find((item) => item.label === e.target.value);

      if (matchedData) {
        updateHiddenInputValue(idName, hiddenTargetName, matchedData);
        return;
      }

      resetText(idName, targetName);
    }
  }

  useEffect(() => {
    const sourceElem = getElem(idName, sourceName);

    if (!sourceElem) return;

    sourceElem.addEventListener('change', validateInput.source);
    sourceElem.addEventListener('change', fetchTargetData);

    return () => {
      sourceElem.removeEventListener('change', validateInput.source);
      sourceElem.removeEventListener('change', fetchTargetData);
    };
  }, [sourceData])

  useEffect(() => {
    const targetElem = getElem(idName, targetName);

    if (!targetElem) return;

    targetElem.addEventListener('change', validateInput.target);

    return () => {
      targetElem.removeEventListener('change', validateInput.target);
    };
  }, [targetData])

  useEffect(() => {
    generateDatalist.source();
  }, [sourceData])

  useEffect(() => {
    generateDatalist.target();
  }, [targetData])

  useEffect(() => {
    const fetchData = async() => {
      if (!endpoint) return;

      try {
        const response = await fetch(endpoint, reqOptions);

        if (!response.ok) {
          console.log(response.status);
          throw new Error('Failed to fetch data.');
        }

        const responseData: any = await response.json();

        console.log('responseData', responseData);

        setSourceData(responseData);
      } catch (error) {
        console.log(error);

        setApiResultSign(dispatch, { msg: errorTxt, status: 500, payload: { fetchFailed: true } });
      }
    }

    fetchData();
  }, []);

  return (
    <>
      <div id={`${idName}-datalistContainer`} >
        { childTypes }
      </div>
    </>
  )
}

export default TypeDatalist
