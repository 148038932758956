import React from "react";
import styled from "styled-components";
import { ValidationContainer } from "../layout/ValidationContainer";

type Props = {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
  value: string;
  validationErr?: string;
  disabled: boolean;
};

export const Input: React.FC<Props> = ({
  onChange,
  onBlur,
  value,
  validationErr,
  disabled,
}) => (
  <ValidationContainer validationErr={validationErr}>
    <StyledInput
      type="number"
      pattern="[0-9]*"
      inputMode="decimal"
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      disabled={disabled}
    />
  </ValidationContainer>
);

const StyledInput = styled.input`
  width: 98%;
  text-align: right;
  height: 40px;
  border: 1px solid lightgray;
  border-radius: 0px;
  font-size: 13px;
  font-family: Meiryo;
  padding: 1px 2px;
`;
