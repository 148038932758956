import React, { useEffect } from "react";
import { Layout, breakpoints, Text, Link } from "@alj-react/ui-components";
import styled, { css } from "styled-components";
import { LayoutInner } from "@alj-react/ui-components/Layout/components";

const manualProps = Object.keys(process.env)
  .filter(key => key.startsWith("REACT_APP_GROUP_CONFIRMATION_MANUAL"))
  .sort()
  .map((key) => process.env[key] ?? '');

const InsuranceCollectiveManual: React.FC = () => {

  const openManual = (manualProps: string[]) => {
    if (manualProps.length !== 3) {
      console.error('Invalid input for openManual');
      return;
    }
    
    const [name, url, openMethod] = manualProps;

    if (openMethod === 'popup') {
      const popupWindow = window.open(
        url, 
        "axamanual",
        "popup=yes,top=50,left=100,width=800,height=600,menubar=0,toolbar=0,resizable=1,scrollbars=1"
      );
      popupWindow?.focus();
    } else if (openMethod === 'download') {
      const linkElement = document.createElement("a");
      linkElement.href = url;
      linkElement.download = name;
      linkElement.click();
    }
  }

  useEffect(() => {
    document.title = "団体扱集団扱確認システム";
  }, [])

  return (
    <LayoutStyled>
      {
        manualProps.map((manualProp, idx) => 
        <ManualLinkListItem key={`manual${idx}`}>
          <ManualLink onClick={() => openManual(manualProp.split(','))}>
            <CustomText size="20">{manualProp.split(',')[0]}</CustomText>
          </ManualLink>
        </ManualLinkListItem>)
      }
    </LayoutStyled>
  );
};

const LayoutStyled = styled(Layout)`
  padding: 1rem;
  ${breakpoints.smallOnly(css`
    ${LayoutInner} {
      padding-top: 8px;
      padding-right: 14px;
      padding-bottom: 24px;
      padding-left: 14px;
    }
  `)}
`;

const ManualLinkListItem = styled.div`
  margin-bottom: 1rem;
`

const ManualLink = styled(Link)`
  text-decoration: underline;
`

const CustomText = styled(Text)`
  font-family: 'Lucida Grande';
`

export default InsuranceCollectiveManual;
