import React from "react";
import styled from "styled-components";

type Props = {
  active: boolean;
  label: string;
  onClick: () => void;
  disabled: boolean;
};

export const ShortButton: React.FC<Props> = ({
  active,
  label,
  onClick,
  disabled
}) => (
  <Button active={active} onClick={onClick} disabled={disabled}>
    {label}
  </Button>
);

const Button = styled.button<{ active: boolean }>`
  border-radius: 0px;
  text-shadow: inherit;
  background: inherit;
  background-color: ${({ active }) => (active ? "#00008f" : "#fff")};
  color: ${({ active }) => (active ? "#fff" : "#00008f")};
  height: auto;
  font-size: 13px;
  width: 44%;
  padding: 8px;
  vertical-align: middle;
  margin: 5px;
  display: inline-block;
  border: 1px solid #333;
  font-family: "Meiryo";
  font-weight: 700;
  box-sizing: border-box;
  text-align: center;
  cursor: pointer;
`;
