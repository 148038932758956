import React from "react";
import styled from "styled-components";
import { ValidationContainer } from "../layout/ValidationContainer";

type Props = {
  value?: string;
  validationErr?: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  disabled: boolean;
};
export const TextArea: React.FC<Props> = ({
  value,
  validationErr,
  onChange,
  onBlur,
  disabled,
}) => (
  <ValidationContainer validationErr={validationErr}>
    <StyledTextArea
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      disabled={disabled}
    />
  </ValidationContainer>
);

const StyledTextArea = styled.textarea`
  margin: 0.3rem;
  margin-right: -15px;
  height: 40px;
  resize: none;
  width: calc(100% - 10px);
  border: 1px solid lightgray;
  border-radius: 0px;
  font-size: 13px;
  font-family: Meiryo;
  padding: 1px 2px;
`;
