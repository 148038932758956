/* tslint:disable */
/* eslint-disable */
/**
 * Authentication
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AmountAnswer
 */
export interface AmountAnswer {
    /**
     * 
     * @type {string}
     * @memberof AmountAnswer
     */
    'questionType': string;
    /**
     * 
     * @type {Array<AmountAnswerAnswersInner>}
     * @memberof AmountAnswer
     */
    'answers': Array<AmountAnswerAnswersInner>;
    /**
     * 
     * @type {boolean}
     * @memberof AmountAnswer
     */
    'unknownAnswer': boolean;
}
/**
 * 
 * @export
 * @interface AmountAnswerAnswersInner
 */
export interface AmountAnswerAnswersInner {
    /**
     * 
     * @type {string}
     * @memberof AmountAnswerAnswersInner
     */
    'value': string;
    /**
     * 
     * @type {number}
     * @memberof AmountAnswerAnswersInner
     */
    'amount': number;
}
/**
 * 
 * @export
 * @interface AmountDetailedAnswer
 */
export interface AmountDetailedAnswer {
    /**
     * 
     * @type {string}
     * @memberof AmountDetailedAnswer
     */
    'questionType': string;
    /**
     * 
     * @type {Array<AmountAnswerAnswersInner>}
     * @memberof AmountDetailedAnswer
     */
    'answers': Array<AmountAnswerAnswersInner>;
    /**
     * 
     * @type {boolean}
     * @memberof AmountDetailedAnswer
     */
    'unknownAnswer': boolean;
}
/**
 * 
 * @export
 * @interface AmountQuestion
 */
export interface AmountQuestion {
    /**
     * 
     * @type {AmountDetailedAnswer}
     * @memberof AmountQuestion
     */
    'answer'?: AmountDetailedAnswer;
    /**
     * 
     * @type {Constraints}
     * @memberof AmountQuestion
     */
    'constraints': Constraints;
    /**
     * 
     * @type {string}
     * @memberof AmountQuestion
     */
    'externalId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AmountQuestion
     */
    'helpText': string;
    /**
     * 
     * @type {number}
     * @memberof AmountQuestion
     */
    'id': number;
    /**
     * 
     * @type {boolean}
     * @memberof AmountQuestion
     */
    'isAnswered': boolean;
    /**
     * 
     * @type {string}
     * @memberof AmountQuestion
     */
    'parentChoiceVal'?: string;
    /**
     * 
     * @type {number}
     * @memberof AmountQuestion
     */
    'parentQuestionId'?: number;
    /**
     * 
     * @type {string}
     * @memberof AmountQuestion
     */
    'richHelpText': string;
    /**
     * 
     * @type {string}
     * @memberof AmountQuestion
     */
    'richText': string;
    /**
     * 
     * @type {string}
     * @memberof AmountQuestion
     */
    'text': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AmountQuestion
     */
    'context': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AmountQuestion
     */
    'type': string;
    /**
     * 
     * @type {Links}
     * @memberof AmountQuestion
     */
    '_links': Links;
}
/**
 * 
 * @export
 * @interface Answer
 */
export interface Answer {
    /**
     * 
     * @type {AnswerAnswer}
     * @memberof Answer
     */
    'answer': AnswerAnswer;
}
/**
 * @type AnswerAnswer
 * @export
 */
export type AnswerAnswer = AmountAnswer | BloodPressureAnswer | ConfirmationAnswer | DateAnswer | DateChoiceAnswer | DoctorAnswer | GuardianCoverageAnswer | MultipleChoiceAnswer | NumericAnswer | NumericChoiceAnswer | PercentAnswer | RxConfirmationAnswer | SearchAnswer | SingleChoiceAnswer | StatementAnswer | TextAnswer | UnitizedAnswer | UnitizedChoiceAnswer;

/**
 * 
 * @export
 * @interface Authentication
 */
export interface Authentication {
    /**
     * 
     * @type {string}
     * @memberof Authentication
     */
    'token_type': string;
    /**
     * 
     * @type {string}
     * @memberof Authentication
     */
    'access_token': string;
    /**
     * 
     * @type {number}
     * @memberof Authentication
     */
    'expires_in': number;
}
/**
 * 
 * @export
 * @interface BloodPressureAnswer
 */
export interface BloodPressureAnswer {
    /**
     * 
     * @type {string}
     * @memberof BloodPressureAnswer
     */
    'questionType': string;
    /**
     * 
     * @type {Array<BloodPressureAnswerReadingsInner>}
     * @memberof BloodPressureAnswer
     */
    'readings': Array<BloodPressureAnswerReadingsInner>;
    /**
     * 
     * @type {boolean}
     * @memberof BloodPressureAnswer
     */
    'unknownAnswer'?: boolean;
}
/**
 * 
 * @export
 * @interface BloodPressureAnswerReadingsInner
 */
export interface BloodPressureAnswerReadingsInner {
    /**
     * 
     * @type {number}
     * @memberof BloodPressureAnswerReadingsInner
     */
    'systolic': number;
    /**
     * 
     * @type {number}
     * @memberof BloodPressureAnswerReadingsInner
     */
    'diastolic': number;
}
/**
 * 
 * @export
 * @interface BloodPressureDetailedAnswer
 */
export interface BloodPressureDetailedAnswer {
    /**
     * 
     * @type {string}
     * @memberof BloodPressureDetailedAnswer
     */
    'questionType': string;
    /**
     * 
     * @type {Array<BloodPressureAnswerReadingsInner>}
     * @memberof BloodPressureDetailedAnswer
     */
    'readings': Array<BloodPressureAnswerReadingsInner>;
    /**
     * 
     * @type {boolean}
     * @memberof BloodPressureDetailedAnswer
     */
    'unknownAnswer'?: boolean;
}
/**
 * 
 * @export
 * @interface BloodPressureQuestion
 */
export interface BloodPressureQuestion {
    /**
     * 
     * @type {BloodPressureDetailedAnswer}
     * @memberof BloodPressureQuestion
     */
    'answer'?: BloodPressureDetailedAnswer;
    /**
     * 
     * @type {Constraints1}
     * @memberof BloodPressureQuestion
     */
    'constraints': Constraints1;
    /**
     * 
     * @type {string}
     * @memberof BloodPressureQuestion
     */
    'externalId'?: string;
    /**
     * 
     * @type {string}
     * @memberof BloodPressureQuestion
     */
    'helpText': string;
    /**
     * 
     * @type {number}
     * @memberof BloodPressureQuestion
     */
    'id': number;
    /**
     * 
     * @type {boolean}
     * @memberof BloodPressureQuestion
     */
    'isAnswered': boolean;
    /**
     * 
     * @type {string}
     * @memberof BloodPressureQuestion
     */
    'parentChoiceVal'?: string;
    /**
     * 
     * @type {number}
     * @memberof BloodPressureQuestion
     */
    'parentQuestionId'?: number;
    /**
     * 
     * @type {string}
     * @memberof BloodPressureQuestion
     */
    'richHelpText': string;
    /**
     * 
     * @type {string}
     * @memberof BloodPressureQuestion
     */
    'richText': string;
    /**
     * 
     * @type {string}
     * @memberof BloodPressureQuestion
     */
    'text': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof BloodPressureQuestion
     */
    'context': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof BloodPressureQuestion
     */
    'type': string;
    /**
     * 
     * @type {Links}
     * @memberof BloodPressureQuestion
     */
    '_links': Links;
}
/**
 * @type Choice
 * @export
 */
export type Choice = DateChoice | MultipleChoice | NumericChoice | SearchChoice | SingleChoice | UnitizedChoice;

/**
 * 
 * @export
 * @interface Choice1
 */
export interface Choice1 {
    /**
     * 
     * @type {string}
     * @memberof Choice1
     */
    'richText': string;
    /**
     * 
     * @type {string}
     * @memberof Choice1
     */
    'text': string;
    /**
     * 
     * @type {boolean}
     * @memberof Choice1
     */
    'unknownAnswer': boolean;
    /**
     * 
     * @type {string}
     * @memberof Choice1
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface Choice2
 */
export interface Choice2 {
    /**
     * 
     * @type {string}
     * @memberof Choice2
     */
    'questionType': string;
    /**
     * 
     * @type {string}
     * @memberof Choice2
     */
    'richText': string;
    /**
     * 
     * @type {string}
     * @memberof Choice2
     */
    'text': string;
    /**
     * 
     * @type {boolean}
     * @memberof Choice2
     */
    'unknownAnswer': boolean;
    /**
     * 
     * @type {string}
     * @memberof Choice2
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface CommonQuestion
 */
export interface CommonQuestion {
    /**
     * 
     * @type {string}
     * @memberof CommonQuestion
     */
    'externalId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommonQuestion
     */
    'helpText': string;
    /**
     * 
     * @type {number}
     * @memberof CommonQuestion
     */
    'id': number;
    /**
     * 
     * @type {boolean}
     * @memberof CommonQuestion
     */
    'isAnswered': boolean;
    /**
     * 
     * @type {string}
     * @memberof CommonQuestion
     */
    'parentChoiceVal'?: string;
    /**
     * 
     * @type {number}
     * @memberof CommonQuestion
     */
    'parentQuestionId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CommonQuestion
     */
    'richHelpText': string;
    /**
     * 
     * @type {string}
     * @memberof CommonQuestion
     */
    'richText': string;
    /**
     * 
     * @type {string}
     * @memberof CommonQuestion
     */
    'text': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CommonQuestion
     */
    'context': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CommonQuestion
     */
    'type': string;
    /**
     * 
     * @type {Links}
     * @memberof CommonQuestion
     */
    '_links': Links;
}
/**
 * 
 * @export
 * @interface ConfirmationAnswer
 */
export interface ConfirmationAnswer {
    /**
     * 
     * @type {string}
     * @memberof ConfirmationAnswer
     */
    'questionType': string;
    /**
     * 
     * @type {string}
     * @memberof ConfirmationAnswer
     */
    'value': string;
    /**
     * 
     * @type {boolean}
     * @memberof ConfirmationAnswer
     */
    'unknownAnswer': boolean;
}
/**
 * 
 * @export
 * @interface ConfirmationDetailedAnswer
 */
export interface ConfirmationDetailedAnswer {
    /**
     * 
     * @type {string}
     * @memberof ConfirmationDetailedAnswer
     */
    'questionType': string;
    /**
     * 
     * @type {string}
     * @memberof ConfirmationDetailedAnswer
     */
    'richText': string;
    /**
     * 
     * @type {string}
     * @memberof ConfirmationDetailedAnswer
     */
    'text': string;
    /**
     * 
     * @type {boolean}
     * @memberof ConfirmationDetailedAnswer
     */
    'unknownAnswer': boolean;
    /**
     * 
     * @type {string}
     * @memberof ConfirmationDetailedAnswer
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface ConfirmationQuestion
 */
export interface ConfirmationQuestion {
    /**
     * 
     * @type {ConfirmationDetailedAnswer}
     * @memberof ConfirmationQuestion
     */
    'answer'?: ConfirmationDetailedAnswer;
    /**
     * 
     * @type {Constraints2}
     * @memberof ConfirmationQuestion
     */
    'constraints': Constraints2;
    /**
     * 
     * @type {string}
     * @memberof ConfirmationQuestion
     */
    'externalId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConfirmationQuestion
     */
    'helpText': string;
    /**
     * 
     * @type {number}
     * @memberof ConfirmationQuestion
     */
    'id': number;
    /**
     * 
     * @type {boolean}
     * @memberof ConfirmationQuestion
     */
    'isAnswered': boolean;
    /**
     * 
     * @type {string}
     * @memberof ConfirmationQuestion
     */
    'parentChoiceVal'?: string;
    /**
     * 
     * @type {number}
     * @memberof ConfirmationQuestion
     */
    'parentQuestionId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ConfirmationQuestion
     */
    'richHelpText': string;
    /**
     * 
     * @type {string}
     * @memberof ConfirmationQuestion
     */
    'richText': string;
    /**
     * 
     * @type {string}
     * @memberof ConfirmationQuestion
     */
    'text': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ConfirmationQuestion
     */
    'context': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ConfirmationQuestion
     */
    'type': string;
    /**
     * 
     * @type {Links}
     * @memberof ConfirmationQuestion
     */
    '_links': Links;
}
/**
 * 
 * @export
 * @interface Constraints
 */
export interface Constraints {
    /**
     * 
     * @type {boolean}
     * @memberof Constraints
     */
    'canBeUnknown': boolean;
    /**
     * 
     * @type {Array<Choice1>}
     * @memberof Constraints
     */
    'choices': Array<Choice1>;
}
/**
 * 
 * @export
 * @interface Constraints1
 */
export interface Constraints1 {
    /**
     * 
     * @type {boolean}
     * @memberof Constraints1
     */
    'canBeUnknown': boolean;
    /**
     * 
     * @type {number}
     * @memberof Constraints1
     */
    'maxNumberOfReadings': number;
    /**
     * 
     * @type {number}
     * @memberof Constraints1
     */
    'maxValDiastolic': number;
    /**
     * 
     * @type {number}
     * @memberof Constraints1
     */
    'minValDiastolic': number;
    /**
     * 
     * @type {number}
     * @memberof Constraints1
     */
    'maxValSystolic': number;
    /**
     * 
     * @type {number}
     * @memberof Constraints1
     */
    'minValSystolic': number;
}
/**
 * 
 * @export
 * @interface Constraints10
 */
export interface Constraints10 {
    /**
     * 
     * @type {boolean}
     * @memberof Constraints10
     */
    'canBeUnknown': boolean;
    /**
     * 
     * @type {Array<Constraints10ChoicesInner>}
     * @memberof Constraints10
     */
    'choices': Array<Constraints10ChoicesInner>;
}
/**
 * 
 * @export
 * @interface Constraints10ChoicesInner
 */
export interface Constraints10ChoicesInner {
    /**
     * 
     * @type {string}
     * @memberof Constraints10ChoicesInner
     */
    'text'?: string;
    /**
     * 
     * @type {string}
     * @memberof Constraints10ChoicesInner
     */
    'value'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Constraints10ChoicesInner
     */
    'unknownAnswer'?: boolean;
}
/**
 * 
 * @export
 * @interface Constraints11
 */
export interface Constraints11 {
    /**
     * 
     * @type {boolean}
     * @memberof Constraints11
     */
    'canBeUnknown': boolean;
    /**
     * 
     * @type {Array<Choice2>}
     * @memberof Constraints11
     */
    'choices': Array<Choice2>;
}
/**
 * 
 * @export
 * @interface Constraints12
 */
export interface Constraints12 {
    /**
     * 
     * @type {boolean}
     * @memberof Constraints12
     */
    'canBeUnknown': boolean;
    /**
     * 
     * @type {string}
     * @memberof Constraints12
     */
    'componentType': string;
    /**
     * 
     * @type {Constraints12NotInListDisclosureType}
     * @memberof Constraints12
     */
    'notInListDisclosureType': Constraints12NotInListDisclosureType;
    /**
     * 
     * @type {Array<SearchChoice>}
     * @memberof Constraints12
     */
    'choices': Array<SearchChoice>;
}
/**
 * 
 * @export
 * @interface Constraints12NotInListDisclosureType
 */
export interface Constraints12NotInListDisclosureType {
    /**
     * 
     * @type {string}
     * @memberof Constraints12NotInListDisclosureType
     */
    'text': string;
    /**
     * 
     * @type {string}
     * @memberof Constraints12NotInListDisclosureType
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface Constraints13
 */
export interface Constraints13 {
    /**
     * 
     * @type {boolean}
     * @memberof Constraints13
     */
    'canBeUnknown': boolean;
    /**
     * 
     * @type {Array<SingleChoice>}
     * @memberof Constraints13
     */
    'choices': Array<SingleChoice>;
    /**
     * 
     * @type {string}
     * @memberof Constraints13
     */
    'componentType': string;
}
/**
 * 
 * @export
 * @interface Constraints14
 */
export interface Constraints14 {
    /**
     * 
     * @type {boolean}
     * @memberof Constraints14
     */
    'canBeUnknown': boolean;
}
/**
 * 
 * @export
 * @interface Constraints15
 */
export interface Constraints15 {
    /**
     * 
     * @type {boolean}
     * @memberof Constraints15
     */
    'canBeUnknown': boolean;
    /**
     * 
     * @type {Array<UnitizedChoice>}
     * @memberof Constraints15
     */
    'choices': Array<UnitizedChoice>;
    /**
     * 
     * @type {Constraints15AvailableUnit}
     * @memberof Constraints15
     */
    'availableUnit': Constraints15AvailableUnit;
    /**
     * 
     * @type {string}
     * @memberof Constraints15
     */
    'componentType': string;
}
/**
 * 
 * @export
 * @interface Constraints15AvailableUnit
 */
export interface Constraints15AvailableUnit {
    /**
     * 
     * @type {string}
     * @memberof Constraints15AvailableUnit
     */
    'label': string;
    /**
     * 
     * @type {string}
     * @memberof Constraints15AvailableUnit
     */
    'unitSymbol': string;
}
/**
 * 
 * @export
 * @interface Constraints2
 */
export interface Constraints2 {
    /**
     * 
     * @type {boolean}
     * @memberof Constraints2
     */
    'canBeUnknown': boolean;
    /**
     * 
     * @type {Array<Choice2>}
     * @memberof Constraints2
     */
    'choices': Array<Choice2>;
}
/**
 * 
 * @export
 * @interface Constraints3
 */
export interface Constraints3 {
    /**
     * 
     * @type {boolean}
     * @memberof Constraints3
     */
    'canBeUnknown': boolean;
    /**
     * 
     * @type {Array<DateChoice>}
     * @memberof Constraints3
     */
    'choices': Array<DateChoice>;
    /**
     * 
     * @type {string}
     * @memberof Constraints3
     */
    'componentType': string;
}
/**
 * 
 * @export
 * @interface Constraints4
 */
export interface Constraints4 {
    /**
     * 
     * @type {boolean}
     * @memberof Constraints4
     */
    'canBeUnknown': boolean;
    /**
     * 
     * @type {string}
     * @memberof Constraints4
     */
    'selectedDatePeriod': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Constraints4
     */
    'availableDateFormats': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Constraints4
     */
    'minDate': string;
    /**
     * 
     * @type {string}
     * @memberof Constraints4
     */
    'maxDate': string;
}
/**
 * 
 * @export
 * @interface Constraints5
 */
export interface Constraints5 {
    /**
     * 
     * @type {boolean}
     * @memberof Constraints5
     */
    'canBeUnknown': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof Constraints5
     */
    'doctors': Array<string>;
}
/**
 * 
 * @export
 * @interface Constraints6
 */
export interface Constraints6 {
    /**
     * 
     * @type {string}
     * @memberof Constraints6
     */
    'canBeUnknown': string;
    /**
     * 
     * @type {Array<Constraints6AvailableProductsInner>}
     * @memberof Constraints6
     */
    'availableProducts': Array<Constraints6AvailableProductsInner>;
    /**
     * 
     * @type {Array<CoverageAmounts>}
     * @memberof Constraints6
     */
    'guardianProductCoverages': Array<CoverageAmounts>;
}
/**
 * 
 * @export
 * @interface Constraints6AvailableProductsInner
 */
export interface Constraints6AvailableProductsInner {
    /**
     * 
     * @type {string}
     * @memberof Constraints6AvailableProductsInner
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Constraints6AvailableProductsInner
     */
    'txt': string;
    /**
     * 
     * @type {string}
     * @memberof Constraints6AvailableProductsInner
     */
    'richText': string;
}
/**
 * 
 * @export
 * @interface Constraints7
 */
export interface Constraints7 {
    /**
     * 
     * @type {boolean}
     * @memberof Constraints7
     */
    'canBeUnknown': boolean;
    /**
     * 
     * @type {Array<MultipleChoice>}
     * @memberof Constraints7
     */
    'choices': Array<MultipleChoice>;
    /**
     * 
     * @type {string}
     * @memberof Constraints7
     */
    'componentType': string;
}
/**
 * 
 * @export
 * @interface Constraints8
 */
export interface Constraints8 {
    /**
     * 
     * @type {boolean}
     * @memberof Constraints8
     */
    'canBeUnknown': boolean;
    /**
     * 
     * @type {Array<NumericChoice>}
     * @memberof Constraints8
     */
    'choices': Array<NumericChoice>;
    /**
     * 
     * @type {string}
     * @memberof Constraints8
     */
    'componentType': string;
}
/**
 * 
 * @export
 * @interface Constraints9
 */
export interface Constraints9 {
    /**
     * 
     * @type {boolean}
     * @memberof Constraints9
     */
    'canBeUnknown': boolean;
    /**
     * 
     * @type {number}
     * @memberof Constraints9
     */
    'minVal': number;
    /**
     * 
     * @type {number}
     * @memberof Constraints9
     */
    'maxVal': number;
    /**
     * 
     * @type {number}
     * @memberof Constraints9
     */
    'decimalPrecision': number;
}
/**
 * 
 * @export
 * @interface CoverageAmounts
 */
export interface CoverageAmounts {
    /**
     * 
     * @type {Array<CoverageAmountsCoverageAmountsInner>}
     * @memberof CoverageAmounts
     */
    'coverageAmounts': Array<CoverageAmountsCoverageAmountsInner>;
}
/**
 * 
 * @export
 * @interface CoverageAmountsCoverageAmountsInner
 */
export interface CoverageAmountsCoverageAmountsInner {
    /**
     * 
     * @type {string}
     * @memberof CoverageAmountsCoverageAmountsInner
     */
    'productName': string;
    /**
     * 
     * @type {string}
     * @memberof CoverageAmountsCoverageAmountsInner
     */
    'inforceAmount': string;
}
/**
 * 
 * @export
 * @interface DateAnswer
 */
export interface DateAnswer {
    /**
     * 
     * @type {string}
     * @memberof DateAnswer
     */
    'questionType': string;
    /**
     * 
     * @type {string}
     * @memberof DateAnswer
     */
    'dateFormat': string;
    /**
     * 
     * @type {string}
     * @memberof DateAnswer
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface DateChoice
 */
export interface DateChoice {
    /**
     * 
     * @type {string}
     * @memberof DateChoice
     */
    'questionType': string;
    /**
     * 
     * @type {string}
     * @memberof DateChoice
     */
    'richText': string;
    /**
     * 
     * @type {string}
     * @memberof DateChoice
     */
    'text': string;
    /**
     * 
     * @type {boolean}
     * @memberof DateChoice
     */
    'unknownAnswer': boolean;
    /**
     * 
     * @type {string}
     * @memberof DateChoice
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface DateChoiceAnswer
 */
export interface DateChoiceAnswer {
    /**
     * 
     * @type {string}
     * @memberof DateChoiceAnswer
     */
    'questionType': string;
    /**
     * 
     * @type {string}
     * @memberof DateChoiceAnswer
     */
    'value': string;
    /**
     * 
     * @type {boolean}
     * @memberof DateChoiceAnswer
     */
    'unknownAnswer': boolean;
}
/**
 * 
 * @export
 * @interface DateChoiceDetailedAnswer
 */
export interface DateChoiceDetailedAnswer {
    /**
     * 
     * @type {string}
     * @memberof DateChoiceDetailedAnswer
     */
    'questionType': string;
    /**
     * 
     * @type {string}
     * @memberof DateChoiceDetailedAnswer
     */
    'richText': string;
    /**
     * 
     * @type {string}
     * @memberof DateChoiceDetailedAnswer
     */
    'text': string;
    /**
     * 
     * @type {boolean}
     * @memberof DateChoiceDetailedAnswer
     */
    'unknownAnswer': boolean;
    /**
     * 
     * @type {string}
     * @memberof DateChoiceDetailedAnswer
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface DateChoiceQuestion
 */
export interface DateChoiceQuestion {
    /**
     * 
     * @type {DateChoiceDetailedAnswer}
     * @memberof DateChoiceQuestion
     */
    'answer'?: DateChoiceDetailedAnswer;
    /**
     * 
     * @type {Constraints3}
     * @memberof DateChoiceQuestion
     */
    'constraints': Constraints3;
    /**
     * 
     * @type {string}
     * @memberof DateChoiceQuestion
     */
    'externalId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DateChoiceQuestion
     */
    'helpText': string;
    /**
     * 
     * @type {number}
     * @memberof DateChoiceQuestion
     */
    'id': number;
    /**
     * 
     * @type {boolean}
     * @memberof DateChoiceQuestion
     */
    'isAnswered': boolean;
    /**
     * 
     * @type {string}
     * @memberof DateChoiceQuestion
     */
    'parentChoiceVal'?: string;
    /**
     * 
     * @type {number}
     * @memberof DateChoiceQuestion
     */
    'parentQuestionId'?: number;
    /**
     * 
     * @type {string}
     * @memberof DateChoiceQuestion
     */
    'richHelpText': string;
    /**
     * 
     * @type {string}
     * @memberof DateChoiceQuestion
     */
    'richText': string;
    /**
     * 
     * @type {string}
     * @memberof DateChoiceQuestion
     */
    'text': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DateChoiceQuestion
     */
    'context': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof DateChoiceQuestion
     */
    'type': string;
    /**
     * 
     * @type {Links}
     * @memberof DateChoiceQuestion
     */
    '_links': Links;
}
/**
 * 
 * @export
 * @interface DateDetailedAnswer
 */
export interface DateDetailedAnswer {
    /**
     * 
     * @type {string}
     * @memberof DateDetailedAnswer
     */
    'questionType': string;
    /**
     * 
     * @type {string}
     * @memberof DateDetailedAnswer
     */
    'dateFormat': string;
    /**
     * 
     * @type {string}
     * @memberof DateDetailedAnswer
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface DateQuestion
 */
export interface DateQuestion {
    /**
     * 
     * @type {DateDetailedAnswer}
     * @memberof DateQuestion
     */
    'answer'?: DateDetailedAnswer;
    /**
     * 
     * @type {Constraints4}
     * @memberof DateQuestion
     */
    'constraints': Constraints4;
    /**
     * 
     * @type {string}
     * @memberof DateQuestion
     */
    'externalId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DateQuestion
     */
    'helpText': string;
    /**
     * 
     * @type {number}
     * @memberof DateQuestion
     */
    'id': number;
    /**
     * 
     * @type {boolean}
     * @memberof DateQuestion
     */
    'isAnswered': boolean;
    /**
     * 
     * @type {string}
     * @memberof DateQuestion
     */
    'parentChoiceVal'?: string;
    /**
     * 
     * @type {number}
     * @memberof DateQuestion
     */
    'parentQuestionId'?: number;
    /**
     * 
     * @type {string}
     * @memberof DateQuestion
     */
    'richHelpText': string;
    /**
     * 
     * @type {string}
     * @memberof DateQuestion
     */
    'richText': string;
    /**
     * 
     * @type {string}
     * @memberof DateQuestion
     */
    'text': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DateQuestion
     */
    'context': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof DateQuestion
     */
    'type': string;
    /**
     * 
     * @type {Links}
     * @memberof DateQuestion
     */
    '_links': Links;
}
/**
 * @type DetailedAnswer
 * @export
 */
export type DetailedAnswer = AmountDetailedAnswer | BloodPressureDetailedAnswer | ConfirmationDetailedAnswer | DateChoiceDetailedAnswer | DateDetailedAnswer | DoctorDetailedAnswer | GuardianCoverageDetailedAnswer | MultipleChoiceDetailedAnswer | NumericChoiceDetailedAnswer | NumericDetailedAnswer | PercentDetailedAnswer | RxConfirmationDetailedAnswer | SearchDetailedAnswer | SingleChoiceDetailedAnswer | StatementDetailedAnswer | TextDetailedAnswer | UnitizedChoiceDetailedAnswer | UnitizedDetailedAnswer;

/**
 * 
 * @export
 * @interface DoctorAnswer
 */
export interface DoctorAnswer {
    /**
     * 
     * @type {string}
     * @memberof DoctorAnswer
     */
    'questionType': string;
    /**
     * 
     * @type {string}
     * @memberof DoctorAnswer
     */
    'key': string;
}
/**
 * 
 * @export
 * @interface DoctorDetailedAnswer
 */
export interface DoctorDetailedAnswer {
    /**
     * 
     * @type {string}
     * @memberof DoctorDetailedAnswer
     */
    'questionType': string;
    /**
     * 
     * @type {string}
     * @memberof DoctorDetailedAnswer
     */
    'key': string;
}
/**
 * 
 * @export
 * @interface DoctorQuestion
 */
export interface DoctorQuestion {
    /**
     * 
     * @type {DoctorDetailedAnswer}
     * @memberof DoctorQuestion
     */
    'answer'?: DoctorDetailedAnswer;
    /**
     * 
     * @type {Constraints5}
     * @memberof DoctorQuestion
     */
    'constraints': Constraints5;
    /**
     * 
     * @type {string}
     * @memberof DoctorQuestion
     */
    'externalId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DoctorQuestion
     */
    'helpText': string;
    /**
     * 
     * @type {number}
     * @memberof DoctorQuestion
     */
    'id': number;
    /**
     * 
     * @type {boolean}
     * @memberof DoctorQuestion
     */
    'isAnswered': boolean;
    /**
     * 
     * @type {string}
     * @memberof DoctorQuestion
     */
    'parentChoiceVal'?: string;
    /**
     * 
     * @type {number}
     * @memberof DoctorQuestion
     */
    'parentQuestionId'?: number;
    /**
     * 
     * @type {string}
     * @memberof DoctorQuestion
     */
    'richHelpText': string;
    /**
     * 
     * @type {string}
     * @memberof DoctorQuestion
     */
    'richText': string;
    /**
     * 
     * @type {string}
     * @memberof DoctorQuestion
     */
    'text': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DoctorQuestion
     */
    'context': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof DoctorQuestion
     */
    'type': string;
    /**
     * 
     * @type {Links}
     * @memberof DoctorQuestion
     */
    '_links': Links;
}
/**
 * 
 * @export
 * @interface FailureItem
 */
export interface FailureItem {
    /**
     * 
     * @type {string}
     * @memberof FailureItem
     */
    'attribute': string;
    /**
     * 
     * @type {number}
     * @memberof FailureItem
     */
    'dateTimestamp': number;
    /**
     * 
     * @type {number}
     * @memberof FailureItem
     */
    'questionId': number;
}
/**
 * 
 * @export
 * @interface GuardianCoverageAnswer
 */
export interface GuardianCoverageAnswer {
    /**
     * 
     * @type {Array<CoverageAmounts>}
     * @memberof GuardianCoverageAnswer
     */
    'guardianProductCoverages': Array<CoverageAmounts>;
    /**
     * 
     * @type {string}
     * @memberof GuardianCoverageAnswer
     */
    'questionType': string;
    /**
     * 
     * @type {boolean}
     * @memberof GuardianCoverageAnswer
     */
    'unknownAnswer': boolean;
}
/**
 * 
 * @export
 * @interface GuardianCoverageDetailedAnswer
 */
export interface GuardianCoverageDetailedAnswer {
    /**
     * 
     * @type {Array<CoverageAmounts>}
     * @memberof GuardianCoverageDetailedAnswer
     */
    'guardianProductCoverages': Array<CoverageAmounts>;
    /**
     * 
     * @type {string}
     * @memberof GuardianCoverageDetailedAnswer
     */
    'questionType': string;
    /**
     * 
     * @type {boolean}
     * @memberof GuardianCoverageDetailedAnswer
     */
    'unknownAnswer': boolean;
}
/**
 * 
 * @export
 * @interface GuardianCoverageQuestion
 */
export interface GuardianCoverageQuestion {
    /**
     * 
     * @type {GuardianCoverageDetailedAnswer}
     * @memberof GuardianCoverageQuestion
     */
    'answer'?: GuardianCoverageDetailedAnswer;
    /**
     * 
     * @type {Constraints6}
     * @memberof GuardianCoverageQuestion
     */
    'constraints': Constraints6;
    /**
     * 
     * @type {string}
     * @memberof GuardianCoverageQuestion
     */
    'externalId'?: string;
    /**
     * 
     * @type {string}
     * @memberof GuardianCoverageQuestion
     */
    'helpText': string;
    /**
     * 
     * @type {number}
     * @memberof GuardianCoverageQuestion
     */
    'id': number;
    /**
     * 
     * @type {boolean}
     * @memberof GuardianCoverageQuestion
     */
    'isAnswered': boolean;
    /**
     * 
     * @type {string}
     * @memberof GuardianCoverageQuestion
     */
    'parentChoiceVal'?: string;
    /**
     * 
     * @type {number}
     * @memberof GuardianCoverageQuestion
     */
    'parentQuestionId'?: number;
    /**
     * 
     * @type {string}
     * @memberof GuardianCoverageQuestion
     */
    'richHelpText': string;
    /**
     * 
     * @type {string}
     * @memberof GuardianCoverageQuestion
     */
    'richText': string;
    /**
     * 
     * @type {string}
     * @memberof GuardianCoverageQuestion
     */
    'text': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GuardianCoverageQuestion
     */
    'context': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof GuardianCoverageQuestion
     */
    'type': string;
    /**
     * 
     * @type {Links}
     * @memberof GuardianCoverageQuestion
     */
    '_links': Links;
}
/**
 * 
 * @export
 * @interface InputXmlWithLocale
 */
export interface InputXmlWithLocale {
    /**
     * 
     * @type {string}
     * @memberof InputXmlWithLocale
     */
    'inputXml': string;
    /**
     * 
     * @type {string}
     * @memberof InputXmlWithLocale
     */
    'localeCode': string;
}
/**
 * @type InseparableQuestion
 * @export
 */
export type InseparableQuestion = DateChoiceQuestion | NumericChoiceQuestion | SingleChoiceQuestion | UnitizedChoiceQuestion;

/**
 * 
 * @export
 * @interface InterviewResult
 */
export interface InterviewResult {
    /**
     * 
     * @type {string}
     * @memberof InterviewResult
     */
    'rulesResults': string;
    /**
     * 
     * @type {string}
     * @memberof InterviewResult
     */
    'interviewDetails': string;
}
/**
 * 
 * @export
 * @interface Links
 */
export interface Links {
    /**
     * 
     * @type {LinksSessionStatus}
     * @memberof Links
     */
    'sessionStatus': LinksSessionStatus;
    /**
     * 
     * @type {SessionStatusLinksSelf}
     * @memberof Links
     */
    'self': SessionStatusLinksSelf;
    /**
     * 
     * @type {SessionStatusLinksSelf}
     * @memberof Links
     */
    'section': SessionStatusLinksSelf;
    /**
     * 
     * @type {SessionStatusLinksSelf}
     * @memberof Links
     */
    'subQuestions'?: SessionStatusLinksSelf;
    /**
     * 
     * @type {Array<LinksSubQuestionsByChoiceInner>}
     * @memberof Links
     */
    'subQuestionsByChoice'?: Array<LinksSubQuestionsByChoiceInner>;
}
/**
 * 
 * @export
 * @interface LinksSessionStatus
 */
export interface LinksSessionStatus {
    /**
     * 
     * @type {boolean}
     * @memberof LinksSessionStatus
     */
    'completed': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LinksSessionStatus
     */
    'submitted': boolean;
    /**
     * 
     * @type {string}
     * @memberof LinksSessionStatus
     */
    'href': string;
}
/**
 * 
 * @export
 * @interface LinksSubQuestionsByChoiceInner
 */
export interface LinksSubQuestionsByChoiceInner {
    /**
     * 
     * @type {string}
     * @memberof LinksSubQuestionsByChoiceInner
     */
    'choiceVal': string;
    /**
     * 
     * @type {string}
     * @memberof LinksSubQuestionsByChoiceInner
     */
    'href': string;
}
/**
 * 
 * @export
 * @interface MultipleChoice
 */
export interface MultipleChoice {
    /**
     * 
     * @type {string}
     * @memberof MultipleChoice
     */
    'questionType'?: string;
    /**
     * 
     * @type {string}
     * @memberof MultipleChoice
     */
    'richText': string;
    /**
     * 
     * @type {string}
     * @memberof MultipleChoice
     */
    'text': string;
    /**
     * 
     * @type {boolean}
     * @memberof MultipleChoice
     */
    'unknownAnswer': boolean;
    /**
     * 
     * @type {string}
     * @memberof MultipleChoice
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface MultipleChoiceAnswer
 */
export interface MultipleChoiceAnswer {
    /**
     * 
     * @type {string}
     * @memberof MultipleChoiceAnswer
     */
    'questionType': string;
    /**
     * 
     * @type {Array<MultipleChoiceAnswerItem>}
     * @memberof MultipleChoiceAnswer
     */
    'answers': Array<MultipleChoiceAnswerItem>;
}
/**
 * 
 * @export
 * @interface MultipleChoiceAnswerItem
 */
export interface MultipleChoiceAnswerItem {
    /**
     * 
     * @type {string}
     * @memberof MultipleChoiceAnswerItem
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface MultipleChoiceDetailedAnswer
 */
export interface MultipleChoiceDetailedAnswer {
    /**
     * 
     * @type {Array<MultipleChoice>}
     * @memberof MultipleChoiceDetailedAnswer
     */
    'answers': Array<MultipleChoice>;
    /**
     * 
     * @type {string}
     * @memberof MultipleChoiceDetailedAnswer
     */
    'questionType': string;
    /**
     * 
     * @type {boolean}
     * @memberof MultipleChoiceDetailedAnswer
     */
    'unknownAnswer': boolean;
}
/**
 * 
 * @export
 * @interface MultipleChoiceQuestion
 */
export interface MultipleChoiceQuestion {
    /**
     * 
     * @type {Constraints7}
     * @memberof MultipleChoiceQuestion
     */
    'constraints': Constraints7;
    /**
     * 
     * @type {MultipleChoiceDetailedAnswer}
     * @memberof MultipleChoiceQuestion
     */
    'answer'?: MultipleChoiceDetailedAnswer;
    /**
     * 
     * @type {string}
     * @memberof MultipleChoiceQuestion
     */
    'externalId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MultipleChoiceQuestion
     */
    'helpText': string;
    /**
     * 
     * @type {number}
     * @memberof MultipleChoiceQuestion
     */
    'id': number;
    /**
     * 
     * @type {boolean}
     * @memberof MultipleChoiceQuestion
     */
    'isAnswered': boolean;
    /**
     * 
     * @type {string}
     * @memberof MultipleChoiceQuestion
     */
    'parentChoiceVal'?: string;
    /**
     * 
     * @type {number}
     * @memberof MultipleChoiceQuestion
     */
    'parentQuestionId'?: number;
    /**
     * 
     * @type {string}
     * @memberof MultipleChoiceQuestion
     */
    'richHelpText': string;
    /**
     * 
     * @type {string}
     * @memberof MultipleChoiceQuestion
     */
    'richText': string;
    /**
     * 
     * @type {string}
     * @memberof MultipleChoiceQuestion
     */
    'text': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof MultipleChoiceQuestion
     */
    'context': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof MultipleChoiceQuestion
     */
    'type': string;
    /**
     * 
     * @type {Links}
     * @memberof MultipleChoiceQuestion
     */
    '_links': Links;
}
/**
 * 
 * @export
 * @interface NumericAnswer
 */
export interface NumericAnswer {
    /**
     * 
     * @type {string}
     * @memberof NumericAnswer
     */
    'questionType': string;
    /**
     * 
     * @type {string}
     * @memberof NumericAnswer
     */
    'value': string;
    /**
     * 
     * @type {boolean}
     * @memberof NumericAnswer
     */
    'unknownAnswer': boolean;
}
/**
 * 
 * @export
 * @interface NumericChoice
 */
export interface NumericChoice {
    /**
     * 
     * @type {string}
     * @memberof NumericChoice
     */
    'questionType': string;
    /**
     * 
     * @type {string}
     * @memberof NumericChoice
     */
    'richText': string;
    /**
     * 
     * @type {string}
     * @memberof NumericChoice
     */
    'text': string;
    /**
     * 
     * @type {boolean}
     * @memberof NumericChoice
     */
    'unknownAnswer': boolean;
    /**
     * 
     * @type {string}
     * @memberof NumericChoice
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface NumericChoiceAnswer
 */
export interface NumericChoiceAnswer {
    /**
     * 
     * @type {string}
     * @memberof NumericChoiceAnswer
     */
    'questionType': string;
    /**
     * 
     * @type {string}
     * @memberof NumericChoiceAnswer
     */
    'value': string;
    /**
     * 
     * @type {boolean}
     * @memberof NumericChoiceAnswer
     */
    'unknownAnswer': boolean;
}
/**
 * 
 * @export
 * @interface NumericChoiceDetailedAnswer
 */
export interface NumericChoiceDetailedAnswer {
    /**
     * 
     * @type {string}
     * @memberof NumericChoiceDetailedAnswer
     */
    'questionType': string;
    /**
     * 
     * @type {string}
     * @memberof NumericChoiceDetailedAnswer
     */
    'richText': string;
    /**
     * 
     * @type {string}
     * @memberof NumericChoiceDetailedAnswer
     */
    'text': string;
    /**
     * 
     * @type {boolean}
     * @memberof NumericChoiceDetailedAnswer
     */
    'unknownAnswer': boolean;
    /**
     * 
     * @type {string}
     * @memberof NumericChoiceDetailedAnswer
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface NumericChoiceQuestion
 */
export interface NumericChoiceQuestion {
    /**
     * 
     * @type {NumericChoiceDetailedAnswer}
     * @memberof NumericChoiceQuestion
     */
    'answer'?: NumericChoiceDetailedAnswer;
    /**
     * 
     * @type {Constraints8}
     * @memberof NumericChoiceQuestion
     */
    'constraints': Constraints8;
    /**
     * 
     * @type {string}
     * @memberof NumericChoiceQuestion
     */
    'externalId'?: string;
    /**
     * 
     * @type {string}
     * @memberof NumericChoiceQuestion
     */
    'helpText': string;
    /**
     * 
     * @type {number}
     * @memberof NumericChoiceQuestion
     */
    'id': number;
    /**
     * 
     * @type {boolean}
     * @memberof NumericChoiceQuestion
     */
    'isAnswered': boolean;
    /**
     * 
     * @type {string}
     * @memberof NumericChoiceQuestion
     */
    'parentChoiceVal'?: string;
    /**
     * 
     * @type {number}
     * @memberof NumericChoiceQuestion
     */
    'parentQuestionId'?: number;
    /**
     * 
     * @type {string}
     * @memberof NumericChoiceQuestion
     */
    'richHelpText': string;
    /**
     * 
     * @type {string}
     * @memberof NumericChoiceQuestion
     */
    'richText': string;
    /**
     * 
     * @type {string}
     * @memberof NumericChoiceQuestion
     */
    'text': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof NumericChoiceQuestion
     */
    'context': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof NumericChoiceQuestion
     */
    'type': string;
    /**
     * 
     * @type {Links}
     * @memberof NumericChoiceQuestion
     */
    '_links': Links;
}
/**
 * 
 * @export
 * @interface NumericDetailedAnswer
 */
export interface NumericDetailedAnswer {
    /**
     * 
     * @type {string}
     * @memberof NumericDetailedAnswer
     */
    'questionType': string;
    /**
     * 
     * @type {string}
     * @memberof NumericDetailedAnswer
     */
    'value': string;
    /**
     * 
     * @type {boolean}
     * @memberof NumericDetailedAnswer
     */
    'unknownAnswer': boolean;
}
/**
 * 
 * @export
 * @interface NumericQuestion
 */
export interface NumericQuestion {
    /**
     * 
     * @type {NumericDetailedAnswer}
     * @memberof NumericQuestion
     */
    'answer'?: NumericDetailedAnswer;
    /**
     * 
     * @type {Constraints9}
     * @memberof NumericQuestion
     */
    'constraints': Constraints9;
    /**
     * 
     * @type {string}
     * @memberof NumericQuestion
     */
    'externalId'?: string;
    /**
     * 
     * @type {string}
     * @memberof NumericQuestion
     */
    'helpText': string;
    /**
     * 
     * @type {number}
     * @memberof NumericQuestion
     */
    'id': number;
    /**
     * 
     * @type {boolean}
     * @memberof NumericQuestion
     */
    'isAnswered': boolean;
    /**
     * 
     * @type {string}
     * @memberof NumericQuestion
     */
    'parentChoiceVal'?: string;
    /**
     * 
     * @type {number}
     * @memberof NumericQuestion
     */
    'parentQuestionId'?: number;
    /**
     * 
     * @type {string}
     * @memberof NumericQuestion
     */
    'richHelpText': string;
    /**
     * 
     * @type {string}
     * @memberof NumericQuestion
     */
    'richText': string;
    /**
     * 
     * @type {string}
     * @memberof NumericQuestion
     */
    'text': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof NumericQuestion
     */
    'context': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof NumericQuestion
     */
    'type': string;
    /**
     * 
     * @type {Links}
     * @memberof NumericQuestion
     */
    '_links': Links;
}
/**
 * 
 * @export
 * @interface PercentAnswer
 */
export interface PercentAnswer {
    /**
     * 
     * @type {string}
     * @memberof PercentAnswer
     */
    'questionType': string;
    /**
     * 
     * @type {Array<AmountAnswerAnswersInner>}
     * @memberof PercentAnswer
     */
    'answers': Array<AmountAnswerAnswersInner>;
    /**
     * 
     * @type {boolean}
     * @memberof PercentAnswer
     */
    'unknownAnswer': boolean;
}
/**
 * 
 * @export
 * @interface PercentDetailedAnswer
 */
export interface PercentDetailedAnswer {
    /**
     * 
     * @type {string}
     * @memberof PercentDetailedAnswer
     */
    'questionType': string;
    /**
     * 
     * @type {Array<AmountAnswerAnswersInner>}
     * @memberof PercentDetailedAnswer
     */
    'answers': Array<AmountAnswerAnswersInner>;
    /**
     * 
     * @type {boolean}
     * @memberof PercentDetailedAnswer
     */
    'unknownAnswer': boolean;
}
/**
 * 
 * @export
 * @interface PercentQuestion
 */
export interface PercentQuestion {
    /**
     * 
     * @type {PercentDetailedAnswer}
     * @memberof PercentQuestion
     */
    'answer'?: PercentDetailedAnswer;
    /**
     * 
     * @type {Constraints10}
     * @memberof PercentQuestion
     */
    'constraints': Constraints10;
    /**
     * 
     * @type {string}
     * @memberof PercentQuestion
     */
    'externalId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PercentQuestion
     */
    'helpText': string;
    /**
     * 
     * @type {number}
     * @memberof PercentQuestion
     */
    'id': number;
    /**
     * 
     * @type {boolean}
     * @memberof PercentQuestion
     */
    'isAnswered': boolean;
    /**
     * 
     * @type {string}
     * @memberof PercentQuestion
     */
    'parentChoiceVal'?: string;
    /**
     * 
     * @type {number}
     * @memberof PercentQuestion
     */
    'parentQuestionId'?: number;
    /**
     * 
     * @type {string}
     * @memberof PercentQuestion
     */
    'richHelpText': string;
    /**
     * 
     * @type {string}
     * @memberof PercentQuestion
     */
    'richText': string;
    /**
     * 
     * @type {string}
     * @memberof PercentQuestion
     */
    'text': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PercentQuestion
     */
    'context': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof PercentQuestion
     */
    'type': string;
    /**
     * 
     * @type {Links}
     * @memberof PercentQuestion
     */
    '_links': Links;
}
/**
 * @type Question
 * @export
 */
export type Question = AmountQuestion | BloodPressureQuestion | ConfirmationQuestion | DateChoiceQuestion | DateQuestion | DoctorQuestion | GuardianCoverageQuestion | MultipleChoiceQuestion | NumericChoiceQuestion | NumericQuestion | PercentQuestion | RxConfirmationQuestion | SearchQuestion | SingleChoiceQuestion | StatementQuestion | TextQuestion | UnitizedChoiceQuestion | UnitizedQuestion;

/**
 * 
 * @export
 * @interface Risk
 */
export interface Risk {
    /**
     * 
     * @type {string}
     * @memberof Risk
     */
    'clientName': string;
    /**
     * 
     * @type {string}
     * @memberof Risk
     */
    'response': string;
    /**
     * 
     * @type {string}
     * @memberof Risk
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof Risk
     */
    'errorMessage'?: string;
    /**
     * 
     * @type {SessionStatusLinks}
     * @memberof Risk
     */
    '_links': SessionStatusLinks;
}
/**
 * 
 * @export
 * @interface RxConfirmationAnswer
 */
export interface RxConfirmationAnswer {
    /**
     * 
     * @type {string}
     * @memberof RxConfirmationAnswer
     */
    'questionType': string;
    /**
     * 
     * @type {string}
     * @memberof RxConfirmationAnswer
     */
    'value': string;
    /**
     * 
     * @type {boolean}
     * @memberof RxConfirmationAnswer
     */
    'unknownAnswer': boolean;
}
/**
 * 
 * @export
 * @interface RxConfirmationDetailedAnswer
 */
export interface RxConfirmationDetailedAnswer {
    /**
     * 
     * @type {string}
     * @memberof RxConfirmationDetailedAnswer
     */
    'questionType': string;
    /**
     * 
     * @type {string}
     * @memberof RxConfirmationDetailedAnswer
     */
    'richText': string;
    /**
     * 
     * @type {string}
     * @memberof RxConfirmationDetailedAnswer
     */
    'text': string;
    /**
     * 
     * @type {boolean}
     * @memberof RxConfirmationDetailedAnswer
     */
    'unknownAnswer': boolean;
    /**
     * 
     * @type {string}
     * @memberof RxConfirmationDetailedAnswer
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface RxConfirmationQuestion
 */
export interface RxConfirmationQuestion {
    /**
     * 
     * @type {RxConfirmationDetailedAnswer}
     * @memberof RxConfirmationQuestion
     */
    'answer'?: RxConfirmationDetailedAnswer;
    /**
     * 
     * @type {Constraints11}
     * @memberof RxConfirmationQuestion
     */
    'constraints': Constraints11;
    /**
     * 
     * @type {string}
     * @memberof RxConfirmationQuestion
     */
    'externalId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RxConfirmationQuestion
     */
    'helpText': string;
    /**
     * 
     * @type {number}
     * @memberof RxConfirmationQuestion
     */
    'id': number;
    /**
     * 
     * @type {boolean}
     * @memberof RxConfirmationQuestion
     */
    'isAnswered': boolean;
    /**
     * 
     * @type {string}
     * @memberof RxConfirmationQuestion
     */
    'parentChoiceVal'?: string;
    /**
     * 
     * @type {number}
     * @memberof RxConfirmationQuestion
     */
    'parentQuestionId'?: number;
    /**
     * 
     * @type {string}
     * @memberof RxConfirmationQuestion
     */
    'richHelpText': string;
    /**
     * 
     * @type {string}
     * @memberof RxConfirmationQuestion
     */
    'richText': string;
    /**
     * 
     * @type {string}
     * @memberof RxConfirmationQuestion
     */
    'text': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RxConfirmationQuestion
     */
    'context': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof RxConfirmationQuestion
     */
    'type': string;
    /**
     * 
     * @type {Links}
     * @memberof RxConfirmationQuestion
     */
    '_links': Links;
}
/**
 * 
 * @export
 * @interface SearchAnswer
 */
export interface SearchAnswer {
    /**
     * 
     * @type {string}
     * @memberof SearchAnswer
     */
    'questionType': string;
    /**
     * 
     * @type {Array<SearchChoice>}
     * @memberof SearchAnswer
     */
    'answers': Array<SearchChoice>;
}
/**
 * 
 * @export
 * @interface SearchChoice
 */
export interface SearchChoice {
    /**
     * 
     * @type {string}
     * @memberof SearchChoice
     */
    'text': string;
    /**
     * 
     * @type {string}
     * @memberof SearchChoice
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface SearchDetailedAnswer
 */
export interface SearchDetailedAnswer {
    /**
     * 
     * @type {string}
     * @memberof SearchDetailedAnswer
     */
    'questionType': string;
    /**
     * 
     * @type {Array<SearchChoice>}
     * @memberof SearchDetailedAnswer
     */
    'answers': Array<SearchChoice>;
}
/**
 * 
 * @export
 * @interface SearchQuestion
 */
export interface SearchQuestion {
    /**
     * 
     * @type {SearchDetailedAnswer}
     * @memberof SearchQuestion
     */
    'answer'?: SearchDetailedAnswer;
    /**
     * 
     * @type {Constraints12}
     * @memberof SearchQuestion
     */
    'constraints': Constraints12;
    /**
     * 
     * @type {string}
     * @memberof SearchQuestion
     */
    'externalId'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchQuestion
     */
    'helpText': string;
    /**
     * 
     * @type {number}
     * @memberof SearchQuestion
     */
    'id': number;
    /**
     * 
     * @type {boolean}
     * @memberof SearchQuestion
     */
    'isAnswered': boolean;
    /**
     * 
     * @type {string}
     * @memberof SearchQuestion
     */
    'parentChoiceVal'?: string;
    /**
     * 
     * @type {number}
     * @memberof SearchQuestion
     */
    'parentQuestionId'?: number;
    /**
     * 
     * @type {string}
     * @memberof SearchQuestion
     */
    'richHelpText': string;
    /**
     * 
     * @type {string}
     * @memberof SearchQuestion
     */
    'richText': string;
    /**
     * 
     * @type {string}
     * @memberof SearchQuestion
     */
    'text': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchQuestion
     */
    'context': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SearchQuestion
     */
    'type': string;
    /**
     * 
     * @type {Links}
     * @memberof SearchQuestion
     */
    '_links': Links;
}
/**
 * @type SeparableQuestion
 * @export
 */
export type SeparableQuestion = MultipleChoiceQuestion | SearchQuestion;

/**
 * 
 * @export
 * @interface Session
 */
export interface Session {
    /**
     * 
     * @type {string}
     * @memberof Session
     */
    'clientName': string;
    /**
     * 
     * @type {string}
     * @memberof Session
     */
    'inputXml': string;
    /**
     * 
     * @type {number}
     * @memberof Session
     */
    'interviewId': number;
    /**
     * 
     * @type {string}
     * @memberof Session
     */
    'localeCode': string;
    /**
     * 
     * @type {string}
     * @memberof Session
     */
    'sessionId': string;
    /**
     * 
     * @type {string}
     * @memberof Session
     */
    'templateVersion': string;
    /**
     * 
     * @type {SessionEmbedded}
     * @memberof Session
     */
    '_embedded': SessionEmbedded;
    /**
     * 
     * @type {SessionLinks}
     * @memberof Session
     */
    '_links': SessionLinks;
}
/**
 * 
 * @export
 * @interface SessionEmbedded
 */
export interface SessionEmbedded {
    /**
     * 
     * @type {SessionStatus}
     * @memberof SessionEmbedded
     */
    'status': SessionStatus;
}
/**
 * 
 * @export
 * @interface SessionLinks
 */
export interface SessionLinks {
    /**
     * 
     * @type {SessionStatusLinksSelf}
     * @memberof SessionLinks
     */
    'questions': SessionStatusLinksSelf;
    /**
     * 
     * @type {Array<SessionLinksSectionsInner>}
     * @memberof SessionLinks
     */
    'sections': Array<SessionLinksSectionsInner>;
    /**
     * 
     * @type {SessionStatusLinksSelf}
     * @memberof SessionLinks
     */
    'self': SessionStatusLinksSelf;
}
/**
 * 
 * @export
 * @interface SessionLinksSectionsInner
 */
export interface SessionLinksSectionsInner {
    /**
     * 
     * @type {string}
     * @memberof SessionLinksSectionsInner
     */
    'href': string;
    /**
     * 
     * @type {string}
     * @memberof SessionLinksSectionsInner
     */
    'title': string;
}
/**
 * 
 * @export
 * @interface SessionStatus
 */
export interface SessionStatus {
    /**
     * 
     * @type {boolean}
     * @memberof SessionStatus
     */
    'completed': boolean;
    /**
     * 
     * @type {number}
     * @memberof SessionStatus
     */
    'lastUsedDate': number;
    /**
     * 
     * @type {boolean}
     * @memberof SessionStatus
     */
    'submitted': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof SessionStatus
     */
    'warnings': Array<string>;
    /**
     * 
     * @type {SessionStatusLinks}
     * @memberof SessionStatus
     */
    '_links': SessionStatusLinks;
}
/**
 * 
 * @export
 * @interface SessionStatusLinks
 */
export interface SessionStatusLinks {
    /**
     * 
     * @type {SessionStatusLinksSelf}
     * @memberof SessionStatusLinks
     */
    'self': SessionStatusLinksSelf;
}
/**
 * 
 * @export
 * @interface SessionStatusLinksSelf
 */
export interface SessionStatusLinksSelf {
    /**
     * 
     * @type {string}
     * @memberof SessionStatusLinksSelf
     */
    'href': string;
}
/**
 * 
 * @export
 * @interface SingleChoice
 */
export interface SingleChoice {
    /**
     * 
     * @type {string}
     * @memberof SingleChoice
     */
    'questionType': string;
    /**
     * 
     * @type {string}
     * @memberof SingleChoice
     */
    'richText': string;
    /**
     * 
     * @type {string}
     * @memberof SingleChoice
     */
    'text': string;
    /**
     * 
     * @type {boolean}
     * @memberof SingleChoice
     */
    'unknownAnswer': boolean;
    /**
     * 
     * @type {string}
     * @memberof SingleChoice
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface SingleChoiceAnswer
 */
export interface SingleChoiceAnswer {
    /**
     * 
     * @type {string}
     * @memberof SingleChoiceAnswer
     */
    'questionType': string;
    /**
     * 
     * @type {string}
     * @memberof SingleChoiceAnswer
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface SingleChoiceDetailedAnswer
 */
export interface SingleChoiceDetailedAnswer {
    /**
     * 
     * @type {string}
     * @memberof SingleChoiceDetailedAnswer
     */
    'questionType': string;
    /**
     * 
     * @type {string}
     * @memberof SingleChoiceDetailedAnswer
     */
    'richText': string;
    /**
     * 
     * @type {string}
     * @memberof SingleChoiceDetailedAnswer
     */
    'text': string;
    /**
     * 
     * @type {boolean}
     * @memberof SingleChoiceDetailedAnswer
     */
    'unknownAnswer': boolean;
    /**
     * 
     * @type {string}
     * @memberof SingleChoiceDetailedAnswer
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface SingleChoiceQuestion
 */
export interface SingleChoiceQuestion {
    /**
     * 
     * @type {SingleChoiceDetailedAnswer}
     * @memberof SingleChoiceQuestion
     */
    'answer'?: SingleChoiceDetailedAnswer;
    /**
     * 
     * @type {Constraints13}
     * @memberof SingleChoiceQuestion
     */
    'constraints': Constraints13;
    /**
     * 
     * @type {string}
     * @memberof SingleChoiceQuestion
     */
    'externalId'?: string;
    /**
     * 
     * @type {string}
     * @memberof SingleChoiceQuestion
     */
    'helpText': string;
    /**
     * 
     * @type {number}
     * @memberof SingleChoiceQuestion
     */
    'id': number;
    /**
     * 
     * @type {boolean}
     * @memberof SingleChoiceQuestion
     */
    'isAnswered': boolean;
    /**
     * 
     * @type {string}
     * @memberof SingleChoiceQuestion
     */
    'parentChoiceVal'?: string;
    /**
     * 
     * @type {number}
     * @memberof SingleChoiceQuestion
     */
    'parentQuestionId'?: number;
    /**
     * 
     * @type {string}
     * @memberof SingleChoiceQuestion
     */
    'richHelpText': string;
    /**
     * 
     * @type {string}
     * @memberof SingleChoiceQuestion
     */
    'richText': string;
    /**
     * 
     * @type {string}
     * @memberof SingleChoiceQuestion
     */
    'text': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SingleChoiceQuestion
     */
    'context': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SingleChoiceQuestion
     */
    'type': string;
    /**
     * 
     * @type {Links}
     * @memberof SingleChoiceQuestion
     */
    '_links': Links;
}
/**
 * 
 * @export
 * @interface StatementAnswer
 */
export interface StatementAnswer {
    /**
     * 
     * @type {string}
     * @memberof StatementAnswer
     */
    'questionType': string;
    /**
     * 
     * @type {boolean}
     * @memberof StatementAnswer
     */
    'unknownAnswer': boolean;
}
/**
 * 
 * @export
 * @interface StatementDetailedAnswer
 */
export interface StatementDetailedAnswer {
    /**
     * 
     * @type {string}
     * @memberof StatementDetailedAnswer
     */
    'questionType': string;
    /**
     * 
     * @type {boolean}
     * @memberof StatementDetailedAnswer
     */
    'unknownAnswer': boolean;
}
/**
 * 
 * @export
 * @interface StatementQuestion
 */
export interface StatementQuestion {
    /**
     * 
     * @type {StatementDetailedAnswer}
     * @memberof StatementQuestion
     */
    'answer'?: StatementDetailedAnswer;
    /**
     * 
     * @type {Constraints14}
     * @memberof StatementQuestion
     */
    'constraints': Constraints14;
    /**
     * 
     * @type {string}
     * @memberof StatementQuestion
     */
    'externalId'?: string;
    /**
     * 
     * @type {string}
     * @memberof StatementQuestion
     */
    'helpText': string;
    /**
     * 
     * @type {number}
     * @memberof StatementQuestion
     */
    'id': number;
    /**
     * 
     * @type {boolean}
     * @memberof StatementQuestion
     */
    'isAnswered': boolean;
    /**
     * 
     * @type {string}
     * @memberof StatementQuestion
     */
    'parentChoiceVal'?: string;
    /**
     * 
     * @type {number}
     * @memberof StatementQuestion
     */
    'parentQuestionId'?: number;
    /**
     * 
     * @type {string}
     * @memberof StatementQuestion
     */
    'richHelpText': string;
    /**
     * 
     * @type {string}
     * @memberof StatementQuestion
     */
    'richText': string;
    /**
     * 
     * @type {string}
     * @memberof StatementQuestion
     */
    'text': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof StatementQuestion
     */
    'context': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof StatementQuestion
     */
    'type': string;
    /**
     * 
     * @type {Links}
     * @memberof StatementQuestion
     */
    '_links': Links;
}
/**
 * 
 * @export
 * @interface StatusUpdateFailure
 */
export interface StatusUpdateFailure {
    /**
     * 
     * @type {string}
     * @memberof StatusUpdateFailure
     */
    'message': string;
    /**
     * 
     * @type {Array<StatusUpdateFailureErrorsInner>}
     * @memberof StatusUpdateFailure
     */
    'errors': Array<StatusUpdateFailureErrorsInner>;
}
/**
 * 
 * @export
 * @interface StatusUpdateFailureErrorsInner
 */
export interface StatusUpdateFailureErrorsInner {
    /**
     * 
     * @type {FailureItem}
     * @memberof StatusUpdateFailureErrorsInner
     */
    'earlierDate': FailureItem;
    /**
     * 
     * @type {FailureItem}
     * @memberof StatusUpdateFailureErrorsInner
     */
    'laterDate': FailureItem;
    /**
     * 
     * @type {string}
     * @memberof StatusUpdateFailureErrorsInner
     */
    'errorType': string;
}
/**
 * 
 * @export
 * @interface Submission
 */
export interface Submission {
    /**
     * 
     * @type {boolean}
     * @memberof Submission
     */
    'submitted': boolean;
}
/**
 * 
 * @export
 * @interface TextAnswer
 */
export interface TextAnswer {
    /**
     * 
     * @type {string}
     * @memberof TextAnswer
     */
    'questionType': string;
    /**
     * 
     * @type {string}
     * @memberof TextAnswer
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface TextDetailedAnswer
 */
export interface TextDetailedAnswer {
    /**
     * 
     * @type {string}
     * @memberof TextDetailedAnswer
     */
    'questionType': string;
    /**
     * 
     * @type {string}
     * @memberof TextDetailedAnswer
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface TextQuestion
 */
export interface TextQuestion {
    /**
     * 
     * @type {TextDetailedAnswer}
     * @memberof TextQuestion
     */
    'answer'?: TextDetailedAnswer;
    /**
     * 
     * @type {Constraints14}
     * @memberof TextQuestion
     */
    'constraints': Constraints14;
    /**
     * 
     * @type {string}
     * @memberof TextQuestion
     */
    'externalId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TextQuestion
     */
    'helpText': string;
    /**
     * 
     * @type {number}
     * @memberof TextQuestion
     */
    'id': number;
    /**
     * 
     * @type {boolean}
     * @memberof TextQuestion
     */
    'isAnswered': boolean;
    /**
     * 
     * @type {string}
     * @memberof TextQuestion
     */
    'parentChoiceVal'?: string;
    /**
     * 
     * @type {number}
     * @memberof TextQuestion
     */
    'parentQuestionId'?: number;
    /**
     * 
     * @type {string}
     * @memberof TextQuestion
     */
    'richHelpText': string;
    /**
     * 
     * @type {string}
     * @memberof TextQuestion
     */
    'richText': string;
    /**
     * 
     * @type {string}
     * @memberof TextQuestion
     */
    'text': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof TextQuestion
     */
    'context': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof TextQuestion
     */
    'type': string;
    /**
     * 
     * @type {Links}
     * @memberof TextQuestion
     */
    '_links': Links;
}
/**
 * 
 * @export
 * @interface UnitizedAnswer
 */
export interface UnitizedAnswer {
    /**
     * 
     * @type {Array<string>}
     * @memberof UnitizedAnswer
     */
    'answerParts': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UnitizedAnswer
     */
    'questionType': string;
    /**
     * 
     * @type {string}
     * @memberof UnitizedAnswer
     */
    'selectedUnit': string;
}
/**
 * 
 * @export
 * @interface UnitizedChoice
 */
export interface UnitizedChoice {
    /**
     * 
     * @type {string}
     * @memberof UnitizedChoice
     */
    'questionType': string;
    /**
     * 
     * @type {string}
     * @memberof UnitizedChoice
     */
    'richText': string;
    /**
     * 
     * @type {string}
     * @memberof UnitizedChoice
     */
    'text': string;
    /**
     * 
     * @type {boolean}
     * @memberof UnitizedChoice
     */
    'unknownAnswer': boolean;
    /**
     * 
     * @type {string}
     * @memberof UnitizedChoice
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface UnitizedChoiceAnswer
 */
export interface UnitizedChoiceAnswer {
    /**
     * 
     * @type {string}
     * @memberof UnitizedChoiceAnswer
     */
    'questionType': string;
    /**
     * 
     * @type {string}
     * @memberof UnitizedChoiceAnswer
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface UnitizedChoiceDetailedAnswer
 */
export interface UnitizedChoiceDetailedAnswer {
    /**
     * 
     * @type {string}
     * @memberof UnitizedChoiceDetailedAnswer
     */
    'questionType': string;
    /**
     * 
     * @type {string}
     * @memberof UnitizedChoiceDetailedAnswer
     */
    'richText': string;
    /**
     * 
     * @type {string}
     * @memberof UnitizedChoiceDetailedAnswer
     */
    'text': string;
    /**
     * 
     * @type {boolean}
     * @memberof UnitizedChoiceDetailedAnswer
     */
    'unknownAnswer': boolean;
    /**
     * 
     * @type {string}
     * @memberof UnitizedChoiceDetailedAnswer
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface UnitizedChoiceQuestion
 */
export interface UnitizedChoiceQuestion {
    /**
     * 
     * @type {UnitizedChoiceDetailedAnswer}
     * @memberof UnitizedChoiceQuestion
     */
    'answer'?: UnitizedChoiceDetailedAnswer;
    /**
     * 
     * @type {Constraints15}
     * @memberof UnitizedChoiceQuestion
     */
    'constraints': Constraints15;
    /**
     * 
     * @type {string}
     * @memberof UnitizedChoiceQuestion
     */
    'externalId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UnitizedChoiceQuestion
     */
    'helpText': string;
    /**
     * 
     * @type {number}
     * @memberof UnitizedChoiceQuestion
     */
    'id': number;
    /**
     * 
     * @type {boolean}
     * @memberof UnitizedChoiceQuestion
     */
    'isAnswered': boolean;
    /**
     * 
     * @type {string}
     * @memberof UnitizedChoiceQuestion
     */
    'parentChoiceVal'?: string;
    /**
     * 
     * @type {number}
     * @memberof UnitizedChoiceQuestion
     */
    'parentQuestionId'?: number;
    /**
     * 
     * @type {string}
     * @memberof UnitizedChoiceQuestion
     */
    'richHelpText': string;
    /**
     * 
     * @type {string}
     * @memberof UnitizedChoiceQuestion
     */
    'richText': string;
    /**
     * 
     * @type {string}
     * @memberof UnitizedChoiceQuestion
     */
    'text': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UnitizedChoiceQuestion
     */
    'context': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UnitizedChoiceQuestion
     */
    'type': string;
    /**
     * 
     * @type {Links}
     * @memberof UnitizedChoiceQuestion
     */
    '_links': Links;
}
/**
 * 
 * @export
 * @interface UnitizedConstraints
 */
export interface UnitizedConstraints {
    /**
     * 
     * @type {boolean}
     * @memberof UnitizedConstraints
     */
    'canBeUnknown': boolean;
    /**
     * 
     * @type {string}
     * @memberof UnitizedConstraints
     */
    'componentType': string;
    /**
     * 
     * @type {Array<UnitizedConstraintsAvailableUnitsInner>}
     * @memberof UnitizedConstraints
     */
    'availableUnits': Array<UnitizedConstraintsAvailableUnitsInner>;
}
/**
 * 
 * @export
 * @interface UnitizedConstraintsAvailableUnitsInner
 */
export interface UnitizedConstraintsAvailableUnitsInner {
    /**
     * 
     * @type {number}
     * @memberof UnitizedConstraintsAvailableUnitsInner
     */
    'decimalPrecision': number;
    /**
     * 
     * @type {string}
     * @memberof UnitizedConstraintsAvailableUnitsInner
     */
    'unitSymbol': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UnitizedConstraintsAvailableUnitsInner
     */
    'symbolParts': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UnitizedConstraintsAvailableUnitsInner
     */
    'label': string;
    /**
     * 
     * @type {Array<UnitizedConstraintsAvailableUnitsInnerValidationRangesInner>}
     * @memberof UnitizedConstraintsAvailableUnitsInner
     */
    'validationRanges': Array<UnitizedConstraintsAvailableUnitsInnerValidationRangesInner>;
}
/**
 * 
 * @export
 * @interface UnitizedConstraintsAvailableUnitsInnerValidationRangesInner
 */
export interface UnitizedConstraintsAvailableUnitsInnerValidationRangesInner {
    /**
     * 
     * @type {number}
     * @memberof UnitizedConstraintsAvailableUnitsInnerValidationRangesInner
     */
    'upper': number;
    /**
     * 
     * @type {number}
     * @memberof UnitizedConstraintsAvailableUnitsInnerValidationRangesInner
     */
    'lower': number;
}
/**
 * 
 * @export
 * @interface UnitizedDetailedAnswer
 */
export interface UnitizedDetailedAnswer {
    /**
     * 
     * @type {Array<string>}
     * @memberof UnitizedDetailedAnswer
     */
    'answerParts': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UnitizedDetailedAnswer
     */
    'questionType': string;
    /**
     * 
     * @type {string}
     * @memberof UnitizedDetailedAnswer
     */
    'selectedUnit': string;
}
/**
 * 
 * @export
 * @interface UnitizedQuestion
 */
export interface UnitizedQuestion {
    /**
     * 
     * @type {UnitizedConstraints}
     * @memberof UnitizedQuestion
     */
    'constraints': UnitizedConstraints;
    /**
     * 
     * @type {UnitizedDetailedAnswer}
     * @memberof UnitizedQuestion
     */
    'answer'?: UnitizedDetailedAnswer;
    /**
     * 
     * @type {string}
     * @memberof UnitizedQuestion
     */
    'externalId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UnitizedQuestion
     */
    'helpText': string;
    /**
     * 
     * @type {number}
     * @memberof UnitizedQuestion
     */
    'id': number;
    /**
     * 
     * @type {boolean}
     * @memberof UnitizedQuestion
     */
    'isAnswered': boolean;
    /**
     * 
     * @type {string}
     * @memberof UnitizedQuestion
     */
    'parentChoiceVal'?: string;
    /**
     * 
     * @type {number}
     * @memberof UnitizedQuestion
     */
    'parentQuestionId'?: number;
    /**
     * 
     * @type {string}
     * @memberof UnitizedQuestion
     */
    'richHelpText': string;
    /**
     * 
     * @type {string}
     * @memberof UnitizedQuestion
     */
    'richText': string;
    /**
     * 
     * @type {string}
     * @memberof UnitizedQuestion
     */
    'text': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UnitizedQuestion
     */
    'context': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UnitizedQuestion
     */
    'type': string;
    /**
     * 
     * @type {Links}
     * @memberof UnitizedQuestion
     */
    '_links': Links;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} ifMatch 
         * @param {string} clientId 
         * @param {string} sessionId 
         * @param {number} questionId 
         * @param {string} tenant 
         * @param {Answer} answer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        answerQuestion: async (ifMatch: string, clientId: string, sessionId: string, questionId: number, tenant: string, answer: Answer, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ifMatch' is not null or undefined
            assertParamExists('answerQuestion', 'ifMatch', ifMatch)
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('answerQuestion', 'clientId', clientId)
            // verify required parameter 'sessionId' is not null or undefined
            assertParamExists('answerQuestion', 'sessionId', sessionId)
            // verify required parameter 'questionId' is not null or undefined
            assertParamExists('answerQuestion', 'questionId', questionId)
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('answerQuestion', 'tenant', tenant)
            // verify required parameter 'answer' is not null or undefined
            assertParamExists('answerQuestion', 'answer', answer)
            const localVarPath = `/disclosures/api/v1/clients/{clientId}/sessions/{sessionId}/questions/{questionId}`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)))
                .replace(`{${"sessionId"}}`, encodeURIComponent(String(sessionId)))
                .replace(`{${"questionId"}}`, encodeURIComponent(String(questionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (tenant !== undefined) {
                localVarQueryParameter['tenant'] = tenant;
            }

            if (ifMatch != null) {
                localVarHeaderParameter['if-match'] = String(ifMatch);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(answer, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} sessionId 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateRisk: async (clientId: string, sessionId: string, tenant: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('calculateRisk', 'clientId', clientId)
            // verify required parameter 'sessionId' is not null or undefined
            assertParamExists('calculateRisk', 'sessionId', sessionId)
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('calculateRisk', 'tenant', tenant)
            const localVarPath = `/disclosures/api/v1/clients/{clientId}/sessions/{sessionId}/riskCalculations`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)))
                .replace(`{${"sessionId"}}`, encodeURIComponent(String(sessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (tenant !== undefined) {
                localVarQueryParameter['tenant'] = tenant;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} sessionId 
         * @param {string} tenant 
         * @param {InputXmlWithLocale} inputXmlWithLocale 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSession: async (clientId: string, sessionId: string, tenant: string, inputXmlWithLocale: InputXmlWithLocale, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('createSession', 'clientId', clientId)
            // verify required parameter 'sessionId' is not null or undefined
            assertParamExists('createSession', 'sessionId', sessionId)
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('createSession', 'tenant', tenant)
            // verify required parameter 'inputXmlWithLocale' is not null or undefined
            assertParamExists('createSession', 'inputXmlWithLocale', inputXmlWithLocale)
            const localVarPath = `/disclosures/api/v1/clients/{clientId}/sessions/{sessionId}`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)))
                .replace(`{${"sessionId"}}`, encodeURIComponent(String(sessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tenant !== undefined) {
                localVarQueryParameter['tenant'] = tenant;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inputXmlWithLocale, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchAuthentication: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/aura/auth`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} sessionId 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchInterviewResult: async (clientId: string, sessionId: string, tenant: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('fetchInterviewResult', 'clientId', clientId)
            // verify required parameter 'sessionId' is not null or undefined
            assertParamExists('fetchInterviewResult', 'sessionId', sessionId)
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('fetchInterviewResult', 'tenant', tenant)
            const localVarPath = `/disclosures/api/v1/clients/{clientId}/sessions/{sessionId}/interviewResults`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)))
                .replace(`{${"sessionId"}}`, encodeURIComponent(String(sessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (tenant !== undefined) {
                localVarQueryParameter['tenant'] = tenant;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} sessionId 
         * @param {number} questionId 
         * @param {string} tenant 
         * @param {string} search 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchQuestion: async (clientId: string, sessionId: string, questionId: number, tenant: string, search: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('fetchQuestion', 'clientId', clientId)
            // verify required parameter 'sessionId' is not null or undefined
            assertParamExists('fetchQuestion', 'sessionId', sessionId)
            // verify required parameter 'questionId' is not null or undefined
            assertParamExists('fetchQuestion', 'questionId', questionId)
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('fetchQuestion', 'tenant', tenant)
            // verify required parameter 'search' is not null or undefined
            assertParamExists('fetchQuestion', 'search', search)
            const localVarPath = `/disclosures/api/v1/clients/{clientId}/sessions/{sessionId}/questions/{questionId}`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)))
                .replace(`{${"sessionId"}}`, encodeURIComponent(String(sessionId)))
                .replace(`{${"questionId"}}`, encodeURIComponent(String(questionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (tenant !== undefined) {
                localVarQueryParameter['tenant'] = tenant;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} sessionId 
         * @param {string} [tenant] 
         * @param {number} [parentQuestionId] 
         * @param {string} [parentChoiceVal] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchQuestions: async (clientId: string, sessionId: string, tenant?: string, parentQuestionId?: number, parentChoiceVal?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('fetchQuestions', 'clientId', clientId)
            // verify required parameter 'sessionId' is not null or undefined
            assertParamExists('fetchQuestions', 'sessionId', sessionId)
            const localVarPath = `/disclosures/api/v1/clients/{clientId}/sessions/{sessionId}/questions/`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)))
                .replace(`{${"sessionId"}}`, encodeURIComponent(String(sessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (tenant !== undefined) {
                localVarQueryParameter['tenant'] = tenant;
            }

            if (parentQuestionId !== undefined) {
                localVarQueryParameter['parentQuestionId'] = parentQuestionId;
            }

            if (parentChoiceVal !== undefined) {
                localVarQueryParameter['parentChoiceVal'] = parentChoiceVal;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} sessionId 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchRisk: async (clientId: string, sessionId: string, tenant: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('fetchRisk', 'clientId', clientId)
            // verify required parameter 'sessionId' is not null or undefined
            assertParamExists('fetchRisk', 'sessionId', sessionId)
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('fetchRisk', 'tenant', tenant)
            const localVarPath = `/disclosures/api/v1/clients/{clientId}/sessions/{sessionId}/riskCalculations`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)))
                .replace(`{${"sessionId"}}`, encodeURIComponent(String(sessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (tenant !== undefined) {
                localVarQueryParameter['tenant'] = tenant;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} sessionId 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchSession: async (clientId: string, sessionId: string, tenant: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('fetchSession', 'clientId', clientId)
            // verify required parameter 'sessionId' is not null or undefined
            assertParamExists('fetchSession', 'sessionId', sessionId)
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('fetchSession', 'tenant', tenant)
            const localVarPath = `/disclosures/api/v1/clients/{clientId}/sessions/{sessionId}`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)))
                .replace(`{${"sessionId"}}`, encodeURIComponent(String(sessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (tenant !== undefined) {
                localVarQueryParameter['tenant'] = tenant;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} sessionId 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchSessionStatus: async (clientId: string, sessionId: string, tenant: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('fetchSessionStatus', 'clientId', clientId)
            // verify required parameter 'sessionId' is not null or undefined
            assertParamExists('fetchSessionStatus', 'sessionId', sessionId)
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('fetchSessionStatus', 'tenant', tenant)
            const localVarPath = `/disclosures/api/v1/clients/{clientId}/sessions/{sessionId}/status`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)))
                .replace(`{${"sessionId"}}`, encodeURIComponent(String(sessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (tenant !== undefined) {
                localVarQueryParameter['tenant'] = tenant;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} sessionId 
         * @param {string} tenant 
         * @param {InputXmlWithLocale} inputXmlWithLocale 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSession: async (clientId: string, sessionId: string, tenant: string, inputXmlWithLocale: InputXmlWithLocale, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('updateSession', 'clientId', clientId)
            // verify required parameter 'sessionId' is not null or undefined
            assertParamExists('updateSession', 'sessionId', sessionId)
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('updateSession', 'tenant', tenant)
            // verify required parameter 'inputXmlWithLocale' is not null or undefined
            assertParamExists('updateSession', 'inputXmlWithLocale', inputXmlWithLocale)
            const localVarPath = `/disclosures/api/v1/clients/{clientId}/sessions/{sessionId}`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)))
                .replace(`{${"sessionId"}}`, encodeURIComponent(String(sessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tenant !== undefined) {
                localVarQueryParameter['tenant'] = tenant;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inputXmlWithLocale, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} ifMatch 
         * @param {string} clientId 
         * @param {string} sessionId 
         * @param {string} tenant 
         * @param {Submission} submission 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSessionStatus: async (ifMatch: string, clientId: string, sessionId: string, tenant: string, submission: Submission, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ifMatch' is not null or undefined
            assertParamExists('updateSessionStatus', 'ifMatch', ifMatch)
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('updateSessionStatus', 'clientId', clientId)
            // verify required parameter 'sessionId' is not null or undefined
            assertParamExists('updateSessionStatus', 'sessionId', sessionId)
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('updateSessionStatus', 'tenant', tenant)
            // verify required parameter 'submission' is not null or undefined
            assertParamExists('updateSessionStatus', 'submission', submission)
            const localVarPath = `/disclosures/api/v1/clients/{clientId}/sessions/{sessionId}/status`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)))
                .replace(`{${"sessionId"}}`, encodeURIComponent(String(sessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (tenant !== undefined) {
                localVarQueryParameter['tenant'] = tenant;
            }

            if (ifMatch != null) {
                localVarHeaderParameter['if-match'] = String(ifMatch);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(submission, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} ifMatch 
         * @param {string} clientId 
         * @param {string} sessionId 
         * @param {number} questionId 
         * @param {string} tenant 
         * @param {Answer} answer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async answerQuestion(ifMatch: string, clientId: string, sessionId: string, questionId: number, tenant: string, answer: Answer, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Question>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.answerQuestion(ifMatch, clientId, sessionId, questionId, tenant, answer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} sessionId 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calculateRisk(clientId: string, sessionId: string, tenant: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Risk>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calculateRisk(clientId, sessionId, tenant, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} sessionId 
         * @param {string} tenant 
         * @param {InputXmlWithLocale} inputXmlWithLocale 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSession(clientId: string, sessionId: string, tenant: string, inputXmlWithLocale: InputXmlWithLocale, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Session>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSession(clientId, sessionId, tenant, inputXmlWithLocale, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchAuthentication(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Authentication>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchAuthentication(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} sessionId 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchInterviewResult(clientId: string, sessionId: string, tenant: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InterviewResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchInterviewResult(clientId, sessionId, tenant, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} sessionId 
         * @param {number} questionId 
         * @param {string} tenant 
         * @param {string} search 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchQuestion(clientId: string, sessionId: string, questionId: number, tenant: string, search: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Question>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchQuestion(clientId, sessionId, questionId, tenant, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} sessionId 
         * @param {string} [tenant] 
         * @param {number} [parentQuestionId] 
         * @param {string} [parentChoiceVal] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchQuestions(clientId: string, sessionId: string, tenant?: string, parentQuestionId?: number, parentChoiceVal?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Question>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchQuestions(clientId, sessionId, tenant, parentQuestionId, parentChoiceVal, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} sessionId 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchRisk(clientId: string, sessionId: string, tenant: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Risk>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchRisk(clientId, sessionId, tenant, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} sessionId 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchSession(clientId: string, sessionId: string, tenant: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Session>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchSession(clientId, sessionId, tenant, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} sessionId 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchSessionStatus(clientId: string, sessionId: string, tenant: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SessionStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchSessionStatus(clientId, sessionId, tenant, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} sessionId 
         * @param {string} tenant 
         * @param {InputXmlWithLocale} inputXmlWithLocale 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSession(clientId: string, sessionId: string, tenant: string, inputXmlWithLocale: InputXmlWithLocale, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Session>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSession(clientId, sessionId, tenant, inputXmlWithLocale, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} ifMatch 
         * @param {string} clientId 
         * @param {string} sessionId 
         * @param {string} tenant 
         * @param {Submission} submission 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSessionStatus(ifMatch: string, clientId: string, sessionId: string, tenant: string, submission: Submission, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSessionStatus(ifMatch, clientId, sessionId, tenant, submission, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @param {string} ifMatch 
         * @param {string} clientId 
         * @param {string} sessionId 
         * @param {number} questionId 
         * @param {string} tenant 
         * @param {Answer} answer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        answerQuestion(ifMatch: string, clientId: string, sessionId: string, questionId: number, tenant: string, answer: Answer, options?: any): AxiosPromise<Question> {
            return localVarFp.answerQuestion(ifMatch, clientId, sessionId, questionId, tenant, answer, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} sessionId 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateRisk(clientId: string, sessionId: string, tenant: string, options?: any): AxiosPromise<Risk> {
            return localVarFp.calculateRisk(clientId, sessionId, tenant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} sessionId 
         * @param {string} tenant 
         * @param {InputXmlWithLocale} inputXmlWithLocale 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSession(clientId: string, sessionId: string, tenant: string, inputXmlWithLocale: InputXmlWithLocale, options?: any): AxiosPromise<Session> {
            return localVarFp.createSession(clientId, sessionId, tenant, inputXmlWithLocale, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchAuthentication(options?: any): AxiosPromise<Authentication> {
            return localVarFp.fetchAuthentication(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} sessionId 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchInterviewResult(clientId: string, sessionId: string, tenant: string, options?: any): AxiosPromise<InterviewResult> {
            return localVarFp.fetchInterviewResult(clientId, sessionId, tenant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} sessionId 
         * @param {number} questionId 
         * @param {string} tenant 
         * @param {string} search 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchQuestion(clientId: string, sessionId: string, questionId: number, tenant: string, search: string, options?: any): AxiosPromise<Question> {
            return localVarFp.fetchQuestion(clientId, sessionId, questionId, tenant, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} sessionId 
         * @param {string} [tenant] 
         * @param {number} [parentQuestionId] 
         * @param {string} [parentChoiceVal] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchQuestions(clientId: string, sessionId: string, tenant?: string, parentQuestionId?: number, parentChoiceVal?: string, options?: any): AxiosPromise<Array<Question>> {
            return localVarFp.fetchQuestions(clientId, sessionId, tenant, parentQuestionId, parentChoiceVal, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} sessionId 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchRisk(clientId: string, sessionId: string, tenant: string, options?: any): AxiosPromise<Risk> {
            return localVarFp.fetchRisk(clientId, sessionId, tenant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} sessionId 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchSession(clientId: string, sessionId: string, tenant: string, options?: any): AxiosPromise<Session> {
            return localVarFp.fetchSession(clientId, sessionId, tenant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} sessionId 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchSessionStatus(clientId: string, sessionId: string, tenant: string, options?: any): AxiosPromise<SessionStatus> {
            return localVarFp.fetchSessionStatus(clientId, sessionId, tenant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} sessionId 
         * @param {string} tenant 
         * @param {InputXmlWithLocale} inputXmlWithLocale 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSession(clientId: string, sessionId: string, tenant: string, inputXmlWithLocale: InputXmlWithLocale, options?: any): AxiosPromise<Session> {
            return localVarFp.updateSession(clientId, sessionId, tenant, inputXmlWithLocale, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} ifMatch 
         * @param {string} clientId 
         * @param {string} sessionId 
         * @param {string} tenant 
         * @param {Submission} submission 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSessionStatus(ifMatch: string, clientId: string, sessionId: string, tenant: string, submission: Submission, options?: any): AxiosPromise<void> {
            return localVarFp.updateSessionStatus(ifMatch, clientId, sessionId, tenant, submission, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @param {string} ifMatch 
     * @param {string} clientId 
     * @param {string} sessionId 
     * @param {number} questionId 
     * @param {string} tenant 
     * @param {Answer} answer 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public answerQuestion(ifMatch: string, clientId: string, sessionId: string, questionId: number, tenant: string, answer: Answer, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).answerQuestion(ifMatch, clientId, sessionId, questionId, tenant, answer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} clientId 
     * @param {string} sessionId 
     * @param {string} tenant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public calculateRisk(clientId: string, sessionId: string, tenant: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).calculateRisk(clientId, sessionId, tenant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} clientId 
     * @param {string} sessionId 
     * @param {string} tenant 
     * @param {InputXmlWithLocale} inputXmlWithLocale 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createSession(clientId: string, sessionId: string, tenant: string, inputXmlWithLocale: InputXmlWithLocale, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createSession(clientId, sessionId, tenant, inputXmlWithLocale, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public fetchAuthentication(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).fetchAuthentication(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} clientId 
     * @param {string} sessionId 
     * @param {string} tenant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public fetchInterviewResult(clientId: string, sessionId: string, tenant: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).fetchInterviewResult(clientId, sessionId, tenant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} clientId 
     * @param {string} sessionId 
     * @param {number} questionId 
     * @param {string} tenant 
     * @param {string} search 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public fetchQuestion(clientId: string, sessionId: string, questionId: number, tenant: string, search: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).fetchQuestion(clientId, sessionId, questionId, tenant, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} clientId 
     * @param {string} sessionId 
     * @param {string} [tenant] 
     * @param {number} [parentQuestionId] 
     * @param {string} [parentChoiceVal] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public fetchQuestions(clientId: string, sessionId: string, tenant?: string, parentQuestionId?: number, parentChoiceVal?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).fetchQuestions(clientId, sessionId, tenant, parentQuestionId, parentChoiceVal, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} clientId 
     * @param {string} sessionId 
     * @param {string} tenant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public fetchRisk(clientId: string, sessionId: string, tenant: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).fetchRisk(clientId, sessionId, tenant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} clientId 
     * @param {string} sessionId 
     * @param {string} tenant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public fetchSession(clientId: string, sessionId: string, tenant: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).fetchSession(clientId, sessionId, tenant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} clientId 
     * @param {string} sessionId 
     * @param {string} tenant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public fetchSessionStatus(clientId: string, sessionId: string, tenant: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).fetchSessionStatus(clientId, sessionId, tenant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} clientId 
     * @param {string} sessionId 
     * @param {string} tenant 
     * @param {InputXmlWithLocale} inputXmlWithLocale 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateSession(clientId: string, sessionId: string, tenant: string, inputXmlWithLocale: InputXmlWithLocale, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateSession(clientId, sessionId, tenant, inputXmlWithLocale, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} ifMatch 
     * @param {string} clientId 
     * @param {string} sessionId 
     * @param {string} tenant 
     * @param {Submission} submission 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateSessionStatus(ifMatch: string, clientId: string, sessionId: string, tenant: string, submission: Submission, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateSessionStatus(ifMatch, clientId, sessionId, tenant, submission, options).then((request) => request(this.axios, this.basePath));
    }
}


