import React, { ReactNode, useState } from "react";
import styled from "styled-components";
import { HelpIcon } from "../atoms/HelpIcon";
import DOMPurify from "dompurify";

type Props = {
  richQuestionText?: string;
  richHelpText?: string;
  answerPart: ReactNode;
  index?: string;
  errorMsg?: string;
  confirmation?: boolean;
};

export const QuestionCardContainer: React.FC<Props> = ({
  richQuestionText,
  richHelpText,
  answerPart,
  index,
  errorMsg,
  confirmation,
}) => {
  const [displayHelp, setDisplayHelp] = useState(false);
  const dispalyHelpHandler = () => setDisplayHelp((existing) => !existing);

  return (
    <>
      <Container highlight={!!errorMsg}>
        {!confirmation && (
          <Left>
            {index} {richHelpText && <HelpIcon onClick={dispalyHelpHandler} />}
          </Left>
        )}
        <Right>
          {richQuestionText && (
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(richQuestionText),
              }}
            />
          )}
          {richHelpText && (
            <HelpText
              displayHelp={displayHelp}
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(richHelpText),
              }}
            />
          )}
          {answerPart}
        </Right>
      </Container>
      {errorMsg && <ErrorBottom>{errorMsg}</ErrorBottom>}
    </>
  );
};

const HelpText = styled.div<{ displayHelp: boolean }>`
  margin: 1rem 0 1rem 1rem;
  line-height: 1.2rem;
  color: blue;
  display: ${({ displayHelp }) => (displayHelp ? "block" : "none")};
`;

const Container = styled.div<{ highlight?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  font-style: normal;
  font-weight: normal;
  border: 1px solid lightgray;
  background-color: ${({ highlight }) => (highlight ? "#fcf" : "")};
`;

const ErrorBottom = styled.div`
  background-color: yellow;
  color: red;
  padding-left: 2.75rem;
`;

const Right = styled.div`
  padding: 0.25rem;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: normal;
  text-align: left;
  vertical-align: top;
  flex-basis: calc(100% - 2.25rem);
  min-width: 0;
`;

const Left = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 0.25rem;
  font-size: 1rem;
  background-color: #00008f;
  color: #fff;
  font-weight: bold;
  text-align: center;
  vertical-align: top;
  flex-basis: 1.75rem;
  min-width: 0;
`;
