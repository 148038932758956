import React from "react";
import styled from "styled-components";

type Props = {
  tableName: string;
  categoryNames?: Array<string>;
  categoryDetails: Array<string | Array<string>>;
  confirmation?: boolean;
  categorized?: boolean;
  comments?: Array<string>;
};

export const Table: React.FC<Props> = ({
  tableName,
  categoryNames,
  categoryDetails,
  confirmation,
  categorized,
  comments,
}) => {
  if (confirmation) {
    return (
      <ConfirmationTable>
        <tbody>
          <tr>
            <ConfirmationTd>{`◇${tableName}`}</ConfirmationTd>
          </tr>

          {categoryDetails?.map((catetoryDetail, index) => (
            <React.Fragment key={index}>
              {categoryNames && (
                <tr>
                  <ConfirmationTd>{categoryNames[index]}</ConfirmationTd>
                </tr>
              )}
              <tr>
                <ConfirmationTd>
                  {Array.isArray(catetoryDetail) ? (
                    <ListItems>
                      {catetoryDetail.map((detail, detailIndex) => (
                        <li key={detailIndex}>{detail}</li>
                      ))}
                    </ListItems>
                  ) : (
                    catetoryDetail
                  )}
                </ConfirmationTd>
              </tr>
            </React.Fragment>
          ))}

          {comments && (
            <tr>
              <ConfirmationTd>
                {comments.map((comment, commentIndex) => (
                  <React.Fragment key={commentIndex}>
                    {comment}
                    <br />
                  </React.Fragment>
                ))}
              </ConfirmationTd>
            </tr>
          )}
        </tbody>
      </ConfirmationTable>
    );
  }

  const flatedCategoryDetails = categoryDetails.flat();

  return (
    <>
      {categorized ? (
        <CategoryTable>
          <tbody>
            <tr>
              <td>
                <TableName>{tableName}</TableName>
                <ContentTable>
                  <tbody>
                    {categoryNames?.map((categoryName, index) => (
                      <tr key={index}>
                        <CategoryName>{categoryName}</CategoryName>
                        <CategoryDetails>
                          {flatedCategoryDetails[index]}
                        </CategoryDetails>
                      </tr>
                    ))}
                  </tbody>
                </ContentTable>
              </td>
            </tr>
          </tbody>
        </CategoryTable>
      ) : (
        <ListTable>
          <TableName>{tableName}</TableName>
          <StyledUl>
            {flatedCategoryDetails.map((detail, index) => (
              <li key={index}>{detail}</li>
            ))}
          </StyledUl>
        </ListTable>
      )}
      {comments && (
        <>
          {comments.map((comment, index) => (
            <StyledP key={index}>{comment}</StyledP>
          ))}
        </>
      )}
    </>
  );
};

const ContentTable = styled.table`
  width: 100%;
`;

const StyledP = styled.p`
  margin: 0;
  display: block;
  color: #103184;
`;

const StyledUl = styled.ul`
  list-style: square;
  border: solid 1px;
  color: #103184;
  margin-top: 0;
  padding-left: 1rem;
`;

const ListTable = styled.div`
  margin: 1rem;
`;

const ConfirmationTable = styled.table`
  width: 100%;
  border: 0.5px solid lightgray;
  border-spacing: 0;
  font-size: 0.75rem;
`;
const ConfirmationTd = styled.td`
  border: 0.5px solid lightgray;
  background-color: lightyellow;
  padding: 7px;
  font-weight: bold;
  white-space: pre-wrap;
  line-height: 24px;
`;

const CategoryTable = styled.table`
  width: 90%;
  padding-left: 1rem;
`;

const TableName = styled.div`
  width: 40px;
  margin: 10px 0 2px 2px;
  padding: 5px 0 3px 8px;
  font-size: 12pt;
  font-weight: bold;
  color: #103184;
  background: #ffffff;
  border: 1px solid #616d80;
`;

const CategoryName = styled.td`
  height: auto;
  padding: 5px;
  color: #ffffff;
  background: #00008f;
  text-align: center;
  box-shadow: 0px 1px 0px rgb(255 255 255 / 50%) inset;
  display: block;
`;

const CategoryDetails = styled.td`
  padding: 0px 5px;
  color: #00008f;
  background: #ffffff;
  border: 1px solid #576273;
  text-align: left;
  display: block;
`;

const ListItems = styled.ul`
  padding-left: 1rem;
  margin: 0;
`;
