import { useQuery } from "react-query";
import { auraServiceApi } from "../utils/AuraApi";

export const useSubQuestions = (
  clientId: string,
  sessionId: string,
  accessToken: string,
  parentQuestionId?: number,
  parentChoiceVal?: string
) =>
  useQuery(
    ["fetchSubQuestions", parentQuestionId, parentChoiceVal],
    () =>
      auraServiceApi.fetchQuestions(
        clientId,
        sessionId,
        undefined,
        parentQuestionId,
        parentChoiceVal,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      ),
    { enabled: !!parentQuestionId && !!parentChoiceVal, cacheTime: 0 }
  );
