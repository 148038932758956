export const toJapaneseYear = (yearStr: string) => {
  const year = parseInt(yearStr);
  if (year > 2019)
    // Everything till present is era of Reiwa, If an new era is to start, the logic here will need to be appended
    return `${year}年(令和${year - 2018}年)`;
  else if (year == 2019)
    //First Year of Reiwa
    return "2019年(令和元年)";
  else if (year > 1989)
    //era of Heisei is till 2018
    return `${year}年(平成${year - 1988}年)`;
  else if (year == 1989)
    //First Year of Heisei
    return "1989年(平成元年)";
  else if (year > 1926)
    //era of Showa is till 1988
    return `${year}年(昭和${year - 1925}年)`;
  else if (year == 1926)
    // First year of Showa begins
    return "1926年(昭和元年)";
  else if (year > 1912)
    //era of Taisho is till 1925
    return `${year}年(大正${year - 1911}年)`;
  else if (year == 1912)
    // First year of Taisho begins   Gregorian year+'year'+(era-yuang[meaning first])+'year']
    return "1912年(大正元年)";
  else if (year > 1899)
    // era of Meiji is till 1911
    return `${year}年(明治${year - 1867}年)`;
  else return `${year}年`;
};

export const dateToString = (date: Date) => `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
