import React from "react";
import styled from "styled-components";

import { Layout } from "@alj-react/ui-components";

export const EsignatureTemplate: React.FC = ({ children }) => (
  <StyledLayout>{children}</StyledLayout>
);

const StyledLayout = styled(Layout)`
  font-family: メイリオ, Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
`;
