export const unitize = (unitSymbol: string) => {
  switch (unitSymbol) {
    case "bsMgDl":
      return "mg/dL";
    case "cellsPerVolume10KuL":
      return "万/μL";
    case "cellsPerVolumeKml":
      return "/μL";
    case "cm":
      return "cm";
    case "kg":
      return "kg";
    case "cholMgDl":
      return "mg/dL";
    case "hemoglobinGDl":
      return "g/dL";
    case "liverGammaGTPuL":
      return "U/L";
    case "liverGOTuL":
      return "U/L";
    case "liverGPTuL":
      return "U/L";
    case "month":
      return "カ月";
    case "Percent":
      return "%";
    case "redBloodCellx10Power4uL":
      return "万/μL";
    case "trigMgDl":
      return "mg/dL";
    case "mg/dL_UricAcid":
      return "mg/dL";
    case "week":
      return "週";
    case "day":
      return "日";
    default:
      return "";
  }
};
