/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

const AxaLogotypeSvg = (props: any): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="155" height="48" viewBox="0 0 180.894 56.688" {...props}>
      <g id="Group_29" data-name="Group 29" transform="translate(-434.302 -191.135)">
        <g id="Group_27" data-name="Group 27">
          <rect id="Rectangle_1" data-name="Rectangle 1" width="56.693" height="56.688" transform="translate(434.302 191.135)" fill="#00008f" />
          <g id="Group_26" data-name="Group 26">
            <g id="Group_25" data-name="Group 25">
              <g id="Group_24" data-name="Group 24">
                <g id="Group_22" data-name="Group 22">
                  <path id="Path_70" data-name="Path 70" d="M469.434,219.129,491,191.135H487.81l-21.633,27.994Z" fill="#ff1721" />
                </g>
                <g id="Group_23" data-name="Group 23">
                  <path
                    id="Path_71"
                    data-name="Path 71"
                    d="M477.625,231.625c.983,2.765,3.018,9.956,3.828,10.531h-5.347a8.658,8.658,0,0,0-.252-1.888c-.223-.822-2.163-7.039-2.163-7.039H465.2l-1.334,1.891s1.6,5.018,1.7,5.266c.174.438.941,1.77.941,1.77H461.39s-.132-.761-.187-1.084c-.034-.258-.484-1.674-.484-1.674a15.265,15.265,0,0,0-1.477,1.865c-.321.588-.466.893-.466.893h-4s-.131-.761-.185-1.084c-.037-.258-.531-1.785-.531-1.785a21.267,21.267,0,0,0-1.44,1.949c-.318.592-.45.92-.45.92H448.2s1.12-1.066,1.514-1.523c.659-.778,3.12-3.99,3.12-3.99l-.989-3.414H443.42s-4.8,6.312-4.99,6.506a18.523,18.523,0,0,0-1.628,2.421h-2.5v-1.587a1.342,1.342,0,0,1,.1-.1c.074-.061,3.642-4.486,6.919-8.849,2.944-3.81,5.7-7.5,5.946-7.859a25.237,25.237,0,0,0,1.433-2.692h4.358a19.545,19.545,0,0,0,.255,2.1c.114.363,2.774,9.078,2.831,9.167l1.468-1.878-2.5-7.719a13.876,13.876,0,0,0-.778-1.67h5.084a4.451,4.451,0,0,0,.162,1.381c.208.639,1.3,4.572,1.3,4.572s3.47-4.351,3.669-4.663a2.784,2.784,0,0,0,.432-1.29h4.237a13.532,13.532,0,0,0-2.136,2.287c-.456.577-4.933,6.271-4.933,6.271s.39,1.327.579,1.993c.051.188.09.319.09.33a3.413,3.413,0,0,0,.273-.33c1.15-1.463,6.4-8.354,6.723-8.949a12.527,12.527,0,0,0,.855-1.6h4.139a11.317,11.317,0,0,0,.218,1.579Zm-6.3-6.523a56.428,56.428,0,0,1-4.185,5.658h5.665s-1.1-3.378-1.293-4.139a6.217,6.217,0,0,1-.11-1.481C471.4,225.072,471.39,224.959,471.328,225.1Zm-21.755,0a55.717,55.717,0,0,1-4.182,5.658h5.661s-1.1-3.378-1.288-4.139a5.936,5.936,0,0,1-.112-1.481C449.652,225.072,449.643,224.959,449.573,225.1Zm8.524,13.2,1.561-2.141c-.15-.155-1.026-2.82-1.026-2.82l-1.5,1.958Z"
                    fill="#fff"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
        <g id="Group_28" data-name="Group 28">
          <path
            id="Path_72"
            data-name="Path 72"
            d="M605.616,236.256l-.06,5.459c0,1.053-.256,6.107-.256,6.107h2.67l-.009-5.257c0-.739.1-3.327.135-4.067h3.173v4.623c0,.453-.138.529-.559.529l-2.1-.013.3,2.445S610.2,246,611.852,246a1.689,1.689,0,0,0,1.25-.476,2.457,2.457,0,0,0,.583-1.83l.047-7.435Z"
            fill="#00008f"
          />
          <path
            id="Path_73"
            data-name="Path 73"
            d="M596.4,236.256l.032,4.746c0,1.607-.247,5.187-.247,5.187h8.01l.033-5.39c0-1.236.189-4.543.189-4.543Zm2.476,2.271h2.985l-.1,5.327H598.8Z"
            fill="#00008f"
          />
          <path
            id="Path_74"
            data-name="Path 74"
            d="M610.062,230.76c-2.819-1.68-3.01-2.78-3.01-2.78h-3.315s-.58,1.211-3.513,2.926a18.176,18.176,0,0,1-5.021,2.144l0,2.473a41.555,41.555,0,0,0,4.166-1.446l-.018.986s4.246-.163,5.854-.1,5.706.1,5.706.1l.065-1.053c1.87.784,4.217,1.531,4.217,1.531v-2.539a19.114,19.114,0,0,1-5.134-2.234m-1.655,1.953-3.193.035-3.129-.035a18.334,18.334,0,0,0,3.266-2.389,13.8,13.8,0,0,0,3.056,2.389"
            fill="#00008f"
          />
          <path
            id="Path_75"
            data-name="Path 75"
            d="M588.289,244.871c-1.111,0-2.217.027-2.755.043v-4.343c.632-.015,3.034-.092,3.725-.041.842.058,2.853.132,2.853.132v-2.6s-1.37.078-2.843.132l-3.735.064v-3.634c.647,0,2.281-.012,2.953.016l4.11.154v-2.577s-3.209.077-4.046.077l-2.96.012c.033-.653.076-2.5.076-2.94,0-.5.115-1.381.115-1.381h-2.765s.03.819.057,1.393c.028.472.013,2.309.008,2.93h-3.96c.134-.288.325-.725.451-1.053.207-.526.6-1.918.621-1.977l.077-.271h-2.819l-.1.6a17.86,17.86,0,0,1-1.157,3.246c-1.008,2.4-2.086,2.787-2.086,2.787l-.005,3.041a6.882,6.882,0,0,0,2.75-2.076,22.232,22.232,0,0,0,1.271-1.981l4.863,0v3.611c-.63-.023-3.037-.078-4.11-.078-1.295,0-2.208-.072-2.208-.072l-.012,2.551s.741-.037,1.462-.037c.619,0,4.053-.059,4.868-.08v4.365c-.637.008-2.914.01-4.282-.014-1.642-.027-4.536-.12-4.536-.12v2.755s8.953-.133,9.671-.1,9.136.1,9.136.1v-2.781s-3.306.154-4.686.154"
            fill="#00008f"
          />
          <path
            id="Path_76"
            data-name="Path 76"
            d="M568.238,231.628l.206-3.63H565.6l.027,3.662-6.544.009.176-3.667h-2.776l-.06,3.658-4.562-.051-.006,2.655,4.586-.121c-.031.88-.134,3.943-.134,4.321,0,.424-.146,1.891-.146,1.911l-.022.232h2.808l.01-.207s.03-.89,0-1.652c-.027-.648.1-3.848.137-4.693h6.447v2.8a7.48,7.48,0,0,1-2.239,5.706c-1.966,1.8-5.617,2.393-5.617,2.393v2.609s5.086-.864,7.929-3.577c2.26-2.149,2.5-5.545,2.5-6.9,0-.453.076-2.324.1-2.949,0,0,2.837.014,3.432.014.212,0,1.194.055,1.194.055v-2.687s-1.843.04-1.2.04c.285,0-3.4.063-3.4.063"
            fill="#00008f"
          />
          <path
            id="Path_77"
            data-name="Path 77"
            d="M540.623,230.465l1.022-2.091h-3.1l-.094.453c0,.011-.213,1.483-2.5,4.273s-3.907,3.21-3.907,3.21l-.01,2.684a12.927,12.927,0,0,0,4.585-2.8,30.2,30.2,0,0,0,2.65-3.214H546.4a15.4,15.4,0,0,1-4.142,7.238,18.278,18.278,0,0,1-6.829,4.2,9.488,9.488,0,0,1-1.648.325v2.826a6.55,6.55,0,0,1,.937-.287,22.659,22.659,0,0,0,9.715-5.47c3.217-3.125,4.476-7.7,4.87-9.512l.012-.066c.117-.508.458-1.465.462-1.472l.1-.287Z"
            fill="#00008f"
          />
          <path
            id="Path_78"
            data-name="Path 78"
            d="M532.406,229.279H520.9c-2.561,0-7.227-.134-7.227-.134l0,2.763s2.016-.137,2.9-.137l5.468-.017H529a7.851,7.851,0,0,1-2.045,3.547,6.652,6.652,0,0,1-3.591,1.809c.029-.337.07-.932.07-1.421,0-.685.132-2.58.132-2.58h-2.732l-.054,3.717a8.958,8.958,0,0,1-2.622,5.662,8.8,8.8,0,0,1-4.186,2.311v2.748a16.812,16.812,0,0,0,6.237-3.373,9.159,9.159,0,0,0,2.692-4.4,11.092,11.092,0,0,0,6.411-3.151,15.1,15.1,0,0,0,3.064-7.1Z"
            fill="#00008f"
          />
        </g>
      </g>
    </svg>
  );
};

export default AxaLogotypeSvg;
