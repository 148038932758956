import React, { useEffect, useState } from "react";
import { Answer, NumericQuestion } from "../../../apis";
import { Input } from "../../atoms/Input";
import { QuestionCardContainer } from "../../layout/QuestionCardContainer";

type Props = {
  question: NumericQuestion;
  index: string;
  subIndex?: string;
  answerQuestion: (answer: Answer) => Promise<void>;
  errorMsg?: string;
  updateInputErrorCount: (key: number, count: number) => void;
  answerable: boolean;
};

export const NumericCard: React.FC<Props> = ({
  question,
  index,
  subIndex,
  answerQuestion,
  errorMsg,
  updateInputErrorCount,
  answerable,
}) => {
  const [existingAnswer, setExistingAnswer] = useState(
    question.answer?.value || ""
  );
  const [validationErr, setValidationErr] = useState<string>();

  const { minVal, maxVal, decimalPrecision } = question.constraints;

  useEffect(
    () => updateInputErrorCount(question.id, validationErr ? 1 : 0),
    [validationErr]
  );

  return (
    <QuestionCardContainer
      richQuestionText={question.richText}
      richHelpText={question.richHelpText}
      answerPart={
        <Input
          value={existingAnswer}
          validationErr={validationErr}
          onChange={(e) => {
            setExistingAnswer(e.target.value);
          }}
          onBlur={(e) => {
            const inputVal = e.target.value;

            if (
              validate(
                inputVal,
                minVal,
                maxVal,
                decimalPrecision,
                setValidationErr
              )
            ) {
              const answer = {
                questionType: "NUMERIC",
                value: inputVal,
              };

              answerQuestion({ answer });
            }
          }}
          disabled={!answerable}
        />
      }
      index={subIndex || index}
      errorMsg={errorMsg}
    />
  );
};

const validate = (
  value: string,
  lowerLimit: number,
  upperLimit: number,
  decimalPrecision: number | null,
  setValidationErr: React.Dispatch<React.SetStateAction<string | undefined>>
) => {
  if (value === "") {
    setValidationErr("回答を入力してください。");
    return false;
  } else if (!/^\d*\.?\d*$/.test(value)) {
    setValidationErr("数字で回答してください。");
    return false;
  } else if (
    decimalPrecision !== null &&
    value.split(".").length > 1 &&
    value.split(".")[1].length > decimalPrecision
  ) {
    setValidationErr(
      decimalPrecision === 0
        ? "整数で入力してください"
        : `小数点第${decimalPrecision}位までの入力としてください`
    );
    return false;
  }

  const numberVal = parseFloat(value);

  if (numberVal > upperLimit || numberVal < lowerLimit) {
    setValidationErr("入力した数字を再度ご確認ください。");
    return false;
  }

  setValidationErr(undefined);

  return true;
};
