import React from "react";
import { Table } from "../layout/Table";

type Props = {
  confirmation?: boolean;
};
export const Table2: React.FC<Props> = ({ confirmation }) => (
  <Table
    tableName={tableName}
    categoryNames={tableCategoryNames}
    categoryDetails={tableCategoryDetails}
    confirmation={confirmation}
    categorized={true}
  />
);

const tableName = "表２";

const tableCategoryNames = ["臓器・部位", "検査項目"];

const tableCategoryDetails = [
  "心臓・脳・甲状腺・肺・胃腸・肝臓・腎臓・すい臓・胆のう・前立腺・子宮・乳房",
  "心電図・血圧・尿・便・血液・眼底・レントゲン・内視鏡・超音波・CT・MRI・PET・マンモグラフィー",
];
