import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Answer, UnitizedConstraints, UnitizedQuestion } from "../../../apis";
import { unitize } from "../../../utils/unitizer";
import { Input } from "../../atoms/Input";
import { QuestionCardContainer } from "../../layout/QuestionCardContainer";

type Props = {
  question: UnitizedQuestion;
  index: string;
  subIndex?: string;
  answerQuestion: (answer: Answer) => void;
  errorMsg?: string;
  updateInputErrorCount: (key: number, count: number) => void;
  answerable: boolean;
};
export const UnitizedCard: React.FC<Props> = ({
  question,
  index,
  subIndex,
  answerQuestion,
  errorMsg,
  updateInputErrorCount,
  answerable,
}) => {
  const [currentAnswer, setCurrentAnswer] = useState(
    question.answer?.answerParts[0] || ""
  );
  const [validationErr, setValidationErr] = useState<string>();

  useEffect(
    () => updateInputErrorCount(question.id, validationErr ? 1 : 0),
    [validationErr]
  );

  return (
    <QuestionCardContainer
      richQuestionText={question.richText}
      richHelpText={question.richHelpText}
      answerPart={
        <AnswerPartContainer>
          <Input
            onChange={(e) => setCurrentAnswer(e.target.value)}
            onBlur={(e) => {
              const inputVal = e.target.value;
              if (validate(question.constraints, inputVal, setValidationErr)) {
                const answer = {
                  questionType: "UNITIZED",
                  selectedUnit:
                    question.constraints.availableUnits[0].unitSymbol,
                  answerParts: [inputVal],
                };
                answerQuestion({ answer });
              }
            }}
            value={currentAnswer}
            validationErr={validationErr}
            disabled={!answerable}
          />
          <UnitSymbol>
            {unitize(question.constraints.availableUnits[0].unitSymbol)}
          </UnitSymbol>
        </AnswerPartContainer>
      }
      index={subIndex || index}
      errorMsg={errorMsg}
    />
  );
};

const validate = (
  constraints: UnitizedConstraints,
  value: string,
  setValidationErr: React.Dispatch<React.SetStateAction<string | undefined>>
) => {
  if (value === "") {
    setValidationErr("回答を入力してください。");
    return false;
  } else if (!/^\d*\.?\d*$/.test(value)) {
    setValidationErr("数字で回答してください。");
    return false;
  }

  const numberVal = parseFloat(value);
  const range = constraints.availableUnits[0].validationRanges[0];
  const decimalPrecision = constraints.availableUnits[0].decimalPrecision;
  if (
    decimalPrecision !== null &&
    value.split(".").length > 1 &&
    value.split(".")[1].length > decimalPrecision
  ) {
    const err =
      decimalPrecision === 0
        ? "整数で入力してください"
        : `小数点第${decimalPrecision}位までの入力としてください`;
    setValidationErr(err);
    return false;
  }

  if (numberVal > range.upper || numberVal < range.lower) {
    setValidationErr("入力した数字を再度ご確認ください。");
    return false;
  }

  setValidationErr(undefined);
  return true;
};

const AnswerPartContainer = styled.div`
  margin: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const UnitSymbol = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: center;
  white-space: nowrap;
`;
