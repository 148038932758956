import React from "react";
import { Answer, SingleChoiceQuestion } from "../../../apis";
import { AnswerPartContainer } from "../../layout/AnswerPartContainer";
import { QuestionCardContainer } from "../../layout/QuestionCardContainer";
import { SingleChoiceRadio } from "../../molecules/SingleChoiceRadio";
import { SingleChoiceSelect } from "../../molecules/SingleChoiceSelect";

type Props = {
  question: SingleChoiceQuestion;
  index: string;
  subIndex?: string;
  answerQuestion: (answer: Answer) => Promise<void>;
  answerable: boolean;
  errorMsg?: string;
};

export const SingleChoiceCard: React.FC<Props> = ({
  question,
  index,
  subIndex,
  answerQuestion,
  answerable,
  errorMsg,
}) => {
  const options = question.constraints.choices.map(
    ({ text: label, value }) => ({ label, value })
  );
  const selected = question.answer && {
    label: question.answer.text,
    value: question.answer.value,
  };

  return (
    <QuestionCardContainer
      richQuestionText={question.richText}
      richHelpText={question.richHelpText}
      answerPart={
        <AnswerPartContainer>
          {question.constraints.componentType === "RadioResp" ? (
            <SingleChoiceRadio
              options={options}
              selected={selected}
              answerQuestion={answerQuestion}
              answerable={answerable}
            />
          ) : (
            <SingleChoiceSelect
              options={options}
              selected={selected}
              answerQuestion={answerQuestion}
            />
          )}
        </AnswerPartContainer>
      }
      index={subIndex || index}
      errorMsg={errorMsg}
    />
  );
};
