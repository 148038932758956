import React from "react";
import styled from "styled-components";

type Props = {
  validationErr?: string;
};
export const ValidationContainer: React.FC<Props> = ({
  validationErr,
  children,
}) => (
  <Container error={!!validationErr}>
    {children}
    {validationErr && <ErrorMessage>{validationErr}</ErrorMessage>}
  </Container>
);

const Container = styled.div<{ error?: boolean }>`
  width: 98%;
  padding-right: ${({ error }) => (error ? "0.5rem" : 0)};
  background-color: ${({ error }) => (error ? "#fcf" : "#fff")};
`;

const ErrorMessage = styled.span`
  word-break: break-all;
  color: red;
  padding-left: 0.3rem;
`;
