import React from "react";
import { Answer, NumericChoiceQuestion } from "../../../apis";
import { AnswerPartContainer } from "../../layout/AnswerPartContainer";
import { QuestionCardContainer } from "../../layout/QuestionCardContainer";
import { NumericChoiceRadio } from "../../molecules/NumericChoiceRadio";
import { NumericChoiceSelect } from "../../molecules/NumericChoiceSelect";

type Props = {
  question: NumericChoiceQuestion;
  index: string;
  subIndex?: string;
  answerQuestion: (answer: Answer) => Promise<void>;
  answerable: boolean;
  errorMsg?: string;
};

export const NumericChoiceCard: React.FC<Props> = ({
  question,
  index,
  subIndex,
  answerQuestion,
  answerable,
  errorMsg,
}) => {
  const options = question.constraints.choices.map(({ text, value }) => ({
    label: text,
    value,
  }));
  const initSelect = question.answer && {
    label: question.answer.text,
    value: question.answer.value,
  };

  return (
    <QuestionCardContainer
      richQuestionText={question.richText}
      richHelpText={question.richHelpText}
      answerPart={
        <AnswerPartContainer>
          {question.constraints.componentType === "RadioResp" ? (
            <NumericChoiceRadio
              options={options}
              answerQuestion={answerQuestion}
              initSelect={initSelect}
              answerable={answerable}
            />
          ) : (
            <NumericChoiceSelect
              options={options}
              answerQuestion={answerQuestion}
              initSelect={initSelect}
            />
          )}
        </AnswerPartContainer>
      }
      index={subIndex || index}
      errorMsg={errorMsg}
    />
  );
};
