
import React, { createContext, useReducer } from 'react';
import {
  SET_SHOWMODAL,
  SET_BDISPLAYERROR,
  SET_ISLOADING,
  SET_AURAXMLOUTPUT,
  SET_BDISPLAYCONFIRMATION,
  SET_BDISPLAYSIGNINPUT,
  SET_CHECKBOXSELECTED,
  SET_CURRENTPAGETODISPLAY,
  SET_DATATODISPLAY,
  SET_QUERYPARAMETERS,
  SET_SIGNATURE,
  SET_ISDOINGAURASTUFF,
  SET_APIRESULT,
  SET_INPUTFORMCONTENT,
} from './actions';

// Reducer for updating the store based on the 'action.type'
export const Reducer = (state: ContextState, action: ContextAction): any => {
  switch (action.type) {
    case SET_SHOWMODAL:
      return {
        ...state,
        showModal: action.payload,
      };
    case SET_APIRESULT:
      return {
        ...state,
        apiResult: action.payload,
      };
    case SET_BDISPLAYERROR:
      return {
        ...state,
        bDisplayError: action.payload,
      };
    case SET_ISLOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case SET_ISDOINGAURASTUFF:
      return {
        ...state,
        isDoingAuraStuff: action.payload,
      };
    case SET_AURAXMLOUTPUT:
      return {
        ...state,
        auraXmlOutput: action.payload,
      };
    case SET_BDISPLAYCONFIRMATION:
      return {
        ...state,
        bDisplayConfirmation: action.payload,
      };
    case SET_BDISPLAYSIGNINPUT:
      return {
        ...state,
        bDisplaySignInput: action.payload,
      };
    case SET_CHECKBOXSELECTED:
      return {
        ...state,
        checkboxSelected: action.payload,
      };
    case SET_CURRENTPAGETODISPLAY:
      return {
        ...state,
        currentPageToDisplay: action.payload,
      };
    case SET_DATATODISPLAY:
      return {
        ...state,
        dataToDisplay: action.payload,
      };
    case SET_QUERYPARAMETERS:
      return {
        ...state,
        queryParameters: action.payload,
      };
    case SET_SIGNATURE:
      return {
        ...state,
        signature: action.payload,
      };
    case SET_INPUTFORMCONTENT:
      return {
        ...state,
        inputResultList: action.payload,
      };
    default:
      return state;
  }
};

export const Context = createContext({} as ContextArgs);

// Initial state for the store
const initialState = {
  showModal: false,
  checkboxSelected: false,
  isLoading: true,
  isDoingAuraStuff: false,
  auraXmlOutput: '',
  currentPageToDisplay: 1,
  dataToDisplay: null,
  signature: '',
  bDisplaySignInput: false,
  bDisplayError: false,
  bDisplayConfirmation: false,
  queryParameters: null,
  apiResult: undefined,
  inputResultList: [],
};

export const ESignatureContext: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, initialState);
  const value = { state, dispatch };
  Context.displayName = 'Context';
  return (
    <Context.Provider value={value}>{children}</Context.Provider>
  );
};

export default ESignatureContext;
