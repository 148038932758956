import { Text } from "@alj-react/ui-components";
import React from "react";
import { UseQueryResult } from "react-query";
import styled from "styled-components";
import LoadingSpinner from "../molecules/LoadingSpinner";

type Props = {
  statuses: Array<Omit<UseQueryResult, "data">>;
};

export const UnsuccessfulRequest: React.FC<Props> = ({ statuses }) => {
  const errStatuses = statuses.filter((status) => status.isError);

  if (errStatuses.length) {
    const errStatus = errStatuses[0].error as any;
    const errMsg = errStatus.response?.data?.message || errStatus.message;
    return <ErrorMessageContainer>{errMsg}</ErrorMessageContainer>;
  }

  return <LoadingSpinner loading={true} />;
};

const ErrorMessageContainer = styled(Text)`
  padding-left: 14.5%;
`;
