import React, { useEffect, useState, useRef } from 'react';
import {
  ArrowButton,
  Text,
} from '@alj-react/ui-components';
import LoadingSpinner from '../molecules/LoadingSpinner';
import { useIntl } from 'react-intl';
import { defineMessages } from 'react-intl.macro';
import { useLocation } from 'react-router-dom';
import qs from 'qs';
import _ from 'lodash';

import DisplayPriorConsent from './DisplayPriorConsent';
import PlanIdIsAlreadySigned from './PlanIdIsAlreadySigned';
import PlanInvalidStatus from './PlanInvalidStatus';
import DisplayConfirmation from './DisplayConfirmation';
import DisplaySignInput from './DisplaySignInput';
import DisplayError from './DisplayError';
import DisplayFinish from './DisplayFinish';
import { ArrowButtonStyled, BottomNavDiv } from './ESignature.styles';
import {
  getComponent,
  getSignType,
  getTransitionBtnTxt,
  URL_API,
  sendToApi,
} from '../../utils/multi';
import { Context } from '../../utils/ESignatureContext';
import { setBDisplaySignInputSign,
  setAuraXmlOutputSign, setIsLoadingSign,
  setSignatureSign, setCheckboxSelectedSign,
  setBDisplayConfirmationSign, setDataToDisplaySign,
  setBDisplayErrorSign, setQueryParametersSign,
  setCurrentPageToDisplaySign, setIsDoingAuraStuffSign, setApiResultSign,
  setInputFormContentSign,
} from '../../utils/dataFetchers';
import { isJSONObject } from '../../utils/utils';

const messages = defineMessages({
  submitTxt: {
    id: 'ESignature.submitTxt',
    defaultMessage: 'Submit',
  },
  pleaseSignTxt: {
    id: 'ESignature.pleaseSignTxt',
    defaultMessage: 'Please sign in the space below',
  },
  clearSignBtnTxt: {
    id: 'ESignature.clearSignBtnTxt',
    defaultMessage: 'Erase',
  },
  saveSignBtnTxt: {
    id: 'ESignature.saveSignBtnTxt',
    defaultMessage: 'Save',
  },
  confirmScreenBtnTxt: {
    id: 'ESignature.confirmScreenBtnTxt',
    defaultMessage: 'Go to confirmation screen',
  },
  thankYouTxt1: {
    id: 'ESignature.thankYouTxt1',
    defaultMessage: 'The submission of your signature has been completed successfully.',
  },
  thankYouTxt2: {
    id: 'ESignature.thankYouTxt2',
    defaultMessage: 'Thank you for taking out our insurance.\nThe person in charge will contact you in the future.',
  },
  errorTxt: {
    id: 'ESignature.errorTxt',
    defaultMessage: 'An error happened. Please contact the agent.',
  },
  confirmationTitle: {
    id: 'ESignature.confirmationTitle',
    defaultMessage: 'Confirmation of signature',
  },
  pleaseCheckTxt: {
    id: 'ESignature.pleaseCheckTxt',
    defaultMessage: 'The following signature will be submitted. Please check it as it cannot be edited once submitted.',
  },
  backToSignBtnTxt: {
    id: 'ESignature.backToSignBtnTxt',
    defaultMessage: 'Back',
  },
  signReqTxt: {
    id: 'ESignature.signReqTxt',
    defaultMessage: 'Signature request',
  },
  goToSignBtnTxt: {
    id: 'ESignature.goToSignBtnTxt',
    defaultMessage: 'Go to Sign screen',
  },
  goBackToDisplayDataBtnTxt: {
    id: 'ESignature.goBackToDisplayDataBtnTxt',
    defaultMessage: 'Go back to contract display',
  },
  turnInLandscapeTxt: {
    id: 'ESignature.turnInLandscapeTxt',
    defaultMessage: 'Please turn your device in landscape orientation',
  },
  goToPrevPageBtnTxt: {
    id: 'ESignature.goToPrevPageBtnTxt',
    defaultMessage: 'Back',
  },
  goToNextPageBtnTxt: {
    id: 'ESignature.goToNextPageBtnTxt',
    defaultMessage: 'Next',
  },
  alreadySignedTxt: {
    id: 'ESignature.alreadySignedTxt',
    defaultMessage: 'The Plan ID {planId} has already been signed.\nYou can close this page safely.',
  },
  clickToEditSign: {
    id: 'ESignature.clickToEditSign',
    defaultMessage: '「Click to edit」',
  },
  clickToSign: {
    id: 'ESignature.clickToSign',
    defaultMessage: '「Click here to sign」',
  },
  closeSignBtnTxt: {
    id: 'ESignature.closeSignBtnTxt',
    defaultMessage: 'Close',
  },
  priorConsentBtnTxt: {
    id: 'ESignature.priorConsentBtnTxt',
    defaultMessage: 'I accept',
  },
  errorFormValidationTxt: {
    id: 'ESignature.errorFormValidationTxt',
    defaultMessage: 'There is some input errors, please check.',
  },
});

type ESignatureProps = { fetch?: typeof window.fetch };

const ESignatureBase: React.FC<ESignatureProps> = ({ fetch = window.fetch, children }) => {
  const { dispatch, state } = React.useContext(Context);
  const { formatMessage } = useIntl();

  const [bDisplayFinish, setBDisplayFinish] = useState<boolean>(false);
  const [planIdIsAlreadySigned, setPlanIdIsAlreadySigned] = useState<boolean>(false);
  const [bDisplayInvalidStatusMessage, setBDisplayInvalidStatusMessage] = useState<boolean>(false);
  const [bDisplayErrorFormValidation, setBDisplayErrorFormValidation] = useState<boolean>(false);

  const isCancelled = useRef(false);
  // const [apiResult, setApiResult] = useState<ApiResult | undefined>();


  const locationSearch = useLocation().search;

  useEffect(() => {
    return () => {
      console.log('ESignature is unmounted.');
      isCancelled.current = true;
    };
  }, []);

  const getDataToDisplayFromApi = async (token: string, planId: string) => {
    try {
      const reqOptions = {
        method: 'GET',
        headers: { Authorization: token },
      }
      const response = await fetch(`${URL_API}/data/${planId}`, reqOptions);
      console.log(response);
      // 401 is a valid response so it will not go in catch !
      const responseData = await response.json();
      if (!isCancelled.current) {
        if (responseData.status === 200) {
          setPlanIdIsAlreadySigned(responseData.isAlreadySigned || false);
          setDataToDisplaySign(dispatch, responseData.payload);
          console.log('saved result in local state');
          if (responseData.payload.validitySts === '02') {
            setBDisplayInvalidStatusMessage(true);
          }
        } else if (responseData.statusCode === 401) {
          setApiResultSign(dispatch, { msg: 'Unauthorized, please check the token value', status: 401, payload: undefined });
          setBDisplayErrorSign(dispatch, true);
        } else {
          setApiResultSign(dispatch, responseData);
          setBDisplayErrorSign(dispatch, true);
        }
      } else {
        console.log('canceled and fetch res is ignored !');
      }
    } catch (error: any) {
      console.error(error);
      if (!isCancelled.current) {
        // Display ERROR;
        setApiResultSign(dispatch, { msg: error.message, status: error.status, payload: undefined });
        setBDisplayErrorSign(dispatch, true);
      } else {
        console.log('canceled and fetch res is ignored !');
      }
    }
    isCancelled.current = false;
    setIsLoadingSign(dispatch, false);
  };

  useEffect(() => {
    const queryParams = qs.parse(locationSearch, { ignoreQueryPrefix: true });
    // Call to get data to display
    console.log('Query params are : ', queryParams);
    if (!queryParams.token) {
      console.log('There is no token in URL query parameter !');
    }
    if (!queryParams.planId) {
      console.log('There is no planId in URL query parameter !');
    }
    if (!queryParams.token || !queryParams.planId) {
      console.log('Parameters are wrong, cannot continue');
      setBDisplayErrorSign(dispatch, true);
      setIsLoadingSign(dispatch, false);
    } else {
      setQueryParametersSign(dispatch, queryParams);
      getDataToDisplayFromApi(queryParams.token as string || '', queryParams.planId as string || '');
    }
  }, []);

  useEffect(() => {
    if (state.apiResult?.status === 200) {
      // Display OK;
      setBDisplayFinish(true);
      // clean state/vars and close
      setCheckboxSelectedSign(dispatch, false);
      setSignatureSign(dispatch, '');
    } else {
      if (state.apiResult) {
        // Display ERROR;
        setBDisplayErrorSign(dispatch, true);
      }
    }
  }, [state.apiResult]);

  const testFormValid: (displaySign: boolean) => boolean = () => {
    // console.log('Checking the screen inputs...');
    return state.checkboxSelected === true
        && (displaySign ? state.signature !== '' : true);
  }

  const goBackToSign = () => {
    setBDisplayConfirmationSign(dispatch, false);
    setBDisplaySignInputSign(dispatch, true);
    setSignatureSign(dispatch, '');
    setTimeout(() => {
      console.log('dispatch resize once');
      window.dispatchEvent(new Event('resize'));
    }, 1);
    window.scrollTo(0, 0);
  };

  const goToNextPage = () => {
    if (!checkFormInputsAndSaveInState()) {
      return false;
    }

    setCurrentPageToDisplaySign(dispatch, state.currentPageToDisplay + 1);
    setCheckboxSelectedSign(dispatch, false);
    window.scrollTo(0, 0);

    // necessary to delay a bit otherwise element are not yet in the dom
    setTimeout(() => {
      // reset to default back button in case aura modified it
      const btnPreviousAura = document.getElementById('goToPrevPageAura');
      btnPreviousAura?.parentNode?.removeChild(btnPreviousAura);
      const btnPreviousOrig = document.getElementById('goToPrevPage');
      // console.log(btnPreviousOrig);
      if (btnPreviousOrig) {
        btnPreviousOrig.style.display = 'flex';
      }
    }, 10);
  };

  const checkFormInputsAndSaveInState = () => {
    // Get form on current page
    const page = state.dataToDisplay?.Contents?.find((content: { pageNo: number, pageContents: any[] }) => content.pageNo === state.currentPageToDisplay)?.pageContents.map(( pageContent: any, subIdx: number) => getComponent(state, dispatch, restoreFormInputsToForm, pageContent, 'page', state.currentPageToDisplay, subIdx));
    const form = page?.filter((x: any) => x.type === 'form');

    let inputResultList = state.inputResultList;

    for (let x = 0; x < form.length; x++) {
      const formElem = document.getElementById(form[x].props.id) as HTMLFormElement;

      if (!formElem.reportValidity()) {
        // alert('form not valid');
        var list = formElem.querySelectorAll(':invalid');
        // console.log(list);
        setBDisplayErrorFormValidation(true);
        return false;
      } else {
        setBDisplayErrorFormValidation(false);
      }

      const data = new FormData(formElem);
      const dataArr = Array.from(data.entries());
      console.log(dataArr);

      // Check for JSON formatted values, and append to the result array if any.
      const processedDataArr = dataArr.reduce((acc: any, curr: any) => {
        const [, value] = curr;

        acc.push(curr);

        if (!isJSONObject(value)) return acc;

        // In case of JSON formatted values, we will parse and those
        // values and add them to the result array.
        // e.g. [["myJSONFormattedValue", '{"foo":"bar"}']] -> [["myJSONFormattedValue", '{"foo":"bar"}'], ["foo", "bar"]]
        const parsedJSON = JSON.parse(value);

        return acc.concat(Object.entries(parsedJSON));
      }, []);

      console.log('processedDataArr', processedDataArr);

      const inputResult = {
        formKey: form[x].props.id,
        formContents: processedDataArr.map((y: any) => ({
          [y[0]]: y[1],
        })),
      }

      // Remove old entry and put new
      inputResultList = inputResultList.filter((x) => x.formKey !== inputResult.formKey);
      inputResultList.push(inputResult);
    }

    // console.log(JSON.stringify(inputResultList, null, 2));
    // Save content in state for later
    setInputFormContentSign(dispatch, inputResultList);
    return true;
  };

  const restoreFormInputsToForm = () => {
    state.inputResultList.forEach((x) => {
      // get form
      // console.log(x);
      const formElem = document.getElementById(x.formKey) as HTMLFormElement;
      // console.log(formElem);
      if (formElem) {
        x.formContents.forEach((y) => {
          // console.log(y);
          // console.log(Object.keys(y));
          const item = formElem.elements.namedItem(Object.keys(y)[0]);
          // console.log(item);
          if (item) {
            (item as HTMLInputElement).value = Object.values(y)[0].toString();
            // Item could be one element or a group of radio buttons, so we will make
            // sure to loop over radio nodes and dispatch change event for the checked one.
            if (Object.prototype.toString.call(item) === '[object RadioNodeList]') {
              (item as RadioNodeList).forEach((i: Node) => {
                if (!(i as HTMLInputElement).checked) return;
                i.dispatchEvent(new Event('change',{bubbles:true,cancelable: true}))
              })
            } else {
              (item as HTMLInputElement).dispatchEvent(new Event('change',{bubbles:true,cancelable: true}))
            }
          }
        });
        // formElem.elements.namedItem(x.formContents.)
      }

    });
  };

  const goToSign = () => {
    if (!checkFormInputsAndSaveInState()) {
      return false;
    }

    // canvas of inktool needs this in order to resize correctly after the first render
    setTimeout(() => {
      console.log('dispatch resize once');
      window.dispatchEvent(new Event('resize'));
    }, 1);
    setBDisplayConfirmationSign(dispatch, false);
    setBDisplaySignInputSign(dispatch, true);
    window.scrollTo(0, 0);

    // necessary to delay a bit otherwise element are not yet in the dom
    setTimeout(() => {
      // reset to default back button in case aura modified it
      const btnPreviousAura = document.getElementById('goToPrevPageAura');
      btnPreviousAura?.parentNode?.removeChild(btnPreviousAura);
      const btnPreviousOrig = document.getElementById('goBackToDisplayData');
      // console.log(btnPreviousOrig);
      if (btnPreviousOrig) {
        btnPreviousOrig.style.display = 'flex';
      }
    }, 10);
  };

  const onAuraPage = getSignType('aura', state.dataToDisplay?.Contents, state.currentPageToDisplay)

  const goToPrevPage = (e: React.MouseEvent<HTMLElement>) => {
    setBDisplayErrorFormValidation(false);
    if (onAuraPage && state.auraXmlOutput) {
      setAuraXmlOutputSign(dispatch, '');
    } else {
      setCurrentPageToDisplaySign(dispatch, state.currentPageToDisplay - 1);
      setCheckboxSelectedSign(dispatch, true);
      window.scrollTo(0, 0);
      e.currentTarget.blur();
    }
  };

  const onSubmit = () => {
    setIsLoadingSign(dispatch, true);
    sendToApi(state, dispatch, isCancelled);
  };

  const displaySign = getSignType('sign', state.dataToDisplay?.Contents);
  const isDoingAuraStuffLocal = getSignType('aura', state.dataToDisplay?.Contents, state.currentPageToDisplay);
  const isFormValid = testFormValid(displaySign);
  const isCheckboxOnPage = getSignType('checkbox', state.dataToDisplay?.Contents, state.currentPageToDisplay);
  const goToPrevPageBtnTxt = getTransitionBtnTxt('goToPrevPageBtnTxt', state.currentPageToDisplay, state.dataToDisplay?.Contents);
  const goToNextPageBtnTxt = getTransitionBtnTxt('goToNextPageBtnTxt', state.currentPageToDisplay, state.dataToDisplay?.Contents);

  useEffect(() => {
    setIsDoingAuraStuffSign(dispatch, isDoingAuraStuffLocal);
  }, [isDoingAuraStuffLocal]);

  useEffect(() => {
    // console.log('useefffect currentPageToDisplay changed !');
    restoreFormInputsToForm();
  }, [state.currentPageToDisplay]);

  useEffect(() => {
    // console.log('useefffect bDisplaySignInput changed !');
    restoreFormInputsToForm();
  }, [state.bDisplaySignInput]);

  if(state.isLoading) return <LoadingSpinner loading={true}/>;
  if(state.bDisplayError) return  <DisplayError apiResult={state.apiResult} messages={messages} />;
  if(bDisplayFinish) return <DisplayFinish messages={messages} restoreFormInputsToForm={restoreFormInputsToForm}/>;
  if(state.bDisplaySignInput) return <DisplaySignInput displaySign={displaySign} isFormValid={isFormValid} messages={messages} onSubmit={onSubmit} />;
  if(state.bDisplayConfirmation) return <DisplayConfirmation displaySign={displaySign} messages={messages} onSubmit={onSubmit} goBackToSign={goBackToSign} isFormValid={isFormValid} />;
  if(planIdIsAlreadySigned) return <PlanIdIsAlreadySigned messages={messages} />;
  if(!displaySign) return <DisplayPriorConsent apiResult={state.apiResult} messages={messages} isFormValid={isFormValid} isCancelled={isCancelled} isCheckboxOnPage={isCheckboxOnPage} goToPrevPageBtnTxt={goToPrevPageBtnTxt} goToNextPageBtnTxt={goToNextPageBtnTxt} goToNextPage={goToNextPage} goToPrevPage={goToPrevPage} restoreFormInputsToForm={restoreFormInputsToForm} />;
  if(bDisplayInvalidStatusMessage) return <PlanInvalidStatus />;

  return(
    <>
      {
        state.dataToDisplay?.Contents?.find((content: { pageNo: number, pageContents: any[] }) => content.pageNo === state.currentPageToDisplay)?.pageContents.map(( pageContent: any, subIdx: number) => getComponent(state, dispatch, restoreFormInputsToForm, pageContent, 'page', state.currentPageToDisplay, subIdx, state.inputResultList))
      }
      {
        bDisplayErrorFormValidation && <Text color='shyTomato' style={{ paddingBottom: '30px', fontWeight: 'bold' }}>{formatMessage(messages.errorFormValidationTxt)}</Text>
      }
      <BottomNavDiv>
        {
          state.currentPageToDisplay !== 1 || onAuraPage && state.auraXmlOutput !== ''
            ? <ArrowButtonStyled iconPosition='left' color='white' onClick={goToPrevPage} id='goToPrevPage' data-testid={'goToPrevPage'}>{goToPrevPageBtnTxt || formatMessage(messages.goToPrevPageBtnTxt)}</ArrowButtonStyled>
            : <div></div>
        }
        {
          (state.currentPageToDisplay + 1) === state.dataToDisplay.Contents?.length
            ? <ArrowButton onClick={goToSign} disabled={!state.checkboxSelected} style={{ display: (state.isDoingAuraStuff && state.auraXmlOutput === '' ? 'none' : 'inherit') }} data-testid={'goToSign'}>{goToNextPageBtnTxt || formatMessage(messages.goToSignBtnTxt)}</ArrowButton>
            : displaySign
              && <ArrowButton onClick={goToNextPage} disabled={isCheckboxOnPage ? ((!state.checkboxSelected) || (state.isDoingAuraStuff ? state.auraXmlOutput === '' : false)) : false} style={{ display: (state.isDoingAuraStuff && state.auraXmlOutput === '' ? 'none' : 'inherit') }} data-testid={'goToNextPage'}>{goToNextPageBtnTxt || formatMessage(messages.goToNextPageBtnTxt)}</ArrowButton>
        }
      </BottomNavDiv>
    </>
  )
};
export default ESignatureBase;
