import React from "react";
import { Table } from "../layout/Table";

type Props = {
  confirmation?: boolean;
};
export const Table7: React.FC<Props> = ({ confirmation }) => (
  <Table
    tableName={tableName}
    categoryNames={tableCategoryNames}
    categoryDetails={tableCategoryDetails}
    confirmation={confirmation}
    categorized={true}
    comments={comments}
  />
);

const tableName = "表１";

const tableCategoryNames = [
  "心臓・血管",
  "脳・精神・神経",
  "肝臓",
  "腎臓",
  "ガン・悪性新生物*",
];

const tableCategoryDetails = [
  "狭心症・心筋こうそく・心筋症・大動脈瘤",
  "脳卒中(脳出血・脳こうそく・くも膜下出血）・統合失調症・うつ病・アルコール依存症",
  "肝硬変",
  "腎不全・糖尿病性腎症",
  "ガン・悪性新生物（肉腫・白血病・悪性リンパ腫・多発性骨髄腫を含みます）",
];

const comments = [
  "*ガン・悪性新生物には上皮内新生物（上皮内ガン）は含みません。",
];
