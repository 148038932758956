import React from "react";
import { Table } from "../layout/Table";

type Props = {
  confirmation?: boolean;
};
export const Table3: React.FC<Props> = ({ confirmation }) => (
  <Table
    tableName={tableName}
    categoryDetails={tableCategoryDetails}
    confirmation={confirmation}
  />
);

const tableName = "表１";

const tableCategoryDetails = [
  [
    "ガン・悪性新生物（肉腫・白血病・悪性リンパ腫・多発性骨髄腫を含みます）および上皮内新生物（上皮内ガン）",
    "虚血性心疾患（狭心症・心筋こうそく・急性冠症候群）",
    "脳卒中（くも膜下出血・脳内出血・脳こうそく）",
    "しゅよう",
    "ポリープ",
    "腫瘤",
    "胸のしこり",
    "子宮頚部異形成",
    "不整脈",
    "一過性脳虚血発作",
  ],
];
