import React from "react";
import { Answer } from "../../apis";
import { ShortButton } from "../atoms/ShortButton";
import { WideButton } from "../atoms/WideButton";

type Props = {
  options: Array<Option>;
  selected?: Option;
  answerQuestion: (answer: Answer) => Promise<void>;
  answerable: boolean;
};

export const SingleChoiceRadio: React.FC<Props> = ({
  options,
  selected,
  answerQuestion,
  answerable
}) => {
  const clickHandler = (value: string) => {
    const answer = {
      questionType: "SINGLE_CHOICE",
      value,
    };
    answerQuestion({ answer });
  };

  const useShortButton = options.length === 2;

  return (
    <>
      {options.map(({ label, value }) =>
        useShortButton ? (
          <ShortButton
            key={value}
            active={selected?.value === value}
            label={label}
            onClick={() => clickHandler(value)}
            disabled={!answerable}
          />
        ) : (
          <WideButton
            key={value}
            active={selected?.value === value}
            label={label}
            onClick={() => clickHandler(value)}
            disabled={!answerable}
          />
        )
      )}
    </>
  );
};
