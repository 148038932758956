import { saveAs } from 'file-saver';
import React from 'react';
import {
  Button as AxaButton,
} from '@alj-react/ui-components';
import { BottomNavDiv } from '../pages/ESignature.styles';

const DownloadBtn = (props: DownloadBtn) => {
  const { downloadBtnTxt, fileUrl, fileName } = props;

  const download = (url: string, fileName: string) => {
    fetch(url, {method: 'GET'})
    .then(res => {
      return res.blob();
    })
    .then(blob => {
      saveAs(blob, fileName);
    })
    .catch(err => {
      console.error('err: ', err);
    })
  };

  return (
    <>
      <BottomNavDiv>
        <AxaButton
          onClick={() => download(
            fileUrl || '',
            fileName || ''
          )}
        >
          {downloadBtnTxt}
        </AxaButton>
      </BottomNavDiv>
    </>
  );
}

export default DownloadBtn;
