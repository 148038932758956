
import React, { useEffect, useState } from 'react';

type TypeHiddenProps = {
  idName: string,
  visibleChildren: JSX.Element,
  hiddenChildren: JSX.Element,
  keyElementToWatch: string,
  valueElementToWatch: string,
};

const TypeHidden = ({ idName, visibleChildren, hiddenChildren, keyElementToWatch, valueElementToWatch }: TypeHiddenProps) => {
  const [showHidden, setShowHidden] = useState<boolean>(false);
  const hiddenContainerId = `#${idName}-hiddenContainer`;
  const select = `${hiddenContainerId} select`;
  const input = `${hiddenContainerId} input`;
  const textarea = `${hiddenContainerId} textarea`;
  const button = `${hiddenContainerId} button`;

  const handleChange = (e: any) => {
    if (e.target.name !== keyElementToWatch) return;

    setShowHidden((e.target as any).value === valueElementToWatch);
  };

  useEffect(() => {
    // loop through all elem under div
    const all = document.querySelectorAll(`${select}, ${input}, ${textarea}, ${button}`);
    // and disable or activate them (this prevent them to be sent in form if not visible)
    all.forEach((el: any) => {
      const isElementHidden = el.offsetParent === null;
      el.disabled = isElementHidden;
    });
  }, [showHidden]);

  useEffect(() => {
    const element = document.getElementsByName(keyElementToWatch);
    document.addEventListener('change', handleChange );
    element[0]?.dispatchEvent(new Event('change',{bubbles:true,cancelable: true}));
    return () => document.removeEventListener('change', handleChange);
  }, []);

  return (
    <>
    {
      visibleChildren
    }
    {
      <div id={`${idName}-hiddenContainer`} style={{ display: showHidden ? 'block' : 'none' }}>
        { hiddenChildren }
      </div>
    }
    </>
  );
}

export default TypeHidden;
