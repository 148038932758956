import React from 'react';
import {
  Layout,
  breakpoints,
} from '@alj-react/ui-components';
import styled, { css } from 'styled-components';
import { LayoutInner } from '@alj-react/ui-components/Layout/components';

import DisplaySign from './DisplaySign';
import ESignatureBase from './ESignatureBase';

const LayoutStyled = styled(Layout)`
  ${breakpoints.smallOnly(css`
    ${LayoutInner} {
      padding-top: 8px;
      padding-right: 14px;
      padding-bottom: 24px;
      padding-left: 14px;
    }
  `)}
`;

type ESignatureProps = { fetch?: typeof window.fetch };

const ESignature: React.FC<ESignatureProps> = ({ fetch = window.fetch }) => {
  return(
    <LayoutStyled>
      <ESignatureBase />
      <DisplaySign />
    </LayoutStyled>
  );
};

export default ESignature;
