import React from "react";
import styled from "styled-components";

type Props = {
  active: boolean;
  label: string;
  onClick: () => void;
  disabled: boolean;
};
export const WideButton: React.FC<Props> = ({
  active,
  label,
  onClick,
  disabled
}) => (
  <Button active={active} onClick={onClick} disabled={disabled}>
    {label}
  </Button>
);

const Button = styled.button<{ active: boolean }>`
  border-radius: 0px;
  text-shadow: inherit;
  background: inherit;
  background-color: ${({ active }) => (active ? "#00008f" : "#fff")};
  color: ${({ active }) => (active ? "#fff" : "#00008f")};
  height: auto;
  width: 84%;
  text-align: center;
  line-height: normal;
  font-size: 13px;
  max-width: inherit;
  display: inline-block;
  padding: 8px;
  margin: 5px;
  border: 1px solid #333;
  font-family: "Meiryo";
  font-weight: 700;
  cursor: pointer;
  overflow: auto;
`;
