// Actions type
export const SET_SHOWMODAL = 'SET_SHOWMODAL';
export const SET_CHECKBOXSELECTED = 'SET_CHECKBOXSELECTED';
export const SET_AURAXMLOUTPUT = 'SET_AURAXMLOUTPUT';
export const SET_ISLOADING = 'SET_ISLOADING';
export const SET_DATATODISPLAY = 'SET_DATATODISPLAY';
export const SET_SIGNATURE = 'SET_SIGNATURE';
export const SET_BDISPLAYSIGNINPUT = 'SET_BDISPLAYSIGNINPUT';
export const SET_QUERYPARAMETERS = 'SET_QUERYPARAMETERS';
export const SET_BDISPLAYERROR = 'SET_BDISPLAYERROR';
export const SET_BDISPLAYCONFIRMATION = 'SET_BDISPLAYCONFIRMATION';
export const SET_CURRENTPAGETODISPLAY = 'SET_CURRENTPAGETODISPLAY';
export const SET_ISDOINGAURASTUFF = 'SET_ISDOINGAURASTUFF';
export const SET_APIRESULT = 'SET_APIRESULT';
export const SET_INPUTFORMCONTENT = 'SET_INPUTFORMCONTENT';

export const setShowModal = (dispatch: any, show: boolean): any => {
  return dispatch({
    type: SET_SHOWMODAL, payload: show,
  });
};

export const setApiResult = (dispatch: any, data: any): any => {
  return dispatch({
    type: SET_APIRESULT, payload: data,
  });
};

export const setIsDoingAuraStuff = (dispatch: any, isDoing: boolean): any => {
  return dispatch({
    type: SET_ISDOINGAURASTUFF, payload: isDoing,
  });
};

export const setCurrentPageToDisplay = (dispatch: any, page: number): any => {
  return dispatch({
    type: SET_CURRENTPAGETODISPLAY, payload: page,
  });
};

export const setBDisplayError = (dispatch: any, bDisplay: boolean): any => {
  return dispatch({
    type: SET_BDISPLAYERROR, payload: bDisplay,
  });
};

export const setBDisplayConfirmation = (dispatch: any, bDisplay: boolean): any => {
  return dispatch({
    type: SET_BDISPLAYCONFIRMATION, payload: bDisplay,
  });
};

export const setQueryParameters = (dispatch: any, params: boolean): any => {
  return dispatch({
    type: SET_QUERYPARAMETERS, payload: params,
  });
};

export const setBDisplaySignInput = (dispatch: any, bDisplay: boolean): any => {
  return dispatch({
    type: SET_BDISPLAYSIGNINPUT, payload: bDisplay,
  });
};

export const setSignature = (dispatch: any, signature: string): any => {
  return dispatch({
    type: SET_SIGNATURE, payload: signature,
  });
};

export const setIsLoading = (dispatch: any, loading: boolean): any => {
  return dispatch({
    type: SET_ISLOADING, payload: loading,
  });
};

export const setCheckboxSelected = (dispatch: any, checked: boolean): any => {
  return dispatch({
    type: SET_CHECKBOXSELECTED, payload: checked,
  });
};

export const setAuraXmlOutput = (dispatch: any, xmlOutput: string): any => {
  return dispatch({
    type: SET_AURAXMLOUTPUT, payload: xmlOutput,
  });
};

export const setDataToDisplay = (dispatch: any, data: any): any => {
  return dispatch({
    type: SET_DATATODISPLAY, payload: data,
  });
};

export const setInputFormContent = (dispatch: any, data: any): any => {
  return dispatch({
    type: SET_INPUTFORMCONTENT, payload: data,
  });
};
