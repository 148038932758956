import React from "react";
import helpImg from '../../assets/help.png'

type Props = {
  onClick?: () => void;
};

export const HelpIcon: React.FC<Props> = ({ onClick }) => (
  <img src={helpImg} alt={"help"} onClick={onClick} />
);
