import React from "react";
import { Table } from "../layout/Table";

type Props = {
  confirmation?: boolean;
};
export const Table4: React.FC<Props> = ({ confirmation }) => (
  <Table
    tableName={tableName}
    categoryDetails={tableCategoryDetails}
    confirmation={confirmation}
    comments={comments}
  />
);

const tableName = "表１";

const tableCategoryDetails = [
  [
    "脳しゅよう（注2）",
    "肺線維症",
    "慢性肝炎（注3）",
    "NASH(非アルコール性脂肪肝炎)",
    "非浸潤性乳管ガン(上皮内ガン）",
    "慢性腎不全",
    "膀胱しゅよう（注2）",
    "卵巣境界悪性しゅよう",
    "色素性乾皮症",
  ],
  [
    "間質性肺炎",
    "じん肺",
    "肝硬変",
    "肝線維症",
    "かいよう性大腸炎",
    "GIST(消化管間質しゅよう)（注2）",
    "子宮頸部異形成(高度異形成CIN3)",
    "白板症",
    "ボーエン病",
  ],
  [
    "COPD(慢性閉塞性肺疾患)",
    "肺気腫",
    "慢性アルコール性肝機能障害",
    "肝炎ウイルスキャリア(B型またはC型）",
    "慢性腎炎",
    "膵のう胞性しゅよう（注2）",
    "子宮内膜異型増殖",
    "日光角化症(老人性角化腫）",
  ],
];

const comments = [
  "（注1）「診察・検査」には、治療終了後の経過観察のための診察待ち期間、検査待ち期間を含みます。",
  "（注2）悪性が否定されているもの。",
  "（注3）慢性肝炎には、肝炎が6ヵ月以上継続しているものも含みます。",
];
