import React from "react";
import Select from "react-select";
import { Answer } from "../../apis";

type Props = {
  options: Array<Option>;
  selected?: Option;
  answerQuestion: (answer: Answer) => Promise<void>;
};

export const SingleChoiceSelect: React.FC<Props> = ({
  options,
  selected,
  answerQuestion,
}) => (
  <Select
    options={options}
    value={selected}
    isMulti={false}
    isSearchable={false}
    onChange={(e) => {
      const newSelect = e as Option;

      const answer = {
        questionType: "SINGLE_CHOICE",
        value: newSelect.value,
      };

      answerQuestion({ answer });
    }}
    placeholder={""}
  />
);
