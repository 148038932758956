import React, { useEffect } from "react";
import { Layout, breakpoints, Text, Link } from "@alj-react/ui-components";
import styled, { css } from "styled-components";
import { LayoutInner } from "@alj-react/ui-components/Layout/components";
import { useCookies } from "react-cookie";

declare const window: Window;

const InsuranceCollectiveLogout: React.FC = () => {

  const [,,removeCookie] = useCookies(["auth0Token", "auth0RefreshToken"]);
  const sessionTimeoutMinute = parseInt(process.env.REACT_APP_SESSION_TIMEOUT_MINUTE?? '60');
  
  useEffect(() => {
    document.title = "団体扱集団扱確認システム";
    removeCookie('auth0RefreshToken', {path: '/insurance/collective'});
    removeCookie('auth0Token', {path: '/insurance/collective'});
  }, [])

  return (
    <LayoutStyled>
      <div>
        <CustomText size="20">{sessionTimeoutMinute}分間使用がなかったため、セキュリティ関係で自動的にログアウトをしました。再度ログインしてください。</CustomText>
        <br />
        <CustomText><Link onClick={() => { window.location.href = '/insurance/collective/home' }}>再度ログインする。</Link></CustomText>
      </div>
    </LayoutStyled>
  );
};

const LayoutStyled = styled(Layout)`
  ${breakpoints.smallOnly(css`
    ${LayoutInner} {
      padding-top: 8px;
      padding-right: 14px;
      padding-bottom: 24px;
      padding-left: 14px;
    }
  `)}
`;

const CustomText = styled(Text)`
  font-family: 'Lucida Grande';
`

export default InsuranceCollectiveLogout;
