import { useQuery } from "react-query";
import { auraServiceApi } from "../utils/AuraApi";

export const useSession = (
  clientId: string,
  tenant: string,
  accessToken: string,
  inputXml: string,
  localeCode: string,
  enabled?: boolean
) => {
  const sessionId = getSessionId(inputXml);
  const headers = { Authorization: `Bearer ${accessToken}` };

  const updateSessionRes = useQuery(
    ["updateSession", accessToken],
    () =>
      auraServiceApi.updateSession(
        clientId,
        sessionId,
        tenant,
        {
          inputXml,
          localeCode,
        },
        {
          headers,
        }
      ),
    { enabled, cacheTime: 0, retry: 1 }
  );

  const needCreateSession =
    updateSessionRes.isError &&
    (updateSessionRes.error as any).response.status === 404;

  const createSessionRes = useQuery(
    ["createSession", accessToken],
    () =>
      auraServiceApi.createSession(
        clientId,
        sessionId,
        tenant,
        {
          inputXml,
          localeCode,
        },
        { headers }
      ),
    { enabled: needCreateSession, cacheTime: 0 }
  );

  return needCreateSession ? createSessionRes : updateSessionRes;
};

const getSessionId = (inputXml: string) => {
  const txMatch = inputXml.match(/transactionId=".*?"/);
  return txMatch
    ? txMatch[0].substring('transactionId="'.length, txMatch[0].length - 1)
    : "";
};
