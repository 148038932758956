
import React, { useEffect } from 'react';

type TypeSelectorProps = {
  idName: string,
  selector: JSX.Element,
  mapping: any,
  keyElementToWatch: string,
};

const TypeSelector = ({ idName, selector, mapping, keyElementToWatch }: TypeSelectorProps) => {

  const handleChange = (e: any) => {
    const hideAllOptions = document.querySelectorAll(`[id^="${keyElementToWatch}-option-"]`);
    hideAllOptions.forEach((el, idx) => 
      {
        const elDiv = (el as HTMLDivElement);
        // hide or show div of options
        elDiv.style.display = e.currentTarget.options.selectedIndex == idx ? 'block' : 'none';
        // and disable or activate all sub inputs (this prevent them to be sent in form if not visible)
        const all = elDiv.querySelectorAll(`select, input, textarea, button`);
        all.forEach((el) => e.currentTarget.options.selectedIndex == idx ? (el as any).disabled = false : (el as any).disabled = true);
      }
    );

  };

  useEffect(() => {
    const element = document.getElementsByName(keyElementToWatch);
    if (element.length > 0) {
      element[0]?.addEventListener('change', handleChange );
      element[0]?.dispatchEvent(new Event('change',{bubbles:true,cancelable: true}));
      return () => element[0]?.removeEventListener('change', handleChange);
    }
  }, []);

  return (
    <>
    {
      selector
    }
    {
      mapping.map((x: any, idx: number) => <div key={`${keyElementToWatch}-option-${idx}`} id={`${keyElementToWatch}-option-${idx}`} >{x}</div>)
    }
    </>
  );
}

export default TypeSelector;
