import React from "react";
import styled from "styled-components";

export const CircleIndex: React.FC = ({children}) => {

  return <StyledIndex>
    {children}
  </StyledIndex>
};

const StyledIndex = styled.span`
  border-radius: 1.5em;
  padding: 0.1rem;
  height: 1em;
  width: 1em;
  color: black;
  border: solid 0.8px;
  text-align: center;
  font-size: 0.6rem;
  line-height: 1em;
`;
