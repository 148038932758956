import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Answer, DateQuestion } from "../../../apis";
import { toJapaneseYear } from "../../../utils/dateFormatter";
import { AnswerPartContainer } from "../../layout/AnswerPartContainer";
import { QuestionCardContainer } from "../../layout/QuestionCardContainer";

type Option = {
  label: string;
  value: string;
};
type Props = {
  question: DateQuestion;
  index: string;
  subIndex?: string;
  answerQuestion: (answer: Answer) => Promise<void>;
  errorMsg?: string;
  answerable: boolean;
};

export const DateCard: React.FC<Props> = ({
  question,
  index,
  subIndex,
  answerQuestion,
  errorMsg,
  answerable,
}) => {
  const [initYear, initMonth] = question.answer?.value.split("-") || [];
  const [selectedMon, setSelectedMon] = useState(
    initMonth ? toMonthOption(initMonth) : null
  );
  const [selectedYear, setSelectedYear] = useState(
    initYear ? toYearOption(initYear) : null
  );

  const [needSubmit, setNeedSubmit] = useState(false);

  useEffect(() => {
    if (!needSubmit || !selectedMon || !selectedYear) {
      return;
    }

    const answer = {
      questionType: "DATE",
      dateFormat: "MONTH_YEAR",
      value: `${selectedYear.value}-${selectedMon.value}`,
    };

    answerQuestion({ answer });
  }, [selectedYear, selectedMon]);

  const currentYear = new Date().getFullYear();
  const yearOptions = Array.from(
    { length: 100 },
    (v, i) => currentYear - i
  ).map((eachYear) => toYearOption(`${eachYear}`));

  const monthOptions = Array.from({ length: 12 }, (v, i) => i + 1).map(
    (eachMonth) => toMonthOption(`${eachMonth}`)
  );

  return (
    <QuestionCardContainer
      richQuestionText={question.richText}
      richHelpText={question.richHelpText}
      answerPart={
        <>
          <AnswerPartContainer>
            <Select
              options={yearOptions}
              value={selectedYear}
              isMulti={false}
              isSearchable={false}
              onChange={(e) => {
                setNeedSubmit(true);
                const selected = e as Option;
                setSelectedYear({ ...selected });
              }}
              placeholder={""}
              isDisabled={!answerable}
            />
          </AnswerPartContainer>
          <AnswerPartContainer>
            <Select
              options={monthOptions}
              value={selectedMon}
              isMulti={false}
              isSearchable={false}
              onChange={(e) => {
                setNeedSubmit(true);
                const selected = e as Option;
                setSelectedMon({ ...selected });
              }}
              placeholder={""}
              isDisabled={!answerable}
            />
          </AnswerPartContainer>
        </>
      }
      index={subIndex || index}
      errorMsg={errorMsg}
    />
  );
};

const toYearOption = (year: string) => ({
  label: toJapaneseYear(year),
  value: year,
});

const toMonthOption = (month: string) => ({
  label: `${month}月`,
  value: month,
});
