import React, { useEffect, useState, useRef } from 'react';
import {
  Text,
  Button,
  ArrowButton,
  ArrowButtonProps,
} from '@alj-react/ui-components';
import { useIntl } from 'react-intl';
import { defineMessages } from 'react-intl.macro';
import styled from 'styled-components';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import {useOrientation} from 'react-use';

import { ArrowButtonStyled, BottomNavDiv } from './ESignature.styles';
import InkTool from '../../utils/InkTool';
import { getComponent, getSignType } from '../../utils/multi';
import { Context } from '../../utils/ESignatureContext';
import { setShowModalSign, setSignatureSign } from '../../utils/dataFetchers';


const TextCustomInfo = styled(Text)`
  transition: none;
  white-space: pre-wrap;
  display: block;

  @media only screen and (min-width: 550px) {
    display: none;
  }
`;

const ButtonStyled = styled(Button)`
  margin-bottom: 10px;
  @media only screen and (min-width: 550px) {
    margin-bottom: 0;
  }
`;

const messages = defineMessages({
  submitTxt: {
    id: 'ESignature.submitTxt',
    defaultMessage: 'Submit',
  },
  pleaseSignTxt: {
    id: 'ESignature.pleaseSignTxt',
    defaultMessage: 'Please sign in the space below',
  },
  clearSignBtnTxt: {
    id: 'ESignature.clearSignBtnTxt',
    defaultMessage: 'Erase',
  },
  saveSignBtnTxt: {
    id: 'ESignature.saveSignBtnTxt',
    defaultMessage: 'Save',
  },
  confirmScreenBtnTxt: {
    id: 'ESignature.confirmScreenBtnTxt',
    defaultMessage: 'Go to confirmation screen',
  },
  thankYouTxt1: {
    id: 'ESignature.thankYouTxt1',
    defaultMessage: 'The submission of your signature has been completed successfully.',
  },
  thankYouTxt2: {
    id: 'ESignature.thankYouTxt2',
    defaultMessage: 'Thank you for taking out our insurance.\nThe person in charge will contact you in the future.',
  },
  errorTxt: {
    id: 'ESignature.errorTxt',
    defaultMessage: 'An error happened. Please contact the agent.',
  },
  confirmationTitle: {
    id: 'ESignature.confirmationTitle',
    defaultMessage: 'Confirmation of signature',
  },
  pleaseCheckTxt: {
    id: 'ESignature.pleaseCheckTxt',
    defaultMessage: 'The following signature will be submitted. Please check it as it cannot be edited once submitted.',
  },
  backToSignBtnTxt: {
    id: 'ESignature.backToSignBtnTxt',
    defaultMessage: 'Back',
  },
  signReqTxt: {
    id: 'ESignature.signReqTxt',
    defaultMessage: 'Signature request',
  },
  goToSignBtnTxt: {
    id: 'ESignature.goToSignBtnTxt',
    defaultMessage: 'Go to Sign screen',
  },
  goBackToDisplayDataBtnTxt: {
    id: 'ESignature.goBackToDisplayDataBtnTxt',
    defaultMessage: 'Go back to contract display',
  },
  turnInLandscapeTxt: {
    id: 'ESignature.turnInLandscapeTxt',
    defaultMessage: 'Please turn your device in landscape orientation',
  },
  goToPrevPageBtnTxt: {
    id: 'ESignature.goToPrevPageBtnTxt',
    defaultMessage: 'Back',
  },
  goToNextPageBtnTxt: {
    id: 'ESignature.goToNextPageBtnTxt',
    defaultMessage: 'Next',
  },
  alreadySignedTxt: {
    id: 'ESignature.alreadySignedTxt',
    defaultMessage: 'The Plan ID {planId} has already been signed.\nYou can close this page safely.',
  },
  clickToEditSign: {
    id: 'ESignature.clickToEditSign',
    defaultMessage: '「Click to edit」',
  },
  clickToSign: {
    id: 'ESignature.clickToSign',
    defaultMessage: '「Click here to sign」',
  },
  closeSignBtnTxt: {
    id: 'ESignature.closeSignBtnTxt',
    defaultMessage: 'Close',
  },
  priorConsentBtnTxt: {
    id: 'ESignature.priorConsentBtnTxt',
    defaultMessage: 'I accept',
  },
});

const DisplaySign: React.FC = () => {
  const { formatMessage } = useIntl();
  const { dispatch, state } = React.useContext(Context);

  const targetRef = useRef<HTMLDivElement | null>(null);
  const orientation = useOrientation();

  
  const [isCanvasEmpty, setIsCanvasEmpty] = useState<boolean>(true);

  const canvasName = 'inktool';

  useEffect(() => {
    InkTool.InkCanvasLib.setPreventDragAndSelect(false);
    const canvas: any = InkTool.InkCanvasLib.createCanvas(canvasName);
    const pen: any = new InkTool.InkBallpointPen();
    // const pen: any = new InkTool.InkFeltPen();
    pen.setPenWidth(3.2);
    pen.setPenMinWidth(2.5);
    pen.setPenMaxWidth(4.0);
    pen.setPenColor(new InkTool.InkColor('black'));
    canvas.setPen(pen);
    // prevent scroll when on this element, for chrome, need to be passive: false
    (document.getElementById('canvas-container') as HTMLElement).addEventListener('wheel', (e) => e.preventDefault(), {passive: false});
  }, []);

  const handleResize = () => {
    InkTool.InkCanvasLib.relayoutCanvas(canvasName);
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (targetRef.current) {
      if (state.showModal) {
        disableBodyScroll(targetRef.current);
      } else {
        enableBodyScroll(targetRef.current);
      }
    }
  }, [state.showModal]);

  const calculateOrientation = () => {
    console.log('Raw orientation values: ', orientation);
    // clear canvas when oriantation change, no clear of sign
    const canvas = getCanvas();
    if (canvas != null) {
      canvas.clearInk();
      setIsCanvasEmpty(true);
    }
    setTimeout(() => {
      console.log('dispatch resize once');
      window.dispatchEvent(new Event('resize'));
    }, 1);
  };

  useEffect(()=> {
    calculateOrientation();
  }, []);

  useEffect(()=> {
    calculateOrientation();
  }, [orientation]);

  const onClickModal = () => {
    setShowModalSign(dispatch, !state.showModal);
    setTimeout(() => {
      // console.log('dispatch resize once');
      window.dispatchEvent(new Event('resize'));
    }, 1);
  };

  const getCanvas = () => {
    return InkTool.InkCanvasLib.getCanvas(canvasName);
  }

  const saveCanvas = () => {
    const canvas = getCanvas();
    const inkdata = canvas.saveImageWithBGColor('#FFFFFF', 'image/png');
    // console.log('DEBUG', inkdata);
    setSignatureSign(dispatch, inkdata);
    setShowModalSign(dispatch, false);
  };

  const clearCanvas = () => {
    const canvas = getCanvas();
    if (canvas != null) {
      canvas.clearInk();
      setIsCanvasEmpty(true);
      setSignatureSign(dispatch, '');
    }
  };

  const mousechange = () => {
    setIsCanvasEmpty(getCanvas() === null);
  };

  const getSignDisplayPreTextComponent = (contents?: { pageNo: number, pageContents: any[] }[]) => {
    let pageSign: { type: string, displayPreText?: {type: string, value: string}[] } = { type: '' };
    contents?.forEach((content) => {
      content.pageContents.forEach((page: {type: string, value: string}) => {
        switch (page.type) {
          case 'sign':
            pageSign = page as { type: string, displayPreText?: {type: string, value: string}[] };
            break;
          default:
            break;
        }
      });
    });
    let res = pageSign.displayPreText?.map((obj, idx) => getComponent(state, dispatch, () => void(0), obj, 'preSig', idx));
    return res;
  };

  
  const displaySign = getSignType('sign', state.dataToDisplay?.Contents);

  return(
    <div ref={targetRef} style={{ padding: '10px', backgroundColor: 'white', position: 'absolute', top: '0px', left: '0px', display: state.showModal ? 'flex':'none', width: 'calc(100% - 20px)', height: 'calc(100vh - 20px)', justifyContent: 'center' }}>
        {
          // the following display the canvas, it is always present in page (for easier setup) and just hidden display when needed thanks to display css propery
        }
        <div style={{ width: '100%', maxWidth: '1024px', display: displaySign && state.checkboxSelected && !state.bDisplayConfirmation && state.bDisplaySignInput ? 'block' : 'none' }}>
          {
            getSignDisplayPreTextComponent(state.dataToDisplay?.Contents)
          }
          {
            <TextCustomInfo color='axaRed' >{formatMessage(messages.turnInLandscapeTxt)}</TextCustomInfo>
          }
          <div style={{ display: 'flex', border: '1px solid lightgray', WebkitTapHighlightColor: 'transparent', cursor: 'pointer'/*, maxWidth: '620px', margin: 'auto'*/ }}>
            <div style={{ width: '100%', height: /*'150px'*/'165px', backgroundColor: '#ffff', clear: 'both', touchAction: 'none' }}
              onTouchMove={(e) => e.preventDefault()}
              onTouchCancel={(e) => e.preventDefault()}
              onTouchStart={mousechange} onTouchEnd={mousechange}
              onMouseDown={mousechange} onMouseUp={mousechange}
              id={'canvas-container'}
              data-testid={'canvas-container'}>
              <div style={{ width: '100%', height: '100%' }} id="inktool"></div>
            </div>
          </div>
          <BottomNavDiv>
            <ArrowButtonStyled iconPosition='left' color='white' onClick={onClickModal} data-testid={'cancelCanvas'}>{formatMessage(messages.closeSignBtnTxt)}</ArrowButtonStyled>
            <ButtonStyled onClick={clearCanvas} data-testid={'clearCanvas'}>{formatMessage(messages.clearSignBtnTxt)}</ButtonStyled>
            <ArrowButtonStyled color='orange' onClick={saveCanvas} disabled={isCanvasEmpty} data-testid={'saveCanvas'}>{formatMessage(messages.saveSignBtnTxt)}</ArrowButtonStyled>
          </BottomNavDiv>
        </div>
      </div>
  )
};
export default DisplaySign;
