import React from "react";
import Text from "@alj-react/ui-components/Text";
import { useIntl } from "react-intl";
import PadlockIcon from '@alj-react/functional-icons/Padlock';
import ArrowButton from '@alj-react/ui-components/ArrowButton';

import { BottomNavDiv, ArrowButtonStyled } from './ESignature.styles';
import { Context } from '../../utils/ESignatureContext';
import { getComponent, getSignType } from "../../utils/multi";
import { setAuraXmlOutputSign, setBDisplaySignInputSign, setShowModalSign } from "../../utils/dataFetchers";

type DisplaySignInputProps = {
  displaySign: boolean;
  messages: any;
  onSubmit: () => void;
  isFormValid: boolean;
};

const DisplaySignInput: React.FC<DisplaySignInputProps> = (props) => {
  const { displaySign, isFormValid, messages, onSubmit } = props;
  const { dispatch, state } = React.useContext(Context);
  const { formatMessage } = useIntl();
  
  const goBackToDisplayData = () => {
    setBDisplaySignInputSign(dispatch, false);
    window.scrollTo(0, 0);
  };
  
  const onClickModal = () => {
    setShowModalSign(dispatch, !state.showModal);
    setTimeout(() => {
      // console.log('dispatch resize once');
      window.dispatchEvent(new Event('resize'));
    }, 1);
  };

  const getTapToSignDisplayPreTextComponent = (contents?: { pageNo: number, pageContents: any[] }[]) => {
    let pageContentsSign: any[] = [];
    let idxTypeSign = -1;
    contents?.forEach((content) => {
      content.pageContents.forEach((page: {type: string, value: string}, idx: number) => {
        switch (page.type) {
          case 'sign':
            pageContentsSign = content.pageContents;
            idxTypeSign = idx;
            break;
          default:
            break;
        }
      });
    });
    let res: JSX.Element[] = [];
    for (let i = 0; i < idxTypeSign; i++) {
      res.push(getComponent(state, dispatch, () => void(0), pageContentsSign[i], 'tapPre', i));
    }
    return res;
  };

  const getTapToSignDisplayAfterTextComponent = (contents?: { pageNo: number, pageContents: any[] }[]) => {
    let pageContentsSign: any[] = [];
    let idxTypeSign = -1;
    contents?.forEach((content) => {
      content.pageContents.forEach((page: {type: string, value: string}, idx: number) => {
        switch (page.type) {
          case 'sign':
            pageContentsSign = content.pageContents;
            idxTypeSign = idx;
            break;
          default:
            break;
        }
      });
    });
    let res: JSX.Element[] = [];
    if (pageContentsSign.length > idxTypeSign) {
      for (let i = idxTypeSign + 1; i < pageContentsSign.length; i++) {
        res.push(getComponent(state, dispatch, () => void(0), pageContentsSign[i], 'tapAft', i));
      }
    }
    return res;
  };

  const getTapToSignDisplayText = (contents?: { pageNo: number, pageContents: any[] }[]) => {
    let textTapToSign = formatMessage(messages.clickToSign);
    contents?.forEach((content) => {
      content.pageContents.forEach((page: {type: string, value: string}, idx: number) => {
        switch (page.type) {
          case 'sign':
            if (page.value) {
              textTapToSign = page.value;
            }
            break;
          default:
            break;
        }
      });
    });
    // console.log('eeeeeeeee');
    return textTapToSign;
  };

  return (
    <>
      <div style={{ display: !state.showModal ? 'flex' : 'none', justifyContent: 'center' }}>
        {
          state.signature
            ? <>
              <div style={{ display: displaySign && state.checkboxSelected ? 'block' : 'none', /*paddingTop: '11px',*/ paddingBottom: '11px' }}>
                {
                  getTapToSignDisplayPreTextComponent(state.dataToDisplay?.Contents)
                }
                <div style={{ display: 'flex' /*, border: '1px solid lightgray'*/, cursor: 'pointer', marginTop: '10px' }} data-testid={'editSign'} onClick={onClickModal} >
                  <div style={{ width: '100%', /*height: '200px',*/ backgroundColor: '#ffff', clear: 'both', /*margin: '16px', */position: 'relative' }}>
                    <Text style={{ color: 'lightgray', position: 'absolute', left: '0px' }}>{formatMessage(messages.clickToEditSign)}</Text>
                    <PadlockIcon style={{ color: 'lightgray', position: 'absolute', right: '0px' }} />
                    <img style={{ width: 'inherit' }} src={state.signature} alt='signature' />
                  </div>
                </div>
                {
                  getTapToSignDisplayAfterTextComponent(state.dataToDisplay?.Contents)
                }
              </div>
            </>
            : <div>
              {
                getTapToSignDisplayPreTextComponent(state.dataToDisplay?.Contents)
              }
              <div style={{ paddingTop: '24px', paddingBottom: '24px', cursor: 'pointer', width: '100%', textAlign: 'center' }} data-testid={'clickToSign'} onClick={onClickModal} >
                <Text color='darkGray' size='28' style={{ paddingBottom: '0px', whiteSpace: 'pre-wrap', fontWeight: 'lighter' }}>{getTapToSignDisplayText(state.dataToDisplay?.Contents)}</Text>
              </div>
              {
                getTapToSignDisplayAfterTextComponent(state.dataToDisplay?.Contents)
              }
            </div>
        }
      </div>
      <BottomNavDiv>
        <ArrowButtonStyled iconPosition='left' color='white' onClick={goBackToDisplayData} id='goBackToDisplayData' data-testid={'goBackToDisplayData'}>{formatMessage(messages.goBackToDisplayDataBtnTxt)}</ArrowButtonStyled>
        <ArrowButton color='orange' onClick={onSubmit} disabled={!isFormValid} data-testid={'onSubmit'}>{formatMessage(messages.submitTxt)}</ArrowButton>
      </BottomNavDiv>
    </>
  );

};

export default DisplaySignInput;