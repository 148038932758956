import React from "react";
import Text from "@alj-react/ui-components/Text";
import { Context } from '../../utils/ESignatureContext';

const PlanInvalidStatus: React.FC = () => {
  const { state } = React.useContext(Context);

  return (
    <>
      <Text color='axaBlue' size="28" style={{ textAlign: 'center', whiteSpace: 'pre-wrap' }}>{state.dataToDisplay.invalidMsg}</Text>
    </>
  );
};

export default PlanInvalidStatus;
