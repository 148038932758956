import React from "react";
import { useIntl } from "react-intl";
import { ArrowButton } from "@alj-react/ui-components";
import { getComponent, sendToApi } from "../../utils/multi";
import { Context } from "../../utils/ESignatureContext";
import { setIsLoadingSign } from "../../utils/dataFetchers";
import { BottomNavDiv, ArrowButtonStyled } from "./ESignature.styles";

type DisplayPriorConsentProps = {
  apiResult: any;
  messages: any;
  isFormValid: boolean;
  isCancelled: React.MutableRefObject<boolean>;
  isCheckboxOnPage: boolean;
  goToPrevPageBtnTxt?: string,
  goToNextPageBtnTxt?: string,
  goToPrevPage: (e: React.MouseEvent<HTMLElement>) => void;
  goToNextPage: () => void;
  restoreFormInputsToForm: () => void;
};

const DisplayPriorConsent: React.FC<DisplayPriorConsentProps> = (props) => {
  const {
    messages,
    isCancelled,
    isCheckboxOnPage,
    goToPrevPageBtnTxt,
    goToNextPageBtnTxt,
    goToNextPage,
    goToPrevPage,
    restoreFormInputsToForm,
  } = props;
  const { formatMessage } = useIntl();
  const { dispatch, state } = React.useContext(Context);

  const onPriorConsent = () => {
    setIsLoadingSign(dispatch, true);
    sendToApi(state, dispatch, isCancelled, true);
  };

  return (
    <>
      {
        state.dataToDisplay?.Contents?.find((content: { pageNo: number, pageContents: any[] }) => content.pageNo === state.currentPageToDisplay)?.pageContents.map(( pageContent: any, subIdx: number) => getComponent(state, dispatch, restoreFormInputsToForm, pageContent, 'page', state.currentPageToDisplay, subIdx, state.inputResultList))
      }
      <BottomNavDiv>
        {
          state.currentPageToDisplay !== 1
            ? <ArrowButtonStyled iconPosition='left' color='white' onClick={goToPrevPage} id='goToPrevPage' data-testid={'goToPrevPage'}>{goToPrevPageBtnTxt || formatMessage(messages.goToPrevPageBtnTxt)}</ArrowButtonStyled>
            : <div></div>
        }
        {
          (state.currentPageToDisplay) === state.dataToDisplay.Contents?.length
            ? <ArrowButton color='orange' disabled={isCheckboxOnPage ? !state.checkboxSelected : false} onClick={onPriorConsent} data-testid={'onPriorConsent'}>{goToNextPageBtnTxt || formatMessage(messages.priorConsentBtnTxt)}</ArrowButton>
            : <ArrowButton onClick={goToNextPage} disabled={isCheckboxOnPage ? ((!state.checkboxSelected ) || (state.isDoingAuraStuff ? state.auraXmlOutput === '' : false)) : false} style={{ display: (state.isDoingAuraStuff && state.auraXmlOutput === '' ? 'none' : 'inherit') }} data-testid={'goToNextPage'}>{goToNextPageBtnTxt || formatMessage(messages.goToNextPageBtnTxt)}</ArrowButton>
        }
      </BottomNavDiv>
    </>
  );
};

export default DisplayPriorConsent;
