
import React from 'react';
import colors from '@alj-react/ui-components/styles/colors';
import { Text } from '@alj-react/ui-components';
import { useIntl } from 'react-intl';
import { defineMessages } from 'react-intl.macro';

import styled, { keyframes, css, FlattenSimpleInterpolation } from 'styled-components';
import Spinner from '../atoms/Spinner';

const rotateAnimation = keyframes`
  to{ transform: rotate(360deg); }
`;

const SpinnerStyled = styled(Spinner)`
  vertical-align: middle;
  text-align: center;
  width: 72px;
  height: 72px;
  color: ${colors.axaBlue};

  animation-name: ${rotateAnimation};
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
`;

type DivProps = {
  isLoading: boolean;
};

const WrapperDivStyled = styled.div<DivProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 100vh;
  width: 100%;

  ${({ isLoading }): FlattenSimpleInterpolation => isLoading ? 
    css`
      animation-name: ${fadeInAnimation};
      animation-fill-mode: both;
      animation-duration: 0.5s;
      animation-iteration-count: 1;
    `
    :
    css`
      animation-name: ${fadeOutAnimation};
      animation-fill-mode: both;
      animation-duration: 0.5s;
      animation-iteration-count: 1;
    `
  };
  
`;

const fadeInAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const fadeOutAnimation = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const WrapperMainOutAnimation = keyframes`
    0% {
      top: 0px;
      left: 0px;
    }
    99% {
      top: 0px;
      left: 0px;
    }
    100% {
      top: 0px;
      left: -100vw;
    }
`;

const WrapperMainInAnimation = keyframes`
    0% {
      top: 0px;
      left: 0px;
    }
    100% {
      top: 0px;
      left: 0px;
    }
`;

const WrapperMainDivStyled = styled.div<DivProps>`
  // z-index: 2000;
  z-index: 50;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;

  opacity: 1;
  background-color: white;

  ${({ isLoading }): FlattenSimpleInterpolation => isLoading ? 
    css`
      animation-name: ${WrapperMainInAnimation};
      animation-fill-mode: both;
      animation-duration: 0.5s;
      animation-iteration-count: 1;
    `
    :
    css`
      animation-name: ${WrapperMainOutAnimation};
      animation-fill-mode: both;
      animation-duration: 0.5s;
      animation-iteration-count: 1;
    `
  };
`;

const messages = defineMessages({
  loadingTxt: {
    id: 'LoadingSpinner.loadingTxt',
    defaultMessage: 'Please wait...',
  },
});

type LoadingSpinnerProps = {
  loading: boolean,
};
const LoadingSpinner = ({ loading }: LoadingSpinnerProps) => {
  const { formatMessage } = useIntl();
  return (
    <WrapperMainDivStyled isLoading={loading}>
      <WrapperDivStyled isLoading={loading}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <SpinnerStyled fill={colors.axaBlue}/>
          <Text color='axaBlue' style={{ marginTop: '10px' }}>{formatMessage(messages.loadingTxt)}</Text>
        </div>
      </WrapperDivStyled>
    </WrapperMainDivStyled>
  );
}

export default LoadingSpinner;
