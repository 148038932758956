import React from "react";
import { Table } from "../layout/Table";

type Props = {
  confirmation?: boolean;
};
export const Table6: React.FC<Props> = ({ confirmation }) => (
  <Table
    tableName={tableName}
    categoryDetails={tableCategoryDetails}
    confirmation={confirmation}
  />
);

const tableName = "表１";

const tableCategoryDetails = [
  [
    "てんかん",
    "精神疾患",
    "アルコール依存症",
    "脳卒中（脳出血・脳こうそく・くも膜下出血）",
    "狭心症",
    "心筋こうそく",
    "心臓弁膜症",
    "心筋症",
    "関節リウマチ",
    "パーキンソン病",
    "アルツハイマー病",
  ],
];
