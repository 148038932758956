/* eslint-disable */
var InkTool = {};
InkTool.InkLib = (function () {
  var _1 = document;
  return {
    Browser: (function () {
      var ua = navigator.userAgent;
      var _2 = Object.prototype.toString.call(window.opera) == "[object Opera]";
      return {
        IE: !!window.attachEvent && !_2,
        Opera: _2,
        WebKit: ua.indexOf("AppleWebKit/") > -1,
        Gecko: ua.indexOf("Gecko") > -1 && ua.indexOf("KHTML") === -1,
        MobileSafari: /Apple.*Mobile/.test(ua),
        Chrome: ua.indexOf("Chrome") > -1,
        Firefox: ua.indexOf("Firefox") > -1,
        Safari: ua.indexOf("Safari") > -1 && ua.indexOf("Chrome") === -1 && ua.indexOf("Mobile") === -1
      };
    })(),
    BrowserVersion: (function () {
      var av = navigator.appVersion.toLowerCase();
      return {
        IE: (function () {
          var _3 = av.indexOf("msie ");
          if (_3 < 0) {
            return 0;
          }
          _3 += 5;
          var _4 = av.indexOf(".", _3);
          if (_4 < 0) {
            return 0;
          }
          return parseInt(av.substring(_3, _4), 10);
        })(),
        Others: 0
      };
    })(),
    SupportsTouches: (("createTouch" in document) || ("ontouchstart" in document)),
    Class: (function () {
      function _5() {};

      function _6(_7, _8) {
        if (_7 == null) {
          _7 = InkTool.Base;
        }

        function _9() {
          this.initialize.apply(this, arguments);
        };
        if (_7 != null) {
          _5.prototype = _7.prototype;
          _9.prototype = new _5;
          _7.subclasses.push(_9);
        }
        for (var _a in _8) {
          _9.prototype[_a] = _8[_a];
        }
        _9.superclass = _7;
        _9.subclasses = [];
        _9.prototype.constructor = _9;
        return _9;
      };

      function _b(_c, _d) {
        for (var _e in _d) {
          _c.prototype[_e] = _d[_e];
        }
        return _c;
      };

      function _f(_10) {
        var _11 = new Object();
        for (var _12 in _10) {
          if (typeof _10[_12].clone == "function") {
            _11[_12] = _10[_12].clone();
          } else {
            _11[_12] = _10[_12];
          }
        }
        return _11;
      };
      return {
        create: _6,
        extend: _b,
        clone: _f
      };
    })(),
    needsEmulateCanvas: function () {
      return (InkTool.InkLib.Browser.IE && InkTool.InkLib.BrowserVersion.IE <= 8);
    },
    getBrowserTypeName: function () {
      var _13 = InkTool.InkLib.Browser;
      var _14 = InkTool.InkLib.BrowserVersion;
      if (_13.IE) {
        return "IE" + _14.IE;
      } else {
        if (_13.Opera) {
          return "Opera";
        } else {
          if (_13.Chrome) {
            return "Chorme";
          } else {
            if (_13.Firefox) {
              return "Firefox";
            } else {
              if (_13.Safari) {
                return "Safari";
              } else {
                if (_13.MobileSafari) {
                  return "MobileSafari";
                } else {
                  if (_13.Gecko) {
                    return "Gecko";
                  } else {
                    if (_13.Webkit) {
                      return "Webkit";
                    } else {
                      return "Unknown Browser";
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    getElementById: function (id) {
      if (_1.getElementById) {
        return _1.getElementById(id);
      } else {
        if (_1.all) {
          return _1.all(id);
        } else {
          if (_1.layers) {
            return _1.layers[id];
          } else {
            return null;
          }
        }
      }
    },
    camelizeString: function (str) {
      var _15 = str.split("-");
      if (_15.length == 1) {
        return _15[0];
      }
      var _16 = (str.indexOf("-") == 0) ? _15[0].charAt(0).toUpperCase() + _15[0].substring(1) : _15[0];
      for (var i = 1, len = _15.length; i < len; i++) {
        var s = _15[i];
        _16 += s.charAt(0).toUpperCase() + s.substring(1);
      }
      return _16;
    },
    trimString: function (str) {
      var _17, ch, _18;
      _18 = -1;
      for (_17 = 0; _17 < str.length; _17++) {
        ch = str.charAt(_17);
        if (ch != " " && ch != "?�@") {
          _18 = _17;
        }
      }
      return str.substring(0, _18 + 1);
    },
    trimWhiteSpace: function (str) {
      var _19, ch, _1a, _1b;
      _1a = -1;
      _1b = -1;
      for (_19 = 0; _19 < str.length; _19++) {
        ch = str.charAt(_19);
        if (ch != " " && ch != "\t" && ch != "\r" && ch != "\n") {
          if (_1a < 0) {
            _1a = _19;
          }
          _1b = _19;
        }
      }
      return str.substring(_1a, _1b + 1);
    },
    getStyleOfElement: function (_1c, _1d, _1e) {
      if (_1c.currentStyle) {
        return _1c.currentStyle[InkTool.InkLib.camelizeString(_1d)];
      } else {
        if (getComputedStyle) {
          return getComputedStyle(_1c, "").getPropertyValue(_1d);
        } else {
          if (_1.defaultView) {
            return _1.defaultView.getComputedStyle(_1c, "").getPropertyValue(_1d);
          } else {
            return _1e;
          }
        }
      }
    },
    getStyleById: function (id, _1f, _20) {
      var _21 = InkTool.InkLib.getElementById(id);
      if (_21 == null) {
        return _20;
      } else {
        return InkTool.InkLib.getStyleOfElement(_21, _1f, _20);
      }
    },
    getScrollX: function () {
      if (window.scrollX) {
        return window.scrollX;
      } else {
        if (window.pageXOffset) {
          return window.pageXOffset;
        } else {
          if (_1.documentElement && _1.documentElement.scrollLeft) {
            return _1.documentElement.scrollLeft;
          } else {
            if (_1.body && _1.body.scrollLeft) {
              return _1.body.scrollLeft;
            } else {
              return 0;
            }
          }
        }
      }
    },
    getScrollY: function () {
      if (window.scrollY) {
        return window.scrollY;
      } else {
        if (window.pageYOffset) {
          return window.pageYOffset;
        } else {
          if (_1.documentElement && _1.documentElement.scrollTop) {
            return _1.documentElement.scrollTop;
          } else {
            if (_1.body && _1.body.scrollTop) {
              return _1.body.scrollTop;
            } else {
              return 0;
            }
          }
        }
      }
    },
    getClientX: function () {
      if (_1.documentElement && _1.documentElement.clientLeft) {
        return _1.documentElement.clientLeft;
      } else {
        if (_1.body && _1.body.clientLeft) {
          return _1.body.clientLeft;
        } else {
          return 0;
        }
      }
    },
    getClientY: function () {
      if (_1.documentElement && _1.documentElement.clientTop) {
        return _1.documentElement.clientTop;
      } else {
        if (_1.body && _1.body.clientTop) {
          return _1.body.clientTop;
        } else {
          return 0;
        }
      }
    },
    getOffsetX: function (_22) {
      if (typeof window.crypto != "undefined" && typeof window.getComputedStyle != "undefined" && obj.style.left) {
        return InkTool.InkLib.getClientX() + parseInt(_22.style.left);
      } else {
        return InkTool.InkLib.getClientX() + (_1.layers ? _22.left : (_22.offsetLeft || _22.style.pixelLeft || 0));
      }
    },
    getOffsetY: function (_23) {
      if (typeof window.crypto != "undefined" && typeof window.getComputedStyle != "undefined" && obj.style.top) {
        return InkTool.InkLib.getClientY() + parseInt(_23.style.top);
      } else {
        return InkTool.InkLib.getClientY() + (_1.layers ? _23.top : (_23.offsetTop || _23.style.pixelTop || 0));
      }
    },
    getEventX: function (_24, _25) {
      sx = InkTool.InkLib.getScrollX();
      if (_25 != null) {
        ox = InkTool.InkLib.getOffsetX(_25);
      } else {
        ox = 0;
      }
      return _24.clientX - ox + sx;
    },
    getEventY: function (_26, _27) {
      sy = InkTool.InkLib.getScrollY();
      if (_27 != null) {
        oy = InkTool.InkLib.getOffsetY(_27);
      } else {
        oy = 0;
      }
      return _26.clientY - oy + sy;
    },
    getClientWidth: function () {
      if (_1.documentElement.clientWidth) {
        return _1.documentElement.clientWidth;
      } else {
        if (_1.body.clientWidth) {
          return _1.body.clientWidth;
        } else {
          return window.innerWidth;
        }
      }
    },
    getClientHeight: function () {
      if (_1.documentElement.clientHeight) {
        return _1.documentElement.clientHeight;
      } else {
        if (_1.body.clientHeight) {
          return _1.body.clientHeight;
        } else {
          return window.innerHeight;
        }
      }
    },
    getElementSize: function (_28) {
      if (_28 == null) {
        return {
          width: InkTool.InkLib.getClientWidth(),
          height: InkTool.InkLib.getClientHeight()
        };
      }
      if (InkTool.InkLib.getStyleOfElement(_28, "display", "") != "none") {
        return {
          width: _28.offsetWidth,
          height: _28.offsetHeight
        };
      }
      var _29 = _28.style.visibility;
      var _2a = _28.style.position;
      _28.style.visibility = "hidden";
      _28.style.position = "absolute";
      _28.style.display = "";
      var _2b = _28.clientWidth;
      var _2c = _28.clientHeight;
      _28.style.display = "none";
      _28.style.position = _2a;
      _28.style.visibility = _29;
      return {
        width: _2b,
        height: _2c
      };
    },
    getElementLocation: function (_2d) {
      var _2e = _2d;
      var xp = 0;
      var yp = 0;
      while (_2e) {
        xp += _2e.offsetLeft;
        yp += _2e.offsetTop;
        _2e = _2e.offsetParent;
        if ((_2e) && (InkTool.InkLib.Browser.IE)) {
          xp += (parseInt(InkTool.InkLib.getStyleOfElement(_2e, "border-left-width", "0")) || 0);
          yp += (parseInt(InkTool.InkLib.getStyleOfElement(_2e, "border-top-width", "0")) || 0);
        }
      }
      if (InkTool.InkLib.Browser.Gecko) {
        var bd = _1.getElementsByTagName("BODY")[0];
        xp += 2 * (parseInt(InkTool.InkLib.getStyleOfElement(bd, "border-left-width", "0")) || 0);
        yp += 2 * (parseInt(InkTool.InkLib.getStyleOfElement(bd, "border-top-width", "0")) || 0);
      }
      return {
        left: xp,
        top: yp
      };
    },
    getElementAbsoluteLocation: function (_2f) {
      var _30 = _2f;
      var xp = 0;
      var yp = 0;
      while (_30) {
        xp += _30.offsetLeft;
        yp += _30.offsetTop;
        _30 = _30.offsetParent;
        if ((_30) && (InkTool.InkLib.Browser.IE)) {
          xp += (parseInt(InkTool.InkLib.getStyleOfElement(_30, "border-left-width", "0")) || 0);
          yp += (parseInt(InkTool.InkLib.getStyleOfElement(_30, "border-top-width", "0")) || 0);
        }
        if ((_30)) {
          var pos = InkTool.InkLib.getStyleOfElement(_30, "position", "");
          if (pos != "" && pos != "static") {
            break;
          }
        }
      }
      if (InkTool.InkLib.Browser.Gecko && (_30 == null)) {
        var bd = _1.getElementsByTagName("BODY")[0];
        xp += 2 * (parseInt(InkTool.InkLib.getStyleOfElement(bd, "border-left-width", "0")) || 0);
        yp += 2 * (parseInt(InkTool.InkLib.getStyleOfElement(bd, "border-top-width", "0")) || 0);
      }
      return {
        left: xp,
        top: yp
      };
    },
    addElementAfter: function (_31, _32) {
      if (_31.nextSibling) {
        _31.parentNode.insertBefore(_32, _31.nextSibling);
      } else {
        _31.parentNode.appendChild(_32);
      }
    },
    getMaxZIndex: function () {
      var _33 = 0;
      var _34 = document.defaultView;
      var _35 = function (_36) {
        var _37 = document.getElementsByTagName(_36);
        for (var _38 = 0; _38 < _37.length; _38++) {
          var _39 = _37[_38];
          var _3a = InkTool.InkLib.getStyleOfElement(_39, "z-index", "0");
          _3a -= 0;
          if (_33 < _3a) {
            _33 = _3a;
          }
        }
      };
      if (arguments.length == 0) {
        _35("*");
      } else {
        for (var ct = 0; ct < arguments.length; ct++) {
          _35(arguments[ct]);
        }
      }
      return _33;
    },
    showElement: function (_3b) {
      if (_3b != null) {
        _3b.style.visibility = "visible";
      }
    },
    hideElement: function (_3c) {
      if (_3c != null) {
        _3c.style.visibility = "hidden";
      }
    },
    showElementById: function (id) {
      InkTool.InkLib.showElement(InkTool.InkLib.getElementById(id));
    },
    hideElementById: function (id) {
      InkTool.InkLib.hideElement(InkTool.InkLib.getElementById(id));
    },
    addEvent: function (_3d, _3e, fn) {
      if (_3d.addEventListener) {
        _3d.addEventListener(_3e, function () {
          return fn.apply(_3d, arguments);
        }, false);
        return true;
      } else {
        if (_3d.attachEvent) {
          return _3d.attachEvent("on" + _3e, function () {
            return fn.apply(_3d, arguments);
          });
        } else {
          return false;
        }
      }
    },
    removeEvent: function (_3f, _40, fn) {
      if (_3f.removeEventListener) {
        _3f.removeEventListener(_40, function () {
          return fn.apply(_3f, arguments);
        }, false);
        return true;
      } else {
        if (_3f.detachEvent) {
          return _3f.detachEvent("on" + _40, function () {
            return fn.apply(_3f, arguments);
          });
        } else {
          return false;
        }
      }
    },
    contains: function (_41, _42) {
      for (var i, len = _41.length; i < len; i++) {
        if (_41[i] === _42) {
          return true;
        }
      }
      return false;
    },
    getLastEvent: function (evt) {
      if (InkTool.InkLib.Browser.IE) {
        return window.event;
      } else {
        return evt;
      }
    },
    getEventPosition: function (evt) {
      return {
        left: (evt.pageX ? evt.pageX : evt.clientX + InkTool.InkLib.getScrollX()),
        top: (evt.pageY ? evt.pageY : evt.clientY + InkTool.InkLib.getScrollY())
      };
    },
    getTouchEventPosition: function (evt, _43) {
      if (evt.touches && _43 >= 0 && _43 < evt.touches.length) {
        return InkTool.InkLib.getEventPosition(evt.touches[_43]);
      } else {
        return null;
      }
    },
    convertPositionDocumentToElement: function (pos, _44) {
      var _45 = InkTool.InkLib.getElementLocation(_44);
      return {
        left: pos.left - _45.left,
        top: pos.top - _45.top
      };
    },
    convertPositionEventToAbsolute: function (pos) {
      return {
        left: pos.left - InkTool.InkLib.getScrollX(),
        top: pos.top - InkTool.InkLib.getScrollY()
      };
    },
    getEventPositionOfElement: function (evt, _46) {
      var _47 = InkTool.InkLib.getEventPosition(evt);
      var _48 = InkTool.InkLib.getElementLocation(_46);
      return {
        left: _47.left - _48.left,
        top: _47.top - _48.top
      };
    },
    preventDefaultActionOfEvent: function (evt, _49, _4a) {
      if (_49) {
        if (InkTool.InkLib.Browser.IE) {
          evt.returnValue = false;
        } else {
          evt.preventDefault();
        }
      }
      if (_4a) {
        if (InkTool.InkLib.Browser.IE) {
          evt.cancelBubble = true;
        } else {
          evt.stopPropagation();
        }
      }
    },
    getButtonPushed: function (_4b, _4c) {
      var _4d = InkTool.InkLib.Browser;
      if (_4d.IE) {
        var _4e = {
          0: 1,
          1: 4,
          2: 2
        };
        return (_4b.button === _4e[_4c]);
      } else {
        if (_4d.WebKit) {
          switch (_4c) {
          case 0:
            return _4b.which == 1 && !_4b.metaKey;
          case 1:
            return _4b.which == 2 || (_4b.which == 1 && _4b.metaKey);
          case 2:
            return _4b.which == 3;
          default:
            return false;
          }
        } else {
          return _4b.which ? (_4b.which === _4c + 1) : (_4b.button === _4c);
        }
      }
    },
    getButtonStatus: function (_4f) {
      if (InkTool.InkLib.getButtonPushed(_4f, 0)) {
        return 1;
      } else {
        if (InkTool.InkLib.getButtonPushed(_4f, 1)) {
          return 2;
        } else {
          if (InkTool.InkLib.getButtonPushed(_4f, 2)) {
            return 3;
          } else {
            return 0;
          }
        }
      }
    },
    preLoadImage: function (_50) {
      var img = new Image();
      img.src = _50 + "?" + new Date().getTime();
      return img;
    },
    setSelectionToElement: function (_51, _52, end) {
      if (_51.setSelectionRange) {
        _51.setSelectionRange(_52, end);
      } else {
        var _53 = -(_51.value.length - end);
        var _54 = _51.createTextRange();
        _54.moveStart("character", _52);
        _54.moveEnd("character", _53);
        _54.select();
      }
    },
    selectAllOfElement: function (_55) {
      InkTool.InkLib.setSelectionToElement(_55, 0, _55.value.length);
    },
    insertTextToElement: function (_56, _57) {
      _56.focus();
      if ((_56.selectionStart || _56.selectionStart == 0) && (_56.selectionEnd || _56.selectionEnd == 0)) {
        var _58 = _56.value;
        _56.value = _58.substring(0, _56.selectionStart) + _57 + _58.substring(_56.selectionEnd, _58.length);
      } else {
        var _59 = _1.selection.createRange();
        _59.text = _57;
      }
    },
    disableSelection: function (_5a) {
      if (typeof _5a.onselectstart != "undefined") {
        _5a.onselectstart = function () {
          return false;
        };
      } else {
        if (typeof _5a.style.MozUserSelect != "undefined") {
          _5a.style.MozUserSelect = "none";
        } else {
          _5a.onmousedown = function () {
            return false;
          };
        }
      }
      _5a.style.cursor = "default";
    },
    setCookie: function (_5b, _5c) {
      for (var key in _5b) {
        var str = "" + key + "=" + encodeURIComponent(_5b[key]);
        if (_5c != null && _5c != undefined) {
          var _5d = new Date();
          _5d.setTime(_5d.getTime() + _5c);
          str += " ;expires=" + _5d.toUTCString();
        }
        document.cookie = str;
      }
    },
    getCookie: function () {
      var _5e = new Object();
      var _5f = document.cookie;
      if (_5f != "") {
        var _60 = _5f.split(";");
        for (var _61 = 0; _61 < _60.length; _61++) {
          var _62 = _60[_61].split("=");
          var key = InkTool.InkLib.trimWhiteSpace(_62[0]);
          var val = InkTool.InkLib.trimWhiteSpace(decodeURIComponent(_62[1]));
          _5e[key] = val;
        }
      }
      return _5e;
    },
    getCookieValue: function (_63, key, _64) {
      if (_63 == null) {
        return _64;
      }
      var _65 = _63[key];
      if (_65 != null && _65 != undefined) {
        if (typeof (_64) == "number") {
          return Number(_65);
        } else {
          if (typeof (_64) == "boolean") {
            if (_65 == "false") {
              return false;
            } else {
              return Boolean(_65);
            }
          } else {
            return _65;
          }
        }
      } else {
        return _64;
      }
    },
    dummyFunction: function () {}
  };
})();
InkTool.Base = function () {};
InkTool.Base.superclass = null;
InkTool.Base.subclasses = [];
InkTool.Base.prototype = {
  initialize: function () {},
  clone: function () {
    var _66 = new Object();
    for (var _67 in this) {
      _66[_67] = this.doClone(this[_67]);
    }
    return _66;
  },
  doClone: function (_68) {
    if (_68 instanceof InkTool.Base) {
      return _68.clone();
    } else {
      if (_68 instanceof Array) {
        var _69 = new Array();
        var _6a = _68.length;
        for (var _6b = 0; _6b < _6a; _6b++) {
          _69.push(this.doClone(_68[_6b]));
        }
        return _69;
      } else {
        if (typeof _68 == "object") {
          var obj = new Object();
          for (var key in _68) {
            obj[key] = this.doClone(_68[key]);
          }
          return obj;
        } else {
          return _68;
        }
      }
    }
  },
  extend: function (_6c) {
    for (var _6d in _6c) {
      this.prototype[_6d] = _6c[_6d];
    }
  }
};
InkTool.InkColor = InkTool.InkLib.Class.create(null, {
  initialize: function (_1) {
    this.setColor(_1);
  },
  clearColor: function (_2) {
    this.ok = _2;
    this.R = 0;
    this.G = 0;
    this.B = 0;
    this.A = 0;
  },
  setColor: function (_3) {
    if (_3 === null) {
      this.clearColor(false);
    } else {
      if (typeof _3 == "string") {
        this.setString(_3);
      } else {
        if (typeof _3 == "number") {
          this.setNumber(_3);
        } else {
          this.copyColor(_3);
        }
      }
    }
  },
  setString: function (_4) {
    this.clearColor(false);
    _4 = _4.replace(/ /g, "");
    _4 = _4.toLowerCase();
    var _5 = {
      aliceblue: "f0f8ff",
      antiquewhite: "faebd7",
      aqua: "00ffff",
      aquamarine: "7fffd4",
      azure: "f0ffff",
      beige: "f5f5dc",
      bisque: "ffe4c4",
      black: "000000",
      blanchedalmond: "ffebcd",
      blue: "0000ff",
      blueviolet: "8a2be2",
      brown: "a52a2a",
      burlywood: "deb887",
      cadetblue: "5f9ea0",
      chartreuse: "7fff00",
      chocolate: "d2691e",
      coral: "ff7f50",
      cornflowerblue: "6495ed",
      cornsilk: "fff8dc",
      crimson: "dc143c",
      cyan: "00ffff",
      darkblue: "00008b",
      darkcyan: "008b8b",
      darkgoldenrod: "b8860b",
      darkgray: "a9a9a9",
      darkgreen: "006400",
      darkkhaki: "bdb76b",
      darkmagenta: "8b008b",
      darkolivegreen: "556b2f",
      darkorange: "ff8c00",
      darkorchid: "9932cc",
      darkred: "8b0000",
      darksalmon: "e9967a",
      darkseagreen: "8fbc8f",
      darkslateblue: "483d8b",
      darkslategray: "2f4f4f",
      darkturquoise: "00ced1",
      darkviolet: "9400d3",
      deeppink: "ff1493",
      deepskyblue: "00bfff",
      dimgray: "696969",
      dodgerblue: "1e90ff",
      feldspar: "d19275",
      firebrick: "b22222",
      floralwhite: "fffaf0",
      forestgreen: "228b22",
      fuchsia: "ff00ff",
      gainsboro: "dcdcdc",
      ghostwhite: "f8f8ff",
      gold: "ffd700",
      goldenrod: "daa520",
      gray: "808080",
      green: "008000",
      greenyellow: "adff2f",
      honeydew: "f0fff0",
      hotpink: "ff69b4",
      indianred: "cd5c5c",
      indigo: "4b0082",
      ivory: "fffff0",
      khaki: "f0e68c",
      lavender: "e6e6fa",
      lavenderblush: "fff0f5",
      lawngreen: "7cfc00",
      lemonchiffon: "fffacd",
      lightblue: "add8e6",
      lightcoral: "f08080",
      lightcyan: "e0ffff",
      lightgoldenrodyellow: "fafad2",
      lightgrey: "d3d3d3",
      lightgreen: "90ee90",
      lightpink: "ffb6c1",
      lightsalmon: "ffa07a",
      lightseagreen: "20b2aa",
      lightskyblue: "87cefa",
      lightslateblue: "8470ff",
      lightslategray: "778899",
      lightsteelblue: "b0c4de",
      lightyellow: "ffffe0",
      lime: "00ff00",
      limegreen: "32cd32",
      linen: "faf0e6",
      magenta: "ff00ff",
      maroon: "800000",
      mediumaquamarine: "66cdaa",
      mediumblue: "0000cd",
      mediumorchid: "ba55d3",
      mediumpurple: "9370d8",
      mediumseagreen: "3cb371",
      mediumslateblue: "7b68ee",
      mediumspringgreen: "00fa9a",
      mediumturquoise: "48d1cc",
      mediumvioletred: "c71585",
      midnightblue: "191970",
      mintcream: "f5fffa",
      mistyrose: "ffe4e1",
      moccasin: "ffe4b5",
      navajowhite: "ffdead",
      navy: "000080",
      oldlace: "fdf5e6",
      olive: "808000",
      olivedrab: "6b8e23",
      orange: "ffa500",
      orangered: "ff4500",
      orchid: "da70d6",
      palegoldenrod: "eee8aa",
      palegreen: "98fb98",
      paleturquoise: "afeeee",
      palevioletred: "d87093",
      papayawhip: "ffefd5",
      peachpuff: "ffdab9",
      peru: "cd853f",
      pink: "ffc0cb",
      plum: "dda0dd",
      powderblue: "b0e0e6",
      purple: "800080",
      red: "ff0000",
      rosybrown: "bc8f8f",
      royalblue: "4169e1",
      saddlebrown: "8b4513",
      salmon: "fa8072",
      sandybrown: "f4a460",
      seagreen: "2e8b57",
      seashell: "fff5ee",
      sienna: "a0522d",
      silver: "c0c0c0",
      skyblue: "87ceeb",
      slateblue: "6a5acd",
      slategray: "708090",
      snow: "fffafa",
      springgreen: "00ff7f",
      steelblue: "4682b4",
      tan: "d2b48c",
      teal: "008080",
      thistle: "d8bfd8",
      tomato: "ff6347",
      turquoise: "40e0d0",
      violet: "ee82ee",
      violetred: "d02090",
      wheat: "f5deb3",
      white: "ffffff",
      whitesmoke: "f5f5f5",
      yellow: "ffff00",
      yellowgreen: "9acd32"
    };
    var _6 = _5[_4];
    if (_6 != undefined) {
      _4 = "#" + _6;
    }
    var _7 = [{
      re: /^rgba\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3}),\s*([0-9\.]*)\)$/,
      example: ["rgba(123, 234, 45, 1.0)", "rgba(255,234,245, 0.5)"],
      process: function (_8) {
        return [parseInt(_8[1]), parseInt(_8[2]), parseInt(_8[3]), Math.floor(parseFloat(_8[4]) * 255 + 0.5)];
      }
    }, {
      re: /^rgb\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3})\)$/,
      example: ["rgb(123, 234, 45)", "rgb(255,234,245)"],
      process: function (_9) {
        return [parseInt(_9[1]), parseInt(_9[2]), parseInt(_9[3]), 255];
      }
    }, {
      re: /^#([0-9a-fA-F]{2})([0-9a-fA-F]{2})([0-9a-fA-F]{2})([0-9a-fA-F]{2})$/,
      example: ["#ff00ff00", "80336699"],
      process: function (_a) {
        return [parseInt(_a[2], 16), parseInt(_a[3], 16), parseInt(_a[4], 16), parseInt(_a[1], 16)];
      }
    }, {
      re: /^#([0-9a-fA-F]{2})([0-9a-fA-F]{2})([0-9a-fA-F]{2})$/,
      example: ["#00ff00", "336699"],
      process: function (_b) {
        return [parseInt(_b[1], 16), parseInt(_b[2], 16), parseInt(_b[3], 16), 255];
      }
    }, {
      re: /^#(\w{1})(\w{1})(\w{1})$/,
      example: ["#fb0", "f0f"],
      process: function (_c) {
        return [parseInt(_c[1] + _c[1], 16), parseInt(_c[2] + _c[2], 16), parseInt(_c[3] + _c[3], 16), 255];
      }
    }];
    for (var i = 0; i < _7.length; i++) {
      var re = _7[i].re;
      var _d = _7[i].process;
      var _e = re.exec(_4);
      if (_e) {
        var channels = _d(_e);
        this.R = channels[0];
        this.G = channels[1];
        this.B = channels[2];
        this.A = channels[3];
        this.ok = true;
        break;
      }
    }
    if (this.ok == true) {
      function _f(val) {
        return (isNaN(val) == false && val >= 0 && val <= 255);
      };
      if (_f(this.R) == false || _f(this.G) == false || _f(this.B) == false || _f(this.A) == false) {
        this.clearColor(false);
      }
    }
  },
  setNumber: function (_10) {
    this.A = (_10 >>> 24);
    this.R = ((_10 & 16711680) >> 16);
    this.G = ((_10 & 65280) >> 8);
    this.B = (_10 & 255);
    this.ok = true;
  },
  copyColor: function (_11) {
    this.A = _11.A;
    this.R = _11.R;
    this.G = _11.G;
    this.B = _11.B;
    this.ok = _11.ok;
  },
  getRGB: function () {
    return "rgb(" + this.R + "," + this.G + "," + this.B + ")";
  },
  getRGBA: function () {
    return "rgba(" + this.R + "," + this.G + "," + this.B + "," + (this.A / 255).toFixed(3) + ")";
  },
  getHex: function () {
    var r = this.R.toString(16);
    var g = this.G.toString(16);
    var b = this.B.toString(16);
    if (r.length == 1) {
      r = "0" + r;
    }
    if (g.length == 1) {
      g = "0" + g;
    }
    if (b.length == 1) {
      b = "0" + b;
    }
    return "#" + r + g + b;
  },
  getNumber: function () {
    return (((this.A << 24) + (this.R << 16) + (this.G << 8) + this.B) >>> 0);
  },
});
InkTool.InkPenType = {
  None: 4,
  BallpointPen: 0,
  FeltPen: 1,
  BrushPen: 1,
  StrokeEraser: 2,
  NormalEraser: 3,
  MarkerPen: 5,
  FudePen: 6,
  AirbrushPen: 7,
  Pencil: 10,
  PencilEmulated: 11,
  Max: 100
};
InkTool.InkStationery = InkTool.InkLib.Class.create(null, {
  initialize: function () {
    this.tp = InkTool.InkPenType.None;
    this.col = new InkTool.InkColor("black");
    this.w = 1;
    this.minw = 1;
    this.maxw = 1;
  },
  getInfo: function () {
    return "type=" + this.tp + ":color=" + this.col.getRGBA() + ":width=" + this.w + "(" + this.minw + "-" + this.maxw + ")";
  },
  getPenType: function () {
    return this.tp;
  },
  setPenColor: function (_1) {
    this.col = _1;
  },
  getPenColor: function () {
    return this.col;
  },
  setPenWidth: function (_2) {
    this.w = _2;
  },
  getPenWidth: function () {
    return this.w;
  },
  setPenMinWidth: function (_3) {
    this.minw = _3;
  },
  getPenMinWidth: function () {
    return this.minw;
  },
  setPenMaxWidth: function (_4) {
    this.maxw = _4;
  },
  getPenMaxWidth: function () {
    return this.maxw;
  },
  hasFullDrawMode: function () {
    return false;
  }
});
InkTool.DrawingContext = InkTool.InkLib.Class.create(null, {
  initialize: function (_5, _6, _7, _8) {
    this.context = _5;
    this.offsetX = _6;
    this.offsetY = _7;
    this.zoomRatio = _8;
    this.eraserBgColor = null;
  },
  setContext: function (_9) {
    this.context = _9;
  },
  getContext: function () {
    return this.context;
  },
  setOffset: function (_a, _b) {
    this.offsetX = _a;
    this.offsetY = _b;
  },
  getOffsetX: function () {
    return this.offsetX;
  },
  getOffsetY: function () {
    return this.offsetY;
  },
  setZoomRatio: function (_c) {
    this.zoomRatio = _c;
  },
  getZoomRatio: function () {
    return this.zoomRatio;
  },
  xpDataToDraw: function (xp) {
    return ((xp - this.offsetX) * this.zoomRatio);
  },
  ypDataToDraw: function (yp) {
    return ((yp - this.offsetY) * this.zoomRatio);
  },
  widthDataToDraw: function (_d) {
    return (_d * this.zoomRatio);
  },
  heightDataToDraw: function (_e) {
    return (_e * this.zoomRatio);
  },
  setEraserBgColor: function (_f) {
    this.eraserBgColor = _f;
  },
  getEraserBgColor: function () {
    return this.eraserBgColor;
  },
  dummy: function () {}
});
InkTool.InkBallpointPen = InkTool.InkLib.Class.create(InkTool.InkStationery, {
  initialize: function () {
    this.tp = InkTool.InkPenType.BallpointPen;
    this.col = new InkTool.InkColor("darkred");
    this.w = 2;
    this.minw = 1;
    this.maxw = 5;
  },
  drawStart: function (_10) {
    var ctx = _10.context;
    ctx.strokeStyle = this.getPenColor().getRGBA();
    ctx.fillStyle = this.getPenColor().getRGBA();
    ctx.globalCompositeOperation = "source-over";
    ctx.lineWidth = _10.widthDataToDraw(this.getPenWidth());
    ctx.lineCap = "round";
    ctx.lineJoin = "round";
  },
  strokeStart: function (_11, xp, yp, tm) {
    var ctx = _11.context;
    var _12 = _11.xpDataToDraw(xp);
    var _13 = _11.ypDataToDraw(yp);
    _11.llastX = _11.lastX = _12;
    _11.llastY = _11.lastY = _13;
  },
  strokeMove: function (_14, xp, yp, tm) {
    var ctx = _14.context;
    var _15 = _14.xpDataToDraw(xp);
    var _16 = _14.ypDataToDraw(yp);
    var x1 = (_14.lastX + _14.llastX) / 2;
    var y1 = (_14.lastY + _14.llastY) / 2;
    var x2 = _14.lastX;
    var y2 = _14.lastY;
    var x3 = (_14.lastX + _15) / 2;
    var y3 = (_14.lastY + _16) / 2;
    ctx.beginPath();
    ctx.moveTo(x1, y1);
    ctx.quadraticCurveTo(x2, y2, x3, y3);
    if (InkTool.InkLib.Browser.Chrome) {
      ctx.lineTo(x3 + 0.1, y3 + 0.1);
    }
    ctx.stroke();
    _14.llastX = _14.lastX;
    _14.llastY = _14.lastY;
    _14.lastX = _15;
    _14.lastY = _16;
  },
  strokeEnd: function (_17, xp, yp, tm) {
    var ctx = _17.context;
    var _18 = _17.xpDataToDraw(xp);
    var _19 = _17.ypDataToDraw(yp);
    var x1 = (_17.lastX + _17.llastX) / 2;
    var y1 = (_17.lastY + _17.llastY) / 2;
    var x2 = _17.lastX;
    var y2 = _17.lastY;
    var x3 = _18;
    var y3 = _19;
    ctx.beginPath();
    ctx.moveTo(x1, y1);
    ctx.quadraticCurveTo(x2, y2, x3, y3);
    if (InkTool.InkLib.Browser.Chrome) {
      ctx.lineTo(x3 + 0.1, y3 + 0.1);
    }
    ctx.stroke();
    _17.llastX = _17.lastX;
    _17.llastY = _17.lastY;
    _17.lastX = _18;
    _17.lastY = _19;
  },
  drawEnd: function (_1a) {},
  fullDrawStart: function (_1b) {
    var ctx = _1b.context;
    ctx.strokeStyle = this.getPenColor().getRGBA();
    ctx.fillStyle = this.getPenColor().getRGBA();
    ctx.globalCompositeOperation = "source-over";
    ctx.lineWidth = _1b.widthDataToDraw(this.getPenWidth());
    ctx.lineCap = "round";
    ctx.lineJoin = "round";
  },
  fullStrokeStart: function (_1c, xp, yp, tm) {
    var ctx = _1c.context;
    var _1d = _1c.xpDataToDraw(xp);
    var _1e = _1c.ypDataToDraw(yp);
    ctx.beginPath();
    ctx.moveTo(_1d, _1e);
    _1c.llastX = _1c.lastX = _1d;
    _1c.llastY = _1c.lastY = _1e;
  },
  fullStrokeMove: function (_1f, xp, yp, tm) {
    var ctx = _1f.context;
    var _20 = _1f.xpDataToDraw(xp);
    var _21 = _1f.ypDataToDraw(yp);
    var x2 = _1f.lastX;
    var y2 = _1f.lastY;
    var x3 = (_1f.lastX + _20) / 2;
    var y3 = (_1f.lastY + _21) / 2;
    ctx.quadraticCurveTo(x2, y2, x3, y3);
    _1f.llastX = _1f.lastX;
    _1f.llastY = _1f.lastY;
    _1f.lastX = _20;
    _1f.lastY = _21;
  },
  fullStrokeEnd: function (_22, xp, yp, tm) {
    var ctx = _22.context;
    var _23 = _22.xpDataToDraw(xp);
    var _24 = _22.ypDataToDraw(yp);
    var x2 = _22.lastX;
    var y2 = _22.lastY;
    var x3 = _23;
    var y3 = _24;
    ctx.quadraticCurveTo(x2, y2, x3, y3);
    if (InkTool.InkLib.Browser.Chrome) {
      ctx.lineTo(x3 + 0.1, y3 + 0.1);
    }
    ctx.stroke();
    _22.llastX = _22.lastX;
    _22.llastY = _22.lastY;
    _22.lastX = _23;
    _22.lastY = _24;
  },
  fullDrawEnd: function (_25) {},
  hasFullDrawMode: function () {
    return true;
  },
  getSubInfo: function () {
    return "BallpointPen";
  }
});
InkTool.InkFeltPen = InkTool.InkLib.Class.create(InkTool.InkStationery, {
  initialize: function () {
    this.tp = InkTool.InkPenType.FeltPen;
    this.col = new InkTool.InkColor("purple");
    this.w = 5;
    this.minw = 3;
    this.maxw = 8;
  },
  drawStart: function (_26) {
    var ctx = _26.context;
    ctx.strokeStyle = this.getPenColor().getRGBA();
    ctx.fillStyle = this.getPenColor().getRGBA();
    ctx.globalCompositeOperation = "source-over";
    ctx.lineWidth = _26.widthDataToDraw(this.getPenWidth());
    ctx.lineCap = "round";
    ctx.lineJoin = "round";
    _26.drawMinPenWidth = _26.widthDataToDraw(this.getPenMinWidth());
    _26.drawMaxPenWidth = _26.widthDataToDraw(this.getPenMaxWidth());
    _26.drawScalePenWidth = _26.drawMaxPenWidth - _26.drawMinPenWidth;
    _26.queuePoints = new Array();
  },
  strokeStart: function (_27, xp, yp, tm) {
    var ctx = _27.context;
    var _28 = _27.xpDataToDraw(xp);
    var _29 = _27.ypDataToDraw(yp);
    _27.llastX = _27.lastX = _28;
    _27.llastY = _27.lastY = _29;
    _27.lastTime = tm;
    _27.lastTimeSlice = 8;
    _27.firstDraw = true;
    _27.moveCount = 0;
    _27.moveSum = 0;
    _27.timeStr = "";
  },
  strokeMove: function (_2a, xp, yp, tm) {
    _2a.timeStr = "";
    if (tm <= _2a.lastTime) {
      _2a.queuePoints.push({
        "x": xp,
        "y": yp
      });
      return;
    }
    var _2b = (tm - _2a.lastTime) / (_2a.queuePoints.length + 1);
    while (_2a.queuePoints.length > 0) {
      var _2c = _2a.queuePoints.shift();
      this.strokeMoveSub(_2a, _2c.x, _2c.y, _2b);
    }
    this.strokeMoveSub(_2a, xp, yp, _2b);
    _2a.lastTime = tm;
  },
  strokeMoveSub: function (_2d, xp, yp, _2e) {
    var ctx = _2d.context;
    var _2f = _2d.xpDataToDraw(xp);
    var _30 = _2d.ypDataToDraw(yp);
    var _31 = _2d.drawMinPenWidth;
    var _32 = _2d.drawScalePenWidth;
    _2d.moveCount++;
    _2d.timeStr = _2d.timeStr + "," + _2e;
    var _33;
    if (_2d.moveCount == 1) {
      var t2 = _2e;
      var px2 = _2d.lastX;
      var py2 = _2d.lastY;
      var px3 = _2f;
      var py3 = _30;
      var d2 = Math.sqrt(px2 * px2 - 2 * px2 * px3 + px3 * px3 + py2 * py2 - 2 * py2 * py3 + py3 * py3);
      var v2 = d2 / t2;
      _2d.lastV1 = _2d.lastV2 = _2d.lastV3 = _2d.lastV4 = _2d.lastV5 = v2;
      _33 = v2;
      _2d.moveSum += d2;
    } else {
      var t1 = _2d.lastTimeSlice;
      var t2 = _2e;
      var px1 = _2d.llastX;
      var py1 = _2d.llastY;
      var px2 = _2d.lastX;
      var py2 = _2d.lastY;
      var px3 = _2f;
      var py3 = _30;
      var d1 = Math.sqrt(px1 * px1 - 2 * px1 * px2 + px2 * px2 + py1 * py1 - 2 * py1 * py2 + py2 * py2);
      var d2 = Math.sqrt(px2 * px2 - 2 * px2 * px3 + px3 * px3 + py2 * py2 - 2 * py2 * py3 + py3 * py3);
      var vc1 = d1 / t1 / _2d.zoomRatio;
      var vc3 = d2 / t2 / _2d.zoomRatio;
      var v1 = (_2d.lastV3 + _2d.lastV4 + vc1) / 3;
      var v3 = (_2d.lastV4 + _2d.lastV5 + vc3) / 3;
      _2d.moveSum += d2;
      var _34 = _2d.moveSum + _2d.moveCount;
      if (_34 < 10) {
        _32 = _32 * _34 / 10;
      }
      var x1 = (_2d.lastX + _2d.llastX) / 2;
      var y1 = (_2d.lastY + _2d.llastY) / 2;
      var x2 = _2d.lastX;
      var y2 = _2d.lastY;
      var x3 = (_2d.lastX + _2f) / 2;
      var y3 = (_2d.lastY + _30) / 2;
      var v2 = (v1 + v3) / 2;
      var _35 = _31 + _32 * Math.exp(-1.5 * v2);
      var _36 = Math.round((d1 + d2) / _35 * 10);
      if (_36 < 10) {
        _36 = 10;
      }
      var _37 = Math.round((d1 + d2) / (6.2 * _2d.zoomRatio) * 10);
      if (_37 < 10) {
        _37 = 10;
      }
      _37 /= 2;
      var _38 = this.getPenColor();
      var _39 = _38.A / 255 / _37;
      var _3a = "rgba(" + _38.R + "," + _38.G + "," + _38.B + "," + _39.toFixed(5) + ")";
      ctx.fillStyle = _3a;
      var _3b = false;
      var dxl = 0;
      var dyl = 0;
      for (var idx = 0; idx < _36; idx++) {
        var dt = idx / _36;
        var dx = x3 * (1 - dt) * (1 - dt) + x2 * 2 * dt * (1 - dt) + x1 * dt * dt;
        var dy = y3 * (1 - dt) * (1 - dt) + y2 * 2 * dt * (1 - dt) + y1 * dt * dt;
        var dv = v3 * (1 - dt) * (1 - dt) + v2 * 2 * dt * (1 - dt) + v1 * dt * dt;
        ctx.beginPath();
        ctx.arc(dx, dy, _31 + _32 * Math.exp(-1.5 * dv), 0, Math.PI * 2, false);
        ctx.fill();
        _3b = true;
        dxl = dx;
        dyl = dy;
      }
      _33 = v2;
    }
    _2d.llastX = _2d.lastX;
    _2d.llastY = _2d.lastY;
    _2d.lastX = _2f;
    _2d.lastY = _30;
    _2d.lastV1 = _2d.lastV2;
    _2d.lastV2 = _2d.lastV3;
    _2d.lastV3 = _2d.lastV4;
    _2d.lastV4 = _2d.lastV5;
    _2d.lastV5 = _33;
  },
  strokeEnd: function (_3c, xp, yp, tm) {
    _3c.timeStr = "";
    var ctx = _3c.context;
    if (tm <= _3c.lastTime) {
      tm = _3c.lastTime + 8;
    }
    if (_3c.queuePoints.length == 0) {
      return;
    }
    var _3d = (tm - _3c.lastTime) / _3c.queuePoints.length;
    while (_3c.queuePoints.length > 0) {
      var _3e = _3c.queuePoints.shift();
      this.strokeMoveSub(_3c, _3e.x, _3e.y, _3d);
    }
  },
  drawEnd: function (_3f) {},
  hasFullDrawMode: function () {
    return false;
  },
  getSubInfo: function () {
    return "FeltPen";
  }
});
InkTool.InkPencil = InkTool.InkLib.Class.create(InkTool.InkStationery, {
  initialize: function () {
    if (InkTool.InkLib.needsEmulateCanvas()) {
      this.tp = InkTool.InkPenType.PencilEmulated;
    } else {
      this.tp = InkTool.InkPenType.PencilEmulated;
    }
    this.col = new InkTool.InkColor("#FF4A4C4B");
    this.w = 1.8;
    this.minw = 0.2;
    this.maxw = 2;
  },
  drawStart: function (_40) {
    if (this.tp == InkTool.InkPenType.PencilEmulated) {
      InkTool.InkBallpointPen.prototype.drawStart.apply(this, arguments);
    } else {
      InkTool.InkFeltPen.prototype.drawStart.apply(this, arguments);
    }
  },
  strokeStart: function (_41, xp, yp, tm) {
    if (this.tp == InkTool.InkPenType.PencilEmulated) {
      InkTool.InkBallpointPen.prototype.strokeStart.apply(this, arguments);
    } else {
      InkTool.InkFeltPen.prototype.strokeStart.apply(this, arguments);
    }
  },
  strokeMove: function (_42, xp, yp, tm) {
    if (this.tp == InkTool.InkPenType.PencilEmulated) {
      InkTool.InkBallpointPen.prototype.strokeMove.apply(this, arguments);
    } else {
      InkTool.InkFeltPen.prototype.strokeMove.apply(this, arguments);
    }
  },
  strokeMoveSub: function (_43, xp, yp, _44) {
    InkTool.InkFeltPen.prototype.strokeMoveSub.apply(this, arguments);
  },
  strokeEnd: function (_45, xp, yp, tm) {
    if (this.tp == InkTool.InkPenType.PencilEmulated) {
      InkTool.InkBallpointPen.prototype.strokeEnd.apply(this, arguments);
    } else {
      InkTool.InkFeltPen.prototype.strokeEnd.apply(this, arguments);
    }
  },
  drawEnd: function (_46) {
    if (this.tp == InkTool.InkPenType.PencilEmulated) {
      InkTool.InkBallpointPen.prototype.drawEnd.apply(this, arguments);
    } else {
      InkTool.InkFeltPen.prototype.drawEnd.apply(this, arguments);
    }
  },
  fullDrawStart: function (_47) {
    InkTool.InkBallpointPen.prototype.fullDrawStart.apply(this, arguments);
  },
  fullStrokeStart: function (_48, xp, yp, tm) {
    InkTool.InkBallpointPen.prototype.fullStrokeStart.apply(this, arguments);
  },
  fullStrokeMove: function (_49, xp, yp, tm) {
    InkTool.InkBallpointPen.prototype.fullStrokeMove.apply(this, arguments);
  },
  fullStrokeEnd: function (_4a, xp, yp, tm) {
    InkTool.InkBallpointPen.prototype.fullStrokeEnd.apply(this, arguments);
  },
  fullDrawEnd: function (_4b) {
    InkTool.InkBallpointPen.prototype.fullDrawEnd.apply(this, arguments);
  },
  hasFullDrawMode: function () {
    return (this.tp == InkTool.InkPenType.PencilEmulated);
  },
  getSubInfo: function () {
    return "FeltPen";
  }
});
InkTool.InkNormalEraser = InkTool.InkLib.Class.create(InkTool.InkStationery, {
  initialize: function () {
    this.tp = InkTool.InkPenType.NormalEraser;
    this.col = new InkTool.InkColor("black");
    this.w = 12;
    this.minw = 8;
    this.maxw = 16;
  },
  drawStart: function (_4c) {
    var ctx = _4c.context;
    if (_4c.eraserBgColor != null) {
      ctx.strokeStyle = _4c.eraserBgColor.getRGBA();
      ctx.fillStyle = _4c.eraserBgColor.getRGBA();
      ctx.globalCompositeOperation = "source-over";
    } else {
      ctx.strokeStyle = this.getPenColor().getRGBA();
      ctx.fillStyle = this.getPenColor().getRGBA();
      ctx.globalCompositeOperation = "destination-out";
    }
    ctx.lineWidth = _4c.widthDataToDraw(this.getPenWidth());
    ctx.lineCap = "round";
    ctx.lineJoin = "round";
  },
  strokeStart: function (_4d, xp, yp, tm) {
    var ctx = _4d.context;
    var _4e = _4d.xpDataToDraw(xp);
    var _4f = _4d.ypDataToDraw(yp);
    _4d.llastX = _4d.lastX = _4e;
    _4d.llastY = _4d.lastY = _4f;
  },
  strokeMove: function (_50, xp, yp, tm) {
    var ctx = _50.context;
    var _51 = _50.xpDataToDraw(xp);
    var _52 = _50.ypDataToDraw(yp);
    var x1 = (_50.lastX + _50.llastX) / 2;
    var y1 = (_50.lastY + _50.llastY) / 2;
    var x2 = _50.lastX;
    var y2 = _50.lastY;
    var x3 = (_50.lastX + _51) / 2;
    var y3 = (_50.lastY + _52) / 2;
    ctx.beginPath();
    ctx.moveTo(x1, y1);
    ctx.quadraticCurveTo(x2, y2, x3, y3);
    if (InkTool.InkLib.Browser.Chrome) {
      ctx.lineTo(x3 + 0.1, y3 + 0.1);
    }
    ctx.stroke();
    _50.llastX = _50.lastX;
    _50.llastY = _50.lastY;
    _50.lastX = _51;
    _50.lastY = _52;
  },
  strokeEnd: function (_53, xp, yp, tm) {
    var ctx = _53.context;
    var _54 = _53.xpDataToDraw(xp);
    var _55 = _53.ypDataToDraw(yp);
    var x1 = (_53.lastX + _53.llastX) / 2;
    var y1 = (_53.lastY + _53.llastY) / 2;
    var x2 = _53.lastX;
    var y2 = _53.lastY;
    var x3 = _54;
    var y3 = _55;
    ctx.beginPath();
    ctx.moveTo(x1, y1);
    ctx.quadraticCurveTo(x2, y2, x3, y3);
    if (InkTool.InkLib.Browser.Chrome) {
      ctx.lineTo(x3 + 0.1, y3 + 0.1);
    }
    ctx.stroke();
    _53.llastX = _53.lastX;
    _53.llastY = _53.lastY;
    _53.lastX = _54;
    _53.lastY = _55;
  },
  drawEnd: function (_56) {},
  hasFullDrawMode: function () {
    return false;
  },
  getSubInfo: function () {
    return "NormalEraser";
  }
});
InkTool.InkCell = InkTool.InkLib.Class.create(null, {
  initialize: function (_1, xp, yp, _2, _3) {
    this.m = _1;
    this.x = xp;
    this.y = yp;
    this.w = _2;
    this.t = _3;
  },
  getMode: function () {
    return this.m;
  },
  getX: function () {
    return this.x;
  },
  getY: function () {
    return this.y;
  },
  getWidth: function () {
    return this.w;
  },
  getTime: function () {
    return this.t;
  }
});
InkTool.InkStroke = InkTool.InkLib.Class.create(null, {
  initialize: function (_4) {
    this.cs = new Array();
    this.st = _4.clone();
  },
  getStationery: function () {
    return this.st;
  },
  getColor: function () {
    return this.st.PenColor;
  },
  getPenType: function () {
    return this.st.PenType;
  },
  countCells: function () {
    return this.cs.length;
  },
  addCell: function (_5) {
    this.cs.push(_5);
  },
  getCell: function (_6) {
    if (_6 < 0 || _6 >= this.cs.length) {
      return null;
    } else {
      return this.cs[_6];
    }
  }
});
InkTool.InkDrawing = InkTool.InkLib.Class.create(null, {
  initialize: function () {
    this.is = new Array();
  },
  countDrawItems: function () {
    return this.is.length;
  },
  addDrawItem: function (_7) {
    this.is.push(_7);
    return true;
  },
  getDrawItem: function (_8) {
    if (_8 < 0 || _8 >= this.is.length) {
      return null;
    } else {
      return this.is[_8];
    }
  },
  deleteDrawItem: function (_9) {
    this.is.splice(_9, 1);
    return true;
  },
  clearDrawItems: function () {
    this.is.length = 0;
  }
});
InkTool.InkDrawn = InkTool.InkLib.Class.create(InkTool.InkDrawing, {
  initialize: function () {
    this.is = new Array();
  }
});
InkTool.InkLayer = InkTool.InkLib.Class.create(null, {
  initialize: function (id, _a) {
    this.Drawing = new InkTool.InkDrawing();
    this.Drawn = new InkTool.InkDrawn();
    this.ID = id;
    this.Zorder = _a;
  }
});
InkTool.InkPage = InkTool.InkLib.Class.create(null, {
  initialize: function () {
    this.layerList = new Array();
    this.layerList.push(new InkTool.InkLayer(1, 100));
    this.currentLayerId = 1;
    this.currentLayer = this.getLayerById(1);
  },
  countLayers: function () {
    return this.layerList.length;
  },
  getLayerList: function () {
    var _b = [];
    var _c = this.layerList.length;
    for (var _d = 0; _d < _c; _d++) {
      var _e = this.layerList[_d];
      _b.push({
        index: _d,
        id: _e.ID,
        zorder: _e.Zorder
      });
    }
    _b.sort(function (_f, _10) {
      return (_10.zorder - _f.zorder);
    });
    return _b;
  },
  addLayer: function (id, _11) {
    this.layerList.push(new InkTool.InkLayer(id, _11));
    return true;
  },
  getCurrentLayer: function () {
    return this.currentLayer;
  },
  getLayerById: function (id) {
    var _12 = this.layerList.length;
    for (var _13 = 0; _13 < _12; _13++) {
      var _14 = this.layerList[_13];
      if (_14.ID == id) {
        return _14;
      }
    }
    return null;
  },
  getLayerByIndex: function (_15) {
    return this.layerList[_15];
  },
  deleteLayerById: function (id) {
    var _16 = this.layerList.length;
    for (var _17 = 0; _17 < _16; _17++) {
      var _18 = this.layerList[_17];
      if (_18.ID == id) {
        this.layerList.splice(_17, 1);
        return true;
      }
    }
    return false;
  },
  getLayerByIndex: function (_19) {
    this.layerList.splice(_19, 1);
    return true;
  },
  clearLayers: function () {
    this.layerList.length = 0;
    return true;
  }
});
InkTool.InkNote = InkTool.InkLib.Class.create(null, {
  initialize: function () {
    this.pageList = new Array();
    this.pageList.push(new InkTool.InkPage());
  },
  countPages: function () {
    return this.pageList.length;
  },
  addPage: function () {
    this.pageList.push(new InkTool.InkPage());
    return true;
  },
  addPages: function (num) {
    for (var ct = 0; ct < num; ct++) {
      this.pageList.push(new InkTool.InkPage());
    }
    return true;
  },
  getPage: function (_1a) {
    return this.pageList[_1a];
  },
  deletePage: function (_1b) {
    this.pageList.splice(_1b, 1);
    return true;
  },
  clearPages: function () {
    this.pageList.length = 0;
    return true;
  }
});
InkTool.InkTabletInfo = InkTool.InkLib.Class.create(null, {
  initialize: function () {}
});
InkTool.InkData = InkTool.InkLib.Class.create(null, {
  initialize: function () {
    this.TabletInfo = new InkTool.InkTabletInfo();
    this.Note = new InkTool.InkNote();
  }
});
InkTool.InkRect = InkTool.InkLib.Class.create(null, {
  initialize: function (_1, _2, _3, _4) {
    this.left = _1;
    this.top = _2;
    this.width = _3;
    this.height = _4;
  },
  clearRect: function () {
    this.left = 0;
    this.top = 0;
    this.width = 0;
    this.height = 0;
  },
  getLeft: function () {
    return this.left;
  },
  getTop: function () {
    return this.top;
  },
  getWidth: function () {
    return this.width;
  },
  getHeight: function () {
    return this.height;
  },
  getRight: function () {
    return this.left + this.width;
  },
  getBottom: function () {
    return this.top + this.height;
  },
  equalsRect: function (_5) {
    return (this.left == _5.left && this.top == _5.top && this.width == _5.width && this.height == _5.height);
  },
  copyRect: function (_6) {
    this.left = _6.left;
    this.top = _6.top;
    this.width = _6.width;
    this.height = _6.height;
  },
  cloneRect: function () {
    return new InkTool.InkRect(this.left, this.top, this.width, this.height);
  },
  isEmpty: function () {
    return (this.width == 0 || this.height == 0);
  },
  containsPoint: function (_7, _8) {
    return (_7 >= this.left && _7 < this.getRight() && _8 >= this.top && _8 < this.getBottom());
  },
  containsRect: function (_9) {
    return (this.left <= _9.left && this.top <= _9.top && this.getRight() >= _9.getRight() && this.getBottom() >= _9.getBottom());
  },
  intersectsRect: function (_a) {
    var _b = (this.left >= _a.left) ? this.left : _a.left;
    var _c = (this.getRight() <= _a.getRight()) ? this.getRight : _a.getRight();
    var _d = (this.top >= _a.top) ? this.top : _a.top;
    var _e = (this.getBottom() <= _a.getBottom()) ? this.getBottom : _a.getBottom();
    return (_b < _c && _d < _e);
  },
  andRect: function (_f) {
    var _10 = (this.left >= _f.left) ? this.left : _f.left;
    var _11 = (this.getRight() <= _f.getRight()) ? this.getRight : _f.getRight();
    if (_11 < _10) {
      _11 = _10;
    }
    var _12 = (this.top >= _f.top) ? this.top : _f.top;
    var _13 = (this.getBottom() <= _f.getBottom()) ? this.getBottom : _f.getBottom();
    if (_13 < _12) {
      _13 = _12;
    }
    return new InkTool.InkRect(_10, _12, (_11 - _10), (_13 - _12));
  },
  orRect: function (_14) {
    var _15 = (this.left <= _14.left) ? this.left : _14.left;
    var _16 = (this.getRight() >= _14.getRight()) ? this.getRight : _14.getRight();
    var _17 = (this.top <= _14.top) ? this.top : _14.top;
    var _18 = (this.getBottom() >= _14.getBottom()) ? this.getBottom : _14.getBottom();
    return new InkTool.InkRect(_15, _17, (_16 - _15), (_18 - _17));
  },
  margeRect: function (_19) {
    if (this.isEmpty()) {
      return _19.cloneRect();
    } else {
      if (_19.isEmpty()) {
        return this.cloneRect();
      } else {
        return this.orRect(_19);
      }
    }
  },
});
InkTool.InkCanvasLib = {
  List: {},
  Initialized: false,
  PreventDragAndSelect: true,
  DocLastX: 0,
  DocLastY: 0,
  DocIsDrawing: false,
  DocIsDrawn: false,
  TimerDraw: null,
  TimerCopy: null,
  InkData: null,
  DummyDiv: null,
  DummyCanvas: null,
  TotalDrawingTime: 0,
  TotalDrawingCells: 0,
  TotalCopyCount: 0,
  initialize: function () {
    var _1 = InkTool.InkLib;
    var _2 = InkTool.InkCanvasLib;
    if (_2.Initialized) {
      return;
    }
    _2.Initialized = true;
    _2.InkData = new InkTool.InkData();
    if (_2.PreventDragAndSelect) {
      document.ondragstart = function () {
        return false;
      };
      document.onselectstart = function () {
        return false;
      };
      document.body.style.MozUserSelect = "none";
    }
    _1.addEvent(document, "mousedown", _2.docPenDown);
    _1.addEvent(document, "mousemove", _2.docPenMove);
    _1.addEvent(document, "mouseup", _2.docPenUp);
    _1.addEvent(window, "resize", _2.docPenCancel);
    _1.addEvent(window, "scroll", _2.docPenCancel);
    _1.addEvent(document, "touchstart", _2.docTouchDown);
    _1.addEvent(document, "touchmove", _2.docTouchMove);
    _1.addEvent(document, "touchend", _2.docTouchUp);
    if (_1.needsEmulateCanvas()) {
      _2.TimerDraw = setInterval(function () {
        _2.docInterval();
      }, 60);
    }
  },
  setPreventDragAndSelect: function (_3) {
    InkTool.InkCanvasLib.PreventDragAndSelect = _3;
  },
  getElementOfPosition: function (_4) {
    var _5 = InkTool.InkLib.convertPositionEventToAbsolute(_4);
    var _6 = document.elementFromPoint(_5.left, _5.top);
    return _6;
  },
  docInterval: function () {
    var _7 = InkTool.InkCanvasLib;
    for (var _8 in _7.List) {
      var _9 = _7.List[_8];
      _9.doInterval();
    }
  },
  docTimeout: function () {
    var _a = InkTool.InkCanvasLib;
    if (_a.DocIsDrawing) {
      return;
    }
    for (var _b in _a.List) {
      var _c = _a.List[_b];
      _c.doTimeout();
    }
    _a.TotalCopyCount++;
  },
  docPenDown: function (e) {
    var _d = InkTool.InkLib;
    var _e = InkTool.InkCanvasLib;
    if (_e.DocIsDrawing) {
      return;
    }
    var _f = _d.getLastEvent(e);
    var _10 = _d.getButtonStatus(_f);
    if (_10 != 1) {
      return;
    }
    var _11 = _d.getEventPosition(_f);
    _e.doPenDown(_11);
  },
  docTouchDown: function (e) {
    var _12 = InkTool.InkLib;
    var _13 = InkTool.InkCanvasLib;
    if (_13.DocIsDrawing) {
      return;
    }
    var evt = _12.getLastEvent(e);
    var _14 = _12.getTouchEventPosition(evt, 0);
    if (_13.doPenDown(_14)) {
      _12.preventDefaultActionOfEvent(evt, true, true);
    }
  },
  doPenDown: function (pos) {
    var _15 = InkTool.InkLib;
    var _16 = InkTool.InkCanvasLib;
    var _17 = false;
    if (isNaN(pos.left) || isNaN(pos.top)) {
      return _17;
    }
    _16.DocIsDrawing = true;
    _16.DocIsDrawn = false;
    if (_16.TimerCopy != null) {
      clearTimeout(_16.TimerCopy);
      _16.TimerCopy = null;
    }
    var _18 = _16.getElementOfPosition(pos);
    for (var _19 in _16.List) {
      var _1a = _16.List[_19];
      if (_1a.penDown(_18, pos)) {
        _17 = true;
      }
    }
    _16.DocLastX = pos.left;
    _16.DocLastY = pos.top;
    if (!_15.needsEmulateCanvas()) {
      _16.docInterval();
    }
    return _17;
  },
  docPenMove: function (e) {
    var _1b = InkTool.InkLib;
    var _1c = InkTool.InkCanvasLib;
    var evt = _1b.getLastEvent(e);
    var _1d = _1b.getButtonStatus(evt);
    var _1e = _1b.getEventPosition(evt);
    if (!_1c.DocIsDrawing) {
      if (_1b.Browser.IE && _1d == 1) {
        _1c.doPenDown(_1e);
      } else {
        return;
      }
    } else {
      if (_1d == 0) {
        _1c.doPenUp(_1e, 1);
        return;
      }
    }
    _1c.doPenMove(_1e);
  },
  docTouchMove: function (e) {
    var _1f = InkTool.InkLib;
    var _20 = InkTool.InkCanvasLib;
    var evt = _1f.getLastEvent(e);
    var _21 = _1f.getTouchEventPosition(evt, 0);
    if (!_20.DocIsDrawing) {
      _20.doPenDown(_21);
    }
    if (_20.doPenMove(_21)) {
      _1f.preventDefaultActionOfEvent(evt, true, true);
    }
  },
  doPenMove: function (pos) {
    var _22 = InkTool.InkLib;
    var _23 = InkTool.InkCanvasLib;
    var _24 = false;
    if (isNaN(pos.left) || isNaN(pos.top)) {
      return _24;
    }
    if (_23.TimerCopy != null) {
      clearTimeout(_23.TimerCopy);
      _23.TimerCopy = null;
    }
    var _25 = _23.getElementOfPosition(pos);
    for (var _26 in _23.List) {
      var _27 = _23.List[_26];
      if (_27.penMove(_25, pos)) {
        _24 = true;
      }
    }
    _23.DocLastX = pos.left;
    _23.DocLastY = pos.top;
    _23.DocIsDrawn = true;
    if (!_22.needsEmulateCanvas()) {
      _23.docInterval();
    }
    return _24;
  },
  docPenUp: function (e) {
    var _28 = InkTool.InkLib;
    var _29 = InkTool.InkCanvasLib;
    if (_29.DocIsDrawing == false) {
      return;
    }
    var evt = _28.getLastEvent(e);
    var _2a = _28.getButtonStatus(evt);
    if (_2a != 1) {
      return;
    }
    var _2b = _28.getEventPosition(evt);
    _29.doPenUp(_2b, 0);
  },
  docPenCancel: function (e) {
    var _2c = InkTool.InkLib;
    var _2d = InkTool.InkCanvasLib;
    if (!_2d.DocIsDrawing || _2d.DocIsDrawn) {
      return;
    }
    var evt = _2c.getLastEvent(e);
    var _2e = _2c.getEventPosition(evt);
    _2d.doPenUp(_2e, 1);
  },
  docTouchUp: function (e) {
    var _2f = InkTool.InkLib;
    var _30 = InkTool.InkCanvasLib;
    if (!_30.DocIsDrawing) {
      return;
    }
    var evt = _2f.getLastEvent(e);
    var _31 = _2f.getTouchEventPosition(evt, 0);
    if (_30.doPenUp(_31, 1)) {
      _2f.preventDefaultActionOfEvent(evt, true, true);
    }
  },
  doPenUp: function (pos, _32) {
    var _33 = InkTool.InkLib;
    var _34 = InkTool.InkCanvasLib;
    var _35 = false;
    if (_32 == 0 && (isNaN(pos.left) || isNaN(pos.top))) {
      return _35;
    }
    _34.DocIsDrawing = false;
    _34.DocIsDrawn = false;
    if (_34.TimerCopy != null) {
      clearTimeout(_34.TimerCopy);
      _34.TimerCopy = null;
    }
    for (var _36 in _34.List) {
      var _37 = _34.List[_36];
      if (_37.penUp(pos, _32)) {
        _35 = true;
      }
    }
    if (_32 == 0) {
      _34.DocLastX = pos.left;
      _34.DocLastY = pos.top;
    }
    if (!_33.needsEmulateCanvas()) {
      _34.docInterval();
    }
    _34.TimerCopy = setTimeout(function () {
      _34.docTimeout();
    }, 250);
    return _35;
  },
  getCanvas: function (id) {
    return InkTool.InkCanvasLib.List[id];
  },
  getDrawingCanvas: function (id) {
    return InkTool.InkCanvasLib.List[id];
  },
  createCanvas: function (id) {
    var _38 = InkTool.InkLib;
    var _39 = InkTool.InkCanvasLib;
    var _3a = _38.getElementById(id);
    if (_3a == null) {
      return null;
    }
    _39.initialize();
    var _3b = new InkTool.InkCanvas(_3a, 0, 0, 0);
    _39.List[id] = _3b;
    return _3b;
  },
  createFepCanvas: function (id) {
    var _3c = InkTool.InkLib;
    var _3d = InkTool.InkCanvasLib;
    var _3e = _3c.getElementById(id);
    if (_3e == null) {
      return null;
    }
    _3d.initialize();
    var _3f = new InkTool.InkCanvas(_3e, 0, 0, 1);
    _3d.List[id] = _3f;
    return _3f;
  },
  relayoutCanvas: function (id) {
    var _40 = InkTool.InkCanvasLib;
    var _41 = _40.List[id];
    if (_41 == null) {
      return false;
    }
    return _41.relayout();
  },
  createDummyCanvas: function (_42, _43) {
    if (InkTool.InkCanvasLib.DummyDiv == null || InkTool.InkCanvasLib.DummyCanvas == null) {
      var _44 = document.createElement("div");
      _44.id = "inkTool_dummy_canvas_div";
      _44.onselectstart = function () {
        return false;
      };
      _44.ondragstart = function () {
        return false;
      };
      _44.style.position = "absolute";
      _44.style.left = "0px";
      _44.style.top = "0px";
      _44.style.visibility = "hidden";
      _44.style.padding = "0px";
      _44.width = _42;
      _44.height = _43;
      InkTool.InkCanvasLib.DummyDiv = _44;
      var _45 = document.createElement("canvas");
      _45.id = "inktool_dummy_canvas";
      _45.onselectstart = function () {
        return false;
      };
      _45.ondragstart = function () {
        return false;
      };
      _45.width = _42;
      _45.height = _43;
      if (InkTool.InkLib.needsEmulateCanvas()) {
        G_vmlCanvasManager.initElement(_45);
      }
      InkTool.InkCanvasLib.DummyCanvas = _45;
      _44.appendChild(_45);
      document.body.appendChild(_44);
      return _45;
    } else {
      var _44 = InkTool.InkCanvasLib.DummyDiv;
      _44.width = _42;
      _44.height = _43;
      var _45 = InkTool.InkCanvasLib.DummyCanvas;
      _45.width = _42;
      _45.height = _43;
      return _45;
    }
  },
  releaseDummyCanvas: function () {
    InkTool.InkCanvasLib.createDummyCanvas(1, 1);
  },
  getTotalDrawingTime: function () {
    return InkTool.InkCanvasLib.TotalDrawingTime;
  },
  getTotalDrawingCells: function () {
    return InkTool.InkCanvasLib.TotalDrawingCells;
  },
  getAveDrawingTime: function () {
    var _46 = InkTool.InkCanvasLib.TotalDrawingTime;
    var _47 = InkTool.InkCanvasLib.TotalDrawingCells;
    return _46 / _47;
  },
  getTotalCopyCount: function () {
    return InkTool.InkCanvasLib.TotalCopyCount;
  },
  doNothing: function () {
    return false;
  },
  sendInk: function (id, url) {
    var _48 = InkTool.InkCanvasLib.getCanvas(id);
    if (_48 == null) {
      return;
    }
    if (_48.countDrawItems() == 0) {
      return;
    }
    var _49 = _48.saveInk();
    this.sendString("InkData=" + _49, url);
  },
  sendInkShort: function (id, url) {
    var _4a = InkTool.InkCanvasLib.getCanvas(id);
    if (_4a == null) {
      return;
    }
    if (_4a.countDrawItems() == 0) {
      return;
    }
    var _4b = _4a.saveInkShort();
    this.sendString("InkData=" + _4b, url);
  },
  sendString: function (str, url, _4c) {
    var _4d = InkTool.InkCanvasLib.getXmlHttpObject();
    _4d.onreadystatechange = function () {
      if (_4d.readyState == 4 && _4d.status == 200) {
        try {
          if (typeof (_4c) === "function") {
            _4c(_4d.responseText);
          } else {
            alert("resp=" + _4d.responseText);
          }
        } catch (e) {
          alert("request parse error:" + e);
        }
      } else {
        if (_4d.readyState == 4) {
          alert("status error:" + _4d.status + ":resp=" + _4d.responseText);
        }
      }
    };
    try {
      _4d.open("POST", url, true);
      _4d.setRequestHeader("CONTENT-TYPE", "application/x-www-form-urlencoded");
      _4d.send(str);
    } catch (e) {
      alert("http send error:" + e);
    }
  },
  getXmlHttpObject: function () {
    var _4e = null;
    try {
      _4e = new XMLHttpRequest();
    } catch (e) {
      try {
        _4e = new ActiveXObject("Msxml2.XMLHTTP");
      } catch (e) {
        _4e = new ActiveXObject("Microsoft.XMLHTTP");
      }
    }
    return _4e;
  },
  Dummy: null
};
InkTool.InkCanvasBase = InkTool.InkLib.Class.create(null, {
  initialize: function (_4f, _50, _51, _52) {
    var _53 = InkTool.InkLib;
    var _54 = InkTool.InkCanvasLib;
    var _55 = _53.getBrowserTypeName();
    var _56 = "In";
    var _57 = "a" + "mp";
    if (_4f == null) {
      this.Initialized = false;
      return;
    }
    var _58 = _53.getElementAbsoluteLocation(_4f);
    var _59 = _53.getElementSize(_4f);
    var _5a = _51;
    if (_5a == 0) {
      _5a = _53.getStyleOfElement(_4f, "z-index", null);
    }
    this.Version = "1.0";
    this.drawingMode = 0;
    this.replayMode = 0;
    _4f.onselectstart = function () {
      return false;
    };
    _4f.ondragstart = function () {
      return false;
    };
    this.id = _4f.id;
    this.zorder = _51;
    this.ezindex = _5a;
    this.dcanvas = document.createElement("canvas");
    this.dcanvas.id = "inktool_" + this.id + "_draw";
    this.dcanvas.onselectstart = function () {
      return false;
    };
    this.dcanvas.ondragstart = function () {
      return false;
    };
    this.dcanvas.style.position = "absolute";
    this.dcanvas.style.left = "" + _58.left + "px";
    this.dcanvas.style.top = "" + _58.top + "px";
    this.dcanvas.width = _59.width;
    this.dcanvas.height = _59.height;
    if (_5a !== null) {
      this.dcanvas.style.zIndex = _5a;
    }
    _53.addElementAfter(_4f, this.dcanvas);
    if (_53.needsEmulateCanvas()) {
      G_vmlCanvasManager.initElement(this.dcanvas);
    }
    this.dcontext = this.dcanvas.getContext("2d");
    this.bcanvas = document.createElement("canvas");
    this.bcanvas.id = "inktool_" + this.id + "_back";
    this.bcanvas.onselectstart = function () {
      return false;
    };
    this.bcanvas.ondragstart = function () {
      return false;
    };
    this.bcanvas.style.position = "absolute";
    this.bcanvas.style.left = "" + _58.left + "px";
    this.bcanvas.style.top = "" + _58.top + "px";
    this.bcanvas.width = _59.width;
    this.bcanvas.height = _59.height;
    if (_5a !== null) {
      this.bcanvas.style.zIndex = _5a;
    }
    _53.addElementAfter(_4f, this.bcanvas);
    if (_53.needsEmulateCanvas()) {
      G_vmlCanvasManager.initElement(this.bcanvas);
    }
    this.slayer = null;
    this.rect = new InkTool.InkRect(_58.left, _58.top, _59.width, _59.height);
    this.arrayCell = new Array();
    this.arrayCopy = new Array();
    this.llastX = 0;
    this.llastY = 0;
    this.llastT = 0;
    this.lastX = 0;
    this.lastY = 0;
    this.lastT = 0;
    this.moveCount = 0;
    this.testTimeStr = "";
    this.lastV1 = 0;
    this.lastV2 = 0;
    this.lastV3 = 0;
    this.lastV4 = 0;
    this.lastV5 = 0;
    this.lastMoveX = 0;
    this.lastMoveY = 0;
    this.isDrawing = false;
    this.drawingContext = new InkTool.DrawingContext(null, 0, 0, 1);
    this.autoZoomRatio = true;
    this.inkWidth = _59.width;
    this.inkHeight = _59.height;
    this.backColor = new InkTool.InkColor("rgba(0, 0, 0, 0.0)");
    this.pen = null;
    this.drawingStroke = false;
    this.currentStroke = null;
    this.pageIndex = _50;
    if (this.pageIndex == 0) {
      this.pageIndex = _54.InkData.Note.countPages() + 1;
    }
    this.penDownFunc = null;
    this.penMoveFunc = null;
    this.penUpFunc = null;
    this.penDownInnerFunc = null;
    this.penMoveInnerFunc = null;
    this.penUpInnerFunc = null;
    this.startTime = null;
    this.endTime = null;
    var _53 = InkTool.InkLib;
    var _54 = InkTool.InkCanvasLib;
    if (this.pageIndex > 0) {
      var _5b = _54.InkData.Note.countPages();
      if (_5b < this.pageIndex) {
        _54.InkData.Note.addPages(this.pageIndex - _5b);
      }
      this.currentPage = _54.InkData.Note.getPage(this.pageIndex - 1);
      this.currentLayer = this.currentPage.getCurrentLayer();
      this.currentDrawing = this.currentLayer.Drawing;
    } else {
      this.currentPage = null;
      this.currentLayer = null;
      this.currentDrawing = null;
    }
    this.clearDrawingCanvas();
    this.drawingBuffer = new InkTool.InkDrawing();
    this.drawingMode = 1;
    this.replayMode = 0;
    this.replaySpeed = 1;
    this.replayMaxInterval = 1000;
    this.replayStrokeIndex = 0;
    this.replayCellIndex = 0;
    this.replayStartTime = 0;
    this.replayTargetBaseTime = 0;
    this.replayLastTime = 0;
    this.replayHandler = null;
    this.replayTimer = null;
  },
  getId: function () {
    return this.id;
  },
  getNumStrokes: function () {
    return this.countDrawItems();
  },
  getMaxStrokes: function () {
    return this.drawingBuffer.countDrawItems();
  },
  setDrawingMode: function (_5c) {
    this.drawingMode = _5c;
    if (_5c == 0 && this.isDrawing) {
      this.isDrawing = false;
      this.doPenUp({
        "left": this.lastMoveX,
        "top": this.lastMoveY
      });
    }
  },
  getDrawingMode: function () {
    return this.drawingMode;
  },
  setHandler: function (_5d, _5e) {
    if (_5d == "pendown") {
      this.penDownFunc = _5e;
    } else {
      if (_5d == "penmove") {
        this.penMoveFunc = _5e;
      } else {
        if (_5d == "penup") {
          this.penUpFunc = _5e;
        }
      }
    }
  },
  setAutoZoomRatio: function () {
    this.autoZoomRatio = true;
    this.calcAutoZoomRatio();
    this.redrawCurrentLayerByInk();
  },
  calcAutoZoomRatio: function () {
    var _5f = this.rect.getWidth() / this.inkWidth;
    var _60 = this.rect.getHeight() / this.inkHeight;
    if (_5f <= _60) {
      this.drawingContext.setZoomRatio(_5f);
    } else {
      this.drawingContext.setZoomRatio(_60);
    }
    this.drawingContext.setOffset(0, 0);
  },
  setZoomRatio: function (_61) {
    this.autoZoomRatio = false;
    this.drawingContext.setZoomRatio(_61);
    this.inkWidth = Math.round(this.rect.getWidth() / this.drawingContext.getZoomRatio());
    this.inkHeight = Math.round(this.rect.getHeight() / this.drawingContext.getZoomRatio());
    this.redrawCurrentLayerByInk();
  },
  setOffset: function (_62, _63) {
    this.autoZoomRatio = false;
    this.drawingContext.setOffset(_62, _63);
    this.redrawCurrentLayerByInk();
  },
  setPen: function (pen) {
    this.pen = pen;
    return true;
  },
  relayout: function () {
    var _64 = InkTool.InkLib;
    var _65 = InkTool.InkCanvasLib;
    var _66 = _64.getElementById(this.id);
    if (_66 == null) {
      return false;
    }
    var _67 = _64.getElementAbsoluteLocation(_66);
    var _68 = _64.getElementSize(_66);
    var _69 = this.zorder;
    if (_69 == 0) {
      _69 = _64.getStyleOfElement(_66, "z-index", null);
    }
    this.bcanvas.style.left = "" + _67.left + "px";
    this.bcanvas.style.top = "" + _67.top + "px";
    if (this.bcanvas.width != _68.width || this.bcanvas.height != _68.height) {
      this.bcanvas.width = _68.width;
      this.bcanvas.height = _68.height;
      this.redrawCurrentLayerByInk();
    }
    this.bcanvas.style.zIndex = _69;
    this.dcanvas.style.left = "" + _67.left + "px";
    this.dcanvas.style.top = "" + _67.top + "px";
    this.dcanvas.width = _68.width;
    this.dcanvas.height = _68.height;
    this.dcanvas.style.zIndex = _69;
    if (this.slayer != null) {
      this.slayer.style.left = "" + _67.left + "px";
      this.slayer.style.top = "" + _67.top + "px";
      this.slayer.width = _68.width;
      this.slayer.height = _68.height;
      this.slayer.style.zIndex = _69;
    }
    this.rect = new InkTool.InkRect(_67.left, _67.top, _68.width, _68.height);
    this.inkWidth = Math.round(_68.width / this.drawingContext.getZoomRatio());
    this.inkHeight = Math.round(_68.height / this.drawingContext.getZoomRatio());
    return true;
  },
  display: function (_6a) {
    var _6b = InkTool.InkLib;
    var _6c = InkTool.InkCanvasLib;
    if (_6a) {
      _6b.showElement(this.bcanvas);
      _6b.showElement(this.dcanvas);
      if (this.slayer != null) {
        _6b.showElement(this.slayer);
      }
    } else {
      _6b.hideElement(this.bcanvas);
      _6b.hideElement(this.dcanvas);
      if (this.slayer != null) {
        _6b.hideElement(this.slayer);
      }
    }
  },
  clearInk: function () {
    this.clearInkOfCurrentLayer();
  },
  undoInk: function () {
    this.undoInkOfCurrentLayer();
  },
  redoInk: function () {
    this.redoInkOfCurrentLayer();
  },
  saveInkObject: function () {
    return this.saveInkObjectShort();
  },
  saveInk: function () {
    var _6d = this.saveInkObject();
    var _6e = JSON.stringify(_6d);
    return _6e;
  },
  saveInkObjectLong: function () {
    var _6f = this.currentDrawing.clone();
    _6f.w = this.inkWidth;
    _6f.h = this.inkHeight;
    _6f.v = this.Version;
    return _6f;
  },
  saveInkLong: function () {
    var _70 = this.saveInkObjectLong();
    var _71 = JSON.stringify(_70);
    return _71;
  },
  saveInkObjectShort: function () {
    var _72 = new Object();
    _72.w = this.inkWidth;
    _72.h = this.inkHeight;
    _72.v = this.Version;
    _72.is = new Array();
    savesrc = this.currentDrawing.clone();
    for (var _73 = 0; _73 < savesrc.is.length; _73++) {
      var _74 = new Object();
      var _75 = savesrc.is[_73];
      var _76 = 0;
      _74.st = _75.st;
      _74.cs = new Array();
      for (var _77 = 0; _77 < _75.cs.length; _77++) {
        var _78 = _75.cs[_77];
        if (_78.m == 2) {
          continue;
        }
        if (_77 == 0) {
          _76 = _78.t;
        }
        var _79 = _78.x + "|" + _78.y + "|" + (_78.t - _76);
        _74.cs.push(_79);
      }
      _74.t = _76;
      _72.is.push(_74);
    }
    return _72;
  },
  saveInkShort: function () {
    var _7a = this.saveInkObjectShort();
    var _7b = JSON.stringify(_7a);
    return _7b;
  },
  loadInkObject: function (_7c) {
    this.loadInkShortObject(_7c);
  },
  loadInk: function (_7d) {
    this.loadInkShort(_7d);
  },
  loadInkLong: function (_7e) {
    var _7f = JSON.parse(_7e);
    this.loadInkLogObject(_7f);
  },
  loadInkLongObject: function (_80) {
    var _81 = new InkTool.InkDrawing();
    for (var _82 = 0; _82 < _80.is.length; _82++) {
      var _83 = _80.is[_82];
      var _84;
      var _85 = _83.st.tp;
      switch (_85) {
      case InkTool.InkPenType.BallpointPen:
      case "pn01":
        _84 = new InkTool.InkBallpointPen();
        break;
      case InkTool.InkPenType.FeltPen:
      case InkTool.InkPenType.BrushPen:
      case "pn02":
        _84 = new InkTool.InkFeltPen();
        break;
      case InkTool.InkPenType.NormalEraser:
      case "er02":
        _84 = new InkTool.InkNormalEraser();
        break;
      case InkTool.InkPenType.Pencil:
      case InkTool.InkPenType.PencilEmulated:
      case "cpn1":
      case "cpn2":
        _84 = new InkTool.InkPencil();
        break;
      default:
        _84 = new InkTool.InkStationery();
        break;
      }
      _84.setPenColor(new InkTool.InkColor(_83.st.col));
      _84.setPenWidth(_83.st.w);
      _84.setPenMinWidth(_83.st.minw);
      _84.setPenMaxWidth(_83.st.maxw);
      var _86 = new InkTool.InkStroke(_84);
      for (var _87 = 0; _87 < _83.cs.length; _87++) {
        var _88 = _83.cs[_87];
        var _89 = new InkTool.InkCell(_88.m, _88.x, _88.y, _88.w, _88.t);
        _86.addCell(_89);
      }
      _81.addDrawItem(_86);
    }
    this.inkWidth = _80.w;
    this.inkHeight = _80.h;
    this.loadInkObjectSub(_81);
  },
  loadInkShort: function (_8a) {
    var _8b = JSON.parse(_8a);
    this.loadInkShortObject(_8b);
  },
  loadInkShortObject: function (_8c) {
    var _8d = new InkTool.InkDrawing();
    for (var _8e = 0; _8e < _8c.is.length; _8e++) {
      var _8f = _8c.is[_8e];
      var _90;
      var _91 = _8f.st.tp;
      switch (_91) {
      case InkTool.InkPenType.BallpointPen:
      case "pn01":
        _90 = new InkTool.InkBallpointPen();
        break;
      case InkTool.InkPenType.FeltPen:
      case InkTool.InkPenType.BrushPen:
      case "pn02":
        _90 = new InkTool.InkFeltPen();
        break;
      case InkTool.InkPenType.NormalEraser:
      case "er02":
        _90 = new InkTool.InkNormalEraser();
        break;
      case InkTool.InkPenType.Pencil:
      case InkTool.InkPenType.PencilEmulated:
      case "cpn1":
      case "cpn2":
        _90 = new InkTool.InkPencil();
        break;
      default:
        _90 = new InkTool.InkStationery();
        break;
      }
      _90.setPenColor(new InkTool.InkColor(_8f.st.col));
      _90.setPenWidth(_8f.st.w);
      _90.setPenMinWidth(_8f.st.minw);
      _90.setPenMaxWidth(_8f.st.maxw);
      var _92 = new InkTool.InkStroke(_90);
      var _93 = _8f.t;
      var _94 = _8f.st.w;
      for (var _95 = 0; _95 < _8f.cs.length; _95++) {
        var _96 = _8f.cs[_95];
        var _97 = (_95 == 0) ? 0 : 1;
        var _98 = _96.split("|");
        var xp = Number(_98[0]);
        var yp = Number(_98[1]);
        var tm = _93 + Number(_98[2]);
        var _99 = new InkTool.InkCell(_97, xp, yp, _94, tm);
        _92.addCell(_99);
        if (_95 == _8f.cs.length - 1) {
          var _9a = new InkTool.InkCell(2, xp, yp, _94, tm);
          _92.addCell(_9a);
        }
      }
      _8d.addDrawItem(_92);
    }
    this.inkWidth = _8c.w;
    this.inkHeight = _8c.h;
    this.loadInkObjectSub(_8d);
  },
  loadInkObjectSub: function (_9b) {
    if (this.autoZoomRatio) {
      this.calcAutoZoomRatio();
    }
    var _9c = this.currentLayer;
    if (_9c != null) {
      _9c.Drawing = _9b.clone();
      this.currentDrawing = _9c.Drawing;
      this.drawingBuffer = this.currentDrawing.clone();
    }
    this.redrawCurrentLayerByInk();
  },
  saveImage: function () {
    if (typeof (this.bcanvas.toDataURL) == "function") {
      var _9d = [];
      for (var _9e = 0; _9e < arguments.length; _9e++) {
        _9d[_9e] = arguments[_9e];
      }
      try {
        return this.bcanvas.toDataURL.apply(this.bcanvas, _9d);
      } catch (e) {
        alert("saveImage error:" + e);
        return null;
      }
    } else {
      return null;
    }
  },
  saveImageWithBGColor: function () {
    if (typeof (this.bcanvas.toDataURL) == "function") {
      var dpi = 96;
      var _9f = arguments[0];
      var _a0 = [];
      for (var _a1 = 1; _a1 < arguments.length; _a1++) {
        _a0[_a1 - 1] = arguments[_a1];
      }
      return this.saveImageSub(dpi, _9f, _a0);
    } else {
      return null;
    }
  },
  saveImageDPI: function () {
    if (typeof (this.bcanvas.toDataURL) == "function") {
      var dpi = arguments[0];
      var _a2 = [];
      for (var _a3 = 1; _a3 < arguments.length; _a3++) {
        _a2[_a3 - 1] = arguments[_a3];
      }
      return this.saveImageSub(dpi, null, _a2);
    } else {
      return null;
    }
  },
  saveImageDPIWithBGColor: function () {
    if (typeof (this.bcanvas.toDataURL) == "function") {
      var dpi = arguments[0];
      var _a4 = arguments[1];
      var _a5 = [];
      for (var _a6 = 2; _a6 < arguments.length; _a6++) {
        _a5[_a6 - 2] = arguments[_a6];
      }
      return this.saveImageSub(dpi, _a4, _a5);
    } else {
      return null;
    }
  },
  saveImageSub: function (dpi, _a7, _a8) {
    try {
      var _a9 = 96;
      var _aa = Math.round(this.rect.getWidth() * dpi / _a9);
      var _ab = Math.round(this.rect.getHeight() * dpi / _a9);
      var _ac = InkTool.InkCanvasLib.createDummyCanvas(_aa, _ab);
      var _ad = dpi / _a9;
      var ctx = _ac.getContext("2d");
      ctx.clearRect(0, 0, _aa, _ab);
      if (_a7 != null) {
        var _ae = new InkTool.InkColor(_a7);
        ctx.fillStyle = _ae.getRGBA();
        ctx.fillRect(0, 0, _aa, _ab);
      }
      var _af = this.currentLayer;
      if (_af != null) {
        var _b0 = new InkTool.DrawingContext(ctx, 0, 0, _ad);
        if (_a7 != null) {
          var _ae = new InkTool.InkColor(_a7);
          _b0.eraserBgColor = _ae;
        }
        var _b1 = _af.Drawing;
        this.drawInkTo(_b0, _b1);
      }
      var _b2 = _ac.toDataURL.apply(_ac, _a8);
      InkTool.InkCanvasLib.releaseDummyCanvas();
      return _b2;
    } catch (e) {
      alert("saveImageSub error:" + e);
      return null;
    }
  },
  replayInk: function (_b3, _b4, _b5) {
    if (this.replayMode != 0) {
      return false;
    }
    var _b6 = this.currentDrawing;
    var _b7 = _b6.countDrawItems();
    if (_b7 == 0) {
      return false;
    }
    var _b8 = _b6.getDrawItem(0);
    if (_b8 == null || _b8.countCells() == 0) {
      return false;
    }
    var _b9 = _b8.getCell(0);
    if (_b9 == null) {
      return false;
    }
    this.replayMode = 1;
    this.replaySpeed = _b3;
    this.replayMaxInterval = _b4;
    this.replayStrokeIndex = 0;
    this.replayCellIndex = 0;
    var _ba = new Date();
    this.replayStartTime = _ba.getTime();
    this.replayTargetBaseTime = _b9.getTime();
    this.replayLastTime = 0;
    this.replayHandler = _b5;
    var _bb = this.drawingContext;
    var _bc = new InkTool.DrawingContext(null, _bb.getOffsetX(), _bb.getOffsetY(), _bb.getZoomRatio());
    var _bd = this;
    this.clearScreen();
    this.replayTimer = setInterval(function () {
      var _be = new Date();
      var _bf = _be.getTime();
      var _c0 = _bd.replayTargetBaseTime + Math.round((_bf - _bd.replayStartTime) * _bd.replaySpeed);
      var _c1 = _b6.getDrawItem(_bd.replayStrokeIndex);
      var _c2 = _c1.getStationery();
      var _c3, ctx;
      if (_c2.hasFullDrawMode() === false) {
        _c3 = _bd.bcanvas;
        ctx = _c3.getContext("2d");
      } else {
        _c3 = _bd.dcanvas;
        ctx = _bd.dcontext;
      }
      _bc.context = ctx;
      _c2.drawStart(_bc);
      var _c4 = false;
      while (true) {
        var _c5 = _c1.getCell(_bd.replayCellIndex);
        var _c6 = _c1.countCells();
        var _c7 = _c5.getTime();
        if (_c7 > _c0) {
          if (_bf >= _bd.replayLastTime + _bd.replayMaxInterval) {
            _bd.replayTargetBaseTime += (_c7 - _c0);
          } else {
            break;
          }
        }
        _bd.replayLastTime = _bf;
        var xp = _c5.getX();
        var yp = _c5.getY();
        var tm = _c5.getTime();
        if (_bd.replayCellIndex == 0) {
          _c2.strokeStart(_bc, xp, yp, tm);
        } else {
          if (_bd.replayCellIndex < _c6 - 1) {
            _c2.strokeMove(_bc, xp, yp, tm);
          } else {
            _c2.strokeEnd(_bc, xp, yp, tm);
          }
        }
        _bd.replayCellIndex++;
        if (_bd.replayCellIndex >= _c6) {
          _c2.drawEnd(_bc);
          if (_c2.hasFullDrawMode()) {
            var _c8 = _bd.bcanvas;
            var _c9 = _c8.getContext("2d");
            var _ca = new InkTool.DrawingContext(_c9, _bb.getOffsetX(), _bb.getOffsetY(), _bb.getZoomRatio());
            _c2.fullDrawStart(_ca);
            for (var _cb = 0; _cb < _c6; _cb++) {
              var _cc = _c1.getCell(_cb);
              var xp = _cc.getX();
              var yp = _cc.getY();
              var tm = _cc.getTime();
              if (_cb == 0) {
                _c2.fullStrokeStart(_ca, xp, yp, tm);
              } else {
                if (_cb < _c6 - 1) {
                  _c2.fullStrokeMove(_ca, xp, yp, tm);
                } else {
                  _c2.fullStrokeEnd(_ca, xp, yp, tm);
                }
              }
            }
            _c2.fullDrawEnd(_ca);
            _bd.clearDrawingCanvas();
          }
          _bd.replayStrokeIndex++;
          _bd.replayCellIndex = 0;
          if (_bd.replayStrokeIndex >= _b7) {
            _c4 = true;
            break;
          }
          _c1 = _b6.getDrawItem(_bd.replayStrokeIndex);
          _c2 = _c1.getStationery();
          if (_c2.hasFullDrawMode() === false) {
            _c3 = _bd.bcanvas;
            ctx = _c3.getContext("2d");
          } else {
            _c3 = _bd.dcanvas;
            ctx = _bd.dcontext;
          }
          _bc.context = ctx;
          _c2.drawStart(_bc);
        }
      }
      if (_c4) {
        if (_bd.replayTimer != null) {
          clearInterval(_bd.replayTimer);
          _bd.replayTimer = null;
        }
        _bd.replayMode = 0;
        if (_bd.replayHandler != null) {
          _bd.replayHandler(_bd, 0);
        }
      } else {
        _c2.drawEnd(_bc);
      }
    }, 60);
    return true;
  },
  stopReplay: function () {
    if (this.replayMode == 0) {
      return;
    }
    if (this.replayTimer != null) {
      clearInterval(this.replayTimer);
      this.replayTimer = null;
    }
    this.replayMode = 0;
    if (this.replayHandler != null) {
      this.replayHandler(this, 1);
    }
    this.redrawCurrentLayerByInk();
  },
  changeReplay: function (_cd, _ce) {},
  isReplaying: function () {
    return (this.replayMode != 0);
  },
  getReplayCurrentStroke: function () {
    if (this.replayMode == 0) {
      return -1;
    } else {
      return this.replayStrokeIndex;
    }
  },
  getReplayMaxStroke: function () {
    return this.getNumStrokes();
  },
  isCanvasTag: function (_cf) {
    var _d0 = _cf;
    var tid = (_cf ? _cf.id : "");
    if (InkTool.InkLib.needsEmulateCanvas()) {
      if (this.id === tid) {
        return true;
      }
      var _d1 = null;
      while (_cf !== null) {
        if (_cf.tagName === undefined) {
          return false;
        }
        var _d2 = _cf.tagName.toLowerCase();
        if (_d2 == "body") {
          return false;
        } else {
          if (_d2 == "canvas") {
            _d1 = _cf;
            break;
          } else {
            if (this.id === _cf.id) {
              return true;
            } else {
              if (this.slayer != null && this.slayer.id === _cf.id) {
                return true;
              }
            }
          }
        }
        _cf = _cf.parentNode;
      }
      if (!_d1) {
        return false;
      }
      if (this.dcanvas.id === _d1.id || this.bcanvas.id === _d1.id) {
        return true;
      } else {
        return false;
      }
    } else {
      return (this.id === tid || this.dcanvas.id === tid || this.bcanvas.id === tid);
    }
  },
  convertPositionElementToCanvas: function (_d3, pos) {
    // Workaround to make the cursor properly fit with the ink's position while drawing,
    // even when the user has scrolled the page.
    var scrollTop = document.documentElement ? document.documentElement.scrollTop : 0;

    return {
      "left": (pos.left / _d3.getZoomRatio()) + _d3.getOffsetX(),
      // "top": (pos.top / _d3.getZoomRatio()) + _d3.getOffsetY() - scrollTop,
      "top": (pos.top / _d3.getZoomRatio()) + _d3.getOffsetY(),
    };
  },
  convertPositionCanvasToElement: function (_d4, pos) {
    return {
      "left": (pos.left - _d4.getOffsetX()) * _d4.getZoomRatio(),
      "top": (pos.top - _d4.getOffsetY()) * _d4.getZoomRatio()
    };
  },
  penDown: function (_d5, pos) {
    if (this.drawingMode == 0 || this.pen == null || this.replayMode != 0) {
      return false;
    }
    var _d6 = InkTool.InkLib;
    var _d7 = InkTool.InkCanvasLib;
    var _d8 = this.dcanvas;
    var _d9 = _d6.convertPositionDocumentToElement(pos, _d8);
    _d9 = this.convertPositionElementToCanvas(this.drawingContext, _d9);
    var _da = false;
    if (this.isDrawing) {
      this.isDrawing = false;
      this.doPenUp({
        "left": this.lastMoveX,
        "top": this.lastMoveY
      });
      _da = true;
    }
    if (this.isCanvasTag(_d5)) {
      this.isDrawing = true;
      this.doPenDown(_d9);
      _da = true;
    }
    this.lastMoveX = _d9.left;
    this.lastMoveY = _d9.top;
    return _da;
  },
  doPenDown: function (_db) {
    this.startTime = new Date();
    var tm = this.startTime.getTime();
    this.arrayCell.push({
      "xp": _db.left,
      "yp": _db.top,
      "tm": tm,
      "stat": 0
    });
    this.currentStroke = new InkTool.InkStroke(this.pen);
    if (this.currentDrawing != null) {
      this.currentDrawing.addDrawItem(this.currentStroke);
    }
    var _dc = new InkTool.InkCell(0, _db.left, _db.top, this.pen.getPenWidth(), tm);
    if (_dc != null) {
      this.currentStroke.addCell(_dc);
    }
    if (this.penDownInnerFunc != null) {
      this.penDownInnerFunc(_db);
    }
    if (this.penDownFunc != null) {
      this.penDownFunc(this, _db);
    }
  },
  penMove: function (_dd, pos) {
    if (this.drawingMode == 0 || this.pen == null || this.replayMode != 0) {
      return false;
    }
    var _de = InkTool.InkLib;
    var _df = InkTool.InkCanvasLib;
    var _e0 = this.dcanvas;
    var _e1 = _de.convertPositionDocumentToElement(pos, _e0);
    _e1 = this.convertPositionElementToCanvas(this.drawingContext, _e1);
    var _e2 = false;
    if (!this.isCanvasTag(_dd)) {
      if (this.isDrawing == true) {
        this.isDrawing = false;
        this.doPenUp(_e1);
        _e2 = true;
      }
    } else {
      if (this.isDrawing == false) {
        this.isDrawing = true;
        this.doPenDown({
          "left": this.lastMoveX,
          "top": this.lastMoveY
        });
      }
      var _e3 = new Date();
      var tm = _e3.getTime();
      this.arrayCell.push({
        "xp": _e1.left,
        "yp": _e1.top,
        "tm": tm,
        "stat": 1
      });
      var _e4 = new InkTool.InkCell(1, _e1.left, _e1.top, this.pen.getPenWidth(), tm);
      if (_e4 != null) {
        this.currentStroke.addCell(_e4);
      }
      if (this.penMoveInnerFunc != null) {
        this.penMoveInnerFunc(_e1);
      }
      if (this.penMoveFunc != null) {
        this.penMoveFunc(this, _e1);
      }
      _e2 = true;
    }
    this.lastMoveX = _e1.left;
    this.lastMoveY = _e1.top;
    return _e2;
  },
  penUp: function (pos, _e5) {
    if (this.drawingMode == 0 || this.pen == null || this.replayMode != 0) {
      return false;
    }
    var _e6 = InkTool.InkLib;
    var _e7 = InkTool.InkCanvasLib;
    var _e8 = this.dcanvas;
    var _e9 = false;
    var _ea;
    if (_e5 == 0) {
      _ea = _e6.convertPositionDocumentToElement(pos, _e8);
      _ea = this.convertPositionElementToCanvas(this.drawingContext, _ea);
      this.lastMoveX = _ea.left;
      this.lastMoveY = _ea.top;
    } else {
      _ea = {
        "left": this.lastMoveX,
        "top": this.lastMoveY
      };
    }
    if (this.isDrawing) {
      this.isDrawing = false;
      this.doPenUp(_ea);
      _e9 = true;
    }
    return _e9;
  },
  doPenUp: function (_eb) {
    this.endTime = new Date();
    var tm = this.endTime.getTime();
    this.arrayCell.push({
      "xp": _eb.left,
      "yp": _eb.top,
      "tm": tm,
      "stat": 2
    });
    var _ec = new InkTool.InkCell(2, _eb.left, _eb.top, this.pen.getPenWidth(), tm);
    if (_ec != null) {
      this.currentStroke.addCell(_ec);
    }
    var _ed = (this.startTime && this.startTime.getTime) ? this.startTime.getTime() : 0;
    var _ee = (this.endTime && this.endTime.getTime) ? this.endTime.getTime() : 0;
    InkTool.InkCanvasLib.TotalDrawingTime += (_ee - _ed);
    InkTool.InkCanvasLib.TotalDrawingCells += this.currentStroke.countCells();
    if (this.currentDrawing != null) {
      this.drawingBuffer = this.currentDrawing.clone();
    }
    if (this.penUpInnerFunc != null) {
      this.penUpInnerFunc(_eb);
    }
    if (this.penUpFunc != null) {
      this.penUpFunc(this, _eb);
    }
  },
  clearInkOfCurrentLayer: function () {
    this.currentLayer.Drawing.clearDrawItems();
    this.drawingBuffer.clearDrawItems();
    this.clearScreen();
    return true;
  },
  undoInkOfCurrentLayer: function () {
    var _ef = this.currentLayer.Drawing;
    var num = _ef.countDrawItems();
    if (num == 0) {
      return false;
    }
    _ef.deleteDrawItem(num - 1);
    this.redrawCurrentLayerByInk();
    return true;
  },
  redoInkOfCurrentLayer: function () {
    var _f0 = this.currentLayer.Drawing;
    var num = _f0.countDrawItems();
    var _f1 = this.drawingBuffer.countDrawItems();
    if (num >= _f1) {
      return false;
    }
    _f0.addDrawItem(this.drawingBuffer.getDrawItem(num));
    this.redrawCurrentLayerByInk();
    return true;
  },
  countDrawItems: function () {
    var _f2 = this.currentLayer.Drawing;
    return _f2.countDrawItems();
  },
  clearScreen: function () {
    var _f3 = this.bcanvas.getContext("2d");
    _f3.clearRect(0, 0, this.rect.getWidth(), this.rect.getHeight());
    if (this.isDrawing !== true) {
      this.arrayCell.length = 0;
      this.arrayCopy.length = 0;
      this.clearDrawingCanvas();
    }
  },
  redrawCurrentLayerByInk: function () {
    var _f4 = InkTool.InkLib;
    var _f5 = this.currentLayer;
    if (_f5 == null) {
      return false;
    }
    this.clearScreen();
    var _f6, ctx;
    _f6 = this.bcanvas;
    ctx = _f6.getContext("2d");
    var _f7 = this.drawingContext;
    _f7.context = ctx;
    var _f8 = _f5.Drawing;
    return this.drawInkTo(_f7, _f8);
  },
  drawInkTo: function (_f9, _fa) {
    var num = _fa.countDrawItems();
    for (var _fb = 0; _fb < num; _fb++) {
      var _fc = _fa.getDrawItem(_fb);
      var _fd = _fc.getStationery();
      if (_fd.hasFullDrawMode()) {
        _fd.fullDrawStart(_f9);
      } else {
        _fd.drawStart(_f9);
      }
      var _fe = _fc.countCells();
      for (var _ff = 0; _ff < _fe; _ff++) {
        var _100 = _fc.getCell(_ff);
        var xp = _100.getX();
        var yp = _100.getY();
        var tm = _100.getTime();
        if (_ff == 0) {
          if (_fd.hasFullDrawMode()) {
            _fd.fullStrokeStart(_f9, xp, yp, tm);
          } else {
            _fd.strokeStart(_f9, xp, yp, tm);
          }
        } else {
          if (_ff < _fe - 1) {
            if (_fd.hasFullDrawMode()) {
              _fd.fullStrokeMove(_f9, xp, yp, tm);
            } else {
              _fd.strokeMove(_f9, xp, yp, tm);
            }
          } else {
            if (_fd.hasFullDrawMode()) {
              _fd.fullStrokeEnd(_f9, xp, yp, tm);
            } else {
              _fd.strokeEnd(_f9, xp, yp, tm);
            }
          }
        }
      }
      if (_fd.hasFullDrawMode()) {
        _fd.fullDrawEnd(_f9);
      } else {
        _fd.drawEnd(_f9);
      }
    }
    return true;
  },
  clearDrawingCanvas: function () {
    var ctx = this.dcanvas.getContext("2d");
    ctx.clearRect(0, 0, this.rect.getWidth(), this.rect.getHeight());
    if (InkTool.InkLib.needsEmulateCanvas()) {
      ctx.strokeStyle = "rgba(255,255,255,0.01)";
      ctx.fillStyle = "rgba(255,255,255,0.01)";
      ctx.globalCompositeOperation = "source-over";
      ctx.fillRect(0, 0, this.rect.getWidth(), this.rect.getHeight());
    }
  },
  doInterval: function () {
    var _101 = InkTool.InkLib;
    var _102 = InkTool.InkCanvasLib;
    if (this.pen == null) {
      return;
    }
    var _103, ctx;
    if (this.pen.hasFullDrawMode() === false) {
      _103 = this.bcanvas;
      ctx = _103.getContext("2d");
    } else {
      _103 = this.dcanvas;
      ctx = this.dcontext;
    }
    var _104 = this.drawingContext;
    _104.context = ctx;
    this.pen.drawStart(_104);
    var _105 = 0;
    while (_105 < 16 && this.arrayCell.length > 0) {
      var cell = this.arrayCell.shift();
      _105++;
      var xp = cell.xp;
      var yp = cell.yp;
      var tm = cell.tm;
      var stat = cell.stat;
      if (this.pen.hasFullDrawMode()) {
        this.arrayCopy.push(cell);
      }
      if (stat == 0) {
        this.pen.strokeStart(_104, xp, yp, tm);
        this.testTimeStr = "";
      } else {
        if (stat == 1) {
          this.pen.strokeMove(_104, xp, yp, tm);
          this.testTimeStr += _104.timeStr;
        } else {
          this.pen.strokeEnd(_104, xp, yp, tm);
          this.testTimeStr += _104.timeStr;
        }
      }
    }
    this.pen.drawEnd(_104);
  },
  doTimeout: function () {
    var _106 = InkTool.InkLib;
    var _107 = InkTool.InkCanvasLib;
    var _108 = this.dcanvas;
    var _109 = 0;
    for (var _10a = 0; _10a < this.arrayCopy.length; _10a++) {
      var stat = this.arrayCopy[_10a].stat;
      if (stat == 2) {
        _109 = _10a + 1;
      }
    }
    if (_109 == 0) {
      return;
    }
    var _10b = this.bcanvas;
    var bctx = _10b.getContext("2d");
    var _10c = this.drawingContext;
    var _10d = new InkTool.DrawingContext(bctx, _10c.getOffsetX(), _10c.getOffsetY(), _10c.getZoomRatio());
    this.pen.fullDrawStart(_10d);
    for (var _10a = 0; _10a < _109; _10a++) {
      var cell = this.arrayCopy.shift();
      var xp = cell.xp;
      var yp = cell.yp;
      var tm = cell.tm;
      var stat = cell.stat;
      if (stat == 0) {
        this.pen.fullStrokeStart(_10d, xp, yp, tm);
      } else {
        if (stat == 1) {
          this.pen.fullStrokeMove(_10d, xp, yp, tm);
        } else {
          this.pen.fullStrokeEnd(_10d, xp, yp, tm);
        }
      }
    }
    this.pen.fullDrawEnd();
    this.clearDrawingCanvas();
  },
  dummy: function () {}
});
InkTool.InkCanvas = InkTool.InkLib.Class.create(InkTool.InkCanvasBase, {
  initialize: function () {
    InkTool.InkCanvasBase.prototype.initialize.apply(this, arguments);
    this.arrayCharacterImages = new Array();
    this.characterImageTag = null;
    this.markingArea = {
      "left": 0,
      "top": 0,
      "width": 0,
      "height": 0
    };
    this.defComment = {
      "left": 0,
      "top": 0
    };
    this.goodMark = {
      "url": "",
      "left": 0,
      "top": 0,
      "width": 0,
      "height": 0,
      "zindex": 0,
      "element": null
    };
    this.wrongMark = {
      "url": "",
      "left": 0,
      "top": 0,
      "width": 0,
      "height": 0,
      "zindex": 0,
      "element": null
    };
    this.wrongStrokeColor = null;
    this.wrongDirectionStrokeColor = null;
    this.wrongFigureStrokeColor = null;
    this.wrongPointCircle = {
      "color": new InkTool.InkColor("#C0FF0000"),
      "radius": 10,
      "width": 2
    };
    this.wrongLengthPointCircle = {
      "color": new InkTool.InkColor("#C0FF0000"),
      "radius": 10,
      "width": 2
    };
    this.wrongArrow = {
      "color": new InkTool.InkColor("#C0FF0000"),
      "width": 2,
      "arrowLength": 10,
      "arrowWidth": 8
    };
    this.listCommentImage = {};
    this.ccanvas = null;
    this.ccontext = null;
    this.commentElements = new Array();
    this.cdiv = null;
    this.goodElem = null;
    this.wrongElem = null;
  },
  relayout: function () {
    InkTool.InkCanvasBase.prototype.relayout.apply(this, arguments);
    var _10e = InkTool.InkLib;
    var _10f = InkTool.InkCanvasLib;
    var _110 = _10e.getElementById(this.id);
    if (_110 == null) {
      return false;
    }
    var _111 = this.zorder;
    if (_111 == 0) {
      _111 = _10e.getStyleOfElement(_110, "z-index", null);
    }
    this.markingAreaPos = _10e.getElementAbsoluteLocation(_110);
    if (this.ccanvas != null) {
      var left = this.rect.left + this.markingArea.left;
      var top = this.rect.top + this.markingArea.top;
      this.ccanvas.style.left = "" + left + "px";
      this.ccanvas.style.top = "" + top + "px";
      this.ccanvas.style.zIndex = _111;
    }
    if (this.cdiv != null) {
      var left = this.rect.left + this.markingArea.left;
      var top = this.rect.top + this.markingArea.top;
      this.cdiv.style.left = "" + left + "px";
      this.cdiv.style.top = "" + top + "px";
      this.cdiv.style.zIndex = _111;
    }
    if (this.goodElem != null) {
      this.goodElem.style.left = "" + (this.rect.left + this.goodMark.left) + "px";
      this.goodElem.style.top = "" + (this.rect.top + this.goodMark.top) + "px";
      this.goodElem.style.zIndex = _111;
    }
    if (this.wrongElem != null) {
      this.wrongElem.style.left = "" + (this.rect.left + this.wrongMark.left) + "px";
      this.wrongElem.style.top = "" + (this.rect.top + this.wrongMark.top) + "px";
      this.wrongElem.style.zIndex = _111;
    }
  },
  display: function (flag) {
    InkTool.InkCanvasBase.prototype.display.apply(this, arguments);
    var _112 = InkTool.InkLib;
    var _113 = InkTool.InkCanvasLib;
    if (flag) {
      _112.showElement(this.ccanvas);
    } else {
      _112.hideElement(this.ccanvas);
    }
    if (this.cdiv != null) {
      if (flag) {
        _112.showElement(this.cdiv);
      } else {
        _112.hideElement(this.cdiv);
      }
    }
    if (this.goodElem != null) {
      if (flag) {
        _112.showElement(this.goodElem);
      } else {
        _112.hideElement(this.goodElem);
      }
    }
    if (this.wrongElem != null) {
      if (flag) {
        _112.showElement(this.wrongElem);
      } else {
        _112.hideElement(this.wrongElem);
      }
    }
  },
  setCharacterImages: function (_114) {
    this.arrayCharacterImages.length = 0;
    if (typeof _114 == "string") {
      var _115 = new Image();
      _115.src = _114;
      this.arrayCharacterImages.push(_114);
    } else {
      for (var _116 = 0; _116 < _114.length; _116++) {
        var _115 = new Image();
        _115.src = _114[_116];
        this.arrayCharacterImages.push(_114[_116]);
      }
    }
    if (this.characterImageTag == null) {
      var _117 = InkTool.InkLib;
      var _118 = this.bcanvas;
      var _119 = document.createElement("img");
      _119.id = "inkTool_" + this.id + "_characterImage";
      _119.src = this.getCharacterImageURL();
      _119.onselectstart = function () {
        return false;
      };
      _119.ondragstart = function () {
        return false;
      };
      _119.style.position = "absolute";
      _119.style.left = _118.style.left;
      _119.style.top = _118.style.top;
      _119.width = _118.width;
      _119.height = _118.height;
      _119.style.zIndex = this.ezindex;
      if (_119.src == "") {
        _119.style.visibility = "hidden";
      } else {
        _119.style.visibility = "visible";
      }
      var _11a = _117.getElementById(this.id);
      if (_11a !== null) {
        this.characterImageTag = _119;
        _117.addElementAfter(_11a, this.characterImageTag);
      }
    } else {
      this.characterImageTag.style.visibility = "visible";
      this.updateImageCharacter(null);
    }
    this.penUpInnerFunc = this.updateImageCharacter;
  },
  getCharacterImageURL: function () {
    var _11b = this.getNumStrokes();
    if (_11b > this.arrayCharacterImages.length - 1) {
      _11b = this.arrayCharacterImages.length - 1;
    }
    if (_11b < 0) {
      return "";
    } else {
      return this.arrayCharacterImages[_11b];
    }
  },
  clearCharacterImages: function () {
    this.arrayCharacterImages.length = 0;
    if (this.characterImageTag != null) {
      this.characterImageTag.style.visibility = "hidden";
    }
    this.penUpInnerFunc = null;
  },
  updateImageCharacter: function (epos) {
    var _11c = this.characterImageTag;
    if (_11c != null) {
      _11c.src = this.getCharacterImageURL();
    }
  },
  undoInk: function () {
    InkTool.InkCanvasBase.prototype.undoInk.apply(this, arguments);
    this.updateImageCharacter(null);
  },
  redoInk: function () {
    InkTool.InkCanvasBase.prototype.redoInk.apply(this, arguments);
    this.updateImageCharacter(null);
  },
  clearInk: function () {
    InkTool.InkCanvasBase.prototype.clearInk.apply(this, arguments);
    this.updateImageCharacter(null);
  },
  loadInkObjectSub: function () {
    InkTool.InkCanvasBase.prototype.loadInkObjectSub.apply(this, arguments);
    this.updateImageCharacter(null);
  },
  setMarkingArea: function (left, top, _11d, _11e, _11f, _120) {
    var _121 = InkTool.InkLib;
    var _122 = InkTool.InkCanvasLib;
    this.markingArea.left = left;
    this.markingArea.top = top;
    this.markingArea.width = _11d;
    this.markingArea.height = _11e;
    this.defComment.left = _11f;
    this.defComment.top = _120;
    var _123 = _121.getElementById(this.id);
    if (_123 == null) {
      return false;
    }
    this.markingAreaPos = _121.getElementAbsoluteLocation(_123);
    var epos = this.markingAreaPos;
    var _124 = _121.getElementSize(_123);
    var _125 = this.zorder;
    if (_125 == 0) {
      _125 = _121.getStyleOfElement(_123, "z-index", null);
    }
    if (this.ccanvas == null) {
      this.ccanvas = document.createElement("canvas");
      this.ccanvas.id = "inktool_" + this.id + "_comment";
      this.ccanvas.onselectstart = function () {
        return false;
      };
      this.ccanvas.ondragstart = function () {
        return false;
      };
      this.ccanvas.style.position = "absolute";
      this.ccanvas.style.left = "" + (epos.left + left) + "px";
      this.ccanvas.style.top = "" + (epos.top + top) + "px";
      this.ccanvas.width = _11d;
      this.ccanvas.height = _11e;
      if (_125 !== null) {
        this.ccanvas.style.zIndex = _125;
      }
      _121.addElementAfter(this.dcanvas, this.ccanvas);
      if (_121.needsEmulateCanvas()) {
        G_vmlCanvasManager.initElement(this.ccanvas);
      }
      this.ccontext = this.ccanvas.getContext("2d");
      this.cdiv = document.createElement("div");
      this.cdiv.id = "inktool_" + this.id + "_cbase";
      this.cdiv.onselectstart = function () {
        return false;
      };
      this.cdiv.ondragstart = function () {
        return false;
      };
      this.cdiv.style.position = "absolute";
      this.cdiv.style.left = "" + (epos.left + left) + "px";
      this.cdiv.style.top = "" + (epos.top + top) + "px";
      this.cdiv.width = _11d;
      this.cdiv.height = _11e;
      if (_125 !== null) {
        this.cdiv.style.zIndex = _125;
      }
      this.cdiv.style.margin = "0px";
      this.cdiv.style.padding = "0px";
      _121.addElementAfter(this.ccanvas, this.cdiv);
    } else {
      this.ccanvas.style.position = "absolute";
      this.ccanvas.style.left = "" + (epos.left + left) + "px";
      this.ccanvas.style.top = "" + (epos.top + top) + "px";
      this.ccanvas.width = _11d;
      this.ccanvas.height = _11e;
    }
  },
  setGoodMark: function (_126, left, top, _127, _128, _129) {
    this.goodMark.url = _126;
    this.goodMark.left = left;
    this.goodMark.top = top;
    this.goodMark.width = _127;
    this.goodMark.height = _128;
    this.goodMark.zindex = _129;
  },
  setWrongMark: function (_12a, left, top, _12b, _12c, _12d) {
    this.wrongMark.url = _12a;
    this.wrongMark.left = left;
    this.wrongMark.top = top;
    this.wrongMark.width = _12b;
    this.wrongMark.height = _12c;
    this.wrongMark.zindex = _12d;
  },
  setWrongStrokeColor: function (_12e) {
    this.wrongStrokeColor = _12e;
  },
  setWrongStrokeColors: function (_12f, _130, _131) {
    this.wrongStrokeColor = color;
    this.wrongDirectionStrokeColor = color;
    this.wrongFigureStrokeColor = color;
  },
  setWrongPointCircle: function (_132, _133, _134) {
    this.wrongPointCircle.color = _132;
    this.wrongPointCircle.radius = _133;
    this.wrongPointCircle.width = _134;
  },
  setWrongLengthPointCircle: function (_135, _136, _137) {
    this.wrongLengthPointCircle.color = _135;
    this.wrongLengthPointCircle.radius = _136;
    this.wrongLengthPointCircle.width = _137;
  },
  setWrongArrow: function (_138, _139, _13a, _13b) {
    this.wrongArrow.color = _138;
    this.wrongArrow.width = _139;
    this.wrongArrow.arrowLength = _13a;
    if (_13b !== undefined) {
      this.wrongArrow.arrowWidth = _13b;
    }
  },
  clearMarkingCommentImages: function () {
    this.listCommentImage = {};
  },
  addMarkingCommentImage: function (code, _13c, _13d, _13e) {
    var _13f = new Image();
    _13f.src = _13c;
    this.listCommentImage[code] = {
      "image": _13c,
      "width": _13d,
      "height": _13e
    };
  },
  clearMarkingResult: function () {
    var _140 = InkTool.InkLib;
    var _141 = InkTool.InkCanvasLib;
    if (this.goodElem != null) {
      var _142 = this.goodElem.parentNode;
      _142.removeChild(this.goodElem);
      this.goodElem = null;
    }
    if (this.wrongElem != null) {
      var _142 = this.wrongElem.parentNode;
      _142.removeChild(this.wrongElem);
      this.wrongElem = null;
    }
    for (var _143 = 0; _143 < this.commentElements.length; _143++) {
      var _144 = this.commentElements[_143];
      var _142 = _144.parentNode;
      _142.removeChild(_144);
    }
    this.commentElements.length = 0;
    var ctx = this.ccontext;
    ctx.clearRect(0, 0, this.markingArea.width, this.markingArea.height);
  },
  createMarkingResult: function (_145, _146, _147, _148, _149, _14a, _14b) {
    var _14c = InkTool.InkLib;
    var _14d = InkTool.InkCanvasLib;
    this.clearMarkingResult();
    var ctx = this.ccontext;
    var _14e = this.parseMarkingResult(_145);
    var _14f = {
      "width": _14e.ink.w,
      "height": _14e.ink.h
    };
    if (_14e.markingError == "stroke" && _14b) {
      for (var _150 = 0; _150 < _14e.comments.length; _150++) {
        var _151 = _14e.comments[_150].code;
        if (_151 == "order" || _151 == "direction") {
          var _152 = _14e.comments[_150].strokeIndex;
          if (_152 >= 0 && _152 < _14e.ink.is.length) {
            var _153 = _14e.ink.is[_152];
            _153.st.col.R = this.wrongStrokeColor.R;
            _153.st.col.G = this.wrongStrokeColor.G;
            _153.st.col.B = this.wrongStrokeColor.B;
            _153.st.col.A = this.wrongStrokeColor.A;
          }
        }
      }
    }
    this.loadInkObject(_14e.ink);
    if (_14e.markingError == "nothing") {
      if (_146) {
        var elem = document.createElement("img");
        elem.src = this.goodMark.url;
        this.goodElem = elem;
        var epos = this.markingAreaPos;
        var _154 = this.ezindex;
        elem.style.position = "absolute";
        elem.style.left = "" + (epos.left + this.goodMark.left) + "px";
        elem.style.top = "" + (epos.top + this.goodMark.top) + "px";
        elem.width = this.goodMark.width;
        elem.height = this.goodMark.height;
        if (_154 !== null) {
          elem.style.zIndex = _154;
        }
        _14c.addElementAfter(this.dcanvas, elem);
      }
    } else {
      if (_147) {
        var elem = document.createElement("img");
        elem.src = this.wrongMark.url;
        this.wrongElem = elem;
        var epos = this.markingAreaPos;
        var _154 = this.ezindex;
        elem.style.position = "absolute";
        elem.style.left = "" + (epos.left + this.wrongMark.left) + "px";
        elem.style.top = "" + (epos.top + this.wrongMark.top) + "px";
        elem.width = this.wrongMark.width;
        elem.height = this.wrongMark.height;
        if (_154 !== null) {
          elem.style.zIndex = _154;
        }
        _14c.addElementAfter(this.dcanvas, elem);
      }
      if (_14e.markingError != "stroke") {
        if (_14a) {
          var _155 = this.getMarkingCommentImage(_14e.markingError);
          var elem = document.createElement("img");
          elem.src = _155.image;
          elem.width = _155.width;
          elem.height = _155.height;
          this.commentElements.push(elem);
          var epos = this.markingAreaPos;
          var _154 = this.ezindex;
          elem.style.position = "absolute";
          elem.style.left = "" + (this.defComment.left - this.markingArea.left) + "px";
          elem.style.top = "" + (this.defComment.top - this.markingArea.top) + "px";
          elem.width = _155.width;
          elem.height = _155.height;
          if (_154 !== null) {
            elem.style.zIndex = _154;
          }
          this.cdiv.appendChild(elem);
        }
      } else {
        var _156 = false;
        var _157 = [{
          "index": -1,
          "left": 0,
          "top": 0,
          "area": {},
          "img": {}
        }, {
          "index": -1,
          "left": 0,
          "top": 0,
          "area": {},
          "img": {}
        }, {
          "index": -1,
          "left": 0,
          "top": 0,
          "area": {},
          "img": {}
        }, {
          "index": -1,
          "left": 0,
          "top": 0,
          "area": {},
          "img": {}
        }];
        _157[0].area.left = 0;
        _157[0].area.top = 0;
        _157[0].area.width = -this.markingArea.left;
        _157[0].area.height = Math.round(this.markingArea.height / 2);
        _157[1].area.left = this.bcanvas.width - this.markingArea.left;
        _157[1].area.top = 0;
        _157[1].area.width = this.markingArea.width - (this.bcanvas.width - this.markingArea.left);
        _157[1].area.height = Math.round(this.markingArea.height / 2);
        _157[2].area.left = 0;
        _157[2].area.top = Math.round(this.markingArea.height / 2);
        _157[2].area.width = -this.markingArea.left;
        _157[2].area.height = Math.round(this.markingArea.height / 2);
        _157[3].area.left = this.bcanvas.width - this.markingArea.left;
        _157[3].area.top = Math.round(this.markingArea.height / 2);
        _157[3].area.width = this.markingArea.width - (this.bcanvas.width - this.markingArea.left);
        _157[3].area.height = Math.round(this.markingArea.height / 2);
        var _158 = new Array();
        for (var ct = 0; ct < _14e.comments.length; ct++) {
          _158[ct] = false;
        }
        var _159 = new Array();
        for (var ct = 0; ct < _14e.ink.is.length; ct++) {
          _159[ct] = false;
        }
        var _15a = new Array();
        for (var pass = 0; pass < _157.length; pass++) {
          for (var _150 = 0; _150 < _14e.comments.length; _150++) {
            var _155 = _14e.comments[_150];
            if (_158[_150] == true) {
              continue;
            }
            if (_155.code == "nothing") {
              _158[_150] = true;
              continue;
            } else {
              if (_155.code == "order") {
                if (_156 == true) {
                  _158[_150] = true;
                  continue;
                }
                var pos = this.getAverageLocationOfTheStroke(_155.strokeIndex);
                pos = this.convertPositionCanvasToElement(this.drawingContext, pos);
                var _15b = this.getPositionIndex(_14f, pos, pass);
                if (_157[_15b].index >= 0) {
                  continue;
                }
                _157[_15b].index = _150;
                _157[_15b].left = pos.left;
                _157[_15b].top = pos.top;
                _156 = true;
                _158[_150] = true;
              } else {
                if (_155.code != "connection" && _155.code != "direction" && _155.code != "position" && _155.code != "angle") {
                  var pos = this.getLocationOfTheCell(_155.strokeIndex, _155.cellIndex);
                  pos = this.convertPositionCanvasToElement(this.drawingContext, pos);
                  var _15b = this.getPositionIndex(_14f, pos, pass);
                  if (_157[_15b].index >= 0) {
                    continue;
                  }
                  var _15c = false;
                  for (var ct = 0; ct < _15a.length; ct++) {
                    var dx = _15a[ct].left - pos.left;
                    var dy = _15a[ct].top - pos.top;
                    var dist = Math.sqrt(dx * dx + dy * dy);
                    var _15d = 16;
                    var _15e = true;
                    if ((_155.code == "hane" || _155.code == "ex_hane" || _155.code == "length" || _155.code == "figure") && _155.code == _15a[ct].code) {
                      _15e = false;
                      _15d = 500;
                    }
                    if (dist <= _15d && (_15a[ct].stroke == _155.strokeIndex || (_15e == true && (_15a[ct].ostroke == _155.strokeIndex || _15a[ct].stroke == _155.ostrokeIndex || (_155.ostrokeIndex >= 0 && _15a[ct].ostroke == _155.ostrokeIndex))))) {
                      _15c = true;
                      break;
                    }
                  }
                  if (_15c == true) {
                    continue;
                  }
                  _157[_15b].index = _150;
                  _157[_15b].left = pos.left;
                  _157[_15b].top = pos.top;
                  _158[_150] = true;
                  _159[_155.strokeIndex] = true;
                  var _15f = {
                    "code": _155.code,
                    "stroke": _155.strokeIndex,
                    "cell": _155.cellIndex,
                    "ostroke": _155.ostrokeIndex,
                    "ocell": _155.ocellIndex,
                    "left": pos.left,
                    "top": pos.top
                  };
                  _15a.push(_15f);
                }
              }
            }
          }
          for (var _150 = 0; _150 < _14e.comments.length; _150++) {
            var _155 = _14e.comments[_150];
            if (_158[_150] == true) {
              continue;
            }
            if (_155.code == "direction") {
              if (_156 == true) {
                _158[_150] = true;
                continue;
              }
              var pos = this.getAverageLocationOfTheStroke(_155.strokeIndex);
              pos = this.convertPositionCanvasToElement(this.drawingContext, pos);
              var _15b = this.getPositionIndex(_14f, pos, pass);
              if (_157[_15b].index >= 0) {
                continue;
              }
              _157[_15b].index = _150;
              _157[_15b].left = pos.left;
              _157[_15b].top = pos.top;
              _158[_150] = true;
            } else {
              if (_155.code == "connection" || _155.code == "position" || _155.code == "angle") {
                if (_159[_155.strokeIndex] == true) {
                  _158[_150] = true;
                  continue;
                }
                var pos = this.getLocationOfTheCell(_155.strokeIndex, _155.cellIndex);
                pos = this.convertPositionCanvasToElement(this.drawingContext, pos);
                var _15b = this.getPositionIndex(_14f, pos, pass);
                if (_157[_15b].index >= 0) {
                  continue;
                }
                var _15c = false;
                for (var ct = 0; ct < _15a.length; ct++) {
                  var dx = _15a[ct].left - pos.left;
                  var dy = _15a[ct].top - pos.top;
                  var dist = Math.sqrt(dx * dx + dy * dy);
                  var _15d = 16;
                  var _15e = true;
                  if ((_155.code == "position" || _155.code == "angle") && _155.code == _15a[ct].code) {
                    _15e = false;
                    _15d = 500;
                  }
                  if (dist <= _15d && (_15a[ct].stroke == _155.strokeIndex || (_15e == true && (_15a[ct].ostroke == _155.strokeIndex || _15a[ct].stroke == _155.ostrokeIndex || (_155.ostrokeIndex >= 0 && _15a[ct].ostroke == _155.ostrokeIndex))))) {
                    _15c = true;
                    break;
                  }
                }
                if (_15c == true) {
                  continue;
                }
                _157[_15b].index = _150;
                _157[_15b].left = pos.left;
                _157[_15b].top = pos.top;
                _158[_150] = true;
                var _15f = {
                  "code": _155.code,
                  "stroke": _155.strokeIndex,
                  "cell": _155.cellIndex,
                  "ostroke": _155.ostrokeIndex,
                  "ocell": _155.ocellIndex,
                  "left": pos.left,
                  "top": pos.top
                };
                _15a.push(_15f);
              }
            }
          }
          var _160 = false;
          for (var pa = 0; pa < _157.length; pa++) {
            if (_157[pa].index < 0) {
              _160 = true;
              break;
            }
          }
          if (_160 == false) {
            break;
          }
        }
        var minx = 1000;
        var miny = 1000;
        var maxx = -1000;
        var maxy = -1000;
        var _161 = 0;
        for (var _162 = 0; _162 < _157.length; _162++) {
          if (_157[_162].index < 0) {
            continue;
          }
          var xp = _157[_162].left;
          var yp = _157[_162].top;
          if (xp < minx) {
            minx = xp;
          }
          if (xp > maxx) {
            maxx = xp;
          }
          if (yp < miny) {
            miny = yp;
          }
          if (yp > maxy) {
            maxy = yp;
          }
          _161++;
        }
        if (_161 >= 2) {
          var _163 = [-1, -1, -1, -1];
          var _164 = 1000000;
          var _165 = [{
            "x": minx,
            "y": miny
          }, {
            "x": maxx,
            "y": miny
          }, {
            "x": minx,
            "y": maxy
          }, {
            "x": maxx,
            "y": maxy
          }];
          for (var ct1 = 0; ct1 < 4; ct1++) {
            for (var ct2 = 0; ct2 < 4; ct2++) {
              if (ct2 == ct1) {
                continue;
              }
              for (var ct3 = 0; ct3 < 4; ct3++) {
                if (ct3 == ct1 || ct3 == ct2) {
                  continue;
                }
                for (var ct4 = 0; ct4 < 4; ct4++) {
                  if (ct4 == ct1 || ct4 == ct2 || ct4 == ct3) {
                    continue;
                  }
                  var dist = 0;
                  var dx, dy;
                  if (_157[0].index >= 0) {
                    dx = _157[0].left - _165[ct1].x;
                    dy = _157[0].top - _165[ct1].y;
                    dist += Math.sqrt(dx * dx + dy * dy);
                  }
                  if (_157[1].index >= 0) {
                    dx = _157[1].left - _165[ct2].x;
                    dy = _157[1].top - _165[ct2].y;
                    dist += Math.sqrt(dx * dx + dy * dy);
                  }
                  if (_157[2].index >= 0) {
                    dx = _157[2].left - _165[ct3].x;
                    dy = _157[2].top - _165[ct3].y;
                    dist += Math.sqrt(dx * dx + dy * dy);
                  }
                  if (_157[3].index >= 0) {
                    dx = _157[3].left - _165[ct4].x;
                    dy = _157[3].top - _165[ct4].y;
                    dist += Math.sqrt(dx * dx + dy * dy);
                  }
                  if (dist < _164) {
                    _163[0] = ct1;
                    _163[1] = ct2;
                    _163[2] = ct3;
                    _163[3] = ct4;
                    _164 = dist;
                  }
                }
              }
            }
          }
          var _166 = [{}, {}, {}, {}];
          for (var _162 = 0; _162 < 4; _162++) {
            _166[_162].index = _157[_162].index;
            _166[_162].left = _157[_162].left;
            _166[_162].top = _157[_162].top;
          }
          for (var _162 = 0; _162 < 4; _162++) {
            _157[_163[_162]].index = _166[_162].index;
            _157[_163[_162]].left = _166[_162].left;
            _157[_163[_162]].top = _166[_162].top;
          }
        }
        for (var _162 = 0; _162 < _157.length; _162++) {
          var _167 = _157[_162];
          if (_167.index < 0) {
            continue;
          }
          var _155 = _14e.comments[_167.index];
          var _168 = this.getMarkingCommentImage(_155.code);
          var _169 = _167.area.left + Math.round((_167.area.width - _168.width) / 2);
          var _16a = _167.area.top + Math.round((_167.area.height - _168.height) / 2);
          _167.img.left = _169;
          _167.img.top = _16a;
          _167.img.width = _168.width;
          _167.img.height = _168.height;
        }
        if (_157[0].index >= 0 && _157[0].img.top < 0) {
          _157[0].img.top = 0;
        }
        if (_157[1].index >= 0 && _157[1].img.top < 0) {
          _157[1].img.top = 0;
        }
        if (_157[2].index >= 0 && _157[2].img.top + _157[2].img.height > this.markingArea.height) {
          _157[2].img.top = this.markingArea.height - _157[2].img.height;
        }
        if (_157[3].index >= 0 && _157[3].img.top + _157[3].img.height > this.markingArea.height) {
          _157[3].img.top = this.markingArea.height - _157[3].img.height;
        }
        if (_157[0].index >= 0 && _157[2].index >= 0) {
          if (_157[0].img.top + _157[0].img.height > _157[2].img.top) {
            var _16b = (_157[0].img.height > _157[2].img.height) ? 0 : 2;
            var _16c = (_16b == 0) ? 2 : 0;
            var _16d = _157[_16b].area.width;
            if (_16d > _157[_16b].img.width + _157[_16c].img.width) {
              var _16e = Math.round((_16d - (_157[_16b].img.width + _157[_16c].img.width)) / 3);
              _157[_16c].img.left = _157[_16b].area.left + _16d - _16e - _157[_16c].img.width;
              _157[_16b].img.left = _157[_16b].area.left + _16e;
            } else {
              _157[_16c].img.left = _157[_16b].area.left + _16d - _157[_16c].img.width;
              _157[_16b].img.left = _157[_16b].area.left;
            }
          }
        }
        if (_157[1].index >= 0 && _157[3].index >= 0) {
          if (_157[1].img.top + _157[1].img.height > _157[3].img.top) {
            var _16b = (_157[1].img.height > _157[3].img.height) ? 1 : 3;
            var _16c = (_16b == 1) ? 3 : 1;
            var _16d = _157[_16b].area.width;
            if (_16d > _157[_16b].img.width + _157[_16c].img.width) {
              var _16e = Math.round((_16d - (_157[_16b].img.width + _157[_16c].img.width)) / 3);
              _157[_16b].img.left = _157[_16b].area.left + _16d - _16e - _157[_16b].img.width;
              _157[_16c].img.left = _157[_16b].area.left + _16e;
            } else {
              _157[_16b].img.left = _157[_16b].area.left + _16d - _157[_16b].img.width;
              _157[_16c].img.left = _157[_16b].area.left;
            }
          }
        }
        for (var _162 = 0; _162 < _157.length; _162++) {
          var _167 = _157[_162];
          if (_167.index < 0) {
            continue;
          }
          var _155 = _14e.comments[_167.index];
          var _16f = _167.left - this.markingArea.left;
          var _170 = _167.top - this.markingArea.top;
          var _168 = this.getMarkingCommentImage(_155.code);
          var _171 = 0;
          if (_155.code == "order" || _155.code == "direction" || _155.code == "position" || _155.code == "length" || _155.code == "angle" || _155.code == "curvature" || _155.code == "figure") {
            _171 = 12;
          } else {
            if (_148) {
              ctx.beginPath();
              ctx.arc(_16f, _170, this.wrongPointCircle.radius, 0, 2 * Math.PI, false);
              ctx.strokeStyle = this.wrongPointCircle.color.getRGBA();
              ctx.lineWidth = this.wrongPointCircle.width;
              ctx.stroke();
            }
            _171 = this.wrongPointCircle.radius + 8;
          }
          var _169 = _167.img.left;
          var _16a = _167.img.top;
          if (_14a) {
            var elem = document.createElement("img");
            elem.src = _168.image;
            elem.width = _168.width;
            elem.height = _168.height;
            this.commentElements.push(elem);
            var _154 = this.ezindex;
            elem.style.position = "absolute";
            elem.style.left = "" + _169 + "px";
            elem.style.top = "" + _16a + "px";
            elem.width = _168.width;
            elem.height = _168.height;
            if (_154 !== null) {
              elem.style.zIndex = _154;
            }
            this.cdiv.appendChild(elem);
          }
          var _172 = _169 + Math.round(_168.width / 2);
          var _173 = _16a + Math.round(_168.height / 2);
          var _174 = Math.atan2(_173 - _170, _172 - _16f);
          var _175 = (_171 + this.wrongArrow.arrowLength - 0.5) * Math.cos(_174);
          var _176 = (_171 + this.wrongArrow.arrowLength - 0.5) * Math.sin(_174);
          var _177 = (_171 + this.wrongArrow.arrowLength) * Math.cos(_174);
          var _178 = (_171 + this.wrongArrow.arrowLength) * Math.sin(_174);
          var _179 = _16f + _171 * Math.cos(_174);
          var _17a = _170 + _171 * Math.sin(_174);
          var _17b = _16f + _175;
          var _17c = _170 + _176;
          var _17d = _16f + _177;
          var _17e = _170 + _178;
          var _17f = _174 + Math.PI / 2;
          var _180 = this.wrongArrow.arrowWidth / 2 * Math.cos(_17f);
          var _181 = this.wrongArrow.arrowWidth / 2 * Math.sin(_17f);
          var _182 = _17d + _180;
          var _183 = _17e + _181;
          var _184 = _17d - _180;
          var _185 = _17e - _181;
          if (_149) {
            ctx.beginPath();
            ctx.moveTo(_172, _173);
            ctx.lineTo(_17b, _17c);
            ctx.strokeStyle = this.wrongArrow.color.getRGBA();
            ctx.lineWidth = this.wrongArrow.width;
            ctx.stroke();
            ctx.beginPath();
            ctx.moveTo(_182, _183);
            ctx.lineTo(_179, _17a);
            ctx.lineTo(_184, _185);
            ctx.closePath();
            ctx.fillStyle = this.wrongArrow.color.getRGBA();
            ctx.fill();
          }
        }
      }
    }
  },
  getMarkingCommentImage: function (_186) {
    var _187 = this.listCommentImage[_186];
    if (_187 == null) {
      _187 = this.listCommentImage["others"];
    }
    return _187;
  },
  getAverageLocationOfTheStroke: function (_188) {
    var left = 0;
    var top = 0;
    var _189 = this.currentDrawing;
    if (_189 == null) {
      return {
        "left": 0,
        "top": 0
      };
    }
    var _18a = _189.getDrawItem(_188);
    if (_18a == null || _18a.countCells() == 0) {
      return {
        "left": 0,
        "top": 0
      };
    }
    var max = _18a.countCells();
    for (var _18b = 0; _18b < max; _18b++) {
      var cell = _18a.getCell(_18b);
      left += cell.getX();
      top += cell.getY();
    }
    left = Math.round(left / max);
    top = Math.round(top / max);
    return {
      "left": left,
      "top": top
    };
  },
  getLocationOfTheCell: function (_18c, _18d) {
    var _18e = this.currentDrawing;
    if (_18e == null) {
      return {
        "left": 0,
        "top": 0
      };
    }
    var _18f = _18e.getDrawItem(_18c);
    if (_18f == null || _18f.countCells() == 0) {
      return {
        "left": 0,
        "top": 0
      };
    }
    var _190 = _18f.getCell(_18d);
    if (_190 == null) {
      return {
        "left": 0,
        "top": 0
      };
    }
    var left = _190.getX();
    var top = _190.getY();
    return {
      "left": left,
      "top": top
    };
  },
  getPositionIndex: function (area, pos, pass) {
    if (pos.top <= area.height / 2) {
      if (pos.left <= area.width / 2) {
        if (pass == 0) {
          return 0;
        } else {
          if (pass == 1) {
            return 2;
          } else {
            if (pass == 2) {
              return 1;
            } else {
              return 3;
            }
          }
        }
      } else {
        if (pass == 0) {
          return 1;
        } else {
          if (pass == 1) {
            return 3;
          } else {
            if (pass == 2) {
              return 0;
            } else {
              return 2;
            }
          }
        }
      }
    } else {
      if (pos.left <= area.width / 2) {
        if (pass == 0) {
          return 2;
        } else {
          if (pass == 1) {
            return 0;
          } else {
            if (pass == 2) {
              return 3;
            } else {
              return 1;
            }
          }
        }
      } else {
        if (pass == 0) {
          return 3;
        } else {
          if (pass == 1) {
            return 1;
          } else {
            if (pass == 2) {
              return 2;
            } else {
              return 0;
            }
          }
        }
      }
    }
  },
  parseMarkingResult: function (_191) {
    var _192 = JSON.parse(_191);
    return this.parseMarkingObject(_192);
  },
  parseMarkingObject: function (_193) {
    var resp = new Object();
    resp.id = _193.id;
    resp.recogChar = _193.c;
    resp.markingError = _193.code;
    resp.ink = _193.ink;
    resp.comments = new Array();
    if (resp.markingError == "stroke") {
      if (_193.comments == null) {} else {
        for (var _194 = 0; _194 < _193.comments.length; _194++) {
          var _195 = new Object();
          var _196 = _193.comments[_194];
          var _197 = _196.split("|");
          if (_197.length > 0) {
            _195.code = _197[0];
          } else {
            _195.code = "unknown";
          }
          if (_197.length > 1) {
            _195.strokeIndex = parseInt(_197[1], 10);
          } else {
            _195.strokeIndex = -1;
          }
          if (_197.length > 2) {
            _195.cellIndex = parseInt(_197[2], 10);
          } else {
            _195.cellIndex = -1;
          }
          if (_197.length > 3) {
            _195.ostrokeIndex = parseInt(_197[3], 10);
          } else {
            _195.ostrokeIndex = -1;
          }
          if (_197.length > 4) {
            _195.ocellIndex = parseInt(_197[4], 10);
          } else {
            _195.ocellIndex = -1;
          }
          resp.comments.push(_195);
        }
      }
    }
    return resp;
  },
  dummy: function () {}
});

export default InkTool;