import { Configuration, DefaultApi } from "../apis";

const auraServiceApiConfig = new Configuration({
  basePath: process.env.REACT_APP_AURA_URL,
});

const defaultApiConfig = new Configuration({
  basePath: process.env.REACT_APP_URL_API,
});

export const defaultApi = new DefaultApi(defaultApiConfig);

export const auraServiceApi = new DefaultApi(auraServiceApiConfig)
