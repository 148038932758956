import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import componentBase from '@alj-react/ui-components/styles/componentBase';
import colors from '@alj-react/ui-components/styles/colors';
import formStyles from '@alj-react/ui-components/styles/forms';
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import IconTick from './IconTick';

export const CheckRadioInputBase = styled.div<{ displayInline: boolean; variant: 'default' | 'button' }>`
  ${componentBase}
  align-items: center;
  min-height: 18px;
  position: relative;
  ${({ displayInline, variant }): FlattenSimpleInterpolation =>
    displayInline || variant === 'button'
      ? css`
          display: inline-flex;
          margin-right: 24px;
        `
      : css`
          display: flex;
          & + & {
            margin-top: 8px;
          }
        `}
`;

export const CheckRadioInputLabelText = styled.span<{ variant: 'default' | 'button' }>`
  ${componentBase}
  color: inherit;
  margin-top: 0;
  ${formStyles.inputLabelText}
  ${({ variant }): FlattenSimpleInterpolation | false =>
    variant === 'default' &&
    css`
      margin-left: 38px;
    `}
`;

type CheckRadioInputLabelProps = {
  disabled: boolean;
  hasError: boolean;
  type: 'checkbox' | 'radio';
  variant: 'default' | 'button';
};

export const CheckRadioInputLabel = styled('label').withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) => !['type'].includes(prop) && defaultValidatorFn(prop),
})<CheckRadioInputLabelProps>`
  ${componentBase}
  align-items: center;
  display: inline-flex;
  line-height: 1;
  margin-top: 0;
  outline: none;
  z-index: 10;
  &:focus {
    outline: none;
  }
  ${({ disabled }): FlattenSimpleInterpolation =>
    disabled
      ? css`
          color: ${colors.lightGray};
          pointer-events: none;
        `
      : css`
          &:hover {
            cursor: pointer;
            ${CheckRadioInputLabelText} {
              color: ${colors.axaBlue};
            }
          }
        `}
  ${({ hasError }): FlattenSimpleInterpolation | false =>
    hasError &&
    css`
      ${formStyles.inputErrorState}
    `}
  ${({ variant, hasError, disabled }): FlattenSimpleInterpolation =>
    variant === 'default'
      ? css`
          min-height: 18px;
          &:focus {
            outline: none;
          }
          &::before {
            content: '';
            align-self: flex-start;
            background-color: ${colors.white};
            border: 1px solid #696969;
            flex-shrink: 0;
            height: 18px;
            left: 0;
            position: absolute;
            top: 0;
            width: 18px;
          }
          &::after {
            // background-color: ${colors.white};
            // border: 1px solid ${colors.silver};
            // content: '';
            height: 8px;
            left: 0px;
            // margin: 5px;
            position: absolute;
            top: 0;
            width: 8px;
          }
          ${disabled
            ? css`
                color: ${colors.lightGray};
                cursor: not-allowed;
                pointer-events: none;
                &::before,
                &::after {
                  background-color: ${colors.alabaster};
                }
              `
            : css`
                &:hover {
                  cursor: pointer;
                  &::before{
                    border: 1px solid ${colors.axaBlue};
                    // background-color: ${colors.axaBlue};
                  }
                  &::after{
                    color: ${colors.white};
                  }
                  ${CheckRadioInputLabelText} {
                    color: ${colors.axaBlue};
                  }
                }
              `}
          ${hasError &&
          css`
            &::before,
            &::after {
              ${formStyles.inputErrorState}
            }
          `}
        `
      : css`
          min-height: 40px;
          padding: 0 20px;
          border: 1px solid #696969;
          background-color: ${colors.wildSand};
          ${!disabled &&
          css`
            &:hover {
              border-color: ${colors.axaBlue};
            }
          `}
          ${hasError && formStyles.inputErrorState}
        `}
  ${({ type }): FlattenSimpleInterpolation | false =>
    type === 'radio' &&
    css`
      &::before,
      &::after {
        border-radius: 50%;
      }
    `}
`;

export const CheckRadioInputInput = styled.input<{ variant: 'default' | 'button' }>`
  ${componentBase}
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  ${({ variant }): FlattenSimpleInterpolation =>
    variant === 'default'
      ? css`
          height: 18px;
          width: 18px;
          &:checked + ${CheckRadioInputLabel} {
            &::before {
              background-color: ${colors.axaBlue};
            }
            &::after {
              // background-color: ${colors.axaBlue};
              // content: '✔';
              content: url('data:image/svg+xml;charset=utf8,${ReactDOMServer.renderToString(<IconTick width='18' fill='rgba(255, 255, 255, 1)' />)}');
              color: white;
            }
          }
        `
      : css`
          min-height: 100%;
          min-width: 100%;
          &:checked + ${CheckRadioInputLabel} {
            background-color: ${colors.white};
            border-color: ${colors.axaBlue};
          }
        `}
`;
