import React, { useEffect, useState } from "react";
import { Answer } from "../../apis";
import { WideButton } from "../atoms/WideButton";

type Props = {
  options: Array<Option>;
  answerQuestion: (answer: Answer) => Promise<void>;
  initChecks?: Array<Option>;
  answerable: boolean;
};

export const MultipleChoiceCheckbox: React.FC<Props> = ({
  options,
  answerQuestion,
  initChecks,
  answerable,
}) => {
  const [checks, setChecks] = useState(initChecks || []);
  const [needSubmit, setNeedSubmit] = useState(false);

  useEffect(() => {
    if (needSubmit) {
      const answer = {
        questionType: "MULTIPLE_CHOICE",
        answers: checks.map(({ value }) => ({ value })),
      };

      answerQuestion({ answer });
    }
  }, [checks]);

  return (
    <>
      {options.map(({ value, label }) => (
        <WideButton
          key={value}
          active={
            !!checks?.filter((checkedOption) => checkedOption.value === value)
              .length
          }
          label={label}
          onClick={() => {
            setNeedSubmit(true);

            if (checks.filter((check) => check.value === value).length) {
              setChecks((existingChecks) =>
                existingChecks?.filter((check) => check.value !== value)
              );
            } else {
              if (isExclusiveOption(value)) {
                setChecks([{ value, label }]);
              } else {
                setChecks((existingChecks) =>
                  existingChecks
                    .filter((check) => !isExclusiveOption(check.value))
                    .concat({ value, label })
                );
              }
            }
          }}
          disabled={!answerable}
        />
      ))}
    </>
  );
};

const isExclusiveOption = (value: string) => {
  return (
    value === "NONE_OF_ABOVE_CHOICE_CODE" || value === "ANSWER_IS_UNKNOWN_CODE"
  );
};
