import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import {
  Answer,
  BloodPressureQuestion,
  Choice,
  DateChoiceQuestion,
  DateQuestion,
  NumericChoiceQuestion,
  NumericQuestion,
  Question,
  SeparableQuestion,
  SingleChoiceQuestion,
  StatementQuestion,
  TextQuestion,
  UnitizedChoiceQuestion,
  UnitizedQuestion,
} from "../../apis";
import { SingleChoiceCard } from "./card/SingleChoiceCard";
import { UnitizedCard } from "./card/UnitizedCard";
import { StatementCard } from "./card/StatementCard";
import { IndexContext } from "../organisms/RootCard";

import { useSubQuestions } from "../../hooks/useSubQuestions";
import { Cards } from "./Cards";
import { CredentialsContext, StatisticsContext } from "../pages/Aura";
import {
  isDateType,
  isInseparableQuestion,
  isSeparableQuestion,
  isStatement,
} from "../../utils/questionHandler";
import { ConfirmationCard } from "./card/ConfirmationCard";
import { alphabetizeIndex } from "../../utils/alphabetizer";
import { BloodPressureCard } from "./card/BloodPressureCard";
import { QuestionCardContainer } from "../layout/QuestionCardContainer";
import { DateCard } from "./card/DateCard";
import { DateChoiceCard } from "./card/DateChoiceCard";
import { NumericChoiceCard } from "./card/NumericChoiceCard";
import { NumericCard } from "./card/NumericCard";
import { TextCard } from "./card/TextCard";
import { UnitizedChoiceCard } from "./card/UnitizedChoiceCard";
import { auraServiceApi } from "../../utils/AuraApi";
import { extractErrMsg } from "../../utils/errorHandler";

export type Props = {
  index: number;
  initQuestion: Question;
  choice?: Choice;
  confirmation: boolean;
  answerable: boolean;
  updateAnswerable: (answerable: boolean) => void;
};

export const Card: React.FC<Props> = ({
  initQuestion,
  choice,
  index,
  confirmation,
  answerable,
  updateAnswerable,
}) => {
  const myselfRef = useRef<HTMLDivElement>(null);
  const scrollToMe = () =>
    myselfRef.current?.scrollIntoView({ behavior: "smooth" });

  const { accessToken, clientId, sessionId, tenant, etag, setEtag } =
    useContext(CredentialsContext);

  const [question, setQuestion] = useState(initQuestion);
  const [errorMsg, setErrorMsg] = useState<string>();
  const [answered, setAnswered] = useState(question.isAnswered);

  const { data: subQuestionsData, ...status } = useSubQuestions(
    clientId,
    sessionId,
    accessToken,
    question.id,
    choice?.value || getChoice(question)?.value
  );
  const { indexMap, updateIndexMap } = useContext(IndexContext);
  const {
    updateQuestionCount,
    updateAnsweredCount,
    updateInputErrorCount,
    conflictIds,
  } = useContext(StatisticsContext);

  useEffect(() => {
    if (!isSeparableQuestion(question) && !isStatement(question)) {
      updateQuestionCount(1);
      updateIndexMap();
    }
    return () => {
      if (!isSeparableQuestion(question) && !isStatement(question)) {
        updateQuestionCount(-1);
        updateIndexMap(question.id);
      }
    };
  }, []);

  useEffect(() => {
    !isSeparableQuestion(question) &&
      !isStatement(question) &&
      answered &&
      updateAnsweredCount(1);
    return () => {
      !isSeparableQuestion(question) &&
        !isStatement(question) &&
        answered &&
        updateAnsweredCount(-1);
    };
  }, [answered]);

  useEffect(() => {
    if (conflictIds.includes(question.id)) {
      setErrorMsg("入力された時期をご確認のうえ、入力しなおしてください");
      if (question.id === Math.min(...conflictIds)) {
        scrollToMe();
      }
    } else {
      setErrorMsg(undefined);
    }
  }, [conflictIds]);

  const answerQuestionHanlder = async (answer: Answer) => {
    try {
      updateAnswerable(false);
      const res = await auraServiceApi.answerQuestion(
        etag,
        clientId,
        sessionId,
        question.id,
        tenant,
        answer,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      setEtag(res.headers.etag);
      setAnswered(true);
      setQuestion(res.data);
      setErrorMsg(undefined);
      updateAnswerable(true);

      return Promise.resolve();
    } catch (err) {
      const anyErr: any = err;
      if (anyErr.response?.status === 401 || anyErr.response?.status === 409) {
        setErrorMsg(
          "セッションが切れたため、一度前の画面に戻ってから、再度告知を再開してください"
        );
      } else if (anyErr.response?.status === 422 && isDateType(question)) {
        setErrorMsg("現在までの年月を選択してください");
        updateAnswerable(true);
      } else {
        setErrorMsg(extractErrMsg(err));
      }
      return Promise.reject();
    }
  };

  return (
    <>
      <div id={getId(question)} ref={myselfRef}>
        {(() => {
          if (!isSeparableQuestion(question)) {
            return toSpecificCard(
              index,
              indexMap[question.id],
              question,
              answerQuestionHanlder,
              confirmation,
              answerable,
              updateInputErrorCount,
              errorMsg ||
                (status.isError
                  ? "質問取得できませんでした、もう一度本質問に回答してください"
                  : undefined)
            );
          }

          return subQuestionsData?.data?.length ? (
            <QuestionCardContainer
              confirmation={confirmation}
              answerPart={<Title>{choice?.text}</Title>}
            />
          ) : null;
        })()}
      </div>

      {subQuestionsData &&
        subQuestionsData.data.map((subQuestion) => {
          return isSeparableQuestion(subQuestion) ? (
            <Cards
              key={subQuestion.id}
              initQuestion={subQuestion as SeparableQuestion}
              confirmation={confirmation}
              index={index}
              answerable={answerable}
              updateAnswerable={updateAnswerable}
            />
          ) : (
            <Card
              index={index}
              key={subQuestion.id}
              initQuestion={subQuestion}
              confirmation={confirmation}
              answerable={answerable}
              updateAnswerable={updateAnswerable}
            />
          );
        })}
    </>
  );
};

const getId = (question: Question) => {
  if (isSeparableQuestion(question) || isStatement(question)) {
    return "";
  }
  return `${question.id}`;
};

const getChoice = (question: Question) => {
  if (isInseparableQuestion(question)) {
    return question.answer as Choice;
  }
};

const toSpecificCard = (
  index: number,
  subIndex: number,
  question: Question,
  answerQuestion: (answer: Answer) => Promise<void>,
  confirmation: boolean,
  answerable: boolean,
  updateInputErrorCount: (key: number, count: number) => void,
  errorMsg?: string
) => {
  const indexToUse = index ? `${index}.` : "";
  const subIndexToUse = subIndex ? `${alphabetizeIndex(subIndex - 1)})` : "";

  if (confirmation) {
    return (
      <ConfirmationCard
        question={question}
        index={index ? `${index}` : ""}
        subIndex={subIndexToUse}
      />
    );
  }

  switch (question.type) {
    case "STATEMENT":
      return <StatementCard question={question as StatementQuestion} />;
    case "UNITIZED":
      return (
        <UnitizedCard
          question={question as UnitizedQuestion}
          index={indexToUse}
          subIndex={subIndexToUse}
          answerQuestion={answerQuestion}
          errorMsg={errorMsg}
          updateInputErrorCount={updateInputErrorCount}
          answerable={answerable}
        />
      );
    case "SINGLE_CHOICE":
      return (
        <SingleChoiceCard
          question={question as SingleChoiceQuestion}
          index={indexToUse}
          subIndex={subIndexToUse}
          answerQuestion={answerQuestion}
          errorMsg={errorMsg}
          answerable={answerable}
        />
      );
    case "BLOOD_PRESSURE":
      return (
        <BloodPressureCard
          question={question as BloodPressureQuestion}
          index={indexToUse}
          subIndex={subIndexToUse}
          answerQuestion={answerQuestion}
          errorMsg={errorMsg}
          updateInputErrorCount={updateInputErrorCount}
          answerable={answerable}
        />
      );
    case "DATE":
      return (
        <DateCard
          question={question as DateQuestion}
          index={indexToUse}
          subIndex={subIndexToUse}
          answerQuestion={answerQuestion}
          errorMsg={errorMsg}
          answerable={answerable}
        />
      );
    case "DATE_CHOICE":
      return (
        <DateChoiceCard
          question={question as DateChoiceQuestion}
          index={indexToUse}
          subIndex={subIndexToUse}
          answerQuestion={answerQuestion}
          errorMsg={errorMsg}
          answerable={answerable}
        />
      );
    case "NUMERIC":
      return (
        <NumericCard
          question={question as NumericQuestion}
          index={indexToUse}
          subIndex={subIndexToUse}
          answerQuestion={answerQuestion}
          errorMsg={errorMsg}
          updateInputErrorCount={updateInputErrorCount}
          answerable={answerable}
        />
      );
    case "NUMERIC_CHOICE":
      return (
        <NumericChoiceCard
          question={question as NumericChoiceQuestion}
          index={indexToUse}
          subIndex={subIndexToUse}
          answerQuestion={answerQuestion}
          errorMsg={errorMsg}
          answerable={answerable}
        />
      );
    case "TEXT":
      return (
        <TextCard
          question={question as TextQuestion}
          index={indexToUse}
          subIndex={subIndexToUse}
          answerQuestion={answerQuestion}
          errorMsg={errorMsg}
          updateInputErrorCount={updateInputErrorCount}
          answerable={answerable}
        />
      );
    case "UNITIZED_CHOICE":
      return (
        <UnitizedChoiceCard
          question={question as UnitizedChoiceQuestion}
          index={indexToUse}
          subIndex={subIndexToUse}
          answerQuestion={answerQuestion}
          errorMsg={errorMsg}
          answerable={answerable}
        />
      );
    default:
      return (
        <QuestionCardContainer
          richQuestionText={question.richText}
          richHelpText={question.richHelpText}
          answerPart={<></>}
          index={subIndexToUse}
          errorMsg={errorMsg}
        />
      );
  }
};

const Title = styled.div`
  width: 95%;
  padding-left: 1rem;
  margin: 0.25rem 0;
  font-size: 1rem;
`;
