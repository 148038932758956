import React, { useContext, useEffect, useState } from "react";
import {
  Answer,
  Choice,
  MultipleChoiceQuestion,
  SearchQuestion,
  SeparableQuestion,
} from "../../apis";
import { IndexContext } from "./RootCard";
import { Card } from "./Card";
import { SearchCard } from "./card/SearchCard";
import { MultipleChoiceCard } from "./card/MultipleChoiceCard";
import { CredentialsContext, StatisticsContext } from "../pages/Aura";
import { ConfirmationCard } from "./card/ConfirmationCard";
import { alphabetizeIndex } from "../../utils/alphabetizer";
import { auraServiceApi } from "../../utils/AuraApi";
import { extractErrMsg } from "../../utils/errorHandler";

type Props = {
  initQuestion: SeparableQuestion;
  index: number;
  confirmation: boolean;
  answerable: boolean;
  updateAnswerable: (answerable: boolean) => void;
};

export const Cards: React.FC<Props> = ({
  initQuestion,
  index,
  confirmation,
  answerable,
  updateAnswerable,
}) => {
  const { accessToken, clientId, sessionId, tenant, etag, setEtag } =
    useContext(CredentialsContext);

  const [question, setQuestion] = useState(initQuestion);
  const [errorMsg, setErrorMsg] = useState<string>();
  const { indexMap, updateIndexMap } = useContext(IndexContext);
  const [answered, setAnswered] = useState(question.isAnswered);
  const { updateQuestionCount, updateAnsweredCount } =
    useContext(StatisticsContext);

  useEffect(() => {
    updateQuestionCount(1);
    updateIndexMap();
    return () => {
      updateQuestionCount(-1);
      updateIndexMap(question.id);
    };
  }, []);

  useEffect(() => {
    answered && updateAnsweredCount(1);
    return () => {
      answered && updateAnsweredCount(-1);
    };
  }, [answered]);

  const answerQuestionHanlder = async (answer: Answer) => {
    try {
      updateAnswerable(false);
      const res = await auraServiceApi.answerQuestion(
        etag,
        clientId,
        sessionId,
        question.id,
        tenant,
        answer,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      setEtag(res.headers.etag);
      setAnswered(true);
      setErrorMsg(undefined);
      setQuestion(res.data as SeparableQuestion);
      updateAnswerable(true);
      return Promise.resolve();
    } catch (err) {
      const anyErr: any = err;
      if (anyErr.response?.status === 401 || anyErr.response?.status === 409) {
        setErrorMsg(
          "セッションが切れたため、一度前の画面に戻ってから、再度告知を再開してください"
        );
      } else {
        setErrorMsg(extractErrMsg(err));
      }
      return Promise.reject();
    }
  };

  return (
    <>
      <div id={`${question.id}`}>
        {toSpecificCard(
          index,
          indexMap[question.id],
          question,
          answerQuestionHanlder,
          confirmation,
          answerable,
          errorMsg
        )}
      </div>

      {question.answer?.answers &&
        (question.answer?.answers as Choice[]).map((choice) => (
          <Card
            key={choice.value}
            initQuestion={question}
            choice={choice}
            index={index}
            confirmation={confirmation}
            answerable={answerable}
            updateAnswerable={updateAnswerable}
          />
        ))}
    </>
  );
};

const toSpecificCard = (
  index: number,
  subIndex: number,
  question: SeparableQuestion,
  answerQuestion: (answer: Answer) => Promise<void>,
  confirmation: boolean,
  answerable: boolean,
  errorMsg?: string
) => {
  const indexToUse = `${index}.`;
  const subIndexToUse = subIndex ? `${alphabetizeIndex(subIndex - 1)})` : "";

  if (confirmation) {
    return (
      <ConfirmationCard
        question={question}
        index={index ? `${index}` : ""}
        subIndex={subIndexToUse}
      />
    );
  }

  switch (question.type) {
    case "SEARCH":
      return (
        <SearchCard
          question={question as SearchQuestion}
          index={indexToUse}
          subIndex={subIndexToUse}
          answerQuestion={answerQuestion}
          errorMsg={errorMsg}
          answerable={answerable}
        />
      );
    case "MULTIPLE_CHOICE":
      return (
        <MultipleChoiceCard
          question={question as MultipleChoiceQuestion}
          index={indexToUse}
          subIndex={subIndexToUse}
          answerQuestion={answerQuestion}
          errorMsg={errorMsg}
          answerable={answerable}
        />
      );
  }
};
