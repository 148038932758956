import DOMPurify from "dompurify";
import React from "react";
import styled from "styled-components";
import {
  BloodPressureDetailedAnswer,
  DateChoiceDetailedAnswer,
  DateDetailedAnswer,
  DetailedAnswer,
  MultipleChoiceDetailedAnswer,
  NumericChoiceDetailedAnswer,
  NumericDetailedAnswer,
  Question,
  SearchDetailedAnswer,
  SingleChoiceDetailedAnswer,
  TextDetailedAnswer,
  UnitizedChoiceDetailedAnswer,
  UnitizedDetailedAnswer,
} from "../../../apis";
import { toJapaneseYear } from "../../../utils/dateFormatter";
import { unitize } from "../../../utils/unitizer";
import { CircleIndex } from "../../atoms/CircleIndex";
import { Table1 } from "../../atoms/Table1";
import { Table2 } from "../../atoms/Table2";
import { Table3 } from "../../atoms/Table3";
import { Table4 } from "../../atoms/Table4";
import { Table5 } from "../../atoms/Table5";
import { Table6 } from "../../atoms/Table6";
import { Table7 } from "../../atoms/Table7";

type Props = {
  question: Question;
  index: string;
  subIndex: string;
};

export const ConfirmationCard: React.FC<Props> = ({
  question,
  index,
  subIndex,
}) => {
  if (question.id === 1) {
    return null;
  }

  if (question.text.startsWith("Table")) {
    switch (question.text) {
      case "Table1":
        return <Table1 confirmation={true} />;
      case "Table2":
        return <Table2 confirmation={true} />;
      case "Table3":
        return <Table3 confirmation={true} />;
      case "Table4":
        return <Table4 confirmation={true} />;
      case "Table5":
        return <Table5 confirmation={true} />;
      case "Table6":
        return <Table6 confirmation={true} />;
      case "Table7":
        return <Table7 confirmation={true} />;
      default:
        return null;
    }
  }

  return (
    <AnsweredQuestionContainer>
      <IndexDiv>
        {index && <CircleIndex>{index}</CircleIndex>}
        <SubIndex>{subIndex}</SubIndex>
      </IndexDiv>
      <QuestionDiv>
        <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(question.richText),
          }}
        />
      </QuestionDiv>
      <AnswerDiv>
        <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(getAnswer(question.answer)),
          }}
        />
      </AnswerDiv>
    </AnsweredQuestionContainer>
  );
};

const SubIndex = styled.span`
  display: inline-block;
  margin-left: 0.3rem;
`;

const getAnswer = (answer?: DetailedAnswer) => {
  if (!answer) {
    return "";
  }

  switch (answer.questionType) {
    case "STATEMENT":
      return "";
    case "UNITIZED":
      const unitizedAnswer = answer as UnitizedDetailedAnswer;
      return `${unitizedAnswer.answerParts[0]}${unitize(
        unitizedAnswer.selectedUnit
      )}`;
    case "SINGLE_CHOICE":
      return (answer as SingleChoiceDetailedAnswer).text;
    case "BLOOD_PRESSURE":
      const [readings] = (answer as BloodPressureDetailedAnswer).readings;
      return `最大値: ${readings.systolic}<br/>最小値: ${readings.diastolic}`;
    case "DATE":
      const [year, month] = (answer as DateDetailedAnswer).value.split("-");
      return `${toJapaneseYear(year)}<br/>${month}月`;
    case "DATE_CHOICE":
      return (answer as DateChoiceDetailedAnswer).text;
    case "NUMERIC":
      return (answer as NumericDetailedAnswer).value;
    case "NUMERIC_CHOICE":
      return (answer as NumericChoiceDetailedAnswer).text;
    case "TEXT":
      return (answer as TextDetailedAnswer).value;
    case "UNITIZED_CHOICE":
      return (answer as UnitizedChoiceDetailedAnswer).text;
    case "SEARCH":
      return (answer as SearchDetailedAnswer).answers
        .map(({ text }) => text)
        .join("<br/>");
    case "MULTIPLE_CHOICE":
      return (answer as MultipleChoiceDetailedAnswer).answers
        .map(({ text }) => text)
        .join("<br/>");
    default:
      return "";
  }
};

const AnsweredQuestionContainer = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 0.75rem;
`;

const IndexDiv = styled.div`
  display: flex;
  justify-content: center;
  padding: 0.4rem;
  border: 0.5px solid lightgray;
  flex-basis: 5%;
  text-align: center;
  min-width: 0;
`;

const QuestionDiv = styled.div`
  padding: 0.4rem;
  align-items: center;
  border: 0.5px solid lightgray;
  flex-basis: 80%;
  min-width: 0;
`;

const AnswerDiv = styled.div`
  padding: 0.4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 0.5px solid lightgray;
  flex-basis: 15%;
  min-width: 0;
`;
