import React, { useContext, useState } from "react";
import Select from "react-select";
import { Answer, SearchQuestion } from "../../../apis";
import { useSearchQuestions } from "../../../hooks/useSearchQuestions";
import { CredentialsContext } from "../../pages/Aura";
import { AnswerPartContainer } from "../../layout/AnswerPartContainer";
import { QuestionCardContainer } from "../../layout/QuestionCardContainer";

type Props = {
  question: SearchQuestion;
  index: string;
  subIndex?: string;
  answerQuestion: (answer: Answer) => Promise<void>;
  errorMsg?: string;
  answerable: boolean;
};

export const SearchCard: React.FC<Props> = ({
  question,
  index,
  subIndex,
  answerQuestion,
  errorMsg,
  answerable,
}) => {
  const { accessToken, clientId, sessionId, tenant } =
    useContext(CredentialsContext);

  const [keyWord, setKeyWord] = useState("");
  const [selects, setSelects] = useState(
    question.answer?.answers?.map(({ text, value }) => ({ label: text, value }))
  );

  const { data, ...status } = useSearchQuestions(
    clientId,
    sessionId,
    accessToken,
    question.id,
    tenant,
    keyWord
  );

  return (
    <QuestionCardContainer
      richQuestionText={question.richText}
      richHelpText={question.richHelpText}
      answerPart={
        <AnswerPartContainer>
          <Select
            options={
              data &&
              (data.data as SearchQuestion).constraints.choices.map(
                ({ text, value }) => ({ label: text, value })
              )
            }
            value={selects}
            isMulti={true}
            isSearchable={true}
            onChange={(e) => {
              const newSelects = e as Option[];
              const answer = {
                questionType: "SEARCH",
                answers: newSelects.map(({ label, value }) => ({
                  text: label,
                  value,
                })),
              };

              answerQuestion({ answer }).then(() => setSelects(newSelects));
            }}
            onInputChange={(e) => setKeyWord(e)}
            placeholder={""}
            noOptionsMessage={() =>
              "検索文字を入力してください。\nひらがなによる検索も可能です。"
            }
            isClearable={false}
            styles={{
              noOptionsMessage: (base) => ({
                ...base,
                whiteSpace: "pre-wrap",
              }),
            }}
            isDisabled={!answerable}
          />
        </AnswerPartContainer>
      }
      index={subIndex || index}
      errorMsg={
        errorMsg ||
        (status.isError
          ? "検索に失敗しました、もう一度検索してください"
          : undefined)
      }
    />
  );
};
