import './utils/ie.polyfills.ts';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { PageWrap } from '@alj-react/ui-components';
import styled from 'styled-components';

import NoRouteMatch from './components/pages/NoRouteMatch';
import ESignature from './components/pages/ESignature';
import InsuranceCollectiveHome from './components/pages/insurance/collective/InsuranceCollectiveHome';
import HeaderSmall from './components/molecules/HeaderSmall';
import { QueryClient, QueryClientProvider } from 'react-query';
import { CookiesProvider } from 'react-cookie';
import InsuranceCollectiveLogout from './components/pages/insurance/collective/InsuranceCollectiveLogout';
import InsuranceCollectiveManual from './components/pages/insurance/collective/InsuranceCollectiveManual';

const MainStyled = styled.div`
  flex-grow: 1;
`;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App = () => {
  return (
    <PageWrap>
      <Router>
        <CookiesProvider>
          <QueryClientProvider client={queryClient} contextSharing={true}>
            <MainStyled>
              <HeaderSmall />
              <Switch>
                <Route path='/' exact component={ESignature} />
                <Route path='/insurance/collective/home/list' exact component={InsuranceCollectiveHome} />
                <Route path='/insurance/collective/home/manual' exact component={InsuranceCollectiveManual} />
                <Route path='/insurance/collective/logout' exact component={InsuranceCollectiveLogout} />
                <Route component={NoRouteMatch} />
              </Switch>
            </MainStyled>
          </QueryClientProvider>
        </CookiesProvider>
      </Router>
    </PageWrap>
  );
};

export default App;
