import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Answer, BloodPressureQuestion } from "../../../apis";
import { Input } from "../../atoms/Input";
import { QuestionCardContainer } from "../../layout/QuestionCardContainer";

type Props = {
  question: BloodPressureQuestion;
  index: string;
  subIndex?: string;
  answerQuestion: (answer: Answer) => Promise<void>;
  errorMsg?: string;
  updateInputErrorCount: (key: number, count: number) => void;
  answerable: boolean;
};

export const BloodPressureCard: React.FC<Props> = ({
  question,
  index,
  subIndex,
  answerQuestion,
  errorMsg,
  updateInputErrorCount,
  answerable,
}) => {
  const { minValSystolic, maxValSystolic, minValDiastolic, maxValDiastolic } =
    question.constraints;

  const { systolic: initSystolic, diastolic: initDiastolic } =
    question.answer?.readings[0] || {};

  const [systolic, setSystolic] = useState(mapToStr(initSystolic));
  const [diastolic, setDiastolic] = useState(mapToStr(initDiastolic));

  const [systolicErrorMsg, setSystolicErrorMsg] = useState<string>();
  const [diastolicErrorMsg, setDiastolicErrorMsg] = useState<string>();

  const [needSubmit, setNeedSubmit] = useState(false);

  useEffect(
    () =>
      updateInputErrorCount(
        question.id,
        systolicErrorMsg || diastolicErrorMsg ? 1 : 0
      ),
    [systolicErrorMsg, diastolicErrorMsg]
  );

  useEffect(() => {
    if (!needSubmit) {
      return;
    }

    const systolicVal = validate(systolic, minValSystolic, maxValSystolic);
    const diastolicVal = validate(diastolic, minValDiastolic, maxValDiastolic);

    if (!systolicVal || !diastolicVal) {
      return;
    }

    if (systolicVal <= diastolicVal) {
      setSystolicErrorMsg(
        "無効な入力です。最大値には最小値より大きな数値をご入力ください。 ご確認のうえ、再度ご入力ください。"
      );
      setDiastolicErrorMsg(
        "無効な入力です。最大値には最小値より大きな数値をご入力ください。 ご確認のうえ、再度ご入力ください。"
      );
      return;
    }

    setSystolicErrorMsg(undefined);
    setDiastolicErrorMsg(undefined);

    const answer = {
      questionType: "BLOOD_PRESSURE",
      readings: [
        {
          systolic: systolicVal,
          diastolic: diastolicVal,
        },
      ],
    };

    answerQuestion({ answer });
  }, [needSubmit]);

  return (
    <QuestionCardContainer
      index={subIndex || index}
      richQuestionText={question.richText}
      richHelpText={question.richHelpText}
      answerPart={
        <>
          <QA>
            <Question>最大値</Question>
            <Input
              onChange={(e) => {
                setNeedSubmit(false);
                setSystolic(e.target.value);
              }}
              onBlur={(e) => {
                const inputVal = e.target.value;
                if (
                  validate(
                    inputVal,
                    minValSystolic,
                    maxValSystolic,
                    setSystolicErrorMsg
                  ) !== undefined
                ) {
                  setNeedSubmit(true);
                  setSystolic(inputVal);
                }
              }}
              value={systolic}
              validationErr={systolicErrorMsg}
              disabled={!answerable}
            />
          </QA>
          <QA>
            <Question>最小値</Question>
            <Input
              onChange={(e) => {
                setNeedSubmit(false);
                setDiastolic(e.target.value);
              }}
              onBlur={(e) => {
                const inputVal = e.target.value;
                if (
                  validate(
                    inputVal,
                    minValDiastolic,
                    maxValDiastolic,
                    setDiastolicErrorMsg
                  ) !== undefined
                ) {
                  setNeedSubmit(true);
                  setDiastolic(inputVal);
                }
              }}
              value={diastolic}
              validationErr={diastolicErrorMsg}
              disabled={!answerable}
            />
          </QA>
        </>
      }
      errorMsg={errorMsg}
    />
  );
};

const mapToStr = (num?: number) => (num ? `${num}` : "");

const validate = (
  value: string,
  lowerLimit: number,
  upperLimit: number,
  setErrorMsg?: React.Dispatch<React.SetStateAction<string | undefined>>
) => {
  if (value === "") {
    setErrorMsg && setErrorMsg("回答を入力してください。");
    return;
  } else if (!/^\d*\.?\d*$/.test(value)) {
    setErrorMsg && setErrorMsg("数字で回答してください。");
    return;
  } else if (value.split(".").length > 1) {
    setErrorMsg && setErrorMsg("整数で回答してください。");
    return;
  }

  const numberVal = parseFloat(value);

  if (numberVal > upperLimit || numberVal < lowerLimit) {
    setErrorMsg && setErrorMsg("入力した数字を再度ご確認ください。");
    return;
  }

  setErrorMsg && setErrorMsg(undefined);
  return numberVal;
};

const QA = styled.div`
  display: flex;
  margin: 0.2rem;
`;

const Question = styled.div`
  margin: 0.4rem;
  flex-basis: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
