import React from "react";
import Text from "@alj-react/ui-components/Text";
import { useIntl } from "react-intl";
import PadlockIcon from '@alj-react/functional-icons/Padlock';
import ArrowButton from '@alj-react/ui-components/ArrowButton';
import {BottomNavDiv, ArrowButtonStyled} from './ESignature.styles';
import { Context } from '../../utils/ESignatureContext';

type DisplayConfirmationProps = {
  displaySign: boolean;
  messages: any;
  onSubmit: () => void;
  goBackToSign: () => void;
  isFormValid: boolean;
};

const DisplayConfirmation: React.FC<DisplayConfirmationProps> = (props) => {
  const { messages, displaySign, onSubmit, isFormValid, goBackToSign } = props;
  const { formatMessage } = useIntl();
  const { state } = React.useContext(Context);

  return (
    <>
      <Text color='axaBlue' size="28">{formatMessage(messages.confirmationTitle)}</Text>
      <div style={{ display: displaySign && state.checkboxSelected ? 'block' : 'none', paddingTop: '11px', paddingBottom: '11px' }}>
        <Text>{formatMessage(messages.pleaseCheckTxt)}</Text>
        <div style={{ display: 'flex', border: '1px solid lightgray' }}>
          <div style={{ width: '100%', /*height: '200px',*/ backgroundColor: '#ffff', clear: 'both', margin: '16px', position: 'relative' }}>
            <PadlockIcon style={{ color: 'lightgray', position: 'absolute', right: '0px' }} />
            <img style={{ width: 'inherit' }} src={state.signature} alt='signature' />
          </div>
        </div>
        <BottomNavDiv>
          <ArrowButtonStyled iconPosition='left' color='white' onClick={goBackToSign} data-testid={'goBackToSign'}>{formatMessage(messages.backToSignBtnTxt)}</ArrowButtonStyled>
          <ArrowButton color='orange' onClick={onSubmit} disabled={!isFormValid} data-testid={'onSubmit'}>{formatMessage(messages.submitTxt)}</ArrowButton>
        </BottomNavDiv>
      </div>
    </>
  );
};

export default DisplayConfirmation;
