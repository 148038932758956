import { Question } from "../apis";

export const isStatement = (question: Question) =>
  question.type === "STATEMENT";

export const isDateType = (question: Question) => question.type === "DATE";

export const isSeparableQuestion = (question: Question) => {
  return question.type === "SEARCH" || question.type === "MULTIPLE_CHOICE";
};

export const isInseparableQuestion = (question: Question) => {
  return (
    question.type === "DATE_CHOICE" ||
    question.type === "NUMERIC_CHOICE" ||
    question.type === "SINGLE_CHOICE" ||
    question.type === "UNITIZED_CHOICE"
  );
};

export const isIndexableQuestion = (question: Question) => {
  return (
    !isStatement(question) &&
    question.externalId !== "Z1" &&
    question.externalId !== "Z2"
  );
};
