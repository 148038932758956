import React from "react";
import Select from "react-select";
import { Answer } from "../../apis";

type Props = {
  options: Array<Option>;
  answerQuestion: (answer: Answer) => Promise<void>;
  initSelects?: Array<Option>;
};

export const MultipleChoiceSelect: React.FC<Props> = ({
  options,
  answerQuestion,
  initSelects,
}) => (
  <Select
    options={options}
    value={initSelects || null}
    isMulti={true}
    isSearchable={false}
    onChange={(e) => {
      const newSelects = e as Option[];
      const answer = {
        questionType: "MULTIPLE_CHOICE",
        answers: newSelects.map(({ value }) => ({ value })),
      };

      answerQuestion({ answer });
    }}
    placeholder={""}
    isClearable={false}
  />
);
