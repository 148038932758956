import {
  setShowModal,
  setCheckboxSelected,
  setAuraXmlOutput,
  setIsLoading,
  setDataToDisplay,
  setSignature,
  setBDisplaySignInput,
  setQueryParameters,
  setBDisplayError,
  setBDisplayConfirmation,
  setCurrentPageToDisplay,
  setIsDoingAuraStuff,
  setApiResult,
  setInputFormContent,
} from './actions';

export const setShowModalSign: any = (dispatch: any, show: boolean) =>{
  setShowModal(dispatch, show);
};

export const setApiResultSign: any = (dispatch: any, data: any) =>{
  setApiResult(dispatch, data);
};

export const setIsDoingAuraStuffSign: any = (dispatch: any, isDoing: boolean) =>{
  setIsDoingAuraStuff(dispatch, isDoing);
};

export const setCurrentPageToDisplaySign: any = (dispatch: any, page: number) =>{
  setCurrentPageToDisplay(dispatch, page);
};

export const setBDisplayErrorSign: any = (dispatch: any, bDisplay: boolean) =>{
  setBDisplayError(dispatch, bDisplay);
};

export const setBDisplayConfirmationSign: any = (dispatch: any, bDisplay: boolean) =>{
  setBDisplayConfirmation(dispatch, bDisplay);
};

export const setQueryParametersSign: any = (dispatch: any, params: boolean) =>{
  setQueryParameters(dispatch, params);
};

export const setBDisplaySignInputSign: any = (dispatch: any, bDisplay: boolean) =>{
  setBDisplaySignInput(dispatch, bDisplay);
};

export const setIsLoadingSign: any = (dispatch: any, loading: boolean) =>{
  setIsLoading(dispatch, loading);
};

export const setCheckboxSelectedSign: any = (dispatch: any, checked: boolean) =>{
  setCheckboxSelected(dispatch, checked);
};

export const setAuraXmlOutputSign: any = (dispatch: any, xmlOutput: string) =>{
  setAuraXmlOutput(dispatch, xmlOutput);
};

export const setDataToDisplaySign: any = (dispatch: any, data: any) =>{
  setDataToDisplay(dispatch, data);
};

export const setSignatureSign: any = (dispatch: any, signature: string) =>{
  setSignature(dispatch, signature);
};

export const setInputFormContentSign: any = (dispatch: any, inputResultList: inputResultList) =>{
  setInputFormContent(dispatch, inputResultList);
};
