import React from "react";
import Text from "@alj-react/ui-components/Text";
import { useIntl } from "react-intl";

import { Context } from '../../utils/ESignatureContext';
import { getComponent } from '../../utils/multi';

type DisplayFinishProps = {
  messages: any;
  restoreFormInputsToForm: () => void;
};

const DisplayFinish: React.FC<DisplayFinishProps> = (props) => {
  const { messages, restoreFormInputsToForm } = props;
  const { formatMessage } = useIntl();
  const { dispatch, state } = React.useContext(Context);

  return (
    <>
      {
        state.dataToDisplay?.CustomFinishPage?.length
        ? state.dataToDisplay.CustomFinishPage.map((pageContent: any, subIdx: number) => getComponent(state, dispatch, restoreFormInputsToForm, pageContent, 'page', state.currentPageToDisplay, subIdx, state.inputResultList))
        : (
          <>
            <Text color='axaBlue' size="28" style={{ textAlign: 'center', whiteSpace: 'pre-wrap' }}>{formatMessage(messages.thankYouTxt1)}</Text>
            <Text color='axaBlue' size="20" style={{ textAlign: 'center', whiteSpace: 'pre-wrap' }}>{formatMessage(messages.thankYouTxt2)}</Text>
          </>
        )
      }
    </>
  );
};

export default DisplayFinish;
