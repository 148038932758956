import React from "react";
import { Answer, MultipleChoiceQuestion } from "../../../apis";
import { AnswerPartContainer } from "../../layout/AnswerPartContainer";
import { QuestionCardContainer } from "../../layout/QuestionCardContainer";
import { MultipleChoiceCheckbox } from "../../molecules/MultipleChoiceCheckbox";
import { MultipleChoiceSelect } from "../../molecules/MultipleChoiceSelect";

type Props = {
  question: MultipleChoiceQuestion;
  index: string;
  subIndex?: string;
  answerQuestion: (answer: Answer) => Promise<void>;
  answerable: boolean;
  errorMsg?: string;
};

export const MultipleChoiceCard: React.FC<Props> = ({
  question,
  index,
  subIndex,
  answerQuestion,
  answerable,
  errorMsg,
}) => {
  const options = question.constraints.choices.map((choice) => ({
    value: choice.value,
    label: choice.text,
  }));

  const initChoices = question.answer?.answers.map((choice) => ({
    value: choice.value,
    label: choice.text,
  }));

  return (
    <QuestionCardContainer
      richQuestionText={question.richText}
      richHelpText={question.richHelpText}
      answerPart={
        <AnswerPartContainer>
          {question.constraints.componentType === "ListBoxResp" ? (
            <MultipleChoiceSelect
              options={options}
              answerQuestion={answerQuestion}
              initSelects={initChoices}
            />
          ) : (
            // CheckBoxResp
            <MultipleChoiceCheckbox
              options={options}
              answerQuestion={answerQuestion}
              initChecks={initChoices}
              answerable={answerable}
            />
          )}
        </AnswerPartContainer>
      }
      index={subIndex || index}
      errorMsg={errorMsg}
    />
  );
};
