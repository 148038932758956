import React from "react";
import styled from "styled-components";

type Props = {
  overall: number;
  now: number;
};

export const ProgressBar: React.FC<Props> = ({ overall, now }) => {
  const percentage = overall === 0 ? 0 : Math.round((now / overall) * 100);
  return (
    <Container>
      <BarContainer>
        <Bar percentage={percentage} />
      </BarContainer>
        {percentage}%
    </Container>
  );
};

const Container = styled.div`
  width: 50%;
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
  font-size: 8pt;
  font-weight: bold;
  text-align: center;
`

const BarContainer = styled.div`
  width: 100%;
  margin-right: 0.25rem;
  float: left;
  height: 0.8rem;
  position: relative;
  overflow: hidden;
  transition: all 0.5s;
  will-change: transform;
  border: 1px solid #d1d1d1;
  box-shadow: 0 0 5px #d1d1d1;
`;

const Bar = styled.div<{ percentage: number }>`
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #00008f;
  top: 0;
  bottom: 0;
  left: ${({ percentage }) => `-${100 - percentage}%`};
  border-radius: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
`;
