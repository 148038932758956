import React from "react";
import Text from "@alj-react/ui-components/Text";
import { useIntl } from "react-intl";

type DisplayErrorProps = {
  apiResult: any;
  messages: any;
};

const DisplayError: React.FC<DisplayErrorProps> = (props) => {
  const { messages, apiResult } = props;
  const { formatMessage } = useIntl();

  console.log(`apiResult: ${JSON.stringify(apiResult)}`);

  return (
    <>
      {!apiResult?.payload?.fetchFailed && <Text>{formatMessage(messages.errorTxt)}</Text>}
      <Text color="axaRed">{apiResult?.msg}</Text>
    </>
  );
};

export default DisplayError;
