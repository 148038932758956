/* eslint-disable global-require */
import React, { useState } from 'react';
import { IntlProvider } from 'react-intl';
import moment from 'moment';
import translations from './assets/i18n/locales/index';
import App from './App';
import ESignatureContext from './utils/ESignatureContext';

if (!Intl.PluralRules) {
  require('@formatjs/intl-pluralrules/polyfill');
  require('@formatjs/intl-pluralrules/dist/locale-data/ja');
  require('@formatjs/intl-pluralrules/dist/locale-data/en');
}
// @ts-ignore
if (!Intl.RelativeTimeFormat) {
  require('@formatjs/intl-relativetimeformat/polyfill');
  require('@formatjs/intl-relativetimeformat/dist/locale-data/ja');
  require('@formatjs/intl-relativetimeformat/dist/locale-data/en');
}

moment.locale('en');

const AppWrapper = () => {
  const [language] = useState('ja');
  const messages = translations[language];
  console.log(`Changed language to ${language}`);
  return (
    <IntlProvider locale={language} key={language} messages={messages}>
      <ESignatureContext>
        <App />
      </ESignatureContext>
    </IntlProvider>
  );
}

export default AppWrapper;
