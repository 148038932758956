import React from "react";
import { Table } from "../layout/Table";

type Props = {
  confirmation?: boolean;
};
export const Table5: React.FC<Props> = ({ confirmation }) => (
  <Table
    tableName={tableName}
    categoryDetails={tableCategoryDetails}
    confirmation={confirmation}
  />
);

const tableName = "表２";

const tableCategoryDetails = [
  [
    "胸部レントゲン検査",
    "胃部レントゲン検査（または内視鏡検査）",
    "乳房レントゲン検査（マンモグラフィ検査）",
    "便潜血検査（または大腸内視鏡検査）",
    "肝炎ウイルス検査（HBｓ抗原・HCV抗体）",
    "腫瘍マーカー（CEA・AFP・CA19-9・PSA等）",
    "腹部超音波検査",
    "乳房超音波検査",
    "CT検査",
    "MRI検査",
    "PET検査",
  ],
];
