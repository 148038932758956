import React from "react";
import { Answer, UnitizedChoiceQuestion } from "../../../apis";
import { AnswerPartContainer } from "../../layout/AnswerPartContainer";
import { QuestionCardContainer } from "../../layout/QuestionCardContainer";
import { UnitizedChoiceRadio } from "../../molecules/UnitizedChoiceRadio";
import { UnitizedChoiceSelect } from "../../molecules/UnitizedChoiceSelect";

type Props = {
  question: UnitizedChoiceQuestion;
  index: string;
  subIndex?: string;
  answerQuestion: (answer: Answer) => Promise<void>;
  answerable: boolean;
  errorMsg?: string;
};

export const UnitizedChoiceCard: React.FC<Props> = ({
  question,
  index,
  subIndex,
  answerQuestion,
  answerable,
  errorMsg,
}) => {
  const options = question.constraints.choices.map(({ text, value }) => ({
    label: text,
    value,
  }));
  const selected = question.answer && {
    label: question.answer.text,
    value: question.answer.value,
  };

  return (
    <QuestionCardContainer
      richQuestionText={question.richText}
      richHelpText={question.richHelpText}
      answerPart={
        <AnswerPartContainer>
          {question.constraints.componentType === "RadioResp" ? (
            <UnitizedChoiceRadio
              options={options}
              selected={selected}
              answerQuestion={answerQuestion}
              answerable={answerable}
            />
          ) : (
            <UnitizedChoiceSelect
              options={options}
              selected={selected}
              answerQuestion={answerQuestion}
            />
          )}
        </AnswerPartContainer>
      }
      index={subIndex || index}
      errorMsg={errorMsg}
    />
  );
};
