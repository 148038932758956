import DOMPurify from "dompurify";
import React from "react";
import { StatementQuestion } from "../../../apis";
import { Table1 } from "../../atoms/Table1";
import { Table2 } from "../../atoms/Table2";
import { Table3 } from "../../atoms/Table3";
import { Table4 } from "../../atoms/Table4";
import { Table5 } from "../../atoms/Table5";
import { Table6 } from "../../atoms/Table6";
import { Table7 } from "../../atoms/Table7";
import { QuestionCardContainer } from "../../layout/QuestionCardContainer";

type Props = {
  question: StatementQuestion;
};

export const StatementCard: React.FC<Props> = ({ question }) => (
  <QuestionCardContainer
    answerPart={(() => {
      if (question.text.startsWith("Table")) {
        switch (question.text) {
          case "Table1":
            return <Table1 />;
          case "Table2":
            return <Table2 />;
          case "Table3":
            return <Table3 />;
          case "Table4":
            return <Table4 />;
          case "Table5":
            return <Table5 />;
          case "Table6":
            return <Table6 />;
          case "Table7":
            return <Table7 />;
          default:
            return null;
        }
      }

      return (
        <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(question.richText),
          }}
        />
      );
    })()}
  />
);
