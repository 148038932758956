import React, { useEffect, useState } from "react";
import { Answer, TextQuestion } from "../../../apis";
import { TextArea } from "../../atoms/TextArea";
import { AnswerPartContainer } from "../../layout/AnswerPartContainer";
import { QuestionCardContainer } from "../../layout/QuestionCardContainer";

type Props = {
  question: TextQuestion;
  index: string;
  subIndex?: string;
  answerQuestion: (answer: Answer) => Promise<void>;
  errorMsg?: string;
  updateInputErrorCount: (key: number, count: number) => void;
  answerable: boolean;
};

export const TextCard: React.FC<Props> = ({
  question,
  index,
  subIndex,
  answerQuestion,
  errorMsg,
  updateInputErrorCount,
  answerable,
}) => {
  const [inputText, setInputText] = useState(question.answer?.value);
  const [validationErr, setValidationErr] = useState<string>();
  const invalidChars =
    "①②③④⑤⑥⑦⑧⑨⑩⑪⑫⑬⑭⑮⑯⑰⑱⑲⑳ Ⅰ Ⅱ Ⅲ Ⅳ Ⅴ Ⅵ Ⅶ Ⅷ Ⅸ Ⅹ ㍉㌔㌢㍍㌘㌧㌃㌶㍑㍗㌍㌦㌣㌫㍊㌻㎜㎝㎞㎎㎏㏄㎡㍻〝〟№㏍℡㊤㊥㊦㊧㊨㈱㈲㈹㍾㍽㍼";

  useEffect(
    () => updateInputErrorCount(question.id, validationErr ? 1 : 0),
    [validationErr]
  );

  return (
    <QuestionCardContainer
      richQuestionText={question.richText}
      richHelpText={question.richHelpText}
      answerPart={
        <AnswerPartContainer>
          <TextArea
            value={inputText}
            validationErr={validationErr}
            onChange={(e) => setInputText(e.target.value)}
            onBlur={(e) => {
              const inputVal = e.target.value;
              if (!inputVal) {
                setValidationErr("回答を入力してください。");
                return;
              }

              if (/[<>&"']+/.test(inputVal)) {
                setValidationErr(
                  "入力できない文字 < > & \" ' が含まれています。"
                );
                return;
              }
              if (new RegExp(`[${invalidChars}]+`).test(inputVal)) {
                setValidationErr(
                  `入力できない文字${invalidChars}が含まれています。`
                );
                return;
              }

              setValidationErr(undefined);
              const answer = {
                questionType: "TEXT",
                value: inputVal.replace(/(\r\n|\n\r|\r|\n)/g, " "),
              };

              answerQuestion({ answer });
            }}
            disabled={!answerable}
          />
        </AnswerPartContainer>
      }
      index={subIndex || index}
      errorMsg={errorMsg}
    />
  );
};
