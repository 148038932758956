export const isJSONObject = (input: any) => {
  if (typeof input !== 'string') return false;

  try {
    const parsedInput = JSON.parse(input);

    return typeof parsedInput === 'object';
  } catch (e) {
    return false;
  }
}
