import React from 'react';
import {
  Layout,
  Text,
} from '@alj-react/ui-components';

const NoRouteMatch = () => {
  return (
    <Layout>
      <Text size="24">404 Not Found</Text>
    </Layout>
  );
};

export default NoRouteMatch;
