import React from "react";
import Text from "@alj-react/ui-components/Text";
import { useIntl } from "react-intl";
import { Context } from '../../utils/ESignatureContext';

type PlanIdIsAlreadySignedProps = {
  messages: any;
};

const PlanIdIsAlreadySigned: React.FC<PlanIdIsAlreadySignedProps> = (props) => {
  const { messages } = props;
  const { formatMessage } = useIntl();
  const { state } = React.useContext(Context);

  return (
    <>
      <Text color='axaBlue' size="28" style={{ textAlign: 'center', whiteSpace: 'pre-wrap' }}>{formatMessage(messages.alreadySignedTxt, { planId: state.queryParameters.planId})}</Text>
      <Text color='axaBlue' size="20" style={{ textAlign: 'center', whiteSpace: 'pre-wrap' }}>{formatMessage(messages.thankYouTxt2)}</Text>
    </>
  );
};

export default PlanIdIsAlreadySigned;
