import { Button } from "@alj-react/ui-components";
import React from "react";
import styled from "styled-components";

type Props = {
  disabled: boolean;
  submitHandler: () => void;
  submitting: boolean;
};

export const SubmitButton: React.FC<Props> = ({
  disabled,
  submitHandler,
  children,
  submitting
}) => {
  return (
    <StyledButton disabled={disabled} onClick={submitHandler} loading={submitting} data-testid={'submitAura'}>
      {children}
    </StyledButton>
  );
};

const StyledButton = styled(Button)`
  border-radius: 0px;
  background-image: none;
  background-color: #00008f;
  text-shadow: none;
  border: unset !important;
  height: 46px;
  width: 100%;
  text-align: center;
  vertical-align: middle;
`;
