import React from 'react';
import { Layout } from '@alj-react/ui-components';
import { LayoutInner } from '@alj-react/ui-components/Layout/components'
import styled, { css } from 'styled-components';
import breakpoints from '@alj-react/ui-components/styles/breakpoints';

import AxaLogoType from '../atoms/AxaLogotypeSvg';

const LayoutStyled = styled(Layout)`
  ${breakpoints.largeOnly(css`
    ${LayoutInner} {
      padding: 14px 0;
    }
  `)}
  ${breakpoints.smallOnly(css`
    ${LayoutInner} {
      padding: 14px;
    }
  `)}
  ${breakpoints.mediumOnly(css`
    ${LayoutInner} {
      padding: 14px 40px;
    }
  `)}
`;

const HeaderSmall = () => {
  return (
    <div style={{borderBottom: '1px solid #e5e5e5'}}>
      <LayoutStyled>
      <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'/*, maxWidth: '1024px', margin: '0 auto'*/}}>
        <AxaLogoType />
      </div>
      </LayoutStyled>
    </div>
  );
}

export default HeaderSmall;
