import React from "react";
import Select from "react-select";
import { Answer } from "../../apis";

type Props = {
  options: Array<Option>;
  initSelect?: Option;
  answerQuestion: (answer: Answer) => Promise<void>;
};

export const DateChoiceSelect: React.FC<Props> = ({
  options,
  initSelect,
  answerQuestion,
}) => {
  return (
    <Select
      options={options}
      value={initSelect}
      isMulti={false}
      isSearchable={false}
      onChange={(e) => {
        const answer = {
          questionType: "DATE_CHOICE",
          value: (e as Option).value,
        };

        answerQuestion({ answer });
      }}
      placeholder={""}
    />
  );
};
